import { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'

import { IxIconButton } from '@siemens/ix-react'

import { useQueryTurnouts } from '@/features/assets/hooks/use-turnout'
import type { TrackLayer, TrackLayerItem } from '@/features/assets/models/track-layer'

import { editTrackLayerItemConfigurationRoute } from '@/features/config/track/routes/track'

import * as S from './styles'

export interface TrackLayerTileProps {
  active: boolean
  item: TrackLayerItem
  layer: TrackLayer
  onClick?: (item: TrackLayerItem) => void
}

export function TrackLayerTile({ active, item, layer, onClick }: TrackLayerTileProps): JSX.Element {
  const { t } = useTranslation()
  const { data: queryResponse } = useQueryTurnouts({ [`${layer.type}Name`]: item.name, size: 1 })

  return (
    <S.Tile active={active} onClick={onClick?.bind(null, item)}>
      <div className="d-flex flex-grow-1 align-items-center justify-content-between" slot="header">
        {item.name}

        <Link
          onClick={(event) => event.stopPropagation()}
          to={generatePath(editTrackLayerItemConfigurationRoute.pathname, {
            trackLayer: String(layer.depth),
            trackLayerItemId: String(item.id),
          })}
        >
          <IxIconButton ghost icon="pen" size="16" />
        </Link>
      </div>

      {item.technicalId && (
        <span>
          {t('Technical ID')}: {item.technicalId}
        </span>
      )}

      {queryResponse != null && <div>{t(`{count} turnouts`, { count: queryResponse.totalElements })}</div>}
    </S.Tile>
  )
}
