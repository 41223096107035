import { JSX, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { generatePath, ParamParseKey, useMatch, useNavigate, useParams } from 'react-router-dom'

import { GlobalPageHeader } from '@/core/components/GlobalPageHeader'
import { appTitle } from '@/core/lib/config'

import { TrackConfig } from '@/features/config/track/components/TrackConfig'
import {
  createTrackLayerItemConfigurationRoute,
  editTrackLayerConfigurationRoute,
  editTrackLayerItemConfigurationRoute,
  trackConfigurationRoute,
} from '@/features/config/track/routes/track'

export function TrackConfigurationPage(): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const createTrackLayerItemMatch = useMatch(createTrackLayerItemConfigurationRoute.pathname)
  const editTrackLayerItemMatch = useMatch(editTrackLayerItemConfigurationRoute.pathname)
  const editTrackLayerMatch = useMatch(editTrackLayerConfigurationRoute.pathname)

  const { trackLayer, trackLayerItemId } =
    useParams<ParamParseKey<typeof editTrackLayerItemConfigurationRoute.pathname>>()

  const trackLayerNum = useMemo(() => {
    if (trackLayer == null) {
      return undefined
    }

    const num = parseInt(trackLayer)

    if (Number.isNaN(num)) {
      navigate(generatePath(trackConfigurationRoute.pathname))
    }

    return num
  }, [navigate, trackLayer])

  return (
    <>
      <Helmet>
        <title>
          {appTitle} - {t(`routes.${trackConfigurationRoute.name}`)}
        </title>
      </Helmet>

      <GlobalPageHeader route={trackConfigurationRoute} title={t(`routes.${trackConfigurationRoute.name}`)}>
        <TrackConfig
          showCreateTrackLayerItem={createTrackLayerItemMatch != null}
          showEditTrackLayer={editTrackLayerMatch != null}
          showEditTrackLayerItem={editTrackLayerItemMatch != null}
          trackLayer={trackLayerNum}
          trackLayerItemId={trackLayerItemId}
        />
      </GlobalPageHeader>
    </>
  )
}
