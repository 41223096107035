import { useCallback, useEffect, useMemo, type ForwardedRef, type MutableRefObject, type RefCallback } from 'react'
import type { GridOptions } from 'ag-grid-community'
import type { AgGridReact } from 'ag-grid-react'

import { JSX as IxJSX } from '@siemens/ix/dist/types/components'

import type { TableProps } from '@/core/components/Table/Table'
import type { BaseModel, PagedResultType } from '@/core/models/base'

export const useSelectedIds = <T extends BaseModel>({
  selectedIds,
  pagedData,
  ref,
}: {
  selectedIds?: number[]
  pagedData?: PagedResultType<T>
  ref: MutableRefObject<AgGridReact<T> | null>
}) => {
  useEffect(() => {
    ref.current?.api?.forEachNode((node) => {
      node.setSelected(Boolean((selectedIds ?? []).find((id) => id === node.data?.id)))
    })
  }, [selectedIds, pagedData?.content, ref])
}

export const useTableRef = <T>({
  gridRef,
  ref,
}: {
  gridRef?: ForwardedRef<AgGridReact<T>>
  ref: MutableRefObject<AgGridReact<T> | null>
}) => {
  const tableRef = useCallback<RefCallback<AgGridReact<T>>>(
    (element) => {
      ref.current = element

      if (gridRef == null) {
        return
      }

      if (typeof gridRef === 'function') {
        return gridRef(element)
      }

      gridRef.current = element
    },
    [gridRef, ref],
  )
  
  return tableRef
}

export const useSelectionChangedInternal = <T extends BaseModel>({
  onSelectionChanged,
}: {
  onSelectionChanged?: GridOptions<T>['onSelectionChanged']
}) => {
  const onSelectionChangedInternal = useCallback<NonNullable<TableProps<T>['onSelectionChanged']>>(
    (event) => {
      if (event.source.startsWith('api')) {
        return
      }
      onSelectionChanged?.(event)
    },
    [onSelectionChanged],
  )
  return onSelectionChangedInternal
}

export const useResetPageOnDataChange = <T>({
  pagedData,
  resetPageOnDataChange,
  ref,
}: {
  pagedData?: PagedResultType<T>
  resetPageOnDataChange?: boolean
  ref: MutableRefObject<AgGridReact<T> | null>
}) => {
  useEffect(() => {
    if (resetPageOnDataChange) {
      ref.current?.api?.paginationGoToPage(0)
    }
  }, [resetPageOnDataChange, pagedData, ref])
}

export const useColumnDefsWithCheckbox = <T>({
  columnDefs,
  selectable,
}: {
  columnDefs: GridOptions<T>['columnDefs']
  selectable?: boolean | { columnIndex: number }
}) => {
  const columnDefsWithCheckbox = useMemo<GridOptions<T>['columnDefs']>(() => {
    if (columnDefs == null || !selectable) {
      return columnDefs
    }

    const exportableColumnIndex = typeof selectable === 'boolean' ? 0 : selectable.columnIndex

    return [
      ...columnDefs.slice(0, exportableColumnIndex),
      {
        ...columnDefs[exportableColumnIndex],
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
      ...columnDefs.slice(exportableColumnIndex + 1),
    ]
  }, [selectable, columnDefs])

  return columnDefsWithCheckbox
}

export const useOverlays = <T>({
  isLoading,
  pagedData,
  ref,
}: {
  isLoading?: boolean
  ref: MutableRefObject<AgGridReact<T> | null>
  pagedData?: PagedResultType<T>
}) => {
  useEffect(() => {
    if (isLoading) {
      return ref.current?.api?.showLoadingOverlay()
    }

    if (pagedData?.content != null && pagedData?.content.length === 0) {
      return ref.current?.api?.showNoRowsOverlay()
    }

    ref.current?.api?.hideOverlay()
  }, [isLoading, pagedData?.content, ref])
}

export const useOnPageSelected = <T>({
  ref,
  onPageSelected,
}: {
  ref: MutableRefObject<AgGridReact<T> | null>
  onPageSelected?: (pageIndex: number) => unknown
}) =>
  useCallback<NonNullable<IxJSX.IxPagination['onPageSelected']>>(
    (event) => {
      onPageSelected?.(event.detail)
      ref.current?.api?.paginationGoToPage(event.detail)
    },
    [onPageSelected, ref],
  )
