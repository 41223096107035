import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { DataFilter } from '@/core/components/DataFilter/DataFilter'

import { useTurntimeThresholdConfigStore } from '@/features/config/threshold/parameter/turntime/store/turntime-threshold-config-store'
import { usePointMachineSearchFields } from '@/features/assets/hooks/use-point-machine'

export const TurntimeThresholdFilter: FC = () => {
  const { t } = useTranslation()
  const filters = useTurntimeThresholdConfigStore((state) => state.filters)
  const setFilters = useTurntimeThresholdConfigStore((state) => state.setFilters)
  const baseSearchFields = usePointMachineSearchFields(filters)

  return (
    <DataFilter
      fields={[
        ...baseSearchFields,
        {
          icon: 'search',
          label: t('Search for turnout name'),
          path: 'turnoutName',
          type: 'text',
        },
      ]}
      onChange={setFilters}
      value={filters}
    />
  )
}
