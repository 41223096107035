import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Encoding } from 'vega-lite/build/src/encoding'
import { AnyMark } from 'vega-lite/build/src/mark'
import { GenericUnitSpec } from 'vega-lite/build/src/spec'

import {
  ConcatenatedIncidentChart,
  type ConcatenatedIncidentChartProps,
} from '@/core/components/ConcatenatedIncidentChart/ConcatenatedIncidentChart'

import type {
  HumidityAboveThresholdMeasurement,
  OutsideTemperatureMeasurement,
  SensorMeasurements,
  TrackTemperatureMeasurement,
} from '@/features/assets/models/sensor-measurements'

/**
 * DataPoint
 * Vega layer structure
 */
interface DataPoint {
  id: number
  start: string // "start" as field name is required! (powerLayer)
  type: string
  sensor?: 'Track' | 'Outside'
  value: number | string | boolean | null
}

export interface WeatherGraphProps extends Omit<ConcatenatedIncidentChartProps, 'height' | 'layers'> {
  height?: number
  /**
   * Mean power graph, which will be tunneled as an additionol layer to the Vega-Container.
   * - the powerLayer is taking care of the x-axis and the left y-axis
   */
  powerLayer: GenericUnitSpec<Encoding<string>, AnyMark>
  /**
   * This data will be split into multiple vega layer
   */
  measurements: SensorMeasurements
}

export const WeatherGraph: FC<WeatherGraphProps> = ({ height = 500, measurements, powerLayer, ...rest }) => {
  const { t } = useTranslation()

  /**
   * Track temperature layer data
   */
  const temperatureData: DataPoint[] = useMemo(() => {
    return measurements.trackTemperatureMeasurements.map((measure: TrackTemperatureMeasurement): DataPoint => {
      return {
        id: measure.id,
        start: measure.measuredAt,
        sensor: 'Track',
        type: 'Track',
        value: measure.measuredValue,
      }
    })
  }, [measurements.trackTemperatureMeasurements])

  /**
   * Outside temperature layer data
   */
  const outsideTemperatureData: DataPoint[] = useMemo(() => {
    return measurements.outsideTemperatureMeasurements.map((measure: OutsideTemperatureMeasurement): DataPoint => {
      return {
        id: measure.id,
        start: measure.measuredAt,
        sensor: 'Outside',
        type: 'Outside',
        value: measure.measuredValue,
      }
    })
  }, [measurements.outsideTemperatureMeasurements])

  /**
   * Humidity layer data
   */
  const humidityAboveThresholdData: DataPoint[] = useMemo(() => {
    return measurements.humidityAboveThresholdMeasurements
      .filter((measure: HumidityAboveThresholdMeasurement) => measure.measuredValue) // Show only "true" values
      .map((measure: HumidityAboveThresholdMeasurement): DataPoint => {
        return {
          id: measure.interlockingId,
          start: measure.measuredAt,
          type: 'Above',
          value: measure.measuredValue,
        }
      })
  }, [measurements.humidityAboveThresholdMeasurements])

  const tempDataValues = useMemo(() => {
    return [...outsideTemperatureData, ...temperatureData]
  }, [outsideTemperatureData, temperatureData])

  const tempLayer = useMemo((): GenericUnitSpec<Encoding<string>, AnyMark> => {
    return {
      data: {
        values: tempDataValues,
      },
      mark: { type: 'line' },
      encoding: {
        y: {
          field: 'value',
          type: 'quantitative',
          title: t('weatherGraph.axis.temperature'),
          scale: { domain: [-30, 80] },
        },
        opacity: { condition: { param: 'highlightTemp', value: 0.9 }, value: 0.2 },
        strokeDash: { field: 'sensor', title: 'Sensor', legend: null },
        color: {
          field: 'sensor',
          scale: { range: ['#0cc', '#ff7687'] },
          title: t('weatherGraph.legend.temperature'),
          legend: {
            labelExpr: `{'Track': '${t('weatherGraph.legend.track')}', 'Outside': '${t('weatherGraph.legend.outside')}'}[datum.label]`,
          },
        },
        tooltip: [
          { field: 'start', format: '%c', title: t('Timestamp'), type: 'temporal' },
          { field: 'type', title: t('weatherGraph.tooltip.sensor') },
          { field: 'value', title: t('weatherGraph.tooltip.temperature'), type: 'quantitative' },
        ],
      },
      params: [
        // highlight
        {
          bind: 'legend',
          name: 'highlightTemp',
          select: { type: 'point', fields: ['sensor'] },
        },
      ],
    }
  }, [t, tempDataValues])

  const humidityLayer = useMemo((): GenericUnitSpec<Encoding<string>, AnyMark> => {
    return {
      data: {
        values: [...humidityAboveThresholdData],
      },
      mark: { type: 'circle', size: 128, fill: 'transparent', stroke: 'orange' },
      encoding: {
        color: {
          field: 'value',
          title: t('weatherGraph.legend.humidity'),
          legend: {
            columns: 2,
            labelExpr: `{'true': '${t('weatherGraph.legend.humidityDetected')}', 'false': '${t('weatherGraph.legend.humidityNotDetected')}'}[datum.label]`,
          },
          sort: 'descending', // turn yes / no
        },
        opacity: { condition: { param: 'highlightHumidity', value: 0.9 }, value: 0.2 },
        tooltip: [
          { field: 'start', format: '%c', title: t('Timestamp'), type: 'temporal' },
          { field: 'id', title: 'ID' },
          { field: 'value', title: t('weatherGraph.tooltip.humidity'), type: 'quantitative' },
        ],
      },
      params: [
        // highlight
        {
          bind: 'legend',
          name: 'highlightHumidity',
          select: { type: 'point', fields: ['value'] },
        },
      ],
    }
  }, [t, humidityAboveThresholdData])

  const layers = useMemo(() => [powerLayer, tempLayer, humidityLayer], [powerLayer, tempLayer, humidityLayer])

  return <ConcatenatedIncidentChart height={height} layers={layers} {...rest} />
}
