import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as OrigLogo } from '@/images/logo.svg'

export const OsBarWrapper = styled.div`
  align-items: center;
  background: var(--theme-app-header--background);
  border-bottom: var(--theme-app-header--border-width) solid var(--theme-app-header--border-color);
  color: var(--theme-app-header--color);
  display: flex;
  height: 2rem;
  left: 0;
  padding: 0 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: calc(var(--theme-z-index-sticky) + 1);
`

export const Logo = styled(OrigLogo)`
  height: 20px;
  width: 120px;
`

export const MenuLink = styled(Link)`
  text-decoration: none;

  &&,
  &&&&:hover {
    color: var(--theme-color-std-text);
  }
`
