import { JSX } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useMatch } from 'react-router-dom'

import { GlobalPageHeader } from '@/core/components/GlobalPageHeader'
import { UserConfigTable } from '@/features/config/user/components/UserConfigTable'
import { appTitle } from '@/core/lib/config'
import { editUserConfigurationRoute, userConfigurationRoute } from '@/core/routes/user'

import { UpdateUserModal } from '@/features/config/user/components/UpdateUserModal'

export function UserConfigurationPage(): JSX.Element {
  const { t } = useTranslation()
  const editUserMatch = useMatch(editUserConfigurationRoute.pathname)

  return (
    <>
      <Helmet>
        <title>
          {appTitle} - {t(`routes.${userConfigurationRoute.name}`)}
        </title>
      </Helmet>

      <GlobalPageHeader route={userConfigurationRoute} title={t(`routes.${userConfigurationRoute.name}`)}>
        <UserConfigTable />

        <UpdateUserModal userId={editUserMatch?.params.userId as any} visible={editUserMatch != null} />
      </GlobalPageHeader>
    </>
  )
}
