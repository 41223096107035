import { useMemo } from 'react'

import { useTurntimeThresholdConfigStore } from '@/features/config/threshold/parameter/turntime/store/turntime-threshold-config-store'
import { useAnalyticsThresholds } from '@/features/config/threshold/parameter/turntime/hooks/use-analytics-threshold'
import { useTurnouts } from '@/features/assets/hooks/use-turnout'

export type MultipleValuesIndicatorDirection = { lower?: boolean; upper?: boolean }
export type MultipleValuesIndicator = {
  left: MultipleValuesIndicatorDirection
  right: MultipleValuesIndicatorDirection
}

export const useTurntimeThresholdEditModal = () => {
  const selectedTurnoutIds = useTurntimeThresholdConfigStore((state) => state.selectedTurnoutIds)
  const { isLoading: isTurnoutsLoading } = useTurnouts(null, selectedTurnoutIds)
  const { data: existingThresholds, isLoading: isThresholdsLoading } = useAnalyticsThresholds(selectedTurnoutIds)
  const initialThresholds = useMemo(() => existingThresholds?.at(0), [existingThresholds])
  const allLeftLowerEqual = useMemo(
    () =>
      existingThresholds?.every(
        (t) => t.left?.turnoverTimeLower === existingThresholds?.at(0)?.left?.turnoverTimeLower,
      ),
    [existingThresholds],
  )
  const allLeftUpperEqual = useMemo(
    () =>
      existingThresholds?.every(
        (t) => t.left?.turnoverTimeUpper === existingThresholds?.at(0)?.left?.turnoverTimeUpper,
      ),
    [existingThresholds],
  )
  const allRightLowerEqual = useMemo(
    () =>
      existingThresholds?.every(
        (t) => t.right?.turnoverTimeLower === existingThresholds?.at(0)?.right?.turnoverTimeLower,
      ),
    [existingThresholds],
  )
  const allRightUpperEqual = useMemo(
    () =>
      existingThresholds?.every(
        (t) => t.right?.turnoverTimeUpper === existingThresholds?.at(0)?.right?.turnoverTimeUpper,
      ),
    [existingThresholds],
  )

  const multipleValuesIndicator = useMemo<MultipleValuesIndicator>(
    () => ({
      left: {
        lower: !allLeftLowerEqual,
        upper: !allLeftUpperEqual,
      },
      right: {
        lower: !allRightLowerEqual,
        upper: !allRightUpperEqual,
      },
    }),
    [allLeftLowerEqual, allLeftUpperEqual, allRightLowerEqual, allRightUpperEqual],
  )

  return {
    selectedTurnoutIds,
    initialThresholds,
    multipleValuesIndicator,
    isTurnoutsLoading,
    isThresholdsLoading,
  }
}
