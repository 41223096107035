import { ApiClientResponse, BaseApiClient } from '@/core/api/base'
import { ProjectApiClient } from '@/core/api/project'
import { AnalyticsTypes, AnalyticsTypesCodec } from '@/core/models/analytics-types'
import { IdType } from '@/core/models/base'
import { Methods } from '@/core/models/http'
import { ProjectConfiguration, ProjectConfigurationCodec } from '@/core/models/project-configuration'
import {
  ProjectConfigurationChangeLog,
  ProjectConfigurationChangeLogsCodec,
} from '@/core/models/project-configuration-change-log'

class ProjectConfigurationApiClient extends BaseApiClient {
  async read(projectId: IdType, signal?: AbortSignal): Promise<ApiClientResponse<ProjectConfiguration>> {
    const path = [this.baseUrl, ProjectApiClient.endpointUrl, String(projectId), this.endpointUrl].join('/')
    const { data, ...rest } = await this.request(path, Methods.GET, undefined, undefined, signal)
    return { ...rest, data: await this.decode(data, ProjectConfigurationCodec) }
  }

  async update(
    projectId: IdType,
    value: Partial<ProjectConfiguration>,
    signal?: AbortSignal,
  ): Promise<ApiClientResponse<ProjectConfiguration>> {
    const path = [this.baseUrl, ProjectApiClient.endpointUrl, String(projectId), this.endpointUrl].join('/')
    const { data, ...rest } = await this.request(path, Methods.PATCH, value, undefined, signal)
    return { ...rest, data: await this.decode(data, ProjectConfigurationCodec) }
  }

  async getChangeLogs(
    projectId: IdType,
    signal?: AbortSignal,
  ): Promise<ApiClientResponse<ProjectConfigurationChangeLog[]>> {
    const path = [this.baseUrl, ProjectApiClient.endpointUrl, String(projectId), this.endpointUrl, 'change-logs'].join(
      '/',
    )
    const { data, ...rest } = await this.request(path, Methods.GET, undefined, undefined, signal)
    return { ...rest, data: await this.decode(data, ProjectConfigurationChangeLogsCodec) }
  }

  async getAnalyticsTypes(projectId: IdType, signal?: AbortSignal): Promise<ApiClientResponse<AnalyticsTypes>> {
    const path = [this.baseUrl, ProjectApiClient.endpointUrl, String(projectId), 'analytics-types'].join('/')
    const { data, ...rest } = await this.request(path, Methods.GET, undefined, undefined, signal)
    return { ...rest, data: await this.decode(data, AnalyticsTypesCodec) }
  }
}

const instance = new ProjectConfigurationApiClient('configurations')

export { instance as ProjectConfigurationApiClient }
