import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalV2 } from '@/core/components/ModalV2'

import { MaintenanceTaskModalContent } from '@/features/maintenance-reports/components/MaintenanceTaskModalContent'
import { MaintenanceTaskModalProvider } from '@/features/maintenance-reports/store/modal-store'

export const MaintenanceTaskCreateModal: FC<{ turnoutId?: number; onClose: () => void }> = ({ turnoutId, onClose }) => {
  const { t } = useTranslation()
  
  return (
    <ModalV2 title={t('assets.maintenance_reports.create.title')} onClose={onClose}>
      <MaintenanceTaskModalProvider task={{ turnoutId }}>
        <MaintenanceTaskModalContent onClose={onClose} />
      </MaintenanceTaskModalProvider>
    </ModalV2>
  )
}
