import { DateTime } from 'luxon'
import { Key, mutate } from 'swr'
import useSWRMutation, { SWRMutationResponse } from 'swr/mutation'

import { useSWR, type SWRResponse } from '@/core/hooks/swr'
import type { IdType } from '@/core/models/base'
import type { ServiceError } from '@/core/models/service-error'

import { AnalyticsThresholdApiClient } from '@/features/config/threshold/parameter/turntime/api/analytics-threshold'
import type {
  AnalyticsThreshold,
  AnalyticsThresholdWithTurnoutId,
  BaseAnalyticsThreshold,
} from '@/features/config/threshold/parameter/turntime/models/analytics-threshold'

export const DEFAULT_LOWER_THRESHOLD = 0.5
export const DEFAULT_UPPER_THRESHOLD = 0.5

const ANALYTICS_THRESHOLDS_BASE_KEY = 'useAnalyticsThresholds'

export const getAnalyticsThresholdsKey = (turnoutIds: IdType[]): Key => {
  return [ANALYTICS_THRESHOLDS_BASE_KEY, ...turnoutIds]
}

export const useAnalyticsThresholds = (turnoutIds: IdType[]): SWRResponse<AnalyticsThresholdWithTurnoutId[]> => {
  return useSWR(getAnalyticsThresholdsKey(turnoutIds), async () => {
    const responses = await Promise.all(
      turnoutIds.map((turnoutId) =>
        AnalyticsThresholdApiClient.list(turnoutId).then((res) => ({
          ...res,
          data: {
            turnoutId,
            left: res.data.find((d) => d.direction === 'left'),
            right: res.data.find((d) => d.direction === 'right'),
          } as AnalyticsThresholdWithTurnoutId,
        })),
      ),
    )
    const allEntries = responses.flatMap((entry) => entry.data)
    return { data: allEntries, dateTime: responses?.at(0)?.dateTime ?? DateTime.now() }
  })
}

interface UpdateThresholdsOpts {
  values: Array<BaseAnalyticsThreshold & { id?: IdType } & { turnoutId: IdType }>
}

export const useUpdateAnalyticsThresholds = (): SWRMutationResponse<
  Key[],
  ServiceError,
  UpdateThresholdsOpts,
  'useUpdateAnalyticsThresholds'
> => {
  return useSWRMutation('useUpdateAnalyticsThresholds', async (_, { arg: { values } }) => {
    await Promise.all(
      values.map((threshold) => {
        if (threshold.id === undefined) {
          return AnalyticsThresholdApiClient.create(threshold, threshold.turnoutId)
        }

        if (threshold.turnoverTimeLower === undefined && threshold.turnoverTimeUpper === undefined) {
          return AnalyticsThresholdApiClient.delete(threshold.id)
        } else {
          return AnalyticsThresholdApiClient.update(threshold as AnalyticsThreshold)
        }
      }),
    )
    //refresh all analytics thresholds, no matter what turnoutId combination
    return mutate((key) => Array.isArray(key) && key.at(0) === ANALYTICS_THRESHOLDS_BASE_KEY)
  })
}

export const useResetAnalyticsThresholds = (
  turnoutIds: IdType[],
): SWRMutationResponse<void, ServiceError, void, Key> => {
  const { trigger } = useUpdateAnalyticsThresholds()
  const { data: thresholds } = useAnalyticsThresholds(turnoutIds)

  return useSWRMutation('useResetAnalyticsThresholds', async (_) => {
    await trigger({
      values: turnoutIds.flatMap((turnoutId) => [
        {
          id: thresholds?.find((t) => t.turnoutId === turnoutId)?.left?.id,
          direction: 'left',
          turnoverTimeLower: DEFAULT_LOWER_THRESHOLD,
          turnoverTimeUpper: DEFAULT_UPPER_THRESHOLD,
          turnoutId,
        },
        {
          id: thresholds?.find((t) => t.turnoutId === turnoutId)?.right?.id,
          direction: 'right',
          turnoverTimeLower: DEFAULT_LOWER_THRESHOLD,
          turnoverTimeUpper: DEFAULT_UPPER_THRESHOLD,
          turnoutId,
        },
      ]),
    })
  })
}
