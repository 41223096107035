import { JSX } from 'react'
import { Helmet } from 'react-helmet-async'
import { Trans, useTranslation } from 'react-i18next'

import { IxBlind, IxTypography } from '@siemens/ix-react'

import { GlobalPageHeader } from '@/core/components/GlobalPageHeader'
import { appTitle } from '@/core/lib/config'
import { faqRoute } from '@/core/routes/faq'

import en from '@/i18n/lang/en'

export function FaqPage(): JSX.Element {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>
          {appTitle} - {t(`routes.${faqRoute.name}`)}
        </title>
      </Helmet>

      <GlobalPageHeader route={faqRoute} title={t(`routes.${faqRoute.name}`)}>
        <div className="d-flex flex-column gap-4">
          {en.translation.FAQ.map((category, categoryIndex) => (
            <div className="d-flex flex-column gap-2">
              <IxTypography format="h3" textColor='soft'>{t(`FAQ.${categoryIndex}.title`)}</IxTypography>
              {category.questions.map(({ question }, questionIndex) => (
                <IxBlind collapsed key={question} label={t(`FAQ.${categoryIndex}.questions.${questionIndex}.question`)}>
                  <Trans
                    components={{
                      li: <li />,
                      p: <p />,
                      ul: <ul />,
                    }}
                    i18nKey={`FAQ.${categoryIndex}.questions.${questionIndex}.answer`}
                  />
                </IxBlind>
              ))}
            </div>
          ))}
        </div>
      </GlobalPageHeader>
    </>
  )
}
