import { useMemo } from 'react'
import { Key } from 'swr'
import useSWRMutation, { SWRMutationResponse } from 'swr/mutation'

import { useSWR, type SWRResponse } from '@/core/hooks/swr'
import { useMainProject } from '@/core/hooks/use-project'
import type { IdType } from '@/core/models/base'
import type { ServiceError } from '@/core/models/service-error'

import {
  PointMachineEventApiClient,
  type QueryPointMachineEventsFilterOpts,
} from '@/features/assets/api/point-machine-event'
import { PointMachineEventWithEstimatedWeatherDataApiClient } from '@/features/assets/api/point-machine-event-with-estimated-weather-data'
import { usePointMachines } from '@/features/assets/hooks/use-point-machine'
import type { PagedPointMachineEvents, RawPointMachineEvent } from '@/features/assets/models/point-machine-event'

export const getQueryPointMachineEventsKey = (
  opts: Parameters<typeof useQueryPointMachineEvents>[0],
  parentId?: IdType,
): Key => {
  if (parentId == null) {
    return null
  }

  return ['useQueryPointMachineEvents', parentId, JSON.stringify(opts)]
}

export const useQueryPointMachineEvents = (
  opts?: Partial<QueryPointMachineEventsFilterOpts> | null,
): SWRResponse<PagedPointMachineEvents> => {
  const mainProject = useMainProject()

  const swrResponse = useSWR(getQueryPointMachineEventsKey(opts, mainProject?.id), () => {
    return PointMachineEventWithEstimatedWeatherDataApiClient.query(mainProject!.id, opts)
  })

  const parentIds = useMemo(() => {
    return swrResponse.data?.content.map((entry) => entry.pointMachineId)
  }, [swrResponse.data])

  const parentSwrResponse = usePointMachines(null, parentIds)

  const data = useMemo<PagedPointMachineEvents | undefined>(() => {
    if (swrResponse.data == null) {
      return swrResponse.data
    }

    return {
      ...swrResponse.data,
      content: swrResponse.data.content.map((entry) => ({
        ...entry,
        pointMachine: parentSwrResponse.data?.find((parent) => parent.id === entry.pointMachineId),
      })),
    }
  }, [parentSwrResponse.data, swrResponse.data])

  return useMemo(() => {
    return {
      ...swrResponse,
      data,
      isLoading: swrResponse.isLoading || parentSwrResponse.isLoading,
      isValidating: swrResponse.isValidating || parentSwrResponse.isValidating,
    }
  }, [data, parentSwrResponse.isLoading, parentSwrResponse.isValidating, swrResponse])
}

export const useUpdatePointMachineEvents = (): SWRMutationResponse<
  RawPointMachineEvent[],
  ServiceError,
  RawPointMachineEvent[],
  Key
> => {
  return useSWRMutation('useUpdatePointMachineEvents', async (_, { arg }) => {
    const promises = arg.map(async (entry) => {
      const { data: updatedModel } = await PointMachineEventApiClient.update(entry)
      return updatedModel
    })

    const updatedModels = await Promise.all(promises)
    return updatedModels
  })
}
