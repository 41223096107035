import { I18nKeys } from '@/i18n/i18n-keys'

const en: I18nKeys = {
  translation: {
    language: 'English',
    auth: {
      login: 'Login',
      logout: 'Logout',
    },
    routes: {
      assets: 'Assets',
      maintenanceReports: 'Maintenance reports',
      Dashboard: 'Dashboard',
      Home: 'Home',
      Settings: 'Settings',
      FAQ: 'FAQ',

      Turnout: 'Turnout',
      Turnouts: 'Turnouts',

      Alarms: 'Alarms',

      Configuration: 'Config',

      'Track configuration': 'IXL structure',
      'Edit layer configuration': 'Edit layer',
      'Edit layer item configuration': 'Edit layer item',
      'Create layer item configuration': 'Create layer item',

      'Asset configuration': 'Assets',
      'Create asset configuration': 'Create asset',
      'Edit asset configuration': 'Edit asset',

      'Threshold configuration': 'Thresholds',
      'Alarm thresholds': 'Alarm thresholds',
      'Parameter thresholds': 'Parameter thresholds',
      'Reference curves': 'Reference curves',

      'User configuration': 'User',
      'Edit user configuration': 'Edit user',

      'Sample Entries': 'Sample Entries',
      'Sample Entry': 'Sample Entry',
      'Create Sample Entry': 'Create Sample Entry',
    },
    dashboard: {
      create_new_report: 'New maintenance report',
    },
    config: {
      thresholds: {
        parameter: {
          turntime: {
            title: 'Analysetyp Umlaufzeit',
          },
          meanpower: {
            title: 'Analysetyp mittlere Leistung',
          },
          ai: {
            title: 'Analysetyp AI',
            'no-config-necessary': 'No configuration necessary for powercurve AI model.',
          },
        },
      },
    },
    models: {
      event: {
        direction: 'Direction',
        'direction-left': 'Left',
        'direction-right': 'Right',
        startTime: 'Start',
        duration: 'Duration',
        meanPower: 'Mean power',
        outsideTemperature: 'Outside',
        trackTemperature: 'Track',
        humidityDetected: 'Humidity',
      },

      alarmEvent: {
        severity: 'Severity',

        'severity-failure': 'Failure',
        'severity-failure_one': 'Failure',
        'severity-failure_other': 'Failures',
        'severity-failure-with-count_one': '{{count}} failure',
        'severity-failure-with-count_other': '{{count}} failures',

        'severity-warning': 'Warning',
        'severity-warning_one': 'Warning',
        'severity-warning_other': 'Warnings',
        'severity-warning-with-count_one': '{{count}} warning',
        'severity-warning-with-count_other': '{{count}} warnings',

        state: 'State',
        'state-active': 'Active',
        'state-resolved': 'Recovered',

        type: 'Alarm type',
        'type-connection_lost': 'Connection lost',
        'type-condition_degradation': 'Condition degradation',
      },

      turnout: {
        state: 'Health status',
        'state-healthy': 'Healthy',
        'state-alert': 'Failure',
        'state-warning': 'Warning',
        'state-connected': 'Connected',
        'state-disconnected': 'Connection lost',
        connectionState: 'Connection state',
        active: 'Active',
        'activity-status': 'Activity status',
        'model-type': 'Analytics type',
        trackPoint: 'Turnout heating group',
        turnoutFunctionalityType: 'Device',
        'turnoutFunctionalityType-turnout': 'Turnout',
        'turnoutFunctionalityType-derail': 'Derail',
        waypoint: 'Kilometer point along track',
        analyticsTypes: {
          turntime_expertsystem: 'Expert turnover time',
          meanpower_expertsystem: 'Expert meanpower',
          powercurve_aimodel: 'AI power curve',
        },
      },

      healthState: {
        healthState: 'Health status',
        score: 'Turnout Health Score',
      },

      pointMachine: {
        thresholdConfiguration: 'Threshold configuration',
        'thresholdConfiguration-complete': 'complete',
        'thresholdConfiguration-incomplete': 'incomplete',
      },
    },
    assets: {
      maintenance_reports: {
        create: {
          title: 'New maintenance report',
          create_maintenance_report_for: 'Create maintenance report for:',
          filter: {
            select_area_short: 'Select area',
            select_area_long: 'Please select an area',
            select_interlocking_short: 'Select interlocking',
            select_interlocking_long: 'Please select an interlocking',
            select_technical_room_short: 'Select technical room',
            select_technical_room_long: 'Please select a technical room',
            select_turnout_short: 'Select turnout',
            select_turnout_long: 'Please select a turnout',
          },
          reason: 'Select reason for maintenance:',
          work: 'What was done (optional):',
          comment: {
            title: 'Comment (optional):',
            placeholder: 'Did you notice anything special? Enter text (max. 240 characters)',
          },
          save: 'Save',
          cancel: 'Cancel',
        },
        view: {
          title: 'Maintenance report',
          maintenance_report_for: 'Maintenance report for:',
          reason: 'Reason for maintenance:',
          work: 'What was done:',
          comment: 'Kommentar:',
          close: 'Close',
        },
        reason: {
          mandatory_manufacturer: 'Specified manufacturer',
          mandatory_operator: 'Specified MGB',
          incident: 'Incident',
        },
        work: {
          readjust: 'Readjust',
          replace: 'Replace',
          lubricate: 'Lubricate',
          check: 'Check',
          clean: 'Clean',
          maintenance_action: 'Maintenance action',
          enter_free_text: 'Enter free text',
        },
        doneAt: 'Done at',
        table: {
          title: 'Maintenance reports',
          turnout: 'Turnout',
          doneAt: 'Date & time',
          reason: 'Reason',
          work: 'What was done',
          comment: 'Comment',
        },
        cta: {
          create_new_report: 'Create report',
          download: 'Download',
        },
      },
      modal: {
        turnout: {
          title: 'Edit turnout device',
        },
        derail: {
          title: 'Edit derailing device',
        },
      },
      steps: {
        step1: 'Choose Asset Type',
        step2: 'Create Asset',
        step3: 'Add Point Machine(s)',
      },
      selection: {
        turnout: {
          heading: 'Add turnout device',
          subheading: 'Create a device of the type "turnout"',
        },
        derail: {
          heading: 'Add derailing device',
          subheading: 'Create a device of the type "derailment"',
        },
      },
      create: {
        description: {
          analyticsType:
            'The selection of the monitoring model type determines how the health status of a turnout is calculated.<br/><a href="{{url}}" target="{{target}}">More Information</a>',
          turntime_expertsystem:
            'The expert system takes as input reference values and current turnout times to calculate an overall healthscore.',
          meanpower_expertsystem:
            'The expert system takes as input reference curves and current power curves to calculate an overall healthscore.',
          powercurve_aimodel:
            'The AI model takes as input the current and historical power curves to calculate the healthscore directly (0 .. 1)',
          status:
            'If you select "Inactive" the turnout will not be listed in the turnouts overview and in the healths status overview.',
        },
        turnout: {
          heading: 'Create new turnout device',
          created: 'Turnout <strong>"{{name}}\n</strong> created successfully.',
        },
        derail: {
          heading: 'Create new derailing device',
          created: 'Derailing device <strong>"{{name}}\n</strong> created successfully.',
        },
        pointMachines: {
          heading: 'Add point machines',
        },
      },
      cta: {
        save_and_activate: 'Save and activate',
        save: 'Save without activation',
      },
      info: {
        PM_required:
          'IF you select a model for power curve monitoring, it is mandatory to create point machines and the turnout has to be equipped with SidisW (because the mean power of all point machines of the turnout is needed for the health score calculation).',
        PM_required_tooltip: 'You need to add a point machine',
      },
      analyticsType: {
        info: {
          turntime_expertsystem: 'Expert turnover time',
          turntime_expertsystem_info: 'Adding a point machine is optional.',
          powercurve_aimodel:
            'If you select a model for "power curve" monitoring, it is mandatory to <b>create point machines on the next step</b> and the turnout has to be equipped with SidisW (because the mean power of all point machines of the turnout is needed for the health score calculation).',
          meanpower_expertsystem:
            'If you select a model for "expert meanpower" monitoring, it is mandatory to <b>create point machines on the next step</b> and the turnout has to be equipped with SidisW (because the mean power of all point machines of the turnout is needed for the health score calculation).',
          PM_required:
            'It is mandatory to create point machines and the turnout has to be equipped with SidisW (because the mean power of all point machines of the turnout is needed for the health score calculation).',
        },
      },
      details: {
        event: 'Point machine event',
        duration_fitness: 'Duration & fitness',
        temperature: 'Temperature & humidity',
        mark: 'Mark as',
      },
    },

    charts: {
      legend: {
        events: 'Events',
        alarms: 'Alarms',
        maintenance: 'Maintenance',
      },
      tooltip: {
        maintenance: {
          reason: 'Reason',
          doneAt: 'Done at',
          work: 'Tasks',
          comment: 'Comment',
        },
      },
    },

    A: 'A',
    'A failure alarm is triggered if the health score for a turnout falls below the defined alarm threshold.':
      'A failure alarm is triggered if the health score for a turnout falls below the defined alarm threshold.',
    'A warning alarm is triggered if the health score for a turnout falls below the defined warning threshold.':
      'A warning alarm is triggered if the health score for a turnout falls below the defined warning threshold.',
    About: 'About',
    'Account management': 'Account management',
    Actions: 'Actions',
    'Active alarms': 'Active alarms',
    Active: 'Active',
    'Add {item}': 'Add {{item}}',
    'Alarm ID': 'Alarm ID',
    'Alarms for': 'Alarms for',
    'Alarms overview': 'Alarms overview',
    'Alarms recovered last 24h': 'Alarms recovered last 24h',
    'Alarms since installation': 'Alarms since installation',
    'Alarms triggered last 24h': 'Alarms triggered last 24h',
    AlarmThresholdConfig:
      "<p>The health status of a turnout is defined by it's health score, a range between 0 and 1 (0 = high risk of failure , 1 = optimal functionality).</p><p>The health score of a turnout is accumulated over the continuously calculated fitness scores of all turn events of the point machines belonging to that turnout.</p><p>The fitness score is based on three performance parameters (if available): time in Seconds, power in Watts and work in Joule/WattSeconds.</p><p>An alarm is triggered if the health score for a turnout falls below the defined thresholds configured by the admin user.</p><p>There are two different alarm types:</p><ul><li>Yellow alarm is a warning alarm, meaning that the condition of the turnout is showing a critical deterioration and attention should be payed to avoid failure.</li><li>Red alarm is a failure alarm, meaning the condition is critical and operation likely to fail.</li></ul>",
    'App settings': 'App settings',
    'App version': 'App version',
    'Are you sure?': 'Are you sure?',
    'Average turn time 24h': 'Average turn time 24h',

    B: 'B',
    'Back home': 'Back home',
    'Build timestamp': 'Build timestamp',
    'Bulk edit': 'Bulk edit',
    'Bulk edit turnouts': 'Bulk edit turnouts',

    C: 'C',
    '{count} turnouts_one': '{{count}} turnout',
    '{count} turnouts_other': '{{count}} turnouts',
    Cancel: 'Cancel',
    'Change e-mail address': 'Change e-mail address',
    Changelog: 'Changelog',
    'Click on the following link to:': 'Click on the following link to:',
    'Commit-ID': 'Commit-ID',
    'Configure thresholds for alarm types': 'Configure thresholds for alarm types',
    'Configure thresholds for performance parameters': 'Configure thresholds for performance parameters',
    Configure: 'Configure',
    'Corporate information': 'Corporate information',
    'Corrected power': 'Corrected power',
    Count: 'Count',
    'Create point machine': 'Add point machine',
    Custom: 'Custom',

    D: 'D',
    'Data privacy': 'Data privacy',
    Date: 'Date',
    'Delete point machine': 'Delete point machine',
    'Delete reference curve_one': 'Delete reference curve',
    'Delete reference curve_other': 'Delete reference curves',
    'Delete reference curve': 'Delete reference curve',
    'Delete turnout_one': 'Delete asset',
    'Delete turnout_other': 'Delete assets',
    'Delete turnout': 'Delete asset',
    'Delete pointmachines': 'All connected point machines are also deleted.',
    'Deleted at': 'Deleted at',
    'Deleted by': 'Deleted by',
    Description: 'Description',
    Done: 'Done',

    E: 'E',
    'Edit {name}': 'Edit {{name}}',
    'Edit point machines': 'Edit point machines',
    'End time': 'End time',
    'Error while loading last status': 'Error while loading last status',
    Export: 'Export',

    F: 'F',
    'Failure alarm': 'Failure alarm',
    Filter: 'Filter',
    'Fitness score': 'Fitness score',
    'For example, if the threshold for mean power difference is set at 300 Watts, it means that as soon as the turn event mean power exceeds the optimal turn event power (provided by optimal reference curves) by 300 Watts, the fitness score of this turn event is voting towards a potential alarm.':
      'For example, if the threshold for mean power difference is set at 300 Watts, it means that as soon as the turn event mean power exceeds the optimal turn event power (provided by optimal reference curves) by 300 Watts, the fitness score of this turn event is voting towards a potential alarm.',
    'For example, if the threshold for time difference is set at 0,5 sec, it means that as soon as the turn time exceeds the optimal turn time (provided by optimal reference curves) by 0,5 sec, the fitness score of this turn event is voting towards a potential alarm.':
      'For example, if the threshold for time difference is set at 0,5 sec, it means that as soon as the turn time exceeds the optimal turn time (provided by optimal reference curves) by 0,5 sec, the fitness score of this turn event is voting towards a potential alarm.',
    'For example, if the threshold for work difference is set at 500 Joule, it means that as soon as the turn event work exceeds the optimal turn event work (provided by optimal reference curves) by 500 Joule, the fitness score of this turn event is voting towards a potential alarm.':
      'For example, if the threshold for work difference is set at 500 Joule, it means that as soon as the turn event work exceeds the optimal turn event work (provided by optimal reference curves) by 500 Joule, the fitness score of this turn event is voting towards a potential alarm.',

    G: 'G',
    General: 'General',

    H: 'H',
    'Health score': 'Health score',
    'Health score at time of alarm': 'Health score at time of alarm',
    'Health score below {threshold}': 'Health score below {{threshold}}',
    'Health status history': 'Health status history',
    'Health status of turnouts': 'Health status of turnouts',

    I: 'I',
    'If the deviation threshold is low, the measured performance of a turn event has to be be within this narrow range to not cause a deterioration of the health score. The lower the deviation threshold, the more sensitive is the system towards alarm generation.':
      'If the deviation threshold is low, the measured performance of a turn event has to be be within this narrow range to not cause a deterioration of the health score. The lower the deviation threshold, the more sensitive is the system towards alarm generation.',
    'Installation date': 'Installation date',

    J: 'J',

    K: 'K',

    L: 'L',
    Language: 'Language',
    'Last 24h': 'Last 24h',
    'Last 3d': 'Last 3d',
    'Last 7d': 'Last 7d',
    'Last 30d': 'Last 30d',
    'Last connection': 'Last connection',
    'Last status not found': 'Last status not found',
    'Last status received': 'Last status received',
    'Last update': 'Last update',
    Layer: 'Layer',
    Loading: 'Loading',

    M: 'M',
    'Manage reference curves': 'Manage reference curves',
    'Mark as "Outlier"': 'Mark as "Outlier"',
    'Mark as "Reference"': 'Mark as "Reference"',
    'Maximum number of entries exceeded. Not all entries are being displayed.':
      '<b>Please note:</b><br/>There are too many events for the selected filter criteria to be displayed - sorry!<br/>Please change the filter settings. For example select only one Point Machine, only one direction or a shorter time range. Thank you!',
    'Mean power & trend': 'Mean power & trend',
    'Mean power': 'Mean power',

    N: 'N',
    Name: 'Name',
    Next: 'Next',
    'No active alarms': 'No active alarms',
    'No alarms in the last {{time}}': 'No alarms in the last {{time}}',
    'No data': 'No data',
    'No range set': 'No range set',
    'No recovered alarms': 'No recovered alarms',
    'Nr of total turn events': 'Nr of total turn events',
    'Number of triggered alarms': 'Number of triggered alarms',

    O: 'O',
    Okay: 'Okay',
    'Once you have successfully entered all mandatory information about the point machine(s) that constitute this turnout, the connection to CSC will be established as soon as possible.':
      'Once you have successfully entered all mandatory information about the point machine(s) that constitute this turnout, the connection to CSC will be established as soon as possible.',
    'Overview all turnouts': 'Overview all turnouts',
    'Overview turnout condition': 'Overview turnout condition',

    P: 'P',
    ParameterThresholdConfig:
      '<p>For each performance parameter, the admin user can configure a deviation threshold. It defines how much the current performance of a turn event is allowed to deviate from the optimal performance for that point machine. The optimal performance is calculated based on the reference curves selected by the admin user.</p><p>If the deviation is within the threshold area, the performance is uncritical. If the deviation is higher than the deviation threshold allows, the performance is assumed to be critical.</p><p>The deviation value impacts the fitness score of a turn event and hence also (albeit with lesser direct impact) the health score of the turnout.</p>',
    Parent: 'Parent',
    'Performance data': 'Performance data',
    'PM{{index}} name': 'PM{{index}} name',
    'PM1 ID': 'PM1 ID',
    'PM2 ID': 'PM2 ID',
    'Point machine info': 'Point machine info',
    'Point machine_one': 'Point machine',
    'Point machine_other': 'Point machines',
    'Point machine': 'Point machine',
    Position: 'Position',
    Power_curves_table_headline_zero: 'Power curves displayed',
    Power_curves_table_headline_one: 'Power curves displayed<i> - one row selected</i>',
    Power_curves_table_headline_other: 'Power curves displayed<i> - {{count}} rows selected</i>',
    'Power curves': 'Power curves',

    Q: 'Q',

    R: 'R',
    'Required field': 'Required field',
    'Reset to default': 'Reset to default',

    S: 'S',
    'Save layer item': 'Save layer item',
    'Save layer': 'Save layer',
    'Save point machine': 'Save point machine',
    'Save thresholds': 'Save thresholds',
    'Save turnout_one': 'Save turnout',
    'Save turnout_other': 'Save turnouts',
    'Save turnout': 'Save turnout',
    'Save user': 'Save user',
    'Search for PM ID': 'Search for PM ID',
    'Search for turnout ID or PM ID': 'Search for turnout ID or PM ID',
    'Search for turnout name': 'Search for turnout name',
    'Serial number': 'Serial number',
    'Show all': 'Show all',
    'Sorry, the page you visited does not exist.': 'Sorry, the page you visited does not exist.',
    'Start time': 'Start time',
    Submit: 'Submit',
    'Successfully updated event': 'Successfully updated event',
    'Successfully updated thresholds': 'Successfully updated thresholds',
    Support: 'Support',

    T: 'T',
    'Technical ID': 'Technical ID',
    'Technical room': 'Technical room',
    'Terms of use': 'Terms of use',
    Theme: 'Theme',
    Threshold: 'Threshold',
    'Thresholds left': 'Thresholds left',
    'Thresholds right': 'Thresholds right',
    Timestamp: 'Timestamp',
    Timezone: 'Timezone',
    Total: 'Total',
    Trend: 'Trend',
    'Turn event': 'Turn event',
    'Turn time': 'Turn time',
    'Turnout condition': 'Turnout condition',
    'Turnout configuration status': 'Turnout configuration status',
    'Turnout health score': 'Turnout health score',
    'Turnout ID': 'Turnout ID',
    'Turnout name': 'Turnout name',
    Type: 'Type',

    U: 'U',
    "Uncheck this box if you don't want to approve of the automatic connection to CSC.":
      "Uncheck this box if you don't want to approve of the automatic connection to CSC.",
    'Unmark as "Reference"': 'Unmark as "Reference"',
    'Update display name': 'Update display name',
    'Update password': 'Update password',
    'Updating event was unsuccessful': 'Updating event was unsuccessful',
    'Updating event': 'Updating event',
    'Updating thresholds was unsuccessful': 'Updating thresholds was unsuccessful',
    'Updating thresholds': 'Updating thresholds',
    'User account settings': 'User account settings',
    'User name': 'User name',
    User: 'User',

    V: 'V',
    'Value changed from': 'Value changed from',
    'Value changed to': 'Value changed to',

    W: 'W',
    'Warning alarm': 'Warning alarm',
    'Weather data': 'Mean power & weather',

    weatherGraph: {
      axis: {
        temperature: 'Celsius',
      },
      legend: {
        temperature: 'Temperature Curves',
        humidity: 'Humidity',
        track: 'Track',
        outside: 'Outside',
        humidityDetected: 'detected',
        humidityNotDetected: 'No',
      },
      tooltip: {
        sensor: 'Sensor',
        temperature: 'Temperature',
        humidity: 'Humidity detected',
      },
    },

    X: 'X',

    Y: 'Y',

    Z: 'Z',

    FAQ: [
      {
        title: 'Alarms',
        questions: [
          {
            question: 'How does the alarm system work?',
            answer:
              '<p>There are two different alarm types:</p><ul><li>Yellow alarm is a warning alarm, meaning that the condition of the turnout is showing a critical deterioration and attention should be payed to avoid failure.</li><li>Red alarm is a failure alarm, meaning the condition is critical and operation likely to fail.</li></ul><p>An alarm is triggered if the health score for a turnout falls below a threshold configured by the admin user.</p>',
          },
        ],
      },
      {
        title: 'Health status & fitness score',
        questions: [
          {
            question: 'How does the health status system work?',
            answer:
              '<p>Each turnout has a health status, green meaning healthy, yellow meaning warning, red meaning high risk to fail. It stands for all point machines of the turnout.</p><p>The health status is defined by the turnout health score, a range between 0 and 1 (0 = high risk of failure , 1 = optimal functionality).</p><p>The health score of a turnout is accumulated over the continuously calculated fitness scores of all turn events of the point machines belonging to that turnout.</p><p>The fitness score is based one three performance parameters: time in Seconds, mean power in Watts and work in Joule.</p>',
          },
          {
            question: 'How is the fitness score per turnover calculated?',
            answer:
              '<p>The turnover fitness score of a turnout depends on how much the performance deviates from the configured thresholds.</p><p>For each performance parameter, the admin user can configure a deviation threshold.</p><p>It defines how much the performance of a turn event can vary/deviate from the optimal performance for that point machine, The optimal performance is calculated based on the reference curves selected by the admin user.</p><p>If the deviation is within the threshold area, it means the performance is uncritical. If the deviation is higher than the deviation threshold allows, it means the performance is assumed to be critical.</p>',
          },
          {
            question: 'How can the admin user influence the fitness score per turnover?',
            answer:
              '<p>The admin user configures the deviation thresholds for the performance parameters time, mean power and work. By this, s/he determines:</p><ul><li>If the deviation threshold is low, the performance of the turn events must be very close to the reference turn event curves.</li><li>If the deviation threshold is high, it allows for a greater scope. The higher the deviation value is set, the less sensitive is the setup to trigger alarms.</li></ul>',
          },
        ],
      },
      {
        title: 'Charts',
        questions: [
          {
            question: 'How does zooming work?',
            answer:
              '<p>For zooming in Charts you can use the mouse wheel or with Touchpads you can use the two finger gesture.</p><p>To reset the zoom level, perform a double click on any area within the chart.</p>',
          },
        ],
      },
    ],
  },
}

// we need default export for i18nally vscode plugin
export default en
