import { RouteConfig } from '@/core/routes'

import { configurationRoute } from '@/features/config/configuration-route'

export const thresholdConfigurationRoute: RouteConfig<
  `${typeof configurationRoute.pathname}/threshold`,
  typeof configurationRoute
> = {
  icon: 'threshold-on',
  name: 'Threshold configuration',
  parent: configurationRoute,
  pathname: `${configurationRoute.pathname}/threshold`,
}

export const alarmThresholdConfigurationRoute: RouteConfig<
  `${typeof thresholdConfigurationRoute.pathname}/alarm`,
  typeof thresholdConfigurationRoute
> = {
  name: 'Alarm thresholds',
  parent: thresholdConfigurationRoute,
  pathname: `${thresholdConfigurationRoute.pathname}/alarm`,
}

export const parameterThresholdConfigurationRoute: RouteConfig<
  `${typeof thresholdConfigurationRoute.pathname}/parameter`,
  typeof thresholdConfigurationRoute
> = {
  name: 'Parameter thresholds',
  parent: thresholdConfigurationRoute,
  pathname: `${thresholdConfigurationRoute.pathname}/parameter`,
}

export const parameterTurntimeThresholdConfigurationRoute: RouteConfig<
  `${typeof parameterThresholdConfigurationRoute.pathname}/turntime`,
  typeof parameterThresholdConfigurationRoute
> = {
  name: 'Parameter thresholds turntime',
  parent: parameterThresholdConfigurationRoute,
  pathname: `${parameterThresholdConfigurationRoute.pathname}/turntime`,
}

export const parameterMeanpowerThresholdConfigurationRoute: RouteConfig<
  `${typeof parameterThresholdConfigurationRoute.pathname}/meanpower`,
  typeof parameterThresholdConfigurationRoute
> = {
  name: 'Parameter thresholds meanpower',
  parent: parameterThresholdConfigurationRoute,
  pathname: `${parameterThresholdConfigurationRoute.pathname}/meanpower`,
}

export const parameterPowercurveAiThresholdConfigurationRoute: RouteConfig<
  `${typeof parameterThresholdConfigurationRoute.pathname}/ai`,
  typeof parameterThresholdConfigurationRoute
> = {
  name: 'Parameter thresholds powercurve ai',
  parent: parameterThresholdConfigurationRoute,
  pathname: `${parameterThresholdConfigurationRoute.pathname}/ai`,
}

export const editParameterTurntimeThresholdConfigurationRoute: RouteConfig<
  `${typeof parameterTurntimeThresholdConfigurationRoute.pathname}/edit/:turnoutId`,
  typeof parameterTurntimeThresholdConfigurationRoute
> = {
  name: 'Edit Parameter thresholds turntime',
  parent: parameterTurntimeThresholdConfigurationRoute,
  pathname: `${parameterTurntimeThresholdConfigurationRoute.pathname}/edit/:turnoutId`,
}

export const editParameterMeanpowerThresholdConfigurationRoute: RouteConfig<
  `${typeof parameterMeanpowerThresholdConfigurationRoute.pathname}/edit/:pointMachineId`,
  typeof parameterMeanpowerThresholdConfigurationRoute
> = {
  name: 'Edit Parameter thresholds meanpower',
  parent: parameterMeanpowerThresholdConfigurationRoute,
  pathname: `${parameterMeanpowerThresholdConfigurationRoute.pathname}/edit/:pointMachineId`,
}

export const referenceThresholdConfigurationRoute: RouteConfig<
  `${typeof thresholdConfigurationRoute.pathname}/reference`,
  typeof thresholdConfigurationRoute
> = {
  name: 'Reference curves',
  parent: thresholdConfigurationRoute,
  pathname: `${thresholdConfigurationRoute.pathname}/reference`,
}
