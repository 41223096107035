import {
  CSSProperties,
  JSX,
  useCallback,
  useEffect,
  useMemo,
  useState,
  type Dispatch,
  type FC,
  type SetStateAction,
} from 'react'
import { useTranslation } from 'react-i18next'

import { IxButton, IxIcon, IxIconButton, IxTooltip } from '@siemens/ix-react'

import { Field } from '@/core/components/DataFilter/components/Field'
import type { FieldProps, Filter, SearchField } from '@/core/components/DataFilter/types'

export interface DataFilterProps {
  className?: string
  fields?: SearchField[]
  onChange?: (value: Filter[]) => void
  onExport?: () => void
  removeGrid?: boolean
  style?: CSSProperties
  value?: Filter[]
}

export function DataFilter({ className, removeGrid = false, style, ...props }: DataFilterProps): JSX.Element {
  const [value, setValue] = useState(props.value)
  const [isCollapsed, setIsCollapsed] = useState(true)

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const onChange = useCallback<FieldProps['onChange']>(
    (path, newValue) => {
      const field = props.fields?.find((field) => field.path === path)
      const filtered = value?.filter((item) => item.path !== path)
      if (field == null || newValue == null || (typeof newValue === 'string' && newValue.length === 0)) {
        props.onChange?.(filtered ?? [])
        setValue(filtered)
        return
      }

      const newFilters = [...(filtered ?? []), { ...field, value: newValue }] as Filter[]
      props.onChange?.(newFilters)
      setValue(newFilters)
    },
    [props, value],
  )

  const collapsibleFields = useMemo(() => {
    return (props.fields ?? []).filter((field) => field.collapsible)
  }, [props.fields])

  if (removeGrid) {
    return (
      <Fields
        fields={props.fields}
        collapsibleFields={collapsibleFields}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        onChange={onChange}
        removeGrid={removeGrid}
        value={value}
        onExport={props.onExport}
      />
    )
  }

  return (
    <div className={className ?? 'w-100'} style={style}>
      <div className="row g-3">
        <div className="col-12">
          <div className="w-100">
            <div className="row g-3">
              <Fields
                fields={props.fields}
                collapsibleFields={collapsibleFields}
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
                onChange={onChange}
                removeGrid={removeGrid}
                value={value}
                onExport={props.onExport}
              />
            </div>
          </div>
        </div>

        {collapsibleFields.length > 0 && (
          <div className="col-12 m-0">
            <div style={{ width: '100%' }}>
              <div
                className="row g-3 mt-0"
                style={{
                  maxHeight: isCollapsed ? 0 : 100,
                  opacity: isCollapsed ? 0 : 1,
                  pointerEvents: isCollapsed ? 'none' : undefined,
                  touchAction: isCollapsed ? 'none' : undefined,
                  transition: '150ms ease-in all',
                }}
              >
                {collapsibleFields.map((field) => (
                  <div className={`col-6 col-lg-4 col-xl-${field.type === 'select' ? 2 : 4}`} key={field.path}>
                    <Field field={field} onChange={onChange} value={value?.find((item) => item.path === field.path)} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const Fields: FC<{
  fields?: SearchField[]
  collapsibleFields: SearchField[]
  removeGrid?: boolean
  isCollapsed: boolean
  value?: Filter[]
  setIsCollapsed: Dispatch<SetStateAction<boolean>>
  onExport?: () => void
  onChange: FieldProps['onChange']
}> = ({ fields, collapsibleFields, isCollapsed, removeGrid, value, setIsCollapsed, onExport, onChange }) => {
  const { t } = useTranslation()

  const toggleCollapse = useCallback(() => {
    setIsCollapsed((prev) => !prev)
  }, [setIsCollapsed])

  const filteredFields = useMemo(() => {
    return (fields ?? []).filter((field) => !field.collapsible)
  }, [fields])

  return (
    <>
      {filteredFields.map((field) => (
        <div
          className={removeGrid ? 'col-auto' : `col-6 col-lg-4 col-xl-${field.type === 'select' ? 2 : 4}`}
          key={`${field.type}-${field.path}`}
        >
          <Field field={field} onChange={onChange} value={value?.find((item) => item.path === field.path)} />
        </div>
      ))}

      {collapsibleFields.length > 0 && (
        <div className="col">
          <IxButton ghost={isCollapsed} onClick={toggleCollapse}>
            <div className="d-flex align-items-center">
              <IxIcon name="filter" />
              <span>{t('Filter')}</span>
            </div>
          </IxButton>
        </div>
      )}

      {onExport != null && (
        <div className="col d-flex align-items-center justify-content-end h-100">
          <IxIconButton data-tooltip="DataFilter-export" ghost icon="export" onClick={onExport} variant="primary" />

          <IxTooltip for='[data-tooltip="DataFilter-export"]'>{t('Export')}</IxTooltip>
        </div>
      )}
    </>
  )
}
