import { ApiClientResponse, BaseApiClient } from '@/core/api/base'
import { IdType } from '@/core/models/base'
import { Methods } from '@/core/models/http'
import { RawUsersCodec, UsersCodec, type User } from '@/core/models/user'

import { RawInterlockingsCodec, type RawInterlocking } from '@/features/assets/models/interlocking'

class UserApiClient extends BaseApiClient {
  async list(signal?: AbortSignal): Promise<ApiClientResponse<User[]>> {
    const path = [this.baseUrl, this.endpointUrl].join('/')
    const { data, ...rest } = await this.request(path, Methods.GET, undefined, undefined, signal)
    const rawData = await this.decode(data, RawUsersCodec)
    const newData = rawData.map((entry) => ({ ...entry, id: entry.idpId }))
    return { ...rest, data: await this.decode(newData, UsersCodec) }
  }

  async getInterlockings(userId: IdType, signal?: AbortSignal): Promise<ApiClientResponse<RawInterlocking[]>> {
    const path = [this.baseUrl, this.endpointUrl, userId, 'interlockings'].join('/')
    const { data, ...rest } = await this.request(path, Methods.GET, undefined, undefined, signal)
    return { ...rest, data: await this.decode(data, RawInterlockingsCodec) }
  }

  async associateInterlockingWithUser(userId: IdType, interlockingId: IdType, signal?: AbortSignal): Promise<void> {
    const path = [this.baseUrl, this.endpointUrl, userId, 'interlockings'].join('/')
    await this.request(path, Methods.POST, { interlockingId }, undefined, signal)
  }

  async disassociateInterlockingWithUser(userId: IdType, interlockingId: IdType, signal?: AbortSignal): Promise<void> {
    const path = [this.baseUrl, this.endpointUrl, userId, 'interlockings', interlockingId].join('/')
    await this.request(path, Methods.DELETE, undefined, undefined, signal)
  }
}

const instance = new UserApiClient('users')

export { instance as UserApiClient }
