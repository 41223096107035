import { createContext, PropsWithChildren, useContext, useRef } from 'react'
import { create, useStore } from 'zustand'

import { Filter } from '@/core/components/DataFilter/types'

type Props = {
  filters: Filter[]
  selectedPointMachineIds: number[]
}

type Actions = {
  setFilters: (filters: Filter[]) => void
  selectPointMachines: (ids: number[]) => void
}

type MeanpowerThresholdConfigState = Props & Actions

const initialDefaultState: Props = {
  filters: [],
  selectedPointMachineIds: [],
}

const createStore = () =>
  create<MeanpowerThresholdConfigState>((set) => ({
    ...initialDefaultState,
    setFilters: (filters) =>
      set(() => ({
        filters,
      })),
    selectPointMachines: (ids) => set(() => ({ selectedPointMachineIds: ids })),
  }))

type MeanpowerThresholdConfigStore = ReturnType<typeof createStore>

const MeanpowerThresholdConfigContext = createContext<MeanpowerThresholdConfigStore | null>(null)

export const MeanpowerThresholdConfigProvider = ({ children }: PropsWithChildren) => {
  const storeRef = useRef<MeanpowerThresholdConfigStore>()
  if (!storeRef.current) {
    storeRef.current = createStore()
  }
  return (
    <MeanpowerThresholdConfigContext.Provider value={storeRef.current}>
      {children}
    </MeanpowerThresholdConfigContext.Provider>
  )
}

export function useMeanpowerThresholdConfigStore<T>(selector: (state: MeanpowerThresholdConfigState) => T): T {
  const store = useContext(MeanpowerThresholdConfigContext)
  if (!store) throw new Error('Missing MeanpowerThresholdConfigContext.Provider in the tree')
  return useStore(store, selector)
}
