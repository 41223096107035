import * as t from 'io-ts'

import { BaseModelCodec, nullableType, PagedResultCodec } from '@/core/models/base'

import { RawTurnoutCodec, TurnoutCodec } from '@/features/assets/models/turnout'

export const AlarmEventSeverityCodec = t.union([t.literal('failure'), t.literal('warning')])
export type AlarmEventSeverity = t.TypeOf<typeof AlarmEventSeverityCodec>

export const AlarmEventStateCodec = t.union([t.literal('active'), t.literal('resolved')])
export type AlarmEventState = t.TypeOf<typeof AlarmEventStateCodec>

export const AlarmEventTypeCodec = t.union([t.literal('connection_lost'), t.literal('condition_degradation')])
export type AlarmEventType = t.TypeOf<typeof AlarmEventTypeCodec>

export const AlarmEventAssetTypeCodec = t.union([t.literal('turnout'), t.literal('technical_room')])
export type AlarmEventAssetType = t.TypeOf<typeof AlarmEventAssetTypeCodec>

export const BaseAlarmEventCodec = t.intersection([
  t.type({
    alarmType: AlarmEventTypeCodec,
    assetType: AlarmEventAssetTypeCodec,
    description: t.string,
    eventTime: t.string,
    severity: AlarmEventSeverityCodec,
    state: AlarmEventStateCodec,
    turnout: RawTurnoutCodec,
  }),
  t.partial({
    resolveTime: t.string,
  }),
])
export const BaseAlarmEventsCodec = t.array(BaseAlarmEventCodec)
export type BaseAlarmEvent = t.TypeOf<typeof BaseAlarmEventCodec>

export const RawAlarmEventCodec = t.intersection([BaseModelCodec, BaseAlarmEventCodec])
export const RawAlarmEventsCodec = t.array(RawAlarmEventCodec)
export type RawAlarmEvent = t.TypeOf<typeof RawAlarmEventCodec>

export const AlarmEventCodec = t.intersection([
  RawAlarmEventCodec,
  t.type({
    _turnout: nullableType(TurnoutCodec),
  }),
])
export const AlarmEventsCodec = t.array(AlarmEventCodec)
export type AlarmEvent = t.TypeOf<typeof AlarmEventCodec>

export const PagedRawAlarmEventsCodec = PagedResultCodec(RawAlarmEventsCodec)
export type PagedRawAlarmEvents = t.TypeOf<typeof PagedRawAlarmEventsCodec>

export const PagedAlarmEventsCodec = PagedResultCodec(AlarmEventsCodec)
export type PagedAlarmEvents = t.TypeOf<typeof PagedAlarmEventsCodec>
