import { apiClient } from '@/core/api/api-client'
import type { IdType, PagedResultType } from '@/core/models/base'
import { useAppStore } from '@/core/store/app-store'

import {
  baseMaintenanceTaskCodec,
  maintenanceTaskCodec,
  type BaseMaintenanceTask,
  type MaintenanceReason,
  type MaintenanceTask,
} from '@/features/maintenance-reports/models/maintenance-task'

export type MaintenanceTaskQueryParams = {
  interlockingName?: string[]
  technicalRoomName?: string[]
  areaName?: string[]
  reason?: MaintenanceReason[]
  assetName?: string
  from?: string
  to?: string
  page?: number
  size?: number
  sortBy?: string
  sortingDirection?: 'asc' | 'desc'
}

export const maintenanceTaskApi = {
  /**
   * Creates a new {@link MaintenanceTask}
   * @param body New {@link BaseMaintenanceTask} without id
   * @param signal Optional abort controller signal ({@link AbortSignal})
   * @returns The created and validated {@link MaintenanceTask} including id
   */
  create: (body: BaseMaintenanceTask, signal?: AbortSignal) => {
    return apiClient.post<BaseMaintenanceTask, MaintenanceTask>({
      apiPath: `/maintenance/tasks`,
      body,
      requestCodec: baseMaintenanceTaskCodec,
      responseCodec: maintenanceTaskCodec,
      signal,
    })
  },

  /**
   * Fetches a {@link MaintenanceTask} by id
   * @param signal Optional abort controller signal ({@link AbortSignal})
   * @returns The {@link MaintenanceTask}
   */
  getById: (id: IdType, signal?: AbortSignal): Promise<MaintenanceTask> => {
    return apiClient.get<MaintenanceTask>({
      apiPath: `/maintenance/tasks/${id}`,
      responseCodec: maintenanceTaskCodec,
      signal,
    })
  },

  /**
   * Fetches all {@link MaintenanceTask}s which match the conditions supplied in queryParameters
   * @param queryParameters Optional query parameters
   * @param signal Optional abort controller signal ({@link AbortSignal})
   * @returns A paged list of {@link MaintenanceTask}s (wrapped in {@link PagedResultType})
   */
  query: (
    queryParameters?: MaintenanceTaskQueryParams,
    signal?: AbortSignal,
  ): Promise<PagedResultType<MaintenanceTask>> => {
    const projectId = useAppStore.getState().mainProject.id
    return apiClient.query<MaintenanceTask>({
      apiPath: `/maintenance/project/${projectId}/tasks`,
      queryParameters,
      responseCodec: maintenanceTaskCodec,
      signal,
    })
  },

  /**
   * Delete a {@link MaintenanceTask} by id
   * @param signal Optional abort controller signal ({@link AbortSignal})
   */
  deleteById: (id: IdType, signal?: AbortSignal) => {
    return apiClient.delete_({
      apiPath: `/maintenance/tasks/${id}`,
      signal,
    })
  },
}
