import { JSX, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Table } from '@/core/components/Table'
import { useUserInterlockings, useUsers } from '@/core/hooks/use-user'
import { mapToPagedResponseDummy } from '@/core/lib/response-util'

import { ActionsCell } from '@/features/config/user/components/UserConfigTable/components/ActionsCell'

const PAGE_SIZE = 15

export function UserConfigTable(): JSX.Element {
  const [pageNo, setPageNo] = useState(0)
  const { t } = useTranslation()

  const { data: users, ...usersData } = useUsers()

  const pagedUsers = useMemo(() => {
    return users?.slice(pageNo * PAGE_SIZE, pageNo * PAGE_SIZE + PAGE_SIZE)
  }, [pageNo, users])

  const userIds = useMemo(() => {
    return pagedUsers?.map((user) => user.id)
  }, [pagedUsers])

  const { data: userInterlockings, ...userInterlockingsData } = useUserInterlockings(userIds)

  const usersWithInterlockings = useMemo(() => {
    return mapToPagedResponseDummy(
      pagedUsers?.map((user, index) => {
        const interlockings = userInterlockings?.[index]
        return { ...user, interlockings }
      }) ?? [],
      { page: pageNo, pageSize: PAGE_SIZE, totalElements: users?.length, totalPages: users?.length ?? 0 / PAGE_SIZE },
    )
  }, [pageNo, pagedUsers, userInterlockings, users?.length])

  return (
    <Table
      columnDefs={[
        {
          field: 'username',
          headerName: t('User name') ?? undefined,
        },
        {
          field: 'interlockings',
          headerName: t('Alarms for') ?? undefined,
          valueGetter: ({ data }) =>
            data == null ? null : data.interlockings?.map((interlocking) => interlocking.name).join(', '),
        },
        {
          cellRenderer: ActionsCell,
          field: 'id',
          headerName: t('Actions') ?? undefined,
        },
      ]}
      paginationPageSize={PAGE_SIZE}
      onPageSelected={setPageNo}
      isLoading={usersData.isLoading || userInterlockingsData.isLoading}
      error={usersData.error ?? userInterlockingsData.error}
      pagedData={usersWithInterlockings}
    />
  )
}
