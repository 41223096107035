import { JSX, useEffect } from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import { IxSpinner, IxTile, IxTooltip } from '@siemens/ix-react'

import { LoadingErrorWrapper } from '@/core/components/LoadingErrorWrapper'
import { usePageHeader } from '@/core/context/PageHeader'
import { useProjectConfiguration } from '@/core/hooks/use-project-configuration'
import type { IdType } from '@/core/models/base'

import { AlarmTabs } from '@/features/alarms/components/AlarmTabs'
import { PointMachineEventCharts } from '@/features/assets/components/PointMachineEventCharts'
import { PointMachineInfoTile } from '@/features/assets/components/PointMachineInfoTile'
import { TurnoutHealthTile } from '@/features/assets/components/TurnoutHealthTile'
import { TurnoutKpiBar } from '@/features/assets/components/TurnoutKpiBar'
import { useLastTurnoutEvent } from '@/features/assets/hooks/use-last-turnout-event'
import { useTurnout } from '@/features/assets/hooks/use-turnout'
import { turnoutRoute } from '@/features/assets/routes/turnouts'
import { MaintenanceReportsPageContent } from '@/features/maintenance-reports/components/MaintenanceReportsPageContent'
import { MaintenanceTaskCreateModal } from '@/features/maintenance-reports/components/MaintenanceTaskCreateModal'
import { MaintenanceTaskViewModal } from '@/features/maintenance-reports/components/MaintenanceTaskViewModal'
import { useMaintenanceTaskCreateModal } from '@/features/maintenance-reports/hooks/use-maintenance-task-create-modal'
import { useMaintenanceTaskViewModal } from '@/features/maintenance-reports/hooks/use-maintenance-task-view-modal'

export interface TurnoutDetailProps {
  turnoutId: IdType
}

export function TurnoutDetail({ turnoutId }: TurnoutDetailProps): JSX.Element {
  const { addRouteConfig, setLastUpdate, setLastUpdateExtra, setTitle } = usePageHeader()
  const { data: turnout, ...turnoutData } = useTurnout(turnoutId)
  const { i18n, t } = useTranslation()
  const { data: lastTurnoutEvent, ...lastTurnoutEventData } = useLastTurnoutEvent(turnout)
  const { data: projectConfiguration, ...projectConfigurationData } = useProjectConfiguration()

  useEffect(() => {
    if (turnout != null) {
      window.requestAnimationFrame(() => {
        addRouteConfig(turnoutRoute.pathname, turnout)
        setTitle(turnoutRoute.render?.(turnout))
      })
    }
  }, [addRouteConfig, setTitle, turnout])

  useEffect(() => {
    window.requestAnimationFrame(() => {
      setLastUpdate(turnoutData.dateTime)
    })
  }, [setLastUpdate, turnoutData.dateTime])

  useEffect(() => {
    window.requestAnimationFrame(() => {
      if (lastTurnoutEventData.isLoading || projectConfigurationData.isLoading) {
        return setLastUpdateExtra(
          <>
            ;&nbsp;&nbsp;
            <IxSpinner size="xx-small" style={{ display: 'inline-block' }} />
          </>,
        )
      }

      if (lastTurnoutEventData.error != null) {
        return setLastUpdateExtra(<>;&nbsp;&nbsp;{t('Error while loading last status')}</>)
      }

      if (lastTurnoutEvent == null) {
        return setLastUpdateExtra(<>;&nbsp;&nbsp;{t('Last status not found')}</>)
      }

      const lastTurnoutEventDateTime = DateTime.fromISO(lastTurnoutEvent.start)
      const timeString = lastTurnoutEventDateTime.setLocale(i18n.language).toRelative()

      const healthStatus =
        projectConfiguration == null || lastTurnoutEvent.healthScore == null
          ? undefined
          : lastTurnoutEvent.healthScore < projectConfiguration.thresholdFailure
            ? t('models.turnout.state-alert')
            : lastTurnoutEvent.healthScore < projectConfiguration.thresholdWarning
              ? t('models.turnout.state-warning')
              : t('models.turnout.state-healthy')

      setLastUpdateExtra(
        <>
          <span>
            ;&nbsp;&nbsp;
            <span data-tooltip="last-connection-tooltip">
              {t('Last connection')}: {timeString}
            </span>
            <IxTooltip for="[data-tooltip='last-connection-tooltip']">
              {lastTurnoutEventDateTime.setLocale(i18n.language).toLocaleString(DateTime.DATETIME_MED)}
            </IxTooltip>
          </span>

          {healthStatus != null && (
            <span>
              ;&nbsp;&nbsp;
              <span data-tooltip="last-status-tooltip">
                {t('Last status received')}: {healthStatus}
              </span>
              <IxTooltip for="[data-tooltip='last-status-tooltip']">
                {t('Health score')}: {lastTurnoutEvent.healthScore.toFixed(2)}
              </IxTooltip>
            </span>
          )}
        </>,
      )
    })
  }, [
    i18n.language,
    lastTurnoutEvent,
    lastTurnoutEventData.error,
    lastTurnoutEventData.isLoading,
    projectConfiguration,
    projectConfigurationData.isLoading,
    setLastUpdateExtra,
    t,
  ])

  const {
    taskId: maintenanceTaskId,
    openModal: openMaintenanceViewModal,
    closeModal: closeMaintenanceViewModal,
  } = useMaintenanceTaskViewModal()

  const {
    isOpen: isMaintenanceCreateModalOpen,
    openModal: openMaintenanceCreateModal,
    closeModal: closeMaintenanceCreateModal,
  } = useMaintenanceTaskCreateModal()

  return (
    <div className="row g-5">
      <div className="col-12">
        <div style={{ width: '100%' }}>
          <LoadingErrorWrapper data={turnout} error={turnoutData.error} isLoading={turnoutData.isLoading}>
            {(turnout) => <TurnoutKpiBar turnout={turnout} />}
          </LoadingErrorWrapper>
        </div>
      </div>

      <div className="col-12">
        {turnout != null && (
          <AlarmTabs filterOpts={{ turnoutName: turnout.name }} title={t('routes.Alarms')} turnout={turnout} />
        )}
      </div>

      <div className="col-12">{turnout != null && <TurnoutHealthTile turnout={turnout} />}</div>

      <div className="col-12">
        <PointMachineEventCharts turnoutId={turnoutId} />
      </div>

      <div className="col-12">
        <PointMachineInfoTile turnoutId={turnoutId} />
      </div>

      <div className="col-12">
        <IxTile className="py-3">
          {turnout?.name && (
            <MaintenanceReportsPageContent
              readOnly={false}
              turnoutName={turnout?.name}
              onViewReportClick={openMaintenanceViewModal}
              onCreateReportClick={openMaintenanceCreateModal}
            />
          )}
          {maintenanceTaskId && (
            <MaintenanceTaskViewModal
              taskId={maintenanceTaskId}
              key={maintenanceTaskId}
              onClose={closeMaintenanceViewModal}
            />
          )}
          {isMaintenanceCreateModalOpen && (
            <MaintenanceTaskCreateModal turnoutId={turnout?.id} onClose={closeMaintenanceCreateModal} />
          )}
        </IxTile>
      </div>
    </div>
  )
}
