import * as t from 'io-ts'

import { nullableType, PagedResultCodec } from '@/core/models/base'

import { PointMachineTypeCodec } from '@/features/assets/models/point-machine-type'
import { RawPointMachineCodec, RawPointMachinesCodec } from '@/features/assets/models/point-machine.base'
import { TurnoutCodec } from '@/features/assets/models/turnout'

export const PointMachineCodec = t.intersection([
  RawPointMachineCodec,
  t.type({
    turnout: nullableType(TurnoutCodec),
    type: nullableType(PointMachineTypeCodec),
  }),
])
export const PointMachinesCodec = t.array(PointMachineCodec)
export type PointMachine = t.TypeOf<typeof PointMachineCodec>

export const PagedRawPointMachinesCodec = PagedResultCodec(RawPointMachinesCodec)
export type RawPagedPointMachines = t.TypeOf<typeof PagedRawPointMachinesCodec>

export const PagedPointMachinesCodec = PagedResultCodec(PointMachinesCodec)
export type PagedPointMachines = t.TypeOf<typeof PagedPointMachinesCodec>
