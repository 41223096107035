import type { BaseModel } from '@/core/models/base'

import type { RawTurnout, Turnout } from '@/features/assets/models/turnout'

export interface UpdateTurnout extends RawTurnout {
  timezone?: string
}

export const getDefaultTurnoutFormValues = (turnouts: Turnout | Turnout[] | undefined): UpdateTurnout => {
  const defaultValue = {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    active: false,
  } as UpdateTurnout

  if (turnouts == null) {
    return defaultValue
  }

  const _turnouts = Array.isArray(turnouts) ? turnouts : [turnouts]

  return Object.keys(_turnouts[0]).reduce((turnout, key) => {
    const values = _turnouts.map((turnout) => turnout[key as keyof Turnout])

    const hasDifferentValues = values.some((value, index, arr) => {
      if (index === 0) {
        return false
      }

      if (value != null && typeof value === 'object' && Object.hasOwn(value, 'id')) {
        return (value as BaseModel)?.id !== (arr[index - 1] as BaseModel)?.id
      }

      return value !== arr[index - 1]
    })

    if (hasDifferentValues) {
      return turnout
    }

    return { ...turnout, [key]: values[0] }
  }, defaultValue)
}
