import { FC, JSX, useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useMatch, useNavigate } from 'react-router-dom'

import type { ModalInstance } from '@siemens/ix'
import { showToast } from '@siemens/ix-react'

import { LoadingErrorWrapper } from '@/core/components/LoadingErrorWrapper'
import { Modal, ModalContent } from '@/core/components/Modal'

import {
  UpdateParameterThresholdsForm,
  type UpdateParameterThresholdsFormProps,
} from '@/features/config/threshold/parameter/meanpower/components/UpdateParameterThresholdsForm'
import { useThresholds } from '@/features/config/threshold/parameter/meanpower/hooks/use-threshold'
import {
  editParameterMeanpowerThresholdConfigurationRoute,
  parameterMeanpowerThresholdConfigurationRoute,
  parameterThresholdConfigurationRoute,
} from '@/features/config/threshold/threshold-route'
import { usePointMachines } from '@/features/assets/hooks/use-point-machine'

type FormDataWrapperProps = Pick<UpdateParameterThresholdsFormProps, 'onAfterSubmit' | 'onCancel' | 'onRemoveItem'> & {
  pointMachineIds: number[]
}

function FormDataWrapper({ pointMachineIds, ...rest }: FormDataWrapperProps): JSX.Element {
  const { data: pointMachines, ...pointMachinesData } = usePointMachines(null, pointMachineIds)
  const { data: thresholds, ...thresholdsData } = useThresholds(pointMachineIds)

  return (
    <LoadingErrorWrapper data={pointMachines} error={pointMachinesData.error} isLoading={pointMachinesData.isLoading}>
      {(pointMachines) => (
        <LoadingErrorWrapper data={true} error={thresholdsData.error} isLoading={thresholdsData.isLoading}>
          <UpdateParameterThresholdsForm pointMachines={pointMachines} thresholds={thresholds ?? []} {...rest} />
        </LoadingErrorWrapper>
      )}
    </LoadingErrorWrapper>
  )
}

export const MeanpowerThresholdEditModal: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const editMeanpowerThresholdMatch = useMatch(editParameterMeanpowerThresholdConfigurationRoute.pathname)
  const pointMachineIdsToEdit = useMemo(() => {
    return editMeanpowerThresholdMatch?.params.pointMachineId
      ?.split(',')
      .map((d) => Number(d))
      .filter((d) => !Number.isNaN(d))
  }, [editMeanpowerThresholdMatch])

  const ref = useRef<ModalInstance>(null)

  const closeModal = useCallback(() => {
    ref.current?.htmlElement.dismissModal()
    navigate(generatePath(parameterMeanpowerThresholdConfigurationRoute.pathname))
  }, [navigate])

  const closeWithSuccess = () => {
    showToast({ message: t('Successfully updated thresholds'), type: 'success' })
    closeModal()
  }

  if (!pointMachineIdsToEdit?.length) {
    return null
  }

  return (
    <Modal
      title={t(`routes.${parameterThresholdConfigurationRoute.name}`)}
      visible={Boolean(pointMachineIdsToEdit?.length)}
      ref={ref}
    >
      <ModalContent
        cancelButton={null}
        okButton={null}
        onCancel={closeModal}
        title={t(`routes.${parameterThresholdConfigurationRoute.name}`)}
      >
        <FormDataWrapper
          onAfterSubmit={closeWithSuccess}
          onCancel={closeModal}
          onRemoveItem={(_, pointMachines) => {
            if (pointMachines == null) {
              return
            }

            if (pointMachines.length === 0) {
              return closeModal()
            }

            navigate(
              generatePath(editParameterMeanpowerThresholdConfigurationRoute.pathname, {
                pointMachineId: pointMachines.map((pm) => pm.id).join(','),
              }),
            )
          }}
          pointMachineIds={pointMachineIdsToEdit}
        />
      </ModalContent>
    </Modal>
  )
}
