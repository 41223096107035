import { JSX, useCallback, useEffect, useState } from 'react'

import { IxButton } from '@siemens/ix-react'

interface Option {
  label?: string | null
  value: string | number
}

export interface RadioButtonProps {
  value?: string | number
  onChange?: (value: string | number) => void
  options: Option[]
}

export function RadioButton({ options, ...props }: RadioButtonProps): JSX.Element {
  const [value, setValue] = useState(props.value)

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const onChange = useCallback(
    (option: Option) => {
      return () => {
        setValue(option.value)
        props.onChange?.(option.value)
      }
    },
    [props],
  )

  return (
    <div className="btn-group">
      {options.map((option) => (
        <IxButton
          key={option.value}
          onClick={onChange(option)}
          outline
          variant={value === option.value ? 'primary' : 'secondary'}
        >
          {option.label ?? option.value}
        </IxButton>
      ))}
    </div>
  )
}
