import { JSONSchema7Object } from 'json-schema'

import { CrudApiClient } from '@/core/api/base'

import type { RawPagedPointMachines } from '@/features/assets/models/point-machine'
import {
  PagedPointMachineEventsCodec,
  RawPointMachineEventCodec,
  type PagedPointMachineEvents,
  type PointMachineEvent,
  type RawPointMachineEvent,
} from '@/features/assets/models/point-machine-event'
import type { RawPointMachine } from '@/features/assets/models/point-machine.base'
import type { RawTechnicalRoom } from '@/features/assets/models/technical-room'

import { PointMachineApiClient, QueryPointMachineFilterOpts } from './point-machine'
import { QueryPointMachineEventsFilterOpts } from './point-machine-event'

class PointMachineEventWithEstimatedWeatherDataApiClient extends CrudApiClient<
  RawPointMachineEvent,
  RawPointMachine,
  PagedPointMachineEvents,
  QueryPointMachineEventsFilterOpts
> {
  protected getUpdateBody({ isOutlier, reference }: PointMachineEvent): JSONSchema7Object {
    return { isOutlier, reference }
  }
}

const instance = new PointMachineEventWithEstimatedWeatherDataApiClient(
  'point-machine-events-with-estimated-weather-data',
  RawPointMachineEventCodec,
  PointMachineApiClient as CrudApiClient<
    RawPointMachine,
    RawTechnicalRoom,
    RawPagedPointMachines,
    QueryPointMachineFilterOpts
  >,
  undefined,
  PagedPointMachineEventsCodec,
)

export { instance as PointMachineEventWithEstimatedWeatherDataApiClient }
