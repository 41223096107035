import { JSX } from 'react'
import type { ICellRendererParams } from 'ag-grid-community'
import { generatePath, Link } from 'react-router-dom'

import type { Turnout } from '@/features/assets/models/turnout'
import { turnoutRoute } from '@/features/assets/routes/turnouts'

export function IdCell({ data }: ICellRendererParams<Turnout>): JSX.Element | null {
  if (data == null) {
    return null
  }

  return <Link to={generatePath(turnoutRoute.pathname, { turnoutId: String(data.id) })}>{data.engineeringId}</Link>
}
