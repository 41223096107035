import { JSX, useMemo } from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import { IxDivider, IxKeyValue, IxKeyValueList } from '@siemens/ix-react'

import { buildDate, gitHash, pJson } from '@/core/lib/config'

const buildDateNum = parseInt(buildDate) * 1000
const now = new Date()

export interface PlatformMenuProps {}

export function PlatformMenu(props: PlatformMenuProps): JSX.Element {
  const { i18n, t } = useTranslation()

  const formattedBuildDate = useMemo(() => {
    return Number.isNaN(buildDateNum)
      ? undefined
      : DateTime.fromMillis(buildDateNum).setLocale(i18n.language).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
  }, [i18n.language])

  return (
    <div className="p-3">
      <div>
        <span className="fw-bold">Platform</span>
        <span className="ms-3">Railigent X</span>
      </div>

      <div className="mt-3">© Siemens Mobility GmbH, {now.getFullYear()}</div>

      <IxDivider className="mt-3 mb-2" />

      <IxKeyValueList>
        <IxKeyValue label={t('App version')} labelPosition="left" style={{ border: 'none' }} value={pJson?.version} />
        <IxKeyValue label={t('Commit-ID')} labelPosition="left" style={{ border: 'none' }} value={gitHash} />
        <IxKeyValue
          label={t('Build timestamp')}
          labelPosition="left"
          style={{ border: 'none' }}
          value={formattedBuildDate}
        />
      </IxKeyValueList>
    </div>
  )
}
