import { create } from 'zustand'

import { ProjectApiClient } from '@/core/api/project'
import { ProjectConfigurationApiClient } from '@/core/api/project-configuration'
import { EnabledAnalyticsTypes } from '@/core/models/analytics-types'
import { Project } from '@/core/models/project'

type AppState = {
  mainProject: Project & EnabledAnalyticsTypes
  isLoading: boolean
  initError?: Error
  initialize: () => Promise<void>
}

const MAIN_PROJECT_NAME_PREFIX = 'spm'

const getMainProjectByPrefix = async (): Promise<Project> => {
  const { data: projects } = await ProjectApiClient.list()
  const masterProject = projects.find((p) => p.master)
  const projectByPrefix = projects.find((project) => project.name.toLowerCase().startsWith(MAIN_PROJECT_NAME_PREFIX))
  const firstProject = projects.at(0)

  const project = masterProject ?? projectByPrefix ?? firstProject
  if (!project) {
    throw new Error('Main project not found')
  }

  return project
}

const getEnabledAnalyticsTypes = async (mainProjectId: number): Promise<EnabledAnalyticsTypes> => {
  const { data: analyticsTypes } = await ProjectConfigurationApiClient.getAnalyticsTypes(mainProjectId)
  return {
    isExpertsystemTurntimeEnabled: analyticsTypes?.some((type) => type.name === 'turntime_expertsystem'),
    isExpertsystemMeanpowerEnabled: analyticsTypes?.some((type) => type.name === 'meanpower_expertsystem'),
    isPowercurveAiEnabled: analyticsTypes?.some((type) => type.name === 'powercurve_aimodel'),
  }
}

export const useAppStore = create<AppState>((set) => ({
  mainProject: {} as Project & EnabledAnalyticsTypes,
  isLoading: true,
  initialize: async () => {
    try {
      const mainProject = await getMainProjectByPrefix()
      console.info(`Using project ${mainProject?.name} (${mainProject?.id})`)
      const enabledAnalyticsTypes = await getEnabledAnalyticsTypes(mainProject.id)
      set(() => ({ isLoading: false, mainProject: { ...mainProject, ...enabledAnalyticsTypes } }))
    } catch (err: any) {
      set(() => ({ initError: err }))
    }
  },
}))
