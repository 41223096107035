import { FC, MutableRefObject } from 'react'
import type { AgGridReact } from 'ag-grid-react'

import { useExportData } from '@/core/hooks/use-export-data'

import { CreateReportButton } from '@/features/maintenance-reports/components/CreateReportButton'
import { DownloadReportsButton } from '@/features/maintenance-reports/components/DownloadReportsButton'
import type { MaintenanceTask } from '@/features/maintenance-reports/models/maintenance-task'

export const ActionButtons: FC<{
  readOnly?: boolean
  gridRef: MutableRefObject<AgGridReact<MaintenanceTask> | null>
  onCreateReportClick: () => void
}> = ({ readOnly, gridRef, onCreateReportClick }) => {
  const { onClickExport } = useExportData(gridRef)

  return (
    <div className="d-flex gap-2 justify-content-end">
      <DownloadReportsButton onClick={onClickExport} />
      {!readOnly && <CreateReportButton onClick={onCreateReportClick} />}
    </div>
  )
}
