import i18n from 'i18next'
import { Settings } from 'luxon'
import { initReactI18next } from 'react-i18next'

import { storagePrefix } from '@/core/lib/config'

import de from './lang/de'
import en from './lang/en'

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: ['de', 'en'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    defaultNS: 'translation',
    resources: {
      en,
      de,
    },
  })

// persist lng setting
i18n.on('languageChanged', function (lng) {
  localStorage.setItem(`${storagePrefix}lng`, lng)
  Settings.defaultLocale = lng
})

const initialLng = localStorage.getItem(`${storagePrefix}lng`)
if (initialLng) {
  i18n.changeLanguage(initialLng)
}

export default i18n
