import type { PagedResultType } from '@/core/models/base'

export const mapToPagedResponseDummy = <T>(
  content: Array<T>,
  opts?: { pageSize?: number; page?: number; totalPages?: number; totalElements?: number },
): PagedResultType<T> => {
  const empty = !content.length
  const sort = {
    empty,
    sorted: true,
    unsorted: false,
  }
  const pageNumber = opts?.page ?? 0
  const pageSize = opts?.pageSize ?? content.length

  return {
    content,
    empty,
    first: true,
    last: true,
    number: pageNumber,
    numberOfElements: content.length,
    size: pageSize,
    totalElements: opts?.totalElements ?? content.length,
    totalPages: opts?.totalPages ?? 1,
    pageable: {
      pageNumber,
      pageSize,
      sort,
      offset: 0,
      unpaged: false,
      paged: true,
    },
    sort,
  }
}
