import { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { Modal, ModalContent } from '@/core/components/Modal'

import type { TrackLayer, TrackLayerItem } from '@/features/assets/models/track-layer'

import { UpdateTrackLayerItemForm } from '@/features/config/track/components/UpdateTrackLayerItemForm'
import { createTrackLayerItemConfigurationRoute, trackConfigurationRoute } from '@/features/config/track/routes/track'

export interface CreateTrackLayerItemModalProps {
  layer: TrackLayer | undefined
  layerItem: TrackLayerItem | undefined
  visible: boolean
}

export function CreateTrackLayerItemModal({ visible, ...rest }: CreateTrackLayerItemModalProps): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Modal title={t(`routes.${createTrackLayerItemConfigurationRoute.name}`)} visible={visible}>
      <ModalContent
        cancelButton={null}
        okButton={null}
        onCancel={() => navigate(generatePath(trackConfigurationRoute.pathname))}
        title={t(`routes.${createTrackLayerItemConfigurationRoute.name}`)}
      >
        <UpdateTrackLayerItemForm
          {...rest}
          onAfterSubmit={() => navigate(generatePath(trackConfigurationRoute.pathname))}
          onCancel={() => navigate(generatePath(trackConfigurationRoute.pathname))}
        />
      </ModalContent>
    </Modal>
  )
}
