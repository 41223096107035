import { JSX, PropsWithChildren, useEffect, useState } from 'react'
import { hasAuthParams, useAuth } from 'react-oidc-context'

import { IxSpinner } from '@siemens/ix-react'

import { ErrorInfo } from '@/core/components/ErrorInfo'

export interface AuthContainerProps {
  autoSignIn?: boolean
}

export function AuthContainer({ autoSignIn, children }: PropsWithChildren<AuthContainerProps>): JSX.Element {
  const auth = useAuth()
  const [hasTriedSignin, setHasTriedSignin] = useState(false)

  /**
   * https://github.com/authts/react-oidc-context/tree/v2.3.0#automatic-sign-in
   */
  useEffect(() => {
    if (
      autoSignIn &&
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect()
      setHasTriedSignin(true)
    }
  }, [auth, autoSignIn, hasTriedSignin])

  if (auth.isLoading) {
    return (
      <div className="d-flex justify-content-center p-4">
        <IxSpinner size="large" />
      </div>
    )
  }

  if (auth.error != null) {
    return <ErrorInfo error={{ message: auth.error.message, title: auth.error.name }} />
  }

  return <>{children}</>
}
