import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { IxActionCard } from '@siemens/ix-react'

import {
  TurnoutFunctionalityTypeDerail,
  TurnoutFunctionalityTypeTurnout,
  type TurnoutFunctionalityType,
} from '@/features/assets/models/turnout'

type AssetTypeSelectionProps = {
  onSelect: (assetType: TurnoutFunctionalityType) => void
}

export const AssetTypeSelection: FC<AssetTypeSelectionProps> = (props): ReactElement => {
  const { t } = useTranslation()

  return (
    <div className="AssetTypeSelection d-flex flex-column my-4 ">
      <div className="d-flex flex-row gap-4 justify-content-center">
        <IxActionCard
          className="create-asset-card"
          heading={t('assets.selection.turnout.heading')!}
          subheading={t('assets.selection.turnout.subheading')!}
          variant="insight"
          onClick={props.onSelect.bind(null, TurnoutFunctionalityTypeTurnout)}
        ></IxActionCard>
        <IxActionCard
          className="create-asset-card"
          heading={t('assets.selection.derail.heading')!}
          subheading={t('assets.selection.derail.subheading')!}
          variant="insight"
          onClick={props.onSelect.bind(null, TurnoutFunctionalityTypeDerail)}
        ></IxActionCard>
      </div>
    </div>
  )
}
