import { JSX, useCallback, useMemo, useRef, useState } from 'react'
import type { AgGridReact } from 'ag-grid-react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { generatePath, Link, useMatch } from 'react-router-dom'

import { IxButton } from '@siemens/ix-react'

import { GlobalPageHeader } from '@/core/components/GlobalPageHeader'
import { useFilterOpts } from '@/core/hooks/use-filter-opts'
import { useMainProject } from '@/core/hooks/use-project'
import { appTitle } from '@/core/lib/config'
import type { PaginationFilterOpts } from '@/core/models/pagination'

import type { QueryTurnoutFilterOpts } from '@/features/assets/api/turnout'
import { useQueryTurnouts } from '@/features/assets/hooks/use-turnout'
import { CreateAssetModal } from '@/features/config/assets/components/CreateAssetModal'
import {
  TurnoutConfigTable,
  type TurnoutConfigTableProps,
} from '@/features/config/assets/components/TurnoutConfigTable'
import { UpdateTurnoutModal } from '@/features/config/assets/components/UpdateTurnoutModal'
import { UpdateTurnoutsModal } from '@/features/config/assets/components/UpdateTurnoutsModal'
import {
  assetConfigurationRoute,
  createAssetConfigurationRoute,
  editAssetConfigurationRoute,
} from '@/features/config/assets/routes/asset'

const pageSize = 15

export function AssetConfigurationPage(): JSX.Element {
  const [selectedTurnoutIds, setSelectedTurnoutIds] = useState<string>()
  const [sortField, setSortField] = useState<string | undefined>('name')
  const [sortOrder, setSortOrder] = useState<PaginationFilterOpts['sortingDirection'] | undefined>('asc')
  const { t } = useTranslation()
  const createMatch = useMatch(createAssetConfigurationRoute.pathname)
  const editMatch = useMatch(editAssetConfigurationRoute.pathname)
  const ref = useRef<AgGridReact | null>(null)

  const { filterOpts, setPageNo } = useFilterOpts<QueryTurnoutFilterOpts>([], {
    size: pageSize,
    sortBy: sortField,
    sortingDirection: sortOrder,
  })

  const mainProject = useMainProject()
  const { data: queryResponse, ...queryResponseData } = useQueryTurnouts(filterOpts)

  const turnoutIds = useMemo(() => {
    const turnoutIds = editMatch?.params.assetId
      ?.split(',')
      .map((d) => Number(d))
      .filter((d) => !Number.isNaN(d))
    return turnoutIds == null || turnoutIds.length < 2 ? undefined : turnoutIds
  }, [editMatch?.params.assetId])

  const turnoutId = useMemo(() => {
    const turnoutIds = editMatch?.params.assetId
      ?.split(',')
      .map((d) => Number(d))
      .filter((d) => !Number.isNaN(d))
    return turnoutIds?.length === 1 ? turnoutIds?.at(0) : undefined
  }, [editMatch?.params.assetId])

  const onSelectionChanged = useCallback<NonNullable<TurnoutConfigTableProps['onSelectionChanged']>>(({ api }) => {
    const turnouts = api.getSelectedRows()
    setSelectedTurnoutIds(turnouts.length === 0 ? undefined : turnouts.map((turnout) => turnout.id).join(','))
  }, [])

  const onSort = useCallback<NonNullable<TurnoutConfigTableProps['onSortChanged']>>(({ columnApi }) => {
    const columns = columnApi.getColumnState()
    const sortedColumn = columns.find((column) => column.sort != null)

    setSortField(sortedColumn?.colId)
    setSortOrder(sortedColumn?.sort ?? undefined)
  }, [])

  return (
    <>
      <Helmet>
        <title>
          {appTitle} - {t(`routes.${assetConfigurationRoute.name}`)}
        </title>
      </Helmet>

      <GlobalPageHeader route={assetConfigurationRoute} title={t(`routes.${assetConfigurationRoute.name}`)}>
        <div className="d-flex justify-content-end mb-4">
          <div>
            <Link
              className="me-2"
              to={
                selectedTurnoutIds == null
                  ? {}
                  : generatePath(editAssetConfigurationRoute.pathname, { assetId: selectedTurnoutIds })
              }
            >
              <IxButton disabled={selectedTurnoutIds == null} outline>
                {t('Bulk edit')}
              </IxButton>
            </Link>

            <Link to={generatePath(createAssetConfigurationRoute.pathname)}>
              <IxButton>{t(`routes.${createAssetConfigurationRoute.name}`)}</IxButton>
            </Link>
          </div>
        </div>

        <TurnoutConfigTable
          pagedData={queryResponse}
          error={queryResponseData.error}
          gridRef={ref}
          isLoading={queryResponseData.isLoading}
          onSelectionChanged={onSelectionChanged}
          onSortChanged={onSort}
          onPageSelected={setPageNo}
        />

        {mainProject && (
          <>
            <CreateAssetModal visible={Boolean(createMatch)} projectId={mainProject!.id} />

            <UpdateTurnoutModal turnoutId={turnoutId} visible={turnoutId != null} projectId={mainProject!.id} />
            <UpdateTurnoutsModal turnoutIds={turnoutIds} visible={turnoutIds != null} projectId={mainProject!.id} />
          </>
        )}
      </GlobalPageHeader>
    </>
  )
}
