import { array, Mixed } from 'io-ts'
import { decode, DecodeError } from 'io-ts-promise'
import { DateTime } from 'luxon'
import { User } from 'oidc-client-ts'

import { PagedResultCodec, PagedResultType } from '@/core/models/base'
import { Methods } from '@/core/models/http'
import { ServiceError } from '@/core/models/service-error'

/**
 * New simplified implementation of BaseApiClient {@link BaseApiClient} (see base.ts)
 * For example usage, see /features/maintenance-reports/api
 */

export type PostOptions<RequestBody> = {
  apiPath: string
  body: RequestBody
  signal?: AbortSignal
  requestCodec: Mixed
  responseCodec: Mixed
}

type QueryParamValue = string | string[] | number | number[] | DateTime | boolean
export type QueryParams = Record<string, QueryParamValue>
export type GetOptions = {
  apiPath: string
  signal?: AbortSignal
  responseCodec: Mixed
}
export type QueryOptions = {
  apiPath: string
  queryParameters?: QueryParams
  signal?: AbortSignal
  responseCodec: Mixed
}

export type DeleteOptions = {
  apiPath: string
  signal?: AbortSignal
}

const getBaseProps = () => {
  const dataServiceUrl = sessionStorage.getItem('REACT_APP_DATA_SERVICE_URL') ?? ''
  return {
    authClientId: sessionStorage.getItem('REACT_APP_AUTH_CLIENT_ID') ?? '',
    authDomain: sessionStorage.getItem('REACT_APP_AUTH_DOMAIN') ?? '',
    baseUrl: [dataServiceUrl, 'api', 'v1'].join('/'),
  }
}

const createApiClient = () => {
  /**
   * Performs a POST request including request and response model validations.
   * Usage: post<RequestBodyType, ResponseBodyType>(options)
   * @param options {@link PostOptions}
   * @returns ResponseBody
   */
  const post = async <RequestBody, ResponseBody>({
    apiPath,
    body,
    requestCodec,
    responseCodec,
    signal,
  }: PostOptions<RequestBody>): Promise<ResponseBody> => {
    const { baseUrl, authDomain, authClientId } = getBaseProps()

    try {
      const validatedBody = await validateRequestBody(body, requestCodec)

      const res = await fetch(
        `${baseUrl}${apiPath}`,
        createFetchOptions({ method: Methods.POST, body: validatedBody, signal, authDomain, authClientId }),
      )
      const resCopy = res.clone()
      const json = await resCopy.json().catch((_) => res.text())

      if (res.status >= 400) {
        throw createServiceError(res, json)
      }

      return validateResponseBody(json, responseCodec)
    } catch (error: any) {
      throw handleApiError(error)
    }
  }

  const getOrQuery = async <ResponseBody>({
    apiPath,
    queryParameters,
    responseCodec,
    isPagedResponse,
    signal,
  }: (GetOptions & QueryOptions) & { isPagedResponse?: boolean }): Promise<ResponseBody> => {
    const { baseUrl, authDomain, authClientId } = getBaseProps()

    const params = createQueryParams(queryParameters)

    try {
      const res = await fetch(
        `${baseUrl}${apiPath}${params ? `?${params.toString()}` : ''}`,
        createFetchOptions({ method: Methods.GET, signal, authDomain, authClientId }),
      )
      const resCopy = res.clone()
      const json = await resCopy.json().catch((_) => res.text())
      if (!res.ok) {
        throw createServiceError(res, json)
      }

      return validateResponseBody(json, isPagedResponse ? PagedResultCodec(array(responseCodec)) : responseCodec)
    } catch (error: any) {
      throw handleApiError(error)
    }
  }

  /**
   * Performs a GET request including response model validations.
   * Usage: get<ResponseBodyType>(options)
   * @param options {@link GetOptions}
   * @returns {@link ResponseBody}
   */
  const get = async <ResponseBody>({ apiPath, responseCodec, signal }: QueryOptions): Promise<ResponseBody> =>
    getOrQuery({ apiPath, responseCodec, signal })

  /**
   * Performs a GET request including query parameters response model validations.
   * Usage: get<ResponseBodyType>(options)
   * @param options {@link GetOptions}
   * @returns a paged ResponseBody ({@link PagedResultCodec})
   */
  const query = async <ResponseBody>({
    apiPath,
    queryParameters,
    responseCodec,
    signal,
  }: QueryOptions): Promise<PagedResultType<ResponseBody>> =>
    getOrQuery({ apiPath, queryParameters, responseCodec, isPagedResponse: true, signal })

  /**
   * Performs a DELETE request
   * Usage: delete(options)
   * @param options {@link DeleteOptions}
   */
  const delete_ = async ({ apiPath, signal }: DeleteOptions): Promise<void> => {
    const { baseUrl, authDomain, authClientId } = getBaseProps()

    try {
      const res = await fetch(
        `${baseUrl}${apiPath}`,
        createFetchOptions({ method: Methods.DELETE, signal, authDomain, authClientId }),
      )

      if (res.status >= 400) {
        throw createServiceError(res)
      }

      return
    } catch (error: any) {
      throw handleApiError(error)
    }
  }

  const appendParam = ({ name, value, params }: { name: string; value: QueryParamValue; params: URLSearchParams }) => {
    if (Array.isArray(value)) {
      value.forEach((arrValue) => appendParam({ name, value: arrValue, params }))
    }

    if (typeof value === 'string' || value instanceof String) {
      params.append(name, value as string)
    }
    if (typeof value === 'number' || value instanceof Number) {
      params.append(name, value.toString())
    }
    if (typeof value === 'boolean' || value instanceof Boolean) {
      params.append(name, value.toString())
    }
    if (DateTime.isDateTime(value)) {
      const dateString = value.toISO()
      if (!dateString) {
        return
      }
      params.append(name, dateString)
    }
  }

  const createQueryParams = (queryParameters?: QueryParams): URLSearchParams | undefined => {
    if (!queryParameters) {
      return
    }
    const params = new URLSearchParams()

    Object.entries(queryParameters).forEach(([name, value]) => {
      appendParam({ name, value, params })
    })

    return params
  }

  const createFetchOptions = ({
    method,
    authDomain,
    authClientId,
    body,
    signal,
  }: {
    method: Methods
    authDomain: string
    authClientId: string
    body?: unknown
    signal?: AbortSignal
  }): RequestInit => {
    const oidcStorage = sessionStorage.getItem(`oidc.user:${authDomain}:${authClientId}`)
    const user = User.fromStorageString(oidcStorage ?? '')
    const token = user.access_token

    if (token == null) {
      throw new Error(`Expected valid token, got ${token}`)
    }

    const options: RequestInit = {
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Charset': 'utf-8',
      },
      method,
      mode: 'cors',
      signal,
    }

    if ([Methods.PATCH, Methods.POST, Methods.PUT].includes(method)) {
      options.body = typeof body === 'string' ? body : JSON.stringify(body)
    }

    return options
  }

  const validateRequestBody = async <Output>(json: any, typeCodec: Mixed): Promise<Output> => {
    return validateJson(json, typeCodec, 'request')
  }
  const validateResponseBody = async <Output>(json: any, typeCodec: Mixed): Promise<Output> => {
    return validateJson(json, typeCodec, 'response')
  }
  const validateJson = async <Output>(
    json: any,
    typeCodec: Mixed,
    type: 'request' | 'response',
    throwOnError = true,
  ): Promise<Output> => {
    try {
      return await decode(typeCodec, json)
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        if (error !== null && typeof error === 'object') {
          console.error(`validation error (${type}):`, (error as DecodeError).errors)
        }
      }

      if (throwOnError) {
        throw error
      }

      return json
    }
  }

  return {
    post,
    query,
    get,
    delete_,
  }
}

const createServiceError = (res: Response, json?: any) => {
  if (json == null || (typeof json === 'string' && json.length === 0)) {
    const error: ServiceError = {
      code: res.status,
      message: res.statusText,
      title: res.type,
    }
    return error
  }

  return json
}

const handleApiError = (error: any) => {
  if (error instanceof Error) {
    const serviceError: ServiceError = {
      message: error.message,
      title: error.name,
    }

    return serviceError
  }

  return error
}

export const apiClient = createApiClient()
