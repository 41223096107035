import type { RouteConfig } from '@/core/routes'

import type { Turnout } from '@/features/assets/models/turnout'

export const turnoutsRoute: RouteConfig<'/turnouts'> = {
  icon: 'hexagon-vertical-bars',
  name: 'Turnouts',
  pathname: '/turnouts',
}

export const turnoutRoute: RouteConfig<
  `${typeof turnoutsRoute.pathname}/:turnoutId`,
  typeof turnoutsRoute,
  Turnout | null
> = {
  icon: turnoutsRoute.icon,
  name: 'Turnout',
  parent: turnoutsRoute,
  pathname: `${turnoutsRoute.pathname}/:turnoutId`,
  render: function (turnout) {
    return turnout == null ? this.name : `${turnout.name} / ID ${turnout.id}`
  },
  virtualAncestors: [
    {
      name: 'Area',
      pathname: '/:area',
      render: function (turnout) {
        return turnout?._technicalRoom?._interlocking?._area?.name
      },
    },
    {
      name: 'Interlocking',
      pathname: '/:interlocking',
      render: function (turnout) {
        return turnout?._technicalRoom?._interlocking?.name
      },
    },
    {
      name: 'TechnicalRoom',
      pathname: '/:technicalRoom',
      render: function (turnout) {
        return turnout?._technicalRoom?.name
      },
    },
  ],
}
