import { FC, useMemo } from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { Encoding } from 'vega-lite/build/src/encoding'
import { AnyMark } from 'vega-lite/build/src/mark'
import { GenericUnitSpec } from 'vega-lite/build/src/spec'

import {
  ConcatenatedIncidentChart,
  type ConcatenatedIncidentChartProps,
  type DataPoint,
} from '@/core/components/ConcatenatedIncidentChart/ConcatenatedIncidentChart'

import type { PointMachineEvent } from '@/features/assets/models/point-machine-event'

export interface FitnessGraphProps extends Omit<ConcatenatedIncidentChartProps, 'height' | 'layers'> {
  height?: number
  pointMachineEvents: PointMachineEvent[]
}

export const FitnessGraph: FC<FitnessGraphProps> = ({ height = 500, pointMachineEvents, ...rest }) => {
  const { i18n, t } = useTranslation()

  const data = useMemo((): DataPoint[] => {
    const data = pointMachineEvents.map((pmEvent): DataPoint => {
      const startDate = DateTime.fromISO(pmEvent.start)
      const start = startDate.setLocale(i18n.language).toLocaleString(DateTime.DATETIME_MED)

      return {
        type: pmEvent.pointMachine?.name,
        start,
        value: pmEvent.fitnessScore,
      }
    })

    return data
  }, [i18n.language, pointMachineEvents])

  const layer = useMemo((): GenericUnitSpec<Encoding<string>, AnyMark> => {
    const encoding: Encoding<string> = {
      color: { field: 'type', scale: { scheme: 'accent' }, title: t('Point machine') },
      opacity: { condition: { param: 'highlightName', value: 1 }, value: 0.2 },
      tooltip: [
        { field: 'start', format: '%c', title: t('Timestamp'), type: 'temporal' },
        { field: 'type', title: t('Point machine') },
        { field: 'value', title: t('Fitness score'), type: 'quantitative' },
      ],
      y: { field: 'value', type: 'quantitative', title: t('Fitness score') },
    }

    return {
      data: { values: data },
      encoding,
      mark: { type: 'line' },
      params: [
        // highlight
        {
          bind: 'legend',
          name: 'highlightName',
          select: { type: 'point', fields: ['start'] },
        },
        // zoom
        {
          name: 'grid',
          select: 'interval',
          bind: 'scales',
        },
      ],
    }
  }, [data, t])

  return <ConcatenatedIncidentChart {...rest} height={height} layers={[layer]} />
}
