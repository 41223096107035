import { FC, useCallback } from 'react'
import { t } from 'i18next'

import { Table as BaseTable, type TableProps } from '@/core/components/Table'
import { useAnalytics } from '@/core/hooks/use-project'

import { useQueryPointMachines } from '@/features/assets/hooks/use-point-machine'
import { useTrackLayers } from '@/features/assets/hooks/use-track-layer'
import { useTurnouts } from '@/features/assets/hooks/use-turnout'
import type { PointMachine } from '@/features/assets/models/point-machine'
import { getAnalyticsTypeById } from '@/features/assets/models/turnout'

import { useExpertMeanpowerFilter } from '../hooks/use-expert-meanpower-filter'
import { useMeanpowerThresholdConfigStore } from '../store/meanpower-threshold-config-store'

export const MeanpowerThresholdTable: FC = () => {
  const { data: analytics } = useAnalytics()
  const { onSortChanged } = useExpertMeanpowerFilter()
  const selectedPointMachineIds = useMeanpowerThresholdConfigStore((state) => state.selectedPointMachineIds)
  const selectPointMachines = useMeanpowerThresholdConfigStore((state) => state.selectPointMachines)
  const { data: layerItems } = useTrackLayers()
  const { data: turnouts } = useTurnouts()
  const { pagedFilterOpts, setPageNo } = useExpertMeanpowerFilter()
  const { data: pagedPointMachines, ...pagedPointMachinesData } = useQueryPointMachines(pagedFilterOpts)

  const onSelectionChanged = useCallback<NonNullable<TableProps<PointMachine>['onSelectionChanged']>>(
    ({ api }) => {
      const pointMachines = api.getSelectedRows()
      selectPointMachines(pointMachines?.map((pm) => pm.id))
    },
    [selectPointMachines],
  )

  return (
    <BaseTable
      columnDefs={[
        {
          field: 'turnoutId',
          headerName: t('routes.Turnout') ?? undefined,
          sortable: true,
          unSortIcon: true,
          valueGetter: ({ data }) => turnouts?.find((turnout) => turnout.id === data?.turnoutId)?.name,
          flex: 1,
        },
        {
          field: 'engineeringId',
          headerName: 'PM ID',
          initialSort: 'asc',
          sortable: true,
          unSortIcon: true,
          flex: 1,
        },
        {
          field: 'id',
          headerName: layerItems?.find((item) => item.type === 'technicalRoom')?.name,
          valueGetter: ({ data }) => turnouts?.find((turnout) => turnout.id === data?.turnoutId)?._technicalRoom?.name,
          flex: 1,
        },
        {
          field: 'type.name',
          headerName: t('Type') ?? undefined,
          sortable: true,
          unSortIcon: true,
          width: 100,
        },
        {
          field: 'turnout.turnoutFunctionalityType',
          headerName: t('models.turnout.turnoutFunctionalityType') ?? undefined,
          valueGetter: ({ data }) => {
            const type = turnouts?.find((turnout) => turnout.id === data?.turnoutId)?.turnoutFunctionalityType
            return type ? t(`models.turnout.turnoutFunctionalityType-${type}`) : undefined
          },
          width: 100,
        },
        {
          field: 'turnout.analyticsTypeId',
          headerName: t('models.turnout.model-type') ?? undefined,
          valueGetter: ({ data }) => {
            const analyticsTypeName = getAnalyticsTypeById(
              turnouts?.find((turnout) => turnout.id === data?.turnoutId)?.analyticsTypeId,
              analytics,
            )?.name

            return analyticsTypeName ? t(`models.turnout.analyticsTypes.${analyticsTypeName}`) : undefined
          },
          width: 150,
        },
      ]}
      error={pagedPointMachinesData.error}
      isLoading={pagedPointMachinesData.isLoading}
      onSelectionChanged={onSelectionChanged}
      onSortChanged={onSortChanged}
      selectedIds={selectedPointMachineIds}
      onPageSelected={setPageNo}
      pagedData={pagedPointMachines}
      selectable
    />
  )
}
