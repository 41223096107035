import { JSONSchema7Object } from 'json-schema'

import { CrudApiClient } from '@/core/api/base'

import { InterlockingApiClient } from '@/features/assets/api/interlocking'
import type { RawInterlocking } from '@/features/assets/models/interlocking'
import { RawTechnicalRoomCodec, type RawTechnicalRoom } from '@/features/assets/models/technical-room'

class TechnicalRoomApiClient extends CrudApiClient<RawTechnicalRoom, RawInterlocking> {
  protected getCreateBody(model: RawTechnicalRoom): JSONSchema7Object {
    return { name: model.name }
  }

  protected getUpdateBody(model: RawTechnicalRoom): JSONSchema7Object {
    return this.getCreateBody(model)
  }
}

const instance = new TechnicalRoomApiClient(
  'technical-rooms',
  RawTechnicalRoomCodec,
  InterlockingApiClient as CrudApiClient<RawInterlocking>,
  'interlockingId',
)

export { instance as TechnicalRoomApiClient }
