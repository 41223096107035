import { JSONSchema7Object } from 'json-schema'

import { CrudApiClient, type ApiClientResponse } from '@/core/api/base'
import type { IdType } from '@/core/models/base'
import { Methods } from '@/core/models/http'
import type { PaginationFilterOpts } from '@/core/models/pagination'

import { TurnoutApiClient, type QueryTurnoutFilterOpts } from '@/features/assets/api/turnout'
import { PagedRawPointMachinesCodec, type RawPagedPointMachines } from '@/features/assets/models/point-machine'
import {
  PointMachineChangeLogsCodec,
  type PointMachineChangeLog,
} from '@/features/assets/models/point-machine-change-log'
import { RawPointMachineCodec, type RawPointMachine } from '@/features/assets/models/point-machine.base'
import type { RawTechnicalRoom } from '@/features/assets/models/technical-room'
import type { RawPagedTurnouts, RawTurnout } from '@/features/assets/models/turnout'

import type { ThresholdConfigurationFilterOpts } from '@/features/config/threshold/parameter/meanpower/models/threshold'
import type { TrackConfigurationFilterOpts } from '@/features/config/track/models/track-config-filter-opts'

export type QueryPointMachineFilterOpts = TrackConfigurationFilterOpts &
  PaginationFilterOpts &
  ThresholdConfigurationFilterOpts

class PointMachineApiClient extends CrudApiClient<
  RawPointMachine,
  RawTurnout,
  RawPagedPointMachines,
  QueryPointMachineFilterOpts
> {
  protected getCreateBody(model: RawPointMachine): JSONSchema7Object {
    const { turnoutId, typeId } = model

    return {
      ...this.getUpdateBody(model),
      turnoutId,
      typeId,
    }
  }

  protected getUpdateBody(model: RawPointMachine): JSONSchema7Object {
    const { engineeringId, fixedDirection, name, position, serialNumber } = model

    return { engineeringId, fixedDirection, name, position, serialNumber }
  }

  async getChangeLogs(
    pointMachineId: IdType,
    signal?: AbortSignal,
  ): Promise<ApiClientResponse<PointMachineChangeLog[]>> {
    const path = [this.baseUrl, this.endpointUrl, pointMachineId, 'change-logs'].join('/')

    const { data, ...rest } = await this.request(path, Methods.GET, undefined, undefined, signal)
    return { ...rest, data: await this.decode(data, PointMachineChangeLogsCodec) }
  }
}

const instance = new PointMachineApiClient(
  'point-machines',
  RawPointMachineCodec,
  TurnoutApiClient as CrudApiClient<RawTurnout, RawTechnicalRoom, RawPagedTurnouts, QueryTurnoutFilterOpts>,
  undefined,
  PagedRawPointMachinesCodec,
)

export { instance as PointMachineApiClient }
