import { createContext, PropsWithChildren, useContext, useRef } from 'react'
import { create, useStore } from 'zustand'

import { Filter } from '@/core/components/DataFilter/types'

type Props = {
  filters: Filter[]
  selectedTurnoutIds: number[]
}

type Actions = {
  setFilters: (filters: Filter[]) => void
  selectTurnouts: (ids: number[]) => void
  removeSelectedTurnout: (id: number) => void
}

type TurntimeThresholdConfigState = Props & Actions

const initialDefaultState: Props = {
  filters: [],
  selectedTurnoutIds: [],
}

const createStore = () =>
  create<TurntimeThresholdConfigState>((set) => ({
    ...initialDefaultState,
    setFilters: (filters) =>
      set(() => ({
        filters,
      })),
    selectTurnouts: (ids) =>
      set(() => ({
        selectedTurnoutIds: ids,
      })),
    removeSelectedTurnout: (idToRemove: number) =>
      set((state) => ({ selectedTurnoutIds: state.selectedTurnoutIds.filter((id) => id !== idToRemove) })),
  }))

type TurntimeThresholdConfigStore = ReturnType<typeof createStore>

const TurntimeThresholdConfigContext = createContext<TurntimeThresholdConfigStore | null>(null)

export const TurntimeThresholdConfigProvider = ({ children }: PropsWithChildren) => {
  const storeRef = useRef<TurntimeThresholdConfigStore>()
  if (!storeRef.current) {
    storeRef.current = createStore()
  }
  return (
    <TurntimeThresholdConfigContext.Provider value={storeRef.current}>
      {children}
    </TurntimeThresholdConfigContext.Provider>
  )
}

export function useTurntimeThresholdConfigStore<T>(selector: (state: TurntimeThresholdConfigState) => T): T {
  const store = useContext(TurntimeThresholdConfigContext)
  if (!store) throw new Error('Missing TurntimeThresholdConfigContext.Provider in the tree')
  return useStore(store, selector)
}
