import { CSSProperties, JSX, useMemo } from 'react'

import { IxMessageBar } from '@siemens/ix-react'
import { JSX as IxJSX } from '@siemens/ix/dist/types/components'

import type { ServiceError } from '@/core/models/service-error'

import { createErrorLines, createErrorObject } from './lib'

export interface ErrorInfoProps {
  className?: string

  /**
   * The error object or string.
   */
  error: ServiceError | string

  style?: CSSProperties

  type?: IxJSX.IxMessageBar['type']
}

/**
 * Render the passed error object as the component of choice.
 */
export function ErrorInfo({ error, ...rest }: ErrorInfoProps): JSX.Element | null {
  const errorObject = useMemo(() => {
    return createErrorObject(error)
  }, [error])

  if (errorObject == null) {
    return null
  }

  return (
    <div {...rest}>
      <IxMessageBar dismissible={false} type="danger" {...rest}>
        {errorObject.title}
        <br />
        <br />
        {createErrorLines(errorObject.message)}
      </IxMessageBar>
    </div>
  )
}
