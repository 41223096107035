import type { CSSProperties } from 'react'
import { DateTime } from 'luxon'

export interface DateRange {
  from: DateTime
  to: DateTime
}

export interface Option {
  label?: string | null
  value: string | DateTime | DateRange
}

export interface SearchField {
  collapsible?: boolean
  icon?: string
  label?: string | null
  options?: Option[] | null
  path: string
  style?: CSSProperties
  type: 'date' | 'date_range' | 'radio' | 'select' | 'text' | 'toggle'
}

export interface FieldProps {
  field: SearchField
  onChange: (path: string, value: Filter['value'] | null | undefined) => void
  value: Filter | undefined
}

export interface Filter extends Omit<SearchField, 'collapsible'> {
  value: Option['value'] | Array<Option['value']>
}

export const isDateRange = (value: Filter['value'] | undefined | null): value is DateRange => {
  return (
    value != null &&
    typeof value !== 'string' &&
    !Array.isArray(value) &&
    Object.hasOwn(value, 'from') &&
    Object.hasOwn(value, 'from')
  )
}
