import { useCallback, useMemo } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import { useTurntimeThresholdConfigStore } from '@/features/config/threshold/parameter/turntime/store/turntime-threshold-config-store'
import {
  editParameterTurntimeThresholdConfigurationRoute,
  parameterTurntimeThresholdConfigurationRoute,
} from '@/features/config/threshold/threshold-route'

export const useTurntimeThresholdRouter = () => {
  const selectedTurnoutIds = useTurntimeThresholdConfigStore((state) => state.selectedTurnoutIds)
  const navigate = useNavigate()
  const editRouteLink = useMemo(
    () =>
      generatePath(editParameterTurntimeThresholdConfigurationRoute.pathname, {
        turnoutId: selectedTurnoutIds.join(','),
      }),
    [selectedTurnoutIds],
  )

  const navigateToEditRoute = useCallback(() => {
    if (!selectedTurnoutIds.length) {
      return navigate(parameterTurntimeThresholdConfigurationRoute.pathname)
    }

    navigate(editRouteLink)
  }, [editRouteLink, navigate, selectedTurnoutIds.length])

  const navigateToOverview = useCallback(() => {
    navigate(generatePath(parameterTurntimeThresholdConfigurationRoute.pathname))
  }, [navigate])

  return {
    editRouteLink,
    navigateToOverview,
    navigateToEditRoute,
  }
}
