import { JSX } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'

import { IxButton } from '@siemens/ix-react'

import { appTitle } from '@/core/lib/config'
import * as routes from '@/core/routes'

export function Error404Page(): JSX.Element {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>{appTitle} - 404</title>
      </Helmet>

      <div className="d-grid gap-3 text-center">
        <div className="text-h2">404</div>

        <div>{t('Sorry, the page you visited does not exist.')}</div>

        <Link to={generatePath(routes.homeRoute.pathname)}>
          <IxButton>{t('Back home')}</IxButton>
        </Link>
      </div>
    </>
  )
}
