import { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { LoadingErrorWrapper } from '@/core/components/LoadingErrorWrapper'
import { Modal, ModalContent } from '@/core/components/Modal'
import { UpdateUserInterlockingsForm } from '@/features/config/user/components/UpdateUserInterlockingsForm'
import { useUserInterlockings } from '@/core/hooks/use-user'
import type { IdType } from '@/core/models/base'
import { editUserConfigurationRoute, userConfigurationRoute } from '@/core/routes/user'

import type { UpdateTurnoutFormProps } from '@/features/config/assets/components/UpdateTurnoutForm'

type FormDataWrapperProps = Pick<UpdateTurnoutFormProps, 'onAfterSubmit' | 'onCancel'> &
  Pick<UpdateUserModalProps, 'userId'>

function FormDataWrapper({ userId, ...rest }: FormDataWrapperProps): JSX.Element {
  const { data: userInterlockings, ...userInterlockingsData } = useUserInterlockings(userId == null ? null : [userId])

  return (
    <LoadingErrorWrapper
      data={userInterlockings}
      error={userInterlockingsData.error}
      isLoading={userInterlockingsData.isLoading}
    >
      {(userInterlockings) => (
        <UpdateUserInterlockingsForm {...rest} userId={userId} userInterlockings={userInterlockings[0]} />
      )}
    </LoadingErrorWrapper>
  )
}

export interface UpdateUserModalProps {
  userId: IdType | undefined
  visible: boolean
}

export function UpdateUserModal({ userId, visible }: UpdateUserModalProps): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Modal title={t(`routes.${editUserConfigurationRoute.name}`)} visible={visible}>
      <ModalContent
        cancelButton={null}
        okButton={null}
        onCancel={() => navigate(generatePath(userConfigurationRoute.pathname))}
        title={t(`routes.${editUserConfigurationRoute.name}`)}
      >
        <FormDataWrapper
          onAfterSubmit={() => navigate(generatePath(userConfigurationRoute.pathname))}
          onCancel={() => navigate(generatePath(userConfigurationRoute.pathname))}
          userId={userId}
        />
      </ModalContent>
    </Modal>
  )
}
