import { IxModalContent } from '@siemens/ix-react'
import { styled } from 'styled-components'

export const ModalContent = styled(IxModalContent)`
  overflow-x: hidden;
`
export const ModalContentWithFooter = styled(IxModalContent)`
  overflow-x: hidden;
  margin-bottom: 3.5rem;
`
