import { ApiClientResponse } from '@/core/api/base'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import origUseSWR, { Fetcher, Key, SWRResponse as OrigSWRResponse } from 'swr'

export interface SWRResponse<Data = any> extends OrigSWRResponse<Data> {
  dateTime: DateTime
}

export function useSWR<Data = any>(key: Key, fetcher: Fetcher<ApiClientResponse<Data>, Key> | null): SWRResponse<Data> {
  const swrResponse = origUseSWR(key, fetcher)

  return useMemo(() => {
    const { data, ...rest } = swrResponse as any

    if (data == null) {
      return { data: undefined, ...rest }
    }

    return { ...data, ...rest }
  }, [swrResponse])
}
