import { JSX, memo, PropsWithChildren } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { SWRConfig } from 'swr'

import { IxApplicationContext, IxToastContainer } from '@siemens/ix-react'

import { PageHeaderProvider } from '@/core/context/PageHeader'
import { swrConfig } from '@/core/lib/swr'

export const LastWrapper = memo<PropsWithChildren<{}>>(({ children }) => <>{children}</>)

export interface ContextsProps {}

export function Contexts({ children }: PropsWithChildren<ContextsProps>): JSX.Element {
  return (
    <SWRConfig value={swrConfig}>
      <HelmetProvider>
        <IxApplicationContext>
          <IxToastContainer />
          <PageHeaderProvider>
            <LastWrapper>{children}</LastWrapper>
          </PageHeaderProvider>
        </IxApplicationContext>
      </HelmetProvider>
    </SWRConfig>
  )
}
