import { JSX } from 'react'
import { useTranslation } from 'react-i18next'

import { IxTile } from '@siemens/ix-react'

import { LoadingErrorWrapper } from '@/core/components/LoadingErrorWrapper'

import { HealthChart } from '@/features/alarms/components/HealthChart'

import type { TurnoutStateStatisticsFilterOpts } from '@/features/assets/api/turnout'
import { useTurnoutStateStatistics } from '@/features/assets/hooks/use-turnout'

export interface HealthKpiTileProps {
  filterOpts: Partial<TurnoutStateStatisticsFilterOpts>
}

export function HealthKpiTile({ filterOpts }: HealthKpiTileProps): JSX.Element {
  const { t } = useTranslation()
  const { data: stateStatistics, ...stateStatisticsData } = useTurnoutStateStatistics(filterOpts)

  return (
    <IxTile>
      <div slot="header">{t('Overview all turnouts')}</div>

      <div>
        <LoadingErrorWrapper
          data={stateStatistics}
          error={stateStatisticsData.error}
          isLoading={stateStatisticsData.isLoading}
        >
          {(stateStatistics) => <HealthChart stateStatistics={stateStatistics} />}
        </LoadingErrorWrapper>
      </div>
    </IxTile>
  )
}
