import { DateTime } from 'luxon'
import useSWR, { Key, type SWRResponse } from 'swr'

import { useMainProject } from '@/core/hooks/use-project'
import type { IdType } from '@/core/models/base'

import { TurnoutEventApiClient } from '@/features/assets/api/turnout-event'
import type { Turnout } from '@/features/assets/models/turnout'
import type { TurnoutEvent } from '@/features/assets/models/turnout-event'

const DAYS = [1, 7, 30, 180, 365]

export const getLastTurnoutEventKey = (model: Parameters<typeof useLastTurnoutEvent>[0], parentId?: IdType): Key => {
  if (parentId == null || model == null) {
    return null
  }

  return ['useLastHealthState', parentId, model.id]
}

export const useLastTurnoutEvent = (turnout: Turnout | null | undefined): SWRResponse<TurnoutEvent | null> => {
  const mainProject = useMainProject()

  return useSWR(getLastTurnoutEventKey(turnout, mainProject?.id), () => {
    const findLastTurnoutEvent = async (index: number): Promise<TurnoutEvent | null> => {
      const now = DateTime.now()
      const from = now.minus({ days: DAYS[index] })
      const response = await TurnoutEventApiClient.query(mainProject!.id, {
        from,
        size: 1,
        sortBy: 'start',
        sortingDirection: 'desc',
        to: now,
        turnoutName: turnout!.name,
      })
      const lastHealthState = response.data.content.at(0)

      if (lastHealthState != null) {
        return lastHealthState
      }

      if (index < DAYS.length) {
        return findLastTurnoutEvent(index + 1)
      }

      return null
    }

    return findLastTurnoutEvent(0)
  })
}
