import useSWR, { Key, mutate, SWRResponse } from 'swr'
import useSWRMutation, { SWRMutationResponse } from 'swr/mutation'

import { useMainProject } from '@/core/hooks/use-project'
import type { IdType, PagedResultType } from '@/core/models/base'
import type { ServiceError } from '@/core/models/service-error'

import {
  maintenanceTaskApi,
  type MaintenanceTaskQueryParams,
} from '@/features/maintenance-reports/api/maintenance-task-api'
import type { BaseMaintenanceTask, MaintenanceTask } from '@/features/maintenance-reports/models/maintenance-task'

export const useMaintenanceTask = (taskId: IdType): SWRResponse<MaintenanceTask> => {
  return useSWR(['useMaintenanceTask', taskId], async () => maintenanceTaskApi.getById(taskId))
}

export const useQueryMaintenanceTasks = (
  params?: MaintenanceTaskQueryParams,
): SWRResponse<PagedResultType<MaintenanceTask>> => {
  const mainProject = useMainProject()
  return useSWR(['useQueryMaintenanceTasks', mainProject.id, JSON.stringify(params)], async () =>
    maintenanceTaskApi.query(params),
  )
}

export const useCreateMaintenanceTask = (): SWRMutationResponse<
  Key[],
  ServiceError,
  BaseMaintenanceTask,
  'useCreateMaintenanceTask'
> => {
  const mainProject = useMainProject()
  return useSWRMutation('useCreateMaintenanceTask', async (_, { arg }) => {
    await maintenanceTaskApi.create(arg)

    //refresh all queries to also show the created one
    return mutate(
      (key) => Array.isArray(key) && key.at(0) === 'useQueryMaintenanceTasks' && key.at(1) === mainProject.id,
    )
  })
}
