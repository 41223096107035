import { JSONSchema7Object } from 'json-schema'

import { CrudApiClient } from '@/core/api/base'

import { PointMachineApiClient, type QueryPointMachineFilterOpts } from '@/features/assets/api/point-machine'
import type { RawPagedPointMachines } from '@/features/assets/models/point-machine'
import type { RawPointMachine } from '@/features/assets/models/point-machine.base'
import type { RawTechnicalRoom } from '@/features/assets/models/technical-room'

import { ThresholdCodec, type Threshold } from '@/features/config/threshold/parameter/meanpower/models/threshold'

class ThresholdApiClient extends CrudApiClient<Threshold, RawPointMachine> {
  protected getCreateBody({ direction, meanPower, throwingTime, work }: Threshold): JSONSchema7Object {
    return { direction, meanPower, throwingTime, work }
  }

  protected getUpdateBody({ meanPower, throwingTime, work }: Threshold): JSONSchema7Object {
    return { meanPower, throwingTime, work }
  }
}

const instance = new ThresholdApiClient(
  'thresholds',
  ThresholdCodec,
  PointMachineApiClient as CrudApiClient<
    RawPointMachine,
    RawTechnicalRoom,
    RawPagedPointMachines,
    QueryPointMachineFilterOpts
  >,
  'pointMachineId',
)

export { instance as ThresholdApiClient }
