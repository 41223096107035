import { Key } from 'swr'

import { SWRResponse, useSWR } from '@/core/hooks/swr'
import type { IdType } from '@/core/models/base'

import {
  SensorMeasurementApiClient,
  type SensorMeasurementFilterOpts,
} from '@/features/assets/api/sensor-measurements'
import type { SensorMeasurements } from '@/features/assets/models/sensor-measurements'

export const getSensorMeasurementsKey = (
  parentId: Parameters<typeof useSensorMeasurements>[0],
  opts: Parameters<typeof useSensorMeasurements>[1],
): Key => {
  if (parentId == null || opts == null) {
    return null
  }

  return ['useSensorMeasurements', parentId, ...JSON.stringify(opts)]
}

export const useSensorMeasurements = (
  parentId: IdType | undefined | null,
  opts?: Partial<SensorMeasurementFilterOpts> | null,
): SWRResponse<SensorMeasurements> => {
  return useSWR(getSensorMeasurementsKey(parentId, opts), () => {
    return SensorMeasurementApiClient.measurements(parentId!, opts)
  })
}
