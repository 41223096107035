import { JSONSchema7Object } from 'json-schema'

import { CrudApiClient } from '@/core/api/base'
import type { DateRangeFilterOpts } from '@/core/models/filter'
import type { PaginationFilterOpts } from '@/core/models/pagination'

import { PointMachineApiClient, type QueryPointMachineFilterOpts } from '@/features/assets/api/point-machine'
import type { PointMachine, RawPagedPointMachines } from '@/features/assets/models/point-machine'
import {
  PagedPointMachineEventsCodec,
  RawPointMachineEventCodec,
  type PagedPointMachineEvents,
  type PointMachineEvent,
  type RawPointMachineEvent,
} from '@/features/assets/models/point-machine-event'
import type { RawPointMachine } from '@/features/assets/models/point-machine.base'
import type { RawTechnicalRoom } from '@/features/assets/models/technical-room'

import type { TrackConfigurationFilterOpts } from '@/features/config/track/models/track-config-filter-opts'

export type QueryPointMachineEventsFilterOpts = TrackConfigurationFilterOpts &
  DateRangeFilterOpts &
  PaginationFilterOpts &
  Pick<PointMachineEvent, 'isOutlier' | 'reference'> &
  Pick<PointMachine, 'engineeringId'>

class PointMachineEventApiClient extends CrudApiClient<
  RawPointMachineEvent,
  RawPointMachine,
  PagedPointMachineEvents,
  QueryPointMachineEventsFilterOpts
> {
  protected getUpdateBody({ isOutlier, reference }: PointMachineEvent): JSONSchema7Object {
    return { isOutlier, reference }
  }
}

const instance = new PointMachineEventApiClient(
  'point-machine-events',
  RawPointMachineEventCodec,
  PointMachineApiClient as CrudApiClient<
    RawPointMachine,
    RawTechnicalRoom,
    RawPagedPointMachines,
    QueryPointMachineFilterOpts
  >,
  undefined,
  PagedPointMachineEventsCodec,
)

export { instance as PointMachineEventApiClient }
