import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { IxIcon } from '@siemens/ix-react'

import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverDescription,
  PopoverHeading,
  PopoverOptions,
  PopoverTrigger,
} from './Popover'

interface InfoPopoverProps extends Pick<PopoverOptions, 'root' | 'allowedPlacements'> {
  heading?: string | ReactElement | null
  copy: string | ReactElement | null
}

/**
 * Adaptation of a Popover with an (i) as trigger
 * @param heading
 * @param copy
 * @constructor
 */

/**
 * Exemplary use:
<InfoPopover
  heading={"Title"}
  copy={"description"}
/>
*/

export const InfoPopover: FC<InfoPopoverProps> = ({ heading, copy, root, allowedPlacements = ['right'] }): ReactElement => {
  const { t } = useTranslation()

  return (
    <Popover root={root} allowedPlacements={allowedPlacements}>
      <PopoverTrigger asChild style={{ verticalAlign: 'top' }}>
        <IxIcon
          className={'info-popover-icon'}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
          name="about-filled"
          size="24"
        />
      </PopoverTrigger>
      <PopoverContent>
        {heading && <PopoverHeading>{heading}</PopoverHeading>}
        <PopoverDescription>{copy}</PopoverDescription>
        <PopoverClose>{t('Okay')}</PopoverClose>
      </PopoverContent>
    </Popover>
  )
}