import { JSX, PropsWithChildren, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Link, useLocation, useNavigate } from 'react-router-dom'

import { IxBasicNavigation, IxMenuCategory, IxMenuItem } from '@siemens/ix-react'

import { OsBar } from '@/core/components/OsBar'
import { getActiveKeys } from '@/core/lib/nav'
import { homeRoute } from '@/core/routes'
import { userConfigurationRoute } from '@/core/routes/user'

import { useIsAdmin } from '@/core/hooks/use-token'

import { alarmsRoute } from '@/features/alarms/routes'

import { assetsRoute } from '@/features/assets/routes/assets'
import { turnoutsRoute } from '@/features/assets/routes/turnouts'

import { assetConfigurationRoute } from '@/features/config/assets/routes/asset'
import { configurationRoute } from '@/features/config/configuration-route'
import { thresholdConfigurationRoute } from '@/features/config/threshold/threshold-route'
import { trackConfigurationRoute } from '@/features/config/track/routes/track'

import { maintenanceReportsRoute } from '@/features/maintenance-reports/routes/maintenance-report-routes'

import * as S from './styles'

export interface PageLayoutProps {}

export function PageLayout({ children }: PropsWithChildren<PageLayoutProps>): JSX.Element {
  const { t } = useTranslation()
  const isAdmin = useIsAdmin()
  const location = useLocation()
  const keys = useMemo(() => getActiveKeys(location), [location])
  const navigate = useNavigate()

  return (
    <>
      <OsBar />
      <IxBasicNavigation hideHeader>
        <S.Menu>
          <Link to={generatePath(homeRoute.pathname)}>
            <IxMenuItem active={keys.join('').replaceAll('/', '').length === 0} icon={homeRoute.icon}>
              {t(`routes.${homeRoute.name}`)}
            </IxMenuItem>
          </Link>
          <IxMenuCategory label={t(`routes.${assetsRoute.name}`) ?? undefined} icon={assetsRoute.icon}>
            <Link to={generatePath(turnoutsRoute.pathname)}>
              <IxMenuItem active={keys?.includes(turnoutsRoute.pathname)} icon={turnoutsRoute.icon}>
                {t(`routes.${turnoutsRoute.name}`)}
              </IxMenuItem>
            </Link>
            <Link to={generatePath(maintenanceReportsRoute.pathname)}>
              <IxMenuItem active={keys?.includes(maintenanceReportsRoute.pathname)} icon={maintenanceReportsRoute.icon}>
                {t('routes.maintenanceReports')}
              </IxMenuItem>
            </Link>
          </IxMenuCategory>
          <Link to={generatePath(alarmsRoute.pathname)}>
            <IxMenuItem active={keys?.includes(alarmsRoute.pathname)} icon={alarmsRoute.icon}>
              {t(`routes.${alarmsRoute.name}`)}
            </IxMenuItem>
          </Link>

          {isAdmin && (
            <IxMenuCategory label={t(`routes.${configurationRoute.name}`) ?? undefined} icon={configurationRoute.icon}>
              <IxMenuItem
                active={keys?.includes(trackConfigurationRoute.pathname)}
                icon={trackConfigurationRoute.icon}
                onClick={() => navigate(generatePath(trackConfigurationRoute.pathname))}
              >
                {t(`routes.${trackConfigurationRoute.name}`)}
              </IxMenuItem>

              <IxMenuItem
                active={keys?.includes(assetConfigurationRoute.pathname)}
                icon={assetConfigurationRoute.icon}
                onClick={() => navigate(generatePath(assetConfigurationRoute.pathname))}
              >
                {t(`routes.${assetConfigurationRoute.name}`)}
              </IxMenuItem>

              <IxMenuItem
                active={keys?.includes(thresholdConfigurationRoute.pathname)}
                icon={thresholdConfigurationRoute.icon}
                onClick={() => navigate(generatePath(thresholdConfigurationRoute.pathname))}
              >
                {t(`routes.${thresholdConfigurationRoute.name}`)}
              </IxMenuItem>

              <IxMenuItem
                active={keys?.includes(userConfigurationRoute.pathname)}
                icon={userConfigurationRoute.icon}
                onClick={() => navigate(generatePath(userConfigurationRoute.pathname))}
              >
                {t(`routes.${userConfigurationRoute.name}`)}
              </IxMenuItem>
            </IxMenuCategory>
          )}
        </S.Menu>

        <S.MainContent>
          <div className="container">{children}</div>
        </S.MainContent>
      </IxBasicNavigation>
    </>
  )
}
