import { JSONSchema7Object } from 'json-schema'

import { CrudApiClient, type ApiClientResponse } from '@/core/api/base'
import { ProjectApiClient } from '@/core/api/project'
import type { IdType } from '@/core/models/base'
import type { DateRangeFilterOpts } from '@/core/models/filter'
import { Methods } from '@/core/models/http'
import type { PaginationFilterOpts } from '@/core/models/pagination'

import { TechnicalRoomApiClient } from '@/features/assets/api/technical-room'
import { DisconnectPeriodsCodec, type DisconnectPeriod } from '@/features/assets/models/disconnect-period'
import { HealthStatesCodec, type HealthState } from '@/features/assets/models/health-state'
import type { RawTechnicalRoom } from '@/features/assets/models/technical-room'
import {
  PagedRawTurnoutsCodec,
  RawTurnoutCodec,
  type RawPagedTurnouts,
  type RawTurnout,
  type Turnout,
} from '@/features/assets/models/turnout'
import {
  TurnoutStateStatisticsCodec,
  type TurnoutStateStatistics,
} from '@/features/assets/models/turnout-state-statistics'
import { TurnoutStatisticsCodec, type TurnoutStatistics } from '@/features/assets/models/turnout-statistics'

import type { TrackConfigurationFilterOpts } from '@/features/config/track/models/track-config-filter-opts'

export type QueryTurnoutFilterOpts = TrackConfigurationFilterOpts &
  PaginationFilterOpts &
  Pick<Turnout, 'operationState'> &
  Pick<Turnout, 'configurationStatus'> &
  Pick<Turnout, 'active'>

export type TurnoutStateStatisticsFilterOpts = TrackConfigurationFilterOpts
export type TurnoutHealthStatesFilterOpts = DateRangeFilterOpts

class TurnoutApiClient extends CrudApiClient<RawTurnout, RawTechnicalRoom, RawPagedTurnouts, QueryTurnoutFilterOpts> {
  protected getCreateBody(model: RawTurnout): JSONSchema7Object {
    const { active = true, engineeringId, name, analyticsTypeId = 0, turnoutType = 'UNDEFINED', trackPoint, turnoutFunctionalityType, waypoint } = model
    return {
      active, engineeringId, name, analyticsTypeId, turnoutType, turnoutFunctionalityType, waypoint,
      ...(trackPoint ? { trackPoint } : undefined) // Optional - only send it if defined
    }
  }

  protected getUpdateBody(model: RawTurnout): JSONSchema7Object {
    return this.getCreateBody(model)
  }

  async stateStatistics(
    projectId: IdType,
    opts: Partial<TurnoutStateStatisticsFilterOpts> | null | undefined,
    signal?: AbortSignal,
  ): Promise<ApiClientResponse<TurnoutStateStatistics>> {
    const path = [
      this.baseUrl,
      ProjectApiClient.endpointUrl,
      String(projectId),
      this.endpointUrl,
      'state-statistics',
    ].join('/')

    const { data, ...rest } = await this.request(path, Methods.GET, undefined, opts ?? undefined, signal)
    return { ...rest, data: await this.decode(data, TurnoutStateStatisticsCodec) }
  }

  async healthStates(
    turnoutId: IdType,
    opts: Partial<TurnoutHealthStatesFilterOpts>,
    signal?: AbortSignal,
  ): Promise<ApiClientResponse<HealthState[]>> {
    const path = [this.baseUrl, this.endpointUrl, String(turnoutId), 'health-states'].join('/')

    const { data, ...rest } = await this.request(path, Methods.GET, undefined, opts, signal)
    return { ...rest, data: await this.decode(data, HealthStatesCodec) }
  }

  async disconnectPeriod(turnoutId: IdType, signal?: AbortSignal): Promise<ApiClientResponse<DisconnectPeriod[]>> {
    const path = [this.baseUrl, this.endpointUrl, String(turnoutId), 'disconnect-periods'].join('/')

    const { data, ...rest } = await this.request(path, Methods.GET, undefined, undefined, signal)
    return { ...rest, data: await this.decode(data, DisconnectPeriodsCodec) }
  }

  async turnoutStatistics(turnoutId: IdType, signal?: AbortSignal): Promise<ApiClientResponse<TurnoutStatistics>> {
    const path = [this.baseUrl, this.endpointUrl, String(turnoutId), 'statistics'].join('/')

    const { data, ...rest } = await this.request(path, Methods.GET, undefined, undefined, signal)
    return { ...rest, data: await this.decode(data, TurnoutStatisticsCodec) }
  }
}

const instance = new TurnoutApiClient(
  'turnouts',
  RawTurnoutCodec,
  TechnicalRoomApiClient as CrudApiClient<RawTechnicalRoom>,
  undefined,
  PagedRawTurnoutsCodec,
)

export { instance as TurnoutApiClient }
