import { homeRoute, RouteConfig } from '@/core/routes'

export const routeToArr = <T extends RouteConfig<string, RouteConfig<string, any, any>, any>>(route: T) => {
  const breadcrumbs: RouteConfig<any, any>[] = [route]

  let head = route.parent

  while (head != null) {
    breadcrumbs.unshift(head)
    head = head.parent
  }

  breadcrumbs.unshift(homeRoute)

  return breadcrumbs
}
