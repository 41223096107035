import { JSX } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import { IxKeyValue, IxTile } from '@siemens/ix-react'

import { GlobalPageHeader } from '@/core/components/GlobalPageHeader'
import { LanguageSelect } from '@/core/components/LanguageSelect'
import { ThemeSelect } from '@/core/components/ThemeSelect'
import { appTitle } from '@/core/lib/config'
import { settingsRoute } from '@/core/routes'

export function SettingsPage(): JSX.Element {
  const { t } = useTranslation()
  const authDomain: string = sessionStorage.getItem('REACT_APP_AUTH_DOMAIN') ?? ''

  return (
    <>
      <Helmet>
        <title>
          {appTitle} - {t(`routes.${settingsRoute.name}`)}
        </title>
      </Helmet>

      <GlobalPageHeader route={settingsRoute} title={t(`routes.${settingsRoute.name}`)}>
        <div className="row g-3">
          <div className="col-6">
            <IxTile>
              <div slot="header">{t('App settings')}</div>

              <IxKeyValue label={t('Language')} style={{ border: 'none' }}>
                <span slot="custom-value">
                  <LanguageSelect />
                </span>
              </IxKeyValue>

              <IxKeyValue label={t('Theme')} style={{ border: 'none' }}>
                <span slot="custom-value">
                  <ThemeSelect />
                </span>
              </IxKeyValue>
            </IxTile>
          </div>

          <div className="col-6">
            <IxTile>
              <div slot="header">{t('User account settings')}</div>

              {t('Click on the following link to:')}

              <ul>
                <li>{t('Update password')}</li>
                <li>{t('Change e-mail address')}</li>
                <li>{t('Update display name')}</li>
              </ul>

              <a href={`${authDomain}/account`} rel="noreferrer" target="_blank">
                {t('Account management')}
              </a>
            </IxTile>
          </div>
        </div>
      </GlobalPageHeader>
    </>
  )
}
