import * as t from 'io-ts'

import { BaseModelCodec } from '@/core/models/base'

import { RawTurnoutCodec } from '@/features/assets/models/turnout'

export const DisconnectPeriodAssetTypeCodec = t.union([t.literal('turnout'), t.literal('technical_room')])
export type DisconnectPeriodAssetType = t.TypeOf<typeof DisconnectPeriodAssetTypeCodec>

export const BaseDisconnectPeriodCodec = t.type({
  assetType: DisconnectPeriodAssetTypeCodec,
  from: t.string,
  to: t.string,
  turnout: RawTurnoutCodec,
})
export const BaseDisconnectPeriodsCodec = t.array(BaseDisconnectPeriodCodec)
export type BaseDisconnectPeriod = t.TypeOf<typeof BaseDisconnectPeriodCodec>

export const DisconnectPeriodCodec = t.intersection([BaseModelCodec, BaseDisconnectPeriodCodec])
export const DisconnectPeriodsCodec = t.array(DisconnectPeriodCodec)
export type DisconnectPeriod = t.TypeOf<typeof DisconnectPeriodCodec>
