import { JSX } from 'react'
import type { ICellRendererParams } from 'ag-grid-community'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'

import { IxIconButton, IxTooltip } from '@siemens/ix-react'

import { editUserConfigurationRoute } from '@/core/routes/user'

import type { Turnout } from '@/features/assets/models/turnout'

export function ActionsCell({ data }: ICellRendererParams<Turnout>): JSX.Element | null {
  const { t } = useTranslation()

  if (data == null) {
    return null
  }

  return (
    <>
      <Link to={generatePath(editUserConfigurationRoute.pathname, { userId: String(data.id) })}>
        <IxIconButton data-tooltip={`turnout-config-table-edit-${data.id}`} icon="pen" ghost />
      </Link>

      <IxTooltip for={`[data-tooltip="turnout-config-table-edit-${data.id}"]`} placement="right">
        {t(`routes.${editUserConfigurationRoute.name}`)}
      </IxTooltip>
    </>
  )
}
