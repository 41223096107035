import { JSX, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IxButton, IxIcon, IxPill } from '@siemens/ix-react'

import { ErrorInfo } from '@/core/components/ErrorInfo'

import { ConfigValues, type ConfigValuesProps } from '@/features/config/threshold/ConfigValues'
import { useResetThresholds, useUpdateThresholds } from '@/features/config/threshold/parameter/meanpower/hooks/use-threshold'
import type { Threshold } from '@/features/config/threshold/parameter/meanpower/models/threshold'
import type { PointMachine } from '@/features/assets/models/point-machine'

import { ThresholdConfigValues, thresholdsToConfigValues, ThresholdType } from './lib'

export interface UpdateParameterThresholdsFormProps {
  onAfterSubmit?: () => void
  onCancel?: () => void
  onRemoveItem?: (item: PointMachine, remaining: PointMachine[]) => void
  pointMachines: PointMachine[]
  thresholds: Threshold[]
}

export function UpdateParameterThresholdsForm({
  onAfterSubmit,
  onCancel,
  ...props
}: UpdateParameterThresholdsFormProps): JSX.Element {
  const [pointMachines, setPointMachines] = useState(props.pointMachines)
  const [values, setValues] = useState<ThresholdConfigValues>()
  const { t } = useTranslation()
  const { trigger: updateThresholds, ...updateThresholdsData } = useUpdateThresholds()
  const { trigger: resetThresholds, ...resetThresholdsData } = useResetThresholds()

  useEffect(() => {
    const values = thresholdsToConfigValues(props.thresholds)

    setValues({
      time: values.time?.map((item) => ({ ...item, label: 'Time difference (s)' })),
      power: values.power?.map((item) => ({ ...item, label: 'Power difference (W)' })),
      work: values.work?.map((item) => ({ ...item, label: 'Work difference (J)' })),
    })
  }, [pointMachines.length, props.thresholds])

  const onChange = useCallback<NonNullable<ConfigValuesProps['onChange']>>((name, values) => {
    setValues((prev) => {
      if (prev == null) {
        return undefined
      }

      const type = name as ThresholdType
      const prevConfigValues = prev?.[type]
      const nextConfigValues = (prevConfigValues ?? []).map((item, index) => ({ ...item, value: values[index] }))

      return { ...prev, [type]: nextConfigValues }
    })
  }, [])

  const onRemoveItem = useCallback(
    (item: PointMachine) => {
      setPointMachines((prev) => {
        const newPointMachines = prev.filter((pm) => pm.id !== item.id)
        props.onRemoveItem?.(item, newPointMachines)
        return newPointMachines
      })
    },
    [props],
  )

  const onSave = useCallback(async () => {
    await updateThresholds({
      pointMachines,
      thresholds: props.thresholds,
      values: {
        time: { left: values?.time?.[0].value, right: values?.time?.[1].value },
        power: { left: values?.power?.[0].value, right: values?.power?.[1].value },
        work: { left: values?.work?.[0].value, right: values?.work?.[1].value },
      },
    })
    onAfterSubmit?.()
  }, [onAfterSubmit, pointMachines, props.thresholds, updateThresholds, values?.power, values?.time, values?.work])

  const onReset = useCallback(async () => {
    await resetThresholds({ pointMachines })
    onAfterSubmit?.()
  }, [onAfterSubmit, pointMachines, resetThresholds])

  return (
    <div className="row gx-5 gy-3 px-1">
      {updateThresholdsData.error != null && <ErrorInfo error={updateThresholdsData.error} />}
      {resetThresholdsData.error != null && <ErrorInfo error={resetThresholdsData.error} />}

      <div>
        {pointMachines.map((pm) => (
          <IxPill key={pm.id}>
            {pm.name}{' '}
            <IxIcon name="close" onClick={onRemoveItem.bind(null, pm)} size="12" style={{ cursor: 'pointer' }} />
          </IxPill>
        ))}
      </div>

      <div style={{ color: 'var(--theme-color-alarm-text)' }}>
        {t(
          'If the deviation threshold is low, the measured performance of a turn event has to be be within this narrow range to not cause a deterioration of the health score. The lower the deviation threshold, the more sensitive is the system towards alarm generation.',
        )}
      </div>

      <div className="row mt-5">
        <div className="col-6"></div>
        <div className="col-3 fs-6 fw-bold">{t('Thresholds left')}</div>
        <div className="col-3 fs-6 fw-bold">{t('Thresholds right')}</div>
      </div>

      <ConfigValues
        className="mt-2"
        description={t(
          'For example, if the threshold for time difference is set at 0,5 sec, it means that as soon as the turn time exceeds the optimal turn time (provided by optimal reference curves) by 0,5 sec, the fitness score of this turn event is voting towards a potential alarm.',
        )}
        name="time"
        onChange={onChange}
        title="Time"
        values={values?.time}
      />

      <ConfigValues
        className="mt-5"
        description={t(
          'For example, if the threshold for mean power difference is set at 300 Watts, it means that as soon as the turn event mean power exceeds the optimal turn event power (provided by optimal reference curves) by 300 Watts, the fitness score of this turn event is voting towards a potential alarm.',
        )}
        name="power"
        onChange={onChange}
        title="Power watt"
        values={values?.power}
      />

      <ConfigValues
        className="mt-5"
        description={t(
          'For example, if the threshold for work difference is set at 500 Joule, it means that as soon as the turn event work exceeds the optimal turn event work (provided by optimal reference curves) by 500 Joule, the fitness score of this turn event is voting towards a potential alarm.',
        )}
        name="work"
        onChange={onChange}
        title="Work WattHours"
        values={values?.work}
      />

      <div className="d-flex justify-content-between mt-5">
        <IxButton loading={updateThresholdsData.isMutating || resetThresholdsData.isMutating} onClick={onReset}>
          {t('Reset to default')}
        </IxButton>

        <div>
          <IxButton className="me-2" onClick={onCancel} outline>
            {t('Cancel')}
          </IxButton>

          <IxButton loading={updateThresholdsData.isMutating || resetThresholdsData.isMutating} onClick={onSave}>
            {t('Save thresholds')}
          </IxButton>
        </div>
      </div>
    </div>
  )
}
