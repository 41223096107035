import { JSX } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Empty } from '@/core/components/Empty'
import { GlobalPageHeader } from '@/core/components/GlobalPageHeader'
import { useParseNumber } from '@/core/hooks/use-parse-number'
import { appTitle } from '@/core/lib/config'

import { TurnoutDetail } from '@/features/assets/components/TurnoutDetail'
import { turnoutRoute } from '@/features/assets/routes/turnouts'

export function TurnoutPage(): JSX.Element {
  const { turnoutId } = useParams()
  const turnoutIdNum = useParseNumber(turnoutId)
  const { t } = useTranslation()

  if (turnoutId == null || turnoutIdNum == null) {
    return <Empty />
  }

  return (
    <>
      <Helmet>
        <title>
          {appTitle} - {t(`routes.${turnoutRoute.name}`)}
        </title>
      </Helmet>

      <GlobalPageHeader route={turnoutRoute} params={{ turnoutId }} title={t(`routes.${turnoutRoute.name}`)}>
        <TurnoutDetail turnoutId={turnoutIdNum} />
      </GlobalPageHeader>
    </>
  )
}
