import { CSSProperties, ForwardedRef, JSX, useCallback, useRef } from 'react'
import type { GridOptions } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { IxPagination, IxSpinner } from '@siemens/ix-react'

import { Empty, type EmptyProps } from '@/core/components/Empty'
import { ErrorInfo } from '@/core/components/ErrorInfo'
import {
  useColumnDefsWithCheckbox,
  useOnPageSelected,
  useOverlays,
  useResetPageOnDataChange,
  useSelectedIds,
  useSelectionChangedInternal,
  useTableRef,
} from '@/core/components/Table/hooks'
import type { BaseModel, PagedResultType } from '@/core/models/base'

import * as S from './styles'

export interface TableProps<T extends BaseModel> {
  className?: string
  columnDefs: GridOptions<T>['columnDefs']
  emptyProps?: EmptyProps
  error?: Error
  gridRef?: ForwardedRef<AgGridReact<T>>
  isLoading?: boolean
  onPageSelected?: (pageIndex: number) => unknown
  onSelectionChanged?: GridOptions<T>['onSelectionChanged']
  onSortChanged?: GridOptions<T>['onSortChanged']
  pagedData?: PagedResultType<T>
  paginationPageSize?: number
  resetPageOnDataChange?: boolean
  rowHeight?: GridOptions<T>['rowHeight']
  selectable?: boolean | { columnIndex: number }
  selectedIds?: number[]
  sortingOrder?: GridOptions<T>['sortingOrder']
  style?: CSSProperties
}

const DEFAULT_PAGE_SIZE = 15

export function Table<T extends BaseModel>({
  className,
  columnDefs,
  emptyProps,
  error,
  gridRef,
  isLoading,
  onPageSelected,
  onSelectionChanged,
  onSortChanged,
  pagedData,
  paginationPageSize,
  resetPageOnDataChange,
  rowHeight,
  selectable,
  selectedIds,
  sortingOrder,
  style,
}: TableProps<T>): JSX.Element {
  const ref = useRef<AgGridReact<T> | null>(null)

  useOverlays({ ref, isLoading, pagedData })
  useResetPageOnDataChange({ ref, pagedData, resetPageOnDataChange })
  useSelectedIds({ selectedIds, pagedData, ref })

  const onSelectionChangedInternal = useSelectionChangedInternal({ onSelectionChanged })
  const pageSelected = useOnPageSelected({ ref, onPageSelected })
  const tableRef = useTableRef({ gridRef, ref })

  const noRowsOverlay = useCallback(() => <Empty {...emptyProps} />, [emptyProps])
  const columnDefsWithCheckbox = useColumnDefsWithCheckbox({ columnDefs, selectable })

  if (error != null) {
    return <ErrorInfo error={error} />
  }

  return (
    <S.TableWrapper className={`ag-theme-alpine-dark ag-theme-ix ${className ?? ''}`} style={style}>
      <AgGridReact
        animateRows
        domLayout="autoHeight"
        loadingOverlayComponent={IxSpinner}
        noRowsOverlayComponent={noRowsOverlay}
        onSelectionChanged={onSelectionChangedInternal}
        pagination
        paginationPageSize={paginationPageSize ?? DEFAULT_PAGE_SIZE}
        ref={tableRef}
        rowSelection={Boolean(selectable) ? 'multiple' : undefined}
        suppressMovableColumns
        suppressPaginationPanel
        suppressRowClickSelection
        sortingOrder={sortingOrder}
        rowData={pagedData?.content}
        columnDefs={columnDefsWithCheckbox}
        rowHeight={rowHeight}
        onSortChanged={onSortChanged}
      />

      <div className="d-flex justify-content-end">
        {pagedData?.content != null && pagedData?.content.length > 0 && (
          <IxPagination
            itemCount={6}
            showItemCount
            count={
              paginationPageSize !== undefined
                ? Math.ceil(pagedData.totalElements / paginationPageSize)
                : pagedData?.totalPages
            }
            onPageSelected={pageSelected}
            selectedPage={pagedData?.number}
          />
        )}
      </div>
    </S.TableWrapper>
  )
}
