import { Key } from 'swr'

import { useSWR, type SWRResponse } from '@/core/hooks/swr'
import type { IdType } from '@/core/models/base'

import {
  TurnoutTrendCurveApiClient,
  type TurnoutTrendCurveFilterOpts,
} from '@/features/assets/api/turnout-trend-curve'
import type { TurnoutTrendCurve } from '@/features/assets/models/turnout-trend-curve'

export const getTurnoutTrendCurveKey = (
  parentId: Parameters<typeof useTurnoutTrendCurve>[0],
  opts: Parameters<typeof useTurnoutTrendCurve>[1],
): Key => {
  if (parentId == null || opts == null) {
    return null
  }

  return ['useTurnoutTrendCurve', parentId, ...JSON.stringify(opts)]
}

export const useTurnoutTrendCurve = (
  parentId: IdType | undefined | null,
  opts?: Partial<TurnoutTrendCurveFilterOpts> | null,
): SWRResponse<TurnoutTrendCurve> => {
  return useSWR(getTurnoutTrendCurveKey(parentId, opts), () => {
    return TurnoutTrendCurveApiClient.trends(parentId!, opts)
  })
}
