import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import cx from 'clsx'
import { decode } from 'io-ts-promise'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import type { DateTimeSelectEvent, IxDatetimePickerCustomEvent, IxSelectCustomEvent } from '@siemens/ix'
import { IxButton, IxDatetimePicker, IxIcon, IxSelect, IxSelectItem, IxTypography } from '@siemens/ix-react'

import { ErrorInfo } from '@/core/components/ErrorInfo'
import { ModalV2 } from '@/core/components/ModalV2'

import { useAreas } from '@/features/assets/hooks/use-area'
import { useInterlockings } from '@/features/assets/hooks/use-interlocking'
import { useTechnicalRooms } from '@/features/assets/hooks/use-technical-room'
import { useQueryTurnouts, useTurnout } from '@/features/assets/hooks/use-turnout'
import { useIsStaticWorkItem } from '@/features/maintenance-reports/hooks/use-is-static-work-item'
import { useCreateMaintenanceTask } from '@/features/maintenance-reports/hooks/use-maintenance-task'
import {
  baseMaintenanceTaskCodec,
  type BaseMaintenanceTask,
} from '@/features/maintenance-reports/models/maintenance-task'
import { useMaintenanceTaskModalStore } from '@/features/maintenance-reports/store/modal-store'
import { STATIC_WORK_ITEMS } from '@/features/maintenance-reports/util/static-work-items'

export const MaintenanceTaskModalContent: FC<{ onClose: () => void }> = ({ onClose }) => {
  const readOnly = useMaintenanceTaskModalStore((state) => state.readOnly)
  return (
    <div className="d-flex flex-column gap-4">
      {!readOnly && <TurnoutFilter />}
      <TaskTurnout />
      <TaskReasonRadioGroup />
      <TaskWorkItems />
      <TaskDateTime />
      <TaskComment />
      <TaskActionButtons onClose={onClose} />
    </div>
  )
}

const TurnoutFilter: FC = () => {
  const { t } = useTranslation()
  const turnoutId = useMaintenanceTaskModalStore((state) => state.task.turnoutId)
  const isTurnoutSelectionDisabled = useMaintenanceTaskModalStore((state) => state.isTurnoutSelectionDisabled)

  const area = useMaintenanceTaskModalStore((state) => state.area)
  const setArea = useMaintenanceTaskModalStore((state) => state.setArea)
  const resetTurnoutId = useMaintenanceTaskModalStore((state) => state.resetTurnoutId)

  const interlocking = useMaintenanceTaskModalStore((state) => state.interlocking)
  const setInterlocking = useMaintenanceTaskModalStore((state) => state.setInterlocking)
  const resetInterlocking = useMaintenanceTaskModalStore((state) => state.resetInterlocking)

  const techRoom = useMaintenanceTaskModalStore((state) => state.techRoom)
  const setTechRoom = useMaintenanceTaskModalStore((state) => state.setTechRoom)
  const resetTechRoom = useMaintenanceTaskModalStore((state) => state.resetTechRoom)

  const { data: areas } = useAreas()
  const selectArea = (e: IxSelectCustomEvent<string | string[]>) => {
    resetInterlocking()
    resetTechRoom()
    resetTurnoutId()
    setArea(JSON.parse(String(e.target.value)))
  }

  const { data: interlockings } = useInterlockings(area?.id)
  const selectInterlocking = (e: IxSelectCustomEvent<string | string[]>) => {
    resetTechRoom()
    resetTurnoutId()
    setInterlocking(JSON.parse(String(e.target.value)))
  }

  const { data: techRooms } = useTechnicalRooms(interlocking?.id)
  const selectTechRoom = (e: IxSelectCustomEvent<string | string[]>) => {
    resetTurnoutId()
    setTechRoom(JSON.parse(String(e.target.value)))
  }

  const { data: selectedTurnout } = useTurnout(turnoutId)
  useEffect(() => {
    // When turnoutId in state changes,
    // automatically set filters (area, interlocking, tech room) accordingly
    if (selectedTurnout?.technicalRoom) {
      setTechRoom(selectedTurnout.technicalRoom)
    }
    if (selectedTurnout?._technicalRoom?.interlocking) {
      setInterlocking(selectedTurnout?._technicalRoom?.interlocking)
    }
    if (selectedTurnout?._technicalRoom?._interlocking?.area) {
      setArea(selectedTurnout?._technicalRoom?._interlocking?.area)
    }
  }, [selectedTurnout, setArea, setInterlocking, setTechRoom])

  return (
    <div className="d-flex flex-column gap-2">
      <IxTypography format="label" textColor="soft">
        Filter
      </IxTypography>

      <div className="d-flex gap-2">
        <IxSelect
          onValueChange={selectArea}
          // see https://github.com/siemens/ix/issues/1554
          // Using selectedIndices instead
          selectedIndices={[JSON.stringify(area) ?? 0]}
          // value={JSON.stringify(area)}
          i18nPlaceholder={t('assets.maintenance_reports.create.filter.select_area_short')}
          i18nSelectListHeader={t('assets.maintenance_reports.create.filter.select_area_long')}
          className="flex-grow-1"
          disabled={isTurnoutSelectionDisabled}
        >
          {areas?.map((area) => (
            <IxSelectItem
              key={`area_${area.id}`}
              label={area.name}
              value={JSON.stringify({ id: area.id, name: area.name })}
            />
          ))}
        </IxSelect>
        <IxSelect
          onValueChange={selectInterlocking}
          // see https://github.com/siemens/ix/issues/1554
          // Using selectedIndices instead
          selectedIndices={[JSON.stringify(interlocking) ?? 0]}
          // value={JSON.stringify(interlocking)}
          i18nPlaceholder={t('assets.maintenance_reports.create.filter.select_interlocking_short')}
          i18nSelectListHeader={t('assets.maintenance_reports.create.filter.select_interlocking_long')}
          className="flex-grow-1"
          disabled={isTurnoutSelectionDisabled}
        >
          {interlockings?.map((interlocking) => (
            <IxSelectItem
              key={`interlocking_${interlocking.id}`}
              label={interlocking.name}
              value={JSON.stringify({ id: interlocking.id, name: interlocking.name })}
            />
          ))}
        </IxSelect>
        <IxSelect
          onValueChange={selectTechRoom}
          // see https://github.com/siemens/ix/issues/1554
          // Using selectedIndices instead
          selectedIndices={[JSON.stringify(techRoom ?? 0)]}
          //value={JSON.stringify(techRoom)}
          i18nPlaceholder={t('assets.maintenance_reports.create.filter.select_technical_room_short')}
          i18nSelectListHeader={t('assets.maintenance_reports.create.filter.select_technical_room_long')}
          className="flex-grow-1"
          disabled={isTurnoutSelectionDisabled}
        >
          {techRooms?.map((techRoom) => (
            <IxSelectItem
              key={`techroom_${techRoom.id}`}
              label={techRoom.name}
              value={JSON.stringify({ id: techRoom.id, name: techRoom.name })}
            />
          ))}
        </IxSelect>
      </div>
    </div>
  )
}

const TaskTurnout: FC = () => {
  const { t } = useTranslation()
  const readOnly = useMaintenanceTaskModalStore((state) => state.readOnly)
  const isTurnoutSelectionDisabled = useMaintenanceTaskModalStore((state) => state.isTurnoutSelectionDisabled)
  const area = useMaintenanceTaskModalStore((state) => state.area)
  const interlocking = useMaintenanceTaskModalStore((state) => state.interlocking)
  const techRoom = useMaintenanceTaskModalStore((state) => state.techRoom)
  const setTurnoutId = useMaintenanceTaskModalStore((state) => state.setTurnoutId)
  const taskTurnoutId = useMaintenanceTaskModalStore((state) => state.task.turnoutId)

  const { data: turnouts } = useQueryTurnouts({
    areaName: area?.name,
    interlockingName: interlocking?.name,
    technicalRoomName: techRoom?.name,
  })
  const selectTurnout = (e: IxSelectCustomEvent<string | string[]>) => {
    setTurnoutId(Number(e.target.value))
  }

  return (
    <div className="d-flex flex-column gap-2">
      <IxTypography format="label" textColor="soft">
        {readOnly
          ? t('assets.maintenance_reports.view.maintenance_report_for')
          : t('assets.maintenance_reports.create.create_maintenance_report_for')}
      </IxTypography>
      {readOnly ? (
        <IxTypography format="body" bold style={{ color: 'var(--theme-color-dynamic)' }}>
          {turnouts?.content.find((turnout) => turnout.id === taskTurnoutId)?.name}
        </IxTypography>
      ) : (
        <IxSelect
          onValueChange={selectTurnout}
          // value={taskTurnoutId?.toString()} // value is not displayed initially, only after opening it once
          // see https://github.com/siemens/ix/issues/1554
          // Using selectedIndices instead
          selectedIndices={taskTurnoutId?.toString() ? [taskTurnoutId?.toString()] : []}
          i18nPlaceholder={t('assets.maintenance_reports.create.filter.select_turnout_short')}
          i18nSelectListHeader={t('assets.maintenance_reports.create.filter.select_turnout_long')}
          disabled={isTurnoutSelectionDisabled}
        >
          {turnouts?.content.map((turnout) => (
            <IxSelectItem key={`turnout_${turnout.id}`} label={turnout.name} value={turnout.id.toString()} />
          ))}
        </IxSelect>
      )}
    </div>
  )
}

const TaskReasonRadioGroup: FC = () => {
  const { t } = useTranslation()
  const readOnly = useMaintenanceTaskModalStore((state) => state.readOnly)
  const taskReason = useMaintenanceTaskModalStore((state) => state.task.reason)
  const setReason = useMaintenanceTaskModalStore((state) => state.setReason)

  const selectedReason = taskReason?.toLowerCase()

  const isMandatoryManufacturer = selectedReason === 'mandatory_manufacturer'
  const isMandatoryOperator = selectedReason === 'mandatory_operator'
  const isIncident = selectedReason === 'incident'

  return (
    <div className="d-flex flex-column gap-2">
      <IxTypography format="label" textColor="soft">
        {readOnly ? t('assets.maintenance_reports.view.reason') : t('assets.maintenance_reports.create.reason')}
      </IxTypography>
      <div className="radio-button">
        <input
          id="checkbox_reason_mandatory_manufacturer"
          name="reason"
          type="radio"
          checked={isMandatoryManufacturer}
          onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.checked && setReason('mandatory_manufacturer')}
          disabled={readOnly && !isMandatoryManufacturer}
        />
        <label
          htmlFor="checkbox_reason_mandatory_manufacturer"
          style={isMandatoryManufacturer ? { color: 'var(--theme-color-dynamic' } : {}}
        >
          {t('assets.maintenance_reports.reason.mandatory_manufacturer')}
        </label>

        <input
          id="checkbox_reason_mandatory_operator"
          name="reason"
          type="radio"
          checked={isMandatoryOperator}
          onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.checked && setReason('mandatory_operator')}
          disabled={readOnly && !isMandatoryOperator}
        />
        <label
          htmlFor="checkbox_reason_mandatory_operator"
          style={isMandatoryOperator ? { color: 'var(--theme-color-dynamic' } : {}}
        >
          {t('assets.maintenance_reports.reason.mandatory_operator')}
        </label>

        <input
          id="checkbox_reason_incident"
          name="reason"
          type="radio"
          checked={isIncident}
          onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.checked && setReason('incident')}
          disabled={readOnly && !isIncident}
        />
        <label htmlFor="checkbox_reason_incident" style={isIncident ? { color: 'var(--theme-color-dynamic' } : {}}>
          {t('assets.maintenance_reports.reason.incident')}
        </label>
      </div>
    </div>
  )
}

const TaskWorkItems: FC = () => {
  const { t } = useTranslation()
  const readOnly = useMaintenanceTaskModalStore((state) => state.readOnly)
  const work = useMaintenanceTaskModalStore((state) => state.task.work)

  const freeTextWorkItems = useMemo(() => work?.filter((w) => !STATIC_WORK_ITEMS.includes(w)), [work])
  const workItems = useMemo(() => {
    if (readOnly) {
      return STATIC_WORK_ITEMS?.concat(freeTextWorkItems ?? [])
    }

    return STATIC_WORK_ITEMS
  }, [freeTextWorkItems, readOnly])

  return (
    <div className="d-flex flex-column gap-2">
      <IxTypography format="label" textColor="soft">
        {readOnly ? t('assets.maintenance_reports.view.work') : t('assets.maintenance_reports.create.work')}
      </IxTypography>
      <div className={cx('d-flex flex-wrap', { 'gap-3': !readOnly, 'gap-2': readOnly })}>
        {workItems.map((workItem) => (
          <CheckField workItem={workItem} key={workItem} />
        ))}
        {!readOnly && <FreetextCheckField />}
      </div>
    </div>
  )
}

const CheckField: FC<{ workItem: string }> = ({ workItem }) => {
  const { t } = useTranslation()
  const work = useMaintenanceTaskModalStore((state) => state.task.work)
  const readOnly = useMaintenanceTaskModalStore((state) => state.readOnly)
  const addWorkItem = useMaintenanceTaskModalStore((state) => state.addWorkItem)
  const removeWorkItem = useMaintenanceTaskModalStore((state) => state.removeWorkItem)
  const isEnabled = useMemo(() => work?.includes(workItem), [work, workItem])
  const isStaticWorkItem = useIsStaticWorkItem(workItem)

  const checkedChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        addWorkItem(workItem)
      } else {
        removeWorkItem(workItem)
      }
    },
    [addWorkItem, removeWorkItem, workItem],
  )

  if (readOnly) {
    return (
      <div className="d-flex gap-1 align-items-center" style={{ minWidth: 130 }}>
        <IxIcon
          name={isEnabled ? 'check' : 'cancel'}
          style={{ color: isEnabled ? 'var(--theme-color-dynamic' : 'var(--theme-color-soft-text)' }}
        />
        <IxTypography
          style={{ color: isEnabled ? 'var(--theme-color-dynamic' : 'var(--theme-color-soft-text)' }}
          format="label"
        >
          {isStaticWorkItem ? t(`assets.maintenance_reports.work.${workItem}`) : workItem}
        </IxTypography>
      </div>
    )
  }

  return (
    <div className="d-flex" style={{ minWidth: 130 }}>
      <input type="checkbox" id={`checkbox_${workItem}`} onChange={checkedChanged} />
      <label htmlFor={`checkbox_${workItem}`} style={isEnabled ? { color: 'var(--theme-color-dynamic' } : {}}>
        {t(`assets.maintenance_reports.work.${workItem}`)}
      </label>
    </div>
  )
}

const FreetextCheckField: FC = () => {
  const { t } = useTranslation()
  const freeTextWorkItem = useMaintenanceTaskModalStore((state) => state.freeTextWorkItem)
  const setFreeTextWorkItem = useMaintenanceTaskModalStore((state) => state.setFreeTextWorkItem)
  const inputRef = useRef<HTMLInputElement>(null)
  const textChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setFreeTextWorkItem(e.target.value),
    [setFreeTextWorkItem],
  )

  const checkedChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.checked) {
        setFreeTextWorkItem('')
        if (inputRef.current) {
          inputRef.current.value = ''
        }
      }
    },
    [setFreeTextWorkItem],
  )

  return (
    <div className="d-flex align-items-center">
      <input type="checkbox" id="checkbox_01" onChange={checkedChanged} checked={Boolean(freeTextWorkItem)} />
      <label htmlFor="checkbox_01">
        <input
          ref={inputRef}
          defaultValue={freeTextWorkItem}
          placeholder={t('assets.maintenance_reports.work.enter_free_text')}
          type="text"
          onChange={textChanged}
        />
      </label>
    </div>
  )
}

const TaskDateTime: FC = () => {
  const { t, i18n } = useTranslation()
  const dueAt = useMaintenanceTaskModalStore((state) => state.task.dueAt)
  const setDueAt = useMaintenanceTaskModalStore((state) => state.setDueAt)
  const readOnly = useMaintenanceTaskModalStore((state) => state.readOnly)

  return (
    <div className="d-flex gap-4 align-items-center">
      {!readOnly && <TaskDatePicker onISODateTimeSelected={setDueAt} />}
      {dueAt && (
        <IxTypography style={{ color: 'var(--theme-color-dynamic' }} className="d-flex gap-1 align-items-center">
          {readOnly && <IxIcon name="calendar" />}
          <span>{t('assets.maintenance_reports.doneAt')}</span>
          <span>{DateTime.fromISO(dueAt).setLocale(i18n.language).toLocaleString(DateTime.DATETIME_SHORT)}</span>
        </IxTypography>
      )}
    </div>
  )
}

const TaskActionButtons: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation()
  const task = useMaintenanceTaskModalStore((state) => state.task)
  const readOnly = useMaintenanceTaskModalStore((state) => state.readOnly)
  const freeTextWorkItem = useMaintenanceTaskModalStore((state) => state.freeTextWorkItem)
  const { trigger, isMutating: isSaving, error } = useCreateMaintenanceTask()

  const saveTask = useCallback(() => {
    trigger({
      ...task,
      work: freeTextWorkItem ? [...(task.work ?? []), freeTextWorkItem] : task.work,
    } as BaseMaintenanceTask)
      .then(() => {
        onClose()
      })
      .catch(() => {})
  }, [freeTextWorkItem, onClose, task, trigger])

  const [isSaveEnabled, setIsSaveEnabled] = useState(false)
  useEffect(() => {
    decode(baseMaintenanceTaskCodec, task)
      .then(() => setIsSaveEnabled(true))
      .catch((e: any) => {
        setIsSaveEnabled(false)
      })
  }, [task])

  return (
    <div className="d-flex flex-column gap-2">
      {error && <ErrorInfo error={error} />}
      <div className="d-flex gap-4 align-self-end">
        <IxButton
          icon="close"
          variant={readOnly ? 'primary' : 'secondary'}
          className="align-self-end"
          onClick={onClose}
        >
          {readOnly ? t('assets.maintenance_reports.view.close') : t('assets.maintenance_reports.create.cancel')}
        </IxButton>
        {!readOnly && (
          <IxButton
            icon="disk"
            variant="primary"
            disabled={!isSaveEnabled || isSaving}
            onClick={saveTask}
            loading={isSaving}
          >
            {t('assets.maintenance_reports.create.save')}
          </IxButton>
        )}
      </div>
    </div>
  )
}

const TaskDatePicker: FC<{ onISODateTimeSelected: (isoDateTime: string) => void }> = ({ onISODateTimeSelected }) => {
  const { t, i18n } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const confirmDateSelection = (e: IxDatetimePickerCustomEvent<DateTimeSelectEvent>) => {
    const isoDateTime = DateTime.fromFormat(`${e.detail.from} ${e.detail.time}`, 'yyyy-LL-dd HH:mm:ss').toISO({
      suppressSeconds: true,
    })
    if (isoDateTime) {
      onISODateTimeSelected(isoDateTime)
    }
    setIsOpen(false)
  }

  if (!isOpen) {
    return (
      <IxButton icon="calendar" onClick={() => setIsOpen(true)} className="align-self-start">
        {t('assets.maintenance_reports.doneAt')}
      </IxButton>
    )
  }

  return (
    <ModalV2
      title={t('assets.maintenance_reports.doneAt')}
      onClose={() => setIsOpen(false)}
      styles={{ modal: { width: 'unset' } }}
    >
      <IxDatetimePicker
        range={false}
        from={DateTime.now().toFormat('yyyy-LL-dd')}
        time={DateTime.now().toFormat('HH:mm:ss')}
        onDateSelect={confirmDateSelection}
        locale={i18n.language}
        showSeconds={false}
        i18nDone="Übernehmen"
        dateFormat="yyyy-LL-dd"
        timeFormat="HH:mm:ss"
      />
    </ModalV2>
  )
}

const TaskComment: FC = () => {
  const { t } = useTranslation()
  const comment = useMaintenanceTaskModalStore((state) => state.task.comment)
  const readOnly = useMaintenanceTaskModalStore((state) => state.readOnly)
  const setComment = useMaintenanceTaskModalStore((state) => state.setComment)

  return (
    <div className="d-flex flex-column gap-2">
      <IxTypography format="label" textColor="soft">
        {readOnly ? t('assets.maintenance_reports.view.comment') : t('assets.maintenance_reports.create.comment.title')}
      </IxTypography>

      {readOnly ? (
        <IxTypography>{comment}</IxTypography>
      ) : (
        <textarea
          placeholder={t('assets.maintenance_reports.create.comment.placeholder')}
          maxLength={240}
          onChange={(e) => setComment(e.target.value)}
        />
      )}
    </div>
  )
}
