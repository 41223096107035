import React, { FC, PropsWithChildren, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { IxButton } from '@siemens/ix-react'

type ModalInlineFooterProps = {
  onCancel?:() => void
  /**
   * If nextButton is passed, the props 'nextDisabled', 'loading', 'onNext', 'nextCopy', 'nextButtonType' will have no effect
   */
  nextButton?:React.JSX.Element
  nextDisabled?:boolean
  onNext?:() => void
  loading?:boolean
  /**
    copy on the next button
   */
  nextCopy?:string | null
  cancelCopy?:string | null
  nextButtonType?:'submit'
}

/**
 * Individual Modal footer which could be passed to the content of a modal (but behaves like a "real" modal footer)
 * ('inlineFooter' must be set for the ModalContent component)
 * @param props
 * @constructor
 */
export const ModalInlineFooter: FC<PropsWithChildren<ModalInlineFooterProps>> = (props): ReactElement => {
  const { t } = useTranslation()

  return (
    <div className="ModalInlineFooter d-flex justify-content-between mt-5 fixed-bottom p-4 modal-footer">
      <div>
        {props.children}
      </div>

      <div className={"d-flex flex-row"}>
        {props.onCancel &&
          <IxButton className="me-2" onClick={props.onCancel} outline>
            {props.cancelCopy ?? t('Cancel')}
          </IxButton>
        }

        {props.nextButton ?? (
          <IxButton
            disabled={props.nextDisabled}
            loading={props.loading}
            type={props.nextButtonType ?? 'button'}
            onClick={props.onNext}
          >
            {props.nextCopy ?? t('Submit')}
          </IxButton>
        )}
      </div>
    </div>


  )
}