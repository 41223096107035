import { CSSProperties, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { IxEmptyState } from '@siemens/ix-react'

export interface EmptyProps {
  className?: string
  style?: CSSProperties
  header?: string
  icon?: string
}

export function Empty(props: PropsWithChildren<EmptyProps>) {
  const { t } = useTranslation()

  return <IxEmptyState {...props} header={props.header ?? t('No data')} icon={props.icon ?? 'warning'} />
}
