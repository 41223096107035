import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'

import { IxButton, IxSpinner, showToast } from '@siemens/ix-react'

import { createErrorObject } from '@/core/components/ErrorInfo'
import { convertError } from '@/core/models/service-error'

import { useResetThresholds } from '@/features/config/threshold/parameter/meanpower/hooks/use-threshold'
import { useMeanpowerThresholdConfigStore } from '@/features/config/threshold/parameter/meanpower/store/meanpower-threshold-config-store'
import { editParameterMeanpowerThresholdConfigurationRoute } from '@/features/config/threshold/threshold-route'
import { usePointMachines } from '@/features/assets/hooks/use-point-machine'

export const MeanpowerThresholdActionPanel: FC = () => {
  const { t } = useTranslation()
  const { trigger: resetThresholds, ...resetThresholdsData } = useResetThresholds()
  const { data: pointMachines } = usePointMachines()
  const selectedPointMachineIds = useMeanpowerThresholdConfigStore((state) => state.selectedPointMachineIds)

  const onReset = useCallback(async () => {
    const selectedPointMachines = pointMachines?.filter((pm) => selectedPointMachineIds?.includes(pm.id))

    if (selectedPointMachines == null) {
      return
    }

    let loadingToast: Awaited<ReturnType<typeof showToast>>

    try {
      loadingToast = await showToast({
        message: (
          <div className="d-flex align-items-center">
            {t('Updating thresholds')} <IxSpinner className="ms-1" />
          </div>
        ),
        autoClose: false,
      })
    } catch (error) {
      console.error(error)
    }

    try {
      await resetThresholds({ pointMachines: selectedPointMachines })

      setTimeout(() => {
        loadingToast.close()
        showToast({ message: t('Successfully updated thresholds'), type: 'success' })
      }, 500)
    } catch (error) {
      setTimeout(() => {
        loadingToast.close()
        showToast({
          title: t('Updating thresholds was unsuccessful') ?? undefined,
          message: createErrorObject(convertError(error)).message.join('\n\n'),
          type: 'error',
        })
      }, 500)
    }
  }, [pointMachines, resetThresholds, selectedPointMachineIds, t])

  return (
    <div className="d-flex justify-content-end">
      <IxButton
        className="me-2"
        disabled={!selectedPointMachineIds.length}
        loading={resetThresholdsData.isMutating}
        onClick={onReset}
        outline
      >
        {t('Reset to default')}
      </IxButton>

      <Link
        to={generatePath(editParameterMeanpowerThresholdConfigurationRoute.pathname, {
          pointMachineId: selectedPointMachineIds.join(','),
        })}
      >
        <IxButton disabled={!selectedPointMachineIds.length}>{t('Configure')}</IxButton>
      </Link>
    </div>
  )
}
