import * as t from 'io-ts'

export const IdTypeCodec = t.number
export type IdType = t.TypeOf<typeof IdTypeCodec>

export const BaseModelCodec = t.type({
  id: IdTypeCodec,
})
export type BaseModel = t.TypeOf<typeof BaseModelCodec>

export const NamedTypeCodec = t.type({
  name: t.string,
})
export type NamedType = t.TypeOf<typeof NamedTypeCodec>

export function nullableType<A>(type: t.Type<A>): t.UnionC<[t.NullC, t.UndefinedC, t.Type<A>]> {
  return t.union([t.null, t.undefined, type])
}

export const FkCodec = nullableType(t.number)

export const DirectionCodec = t.union([t.literal('left'), t.literal('right')])
export type Direction = t.TypeOf<typeof DirectionCodec>

export const PagingOptsCodec = t.type({
  pageNo: t.number,
  pageSize: t.number,
})
export type PagingOpts = t.TypeOf<typeof PagingOptsCodec>

const PagedBaseTypeCodec = t.type({
  empty: t.boolean,
  first: t.boolean,
  last: t.boolean,
  number: t.number,
  numberOfElements: t.number,
  pageable: t.type({
    sort: t.type({
      empty: t.boolean,
      sorted: t.boolean,
      unsorted: t.boolean,
    }),
    offset: t.number,
    paged: t.boolean,
    pageNumber: t.number,
    pageSize: t.number,
    unpaged: t.boolean,
  }),
  size: t.number,
  sort: t.type({
    empty: t.boolean,
    sorted: t.boolean,
    unsorted: t.boolean,
  }),
  totalElements: t.number,
  totalPages: t.number,
})

export type PagedResultType<C> = t.TypeOf<typeof PagedBaseTypeCodec> & { content: C[] }

export const PagedResultCodec = <C extends t.Mixed>(codec: C) =>
  t.intersection([
    t.type({
      content: codec,
    }),
    PagedBaseTypeCodec,
  ])

export function caseInsensitive(value: string): t.UnionC<[t.LiteralC<string>, t.LiteralC<string>]> {
  return t.union([t.literal(value.toUpperCase()), t.literal(value.toLowerCase())])
}
