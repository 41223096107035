import { ReactElement, useRef } from 'react'
import type { AgGridReact } from 'ag-grid-react'
import { Outlet } from 'react-router-dom'

import type { IdType } from '@/core/models/base'

import { ActionButtons } from '@/features/maintenance-reports/components/ActionButtons'
import { Filter } from '@/features/maintenance-reports/components/Filter'
import { Table } from '@/features/maintenance-reports/components/Table'
import { MaintenanceReportsProvider } from '@/features/maintenance-reports/store/store'

type BaseProps = {
  turnoutName?: string
}
export type ReadOnlyProps = BaseProps & {
  readOnly?: true
  onViewReportClick: (reportId: IdType) => void
}
export type ReadAndCreateProps = BaseProps & {
  readOnly?: false
  onViewReportClick: (reportId: IdType) => void
  onCreateReportClick: () => void
}
type AllProps = BaseProps & {
  readOnly?: boolean
  onViewReportClick: (reportId: IdType) => void
  onCreateReportClick?: () => void
}

/**
 * @param turnoutName - Optional if you want to only show reports for a specific turnout
 * We need the turnout name instead of the id, because the query endpoint only has 'assetName' as parameter
 * @param readOnly - if true, hides the 'create report' button - default false
 * @param onViewReportClick - handling the click on the view report icon in the table
 * @param onCreateReportClick - optional if readOnly = true - handling the click on the 'Create report' button
 */
export function MaintenanceReportsPageContent({ turnoutName, readOnly, onViewReportClick }: ReadOnlyProps): ReactElement
export function MaintenanceReportsPageContent({
  turnoutName,
  readOnly,
  onViewReportClick,
  onCreateReportClick,
}: ReadAndCreateProps): ReactElement
export function MaintenanceReportsPageContent({
  turnoutName,
  readOnly = false,
  onViewReportClick,
  onCreateReportClick = () => {},
}: AllProps): ReactElement {
  const gridRef = useRef<AgGridReact | null>(null)

  return (
    <div className="d-flex flex-column gap-5">
      <MaintenanceReportsProvider turnoutName={turnoutName}>
        {!turnoutName && <Filter />}
        <div className="d-flex flex-column gap-2">
          <ActionButtons gridRef={gridRef} readOnly={readOnly} onCreateReportClick={onCreateReportClick} />
          <Table gridRef={gridRef} onViewReportClick={onViewReportClick} />
        </div>
        <Outlet />
      </MaintenanceReportsProvider>
    </div>
  )
}
