import { JSX } from 'react'
import { useTranslation } from 'react-i18next'

import type { QueryAlarmEventsFilterOpts } from '@/features/alarms/api/alarm-event'

import { AlarmTabs } from './AlarmTabs'

export interface AlarmsTileProps {
  filterOpts: Partial<QueryAlarmEventsFilterOpts>
}

export function AlarmsTile({ filterOpts }: AlarmsTileProps): JSX.Element {
  const { t } = useTranslation()

  return <AlarmTabs filterOpts={filterOpts} title={t('Alarms overview')} />
}
