import { JSX, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { DataFilter, type DataFilterProps } from '@/core/components/DataFilter/DataFilter'

import type { PointMachine } from '@/features/assets/models/point-machine'

export interface PointMachineEventFiltersProps {
  pointMachines: PointMachine[]
}

export function PointMachineEventFilters({
  pointMachines,
  ...rest
}: PointMachineEventFiltersProps & Pick<DataFilterProps, 'onChange' | 'value'>): JSX.Element {
  const { t } = useTranslation()

  const fields = useMemo<NonNullable<DataFilterProps['fields']>>(() => {
    return [
      {
        path: 'pointMachineName',
        type: 'toggle',
        options: pointMachines.map((pm) => ({ label: pm.name, value: pm.name })),
      },
      {
        path: 'direction',
        type: 'toggle',
        options: [
          { label: t('models.event.direction-left'), value: 'left' },
          { label: t('models.event.direction-right'), value: 'right' },
        ],
      },
      {
        path: 'reference',
        type: 'toggle',
        options: [{ label: t('routes.Reference curves'), value: 'true' }],
      },
    ]
  }, [pointMachines, t])

  useEffect(() => {
    const onChange = rest.onChange
    onChange?.(
      fields.slice(0, -1).map((field) => ({
        ...field,
        value: field.options == null ? '' : field.options.map((option) => option.value),
      })),
    )
  }, [fields, rest.onChange])

  return (
    <div className="row gx-4 gy-2">
      <DataFilter {...rest} fields={fields} removeGrid />
    </div>
  )
}
