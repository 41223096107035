import { createBrowserRouter, Outlet, RouteObject } from 'react-router-dom'

import { PageLayout } from '@/core/components/PageLayout'
import { Error404Page } from '@/core/pages/404'
import { FaqPage } from '@/core/pages/faq'
import { HomePage } from '@/core/pages/home'
import { LogoutPage } from '@/core/pages/logout'
import { SettingsPage } from '@/core/pages/settings'
import { homeRoute, logoutRoute, settingsRoute } from '@/core/routes'
import { faqRoute } from '@/core/routes/faq'
import { editUserConfigurationRoute, userConfigurationRoute } from '@/core/routes/user'

import { AlarmsPage } from '@/features/alarms/pages/AlarmsPage'
import { alarmsRoute } from '@/features/alarms/routes'
import { AssetConfigurationPage } from '@/features/config/assets/pages/AssetConfigurationPage'
import {
  assetConfigurationRoute,
  createAssetConfigurationRoute,
  editAssetConfigurationRoute,
} from '@/features/config/assets/routes/asset'
import {
  createTrackLayerItemConfigurationRoute,
  editTrackLayerConfigurationRoute,
  editTrackLayerItemConfigurationRoute,
  trackConfigurationRoute,
} from '@/features/config/track/routes/track'
import { configurationRoute } from '@/features/config/configuration-route'
import { AlarmThresholdConfig } from '@/features/config/threshold/alarm/AlarmThresholdConfig'
import { PowercurveAiThresholdConfig } from '@/features/config/threshold/parameter/ai/PowercurveAiThresholdConfig'
import { MeanpowerThresholdConfig } from '@/features/config/threshold/parameter/meanpower/components/MeanpowerThresholdConfig'
import { ParameterThresholdConfig } from '@/features/config/threshold/parameter/ParameterThresholdConfig'
import { TurntimeThresholdConfig } from '@/features/config/threshold/parameter/turntime/components/TurntimeThresholdConfig'
import { ReferenceCurveConfig } from '@/features/config/threshold/reference/ReferenceCurveConfig'
import {
  alarmThresholdConfigurationRoute,
  editParameterMeanpowerThresholdConfigurationRoute,
  editParameterTurntimeThresholdConfigurationRoute,
  parameterMeanpowerThresholdConfigurationRoute,
  parameterPowercurveAiThresholdConfigurationRoute,
  parameterThresholdConfigurationRoute,
  parameterTurntimeThresholdConfigurationRoute,
  referenceThresholdConfigurationRoute,
  thresholdConfigurationRoute,
} from '@/features/config/threshold/threshold-route'
import { ThresholdConfigurationPage } from '@/features/config/threshold/ThresholdConfigurationPage'
import { TrackConfigurationPage } from '@/features/config/track/pages/TrackConfigurationPage'
import { UserConfigurationPage } from '@/features/config/user/pages/UserConfigurationPage'
import { MaintenanceReportsPage } from '@/features/maintenance-reports/pages/MaintenanceReportsPage'
import { MaintenanceTaskCreatePage } from '@/features/maintenance-reports/pages/MaintenanceTaskCreatePage'
import { MaintenanceTaskViewPage } from '@/features/maintenance-reports/pages/MaintenanceTaskViewPage'
import {
  maintenanceReportsCreateRoute,
  maintenanceReportsRoute,
  maintenanceReportsViewRoute,
} from '@/features/maintenance-reports/routes/maintenance-report-routes'
import { TurnoutPage } from '@/features/assets/pages/[turnoutId]'
import { TurnoutsPage } from '@/features/assets/pages/turnouts'
import { turnoutRoute, turnoutsRoute } from '@/features/assets/routes/turnouts'

const home: RouteObject = {
  index: true,
  element: <HomePage />,
}

const logout: RouteObject = {
  path: logoutRoute.pathname,
  element: <LogoutPage />,
}

const settings: RouteObject = {
  path: settingsRoute.pathname,
  element: <SettingsPage />,
}

const faq: RouteObject = {
  path: faqRoute.pathname,
  element: <FaqPage />,
}

const alarms: RouteObject = {
  path: alarmsRoute.pathname,
  element: <AlarmsPage />,
}

const configuration: RouteObject = {
  path: configurationRoute.pathname,
  children: [
    ...[
      editTrackLayerItemConfigurationRoute,
      createTrackLayerItemConfigurationRoute,
      editTrackLayerConfigurationRoute,
      trackConfigurationRoute,
    ].map((route) => ({
      path: route.pathname,
      element: <TrackConfigurationPage />,
    })),

    ...[createAssetConfigurationRoute, editAssetConfigurationRoute, assetConfigurationRoute].map((route) => ({
      path: route.pathname,
      element: <AssetConfigurationPage />,
    })),

    {
      path: thresholdConfigurationRoute.pathname,
      element: <ThresholdConfigurationPage />,
      children: [
        {
          path: parameterThresholdConfigurationRoute.pathname,
          element: <ParameterThresholdConfig />,
          children: [
            {
              path: parameterTurntimeThresholdConfigurationRoute.pathname,
              element: <TurntimeThresholdConfig />,
              children: [
                {
                  path: editParameterTurntimeThresholdConfigurationRoute.pathname,
                },
              ],
            },
            {
              path: parameterMeanpowerThresholdConfigurationRoute.pathname,
              element: <MeanpowerThresholdConfig />,
              children: [
                {
                  path: editParameterMeanpowerThresholdConfigurationRoute.pathname,
                },
              ],
            },
            {
              path: parameterPowercurveAiThresholdConfigurationRoute.pathname,
              element: <PowercurveAiThresholdConfig />,
            },
          ],
        },
        {
          path: referenceThresholdConfigurationRoute.pathname,
          element: <ReferenceCurveConfig />,
        },
        {
          path: alarmThresholdConfigurationRoute.pathname,
          element: <AlarmThresholdConfig />,
        },
      ],
    },

    ...[editUserConfigurationRoute, userConfigurationRoute].map((route) => ({
      path: route.pathname,
      element: <UserConfigurationPage />,
    })),
  ],
}

const turnouts: RouteObject = {
  path: turnoutsRoute.pathname,
  children: [
    {
      index: true,
      element: <TurnoutsPage />,
    },
    {
      path: turnoutRoute.pathname,
      element: <TurnoutPage />,
    },
  ],
}

const maintenanceReports: RouteObject = {
  path: maintenanceReportsRoute.pathname,
  element: <MaintenanceReportsPage />,
  children: [
    {
      path: maintenanceReportsCreateRoute.pathname,
      element: <MaintenanceTaskCreatePage />,
    },
    {
      path: maintenanceReportsViewRoute.pathname,
      element: <MaintenanceTaskViewPage />,
    },
  ],
}

const assets: RouteObject = {
  children: [turnouts, maintenanceReports],
}

const error: RouteObject = {
  path: '*',
  element: <Error404Page />,
}

export const router = createBrowserRouter([
  {
    path: homeRoute.pathname,
    element: (
      <PageLayout>
        <Outlet />
      </PageLayout>
    ),
    children: [home, logout, settings, faq, alarms, configuration, assets, error],
  },
])
