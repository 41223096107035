import { JSONSchema7Object } from 'json-schema'

import { CrudApiClient } from '@/core/api/base'

import type { RawArea } from '@/features/assets/models/area'
import { RawInterlockingCodec, type RawInterlocking } from '@/features/assets/models/interlocking'

import { AreaApiClient } from './area'

class InterlockingApiClient extends CrudApiClient<RawInterlocking, RawArea> {
  protected getCreateBody(model: RawInterlocking): JSONSchema7Object {
    const body: JSONSchema7Object = { name: model.name }

    if (model.technicalId != null && model.technicalId.length > 0) {
      body.technicalId = model.technicalId
    }

    return body
  }

  protected getUpdateBody(model: RawInterlocking): JSONSchema7Object {
    return this.getCreateBody(model)
  }
}

const instance = new InterlockingApiClient(
  'interlockings',
  RawInterlockingCodec,
  AreaApiClient as CrudApiClient<RawArea>,
  'areaId',
)
export { instance as InterlockingApiClient }
