import { JSX } from 'react'

import { DateField } from '@/core/components/DataFilter/components/DateField'
import { DateRangeField } from '@/core/components/DataFilter/components/DateRangeField'
import { RadioField } from '@/core/components/DataFilter/components/RadioField'
import { SelectField } from '@/core/components/DataFilter/components/SelectField'
import { TextField } from '@/core/components/DataFilter/components/TextField'
import ToggleField from '@/core/components/DataFilter/components/ToggleField'
import type { FieldProps } from '@/core/components/DataFilter/types'

export function Field(props: FieldProps): JSX.Element | null {
  switch (props.field.type) {
    case 'date':
      return <DateField {...props} />

    case 'date_range':
      return <DateRangeField {...props} />

    case 'radio':
      return <RadioField {...props} />

    case 'select':
      return <SelectField {...props} />

    case 'text':
      return <TextField {...props} />

    case 'toggle':
      return <ToggleField {...props} />

    default:
      return null
  }
}
