import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { IxButton, IxSpinner, showToast } from '@siemens/ix-react'

import { createErrorObject } from '@/core/components/ErrorInfo'
import { convertError } from '@/core/models/service-error'

import { useTurntimeThresholdConfigStore } from '@/features/config/threshold/parameter/turntime/store/turntime-threshold-config-store'
import { useTurntimeThresholdRouter } from '@/features/config/threshold/parameter/turntime/hooks/use-turntime-threshold-router'
import { useResetAnalyticsThresholds } from '@/features/config/threshold/parameter/turntime/hooks/use-analytics-threshold'
import { useTurnouts } from '@/features/assets/hooks/use-turnout'

export const TurntimeThresholdActionPanel: FC = () => {
  const { t } = useTranslation()
  const { data: turnouts } = useTurnouts()
  const selectedTurnoutIds = useTurntimeThresholdConfigStore((state) => state.selectedTurnoutIds)
  const { trigger: resetThresholds, isMutating: isResetting } = useResetAnalyticsThresholds(selectedTurnoutIds)
  const { editRouteLink } = useTurntimeThresholdRouter()

  const onReset = useCallback(async () => {
    const selectedTurnouts = turnouts?.filter((turnout) => selectedTurnoutIds?.includes(turnout.id))

    if (!selectedTurnouts?.length) {
      return
    }

    let loadingToast: Awaited<ReturnType<typeof showToast>>

    try {
      loadingToast = await showToast({
        message: (
          <div className="d-flex align-items-center">
            {t('Updating thresholds')} <IxSpinner className="ms-1" />
          </div>
        ),
        autoClose: false,
      })
    } catch (error) {
      console.error(error)
    }

    try {
      await resetThresholds()

      setTimeout(() => {
        loadingToast.close()
        showToast({ message: t('Successfully updated thresholds'), type: 'success' })
      }, 500)
    } catch (error) {
      setTimeout(() => {
        loadingToast.close()
        showToast({
          title: t('Updating thresholds was unsuccessful') ?? undefined,
          message: createErrorObject(convertError(error)).message.join('\n\n'),
          type: 'error',
        })
      }, 500)
    }
  }, [turnouts, selectedTurnoutIds, t, resetThresholds])

  return (
    <div className="d-flex justify-content-end">
      <IxButton
        className="me-2"
        disabled={!selectedTurnoutIds.length || isResetting}
        loading={isResetting}
        onClick={onReset}
        outline
      >
        {t('Reset to default')}
      </IxButton>

      <Link to={editRouteLink}>
        <IxButton disabled={!selectedTurnoutIds.length}>{t('Configure')}</IxButton>
      </Link>
    </div>
  )
}
