import { FC, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { generatePath, Navigate, useMatch, useNavigate } from 'react-router-dom'

import { useMainProject } from '@/core/hooks/use-project'
import { appTitle } from '@/core/lib/config'

import { TurntimeThresholdConfigProvider } from '@/features/config/threshold/parameter/turntime/store/turntime-threshold-config-store'
import { TurntimeThresholdActionPanel } from '@/features/config/threshold/parameter/turntime/components/TurntimeThresholdActionPanel'
import { TurntimeThresholdEditModal } from '@/features/config/threshold/parameter/turntime/components/TurntimeThresholdEditModal'
import { TurntimeThresholdFilter } from '@/features/config/threshold/parameter/turntime/components/TurntimeThresholdFilter'
import { TurntimeThresholdTable } from '@/features/config/threshold/parameter/turntime/components/TurntimeThresholdTable'
import {
  editParameterTurntimeThresholdConfigurationRoute,
  parameterThresholdConfigurationRoute,
  parameterTurntimeThresholdConfigurationRoute,
  thresholdConfigurationRoute,
} from '@/features/config/threshold/threshold-route'

export const TurntimeThresholdConfig: FC = () => {
  const mainProject = useMainProject()
  const { t } = useTranslation()
  const editTurntimeThresholdMatch = useMatch(editParameterTurntimeThresholdConfigurationRoute.pathname)
  const navigate = useNavigate()
  const navigateToOverview = useCallback(() => {
    navigate(generatePath(parameterTurntimeThresholdConfigurationRoute.pathname))
  }, [navigate])

  if (!mainProject.isExpertsystemTurntimeEnabled) {
    return <Navigate to={generatePath(parameterThresholdConfigurationRoute.pathname)} replace />
  }

  return (
    <TurntimeThresholdConfigProvider>
      <Helmet>
        <title>
          {`${appTitle} - ${t(`routes.${thresholdConfigurationRoute.name}`)} - ${t(
            `routes.${parameterThresholdConfigurationRoute.name}`,
          )} - ${t('config.thresholds.parameter.turntime.title')}`}
        </title>
      </Helmet>
      <TurntimeThresholdFilter />
      <TurntimeThresholdActionPanel />
      <TurntimeThresholdTable />
      {editTurntimeThresholdMatch && <TurntimeThresholdEditModal onClose={navigateToOverview} />}
    </TurntimeThresholdConfigProvider>
  )
}
