import { JSX, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import type { AgGridReact } from 'ag-grid-react'
import { useTranslation } from 'react-i18next'

import { IxTile } from '@siemens/ix-react'

import { DataFilter } from '@/core/components/DataFilter/DataFilter'
import type { Filter } from '@/core/components/DataFilter/types'
import { usePageHeader } from '@/core/context/PageHeader'
import { useExportData } from '@/core/hooks/use-export-data'
import { useFilterOpts } from '@/core/hooks/use-filter-opts'
import type { PaginationFilterOpts } from '@/core/models/pagination'

import type { QueryTurnoutFilterOpts } from '@/features/assets/api/turnout'
import { TurnoutsKpiBar } from '@/features/assets/components/TurnoutsKpiBar'
import { TurnoutTable, type TurnoutTableProps } from '@/features/assets/components/TurnoutTable'
import { useQueryTurnouts, useTurnoutSearchFields } from '@/features/assets/hooks/use-turnout'

const pageSize = 15

export function TurnoutListing(): JSX.Element {
  const [filters, setFilters] = useState<Filter[]>([])
  const [sortField, setSortField] = useState<string | undefined>('operationState')
  const [sortOrder, setSortOrder] = useState<PaginationFilterOpts['sortingDirection'] | undefined>('desc')
  const { setLastUpdate } = usePageHeader()
  const { t } = useTranslation()

  const ref = useRef<AgGridReact | null>(null)
  const { onClickExport } = useExportData(ref)

  const { filterOpts, setPageNo } = useFilterOpts<QueryTurnoutFilterOpts>(filters)
  const pagedFilterOpts = useMemo<Partial<QueryTurnoutFilterOpts>>(() => {
    return {
      ...filterOpts,
      size: pageSize,
      sortBy: sortField,
      sortingDirection: sortOrder,
      active: true,
    }
  }, [filterOpts, sortField, sortOrder])

  const { data: pagedTurnouts, ...pagedTurnoutsData } = useQueryTurnouts(pagedFilterOpts)

  useEffect(() => {
    setLastUpdate(pagedTurnoutsData.dateTime)
  }, [setLastUpdate, pagedTurnoutsData.dateTime])

  const onSort = useCallback<NonNullable<TurnoutTableProps['onSortChanged']>>(({ columnApi }) => {
    const columns = columnApi.getColumnState()
    const sortedColumn = columns.find((column) => column.sort != null)

    setSortField(sortedColumn?.colId)
    setSortOrder(sortedColumn?.sort ?? undefined)
  }, [])

  return (
    <div className="row g-3">
      <div className="col-12">
        <DataFilter
          fields={[
            {
              icon: 'search',
              label: t('Search for turnout name'),
              path: 'turnoutName',
              type: 'text',
            },
            ...useTurnoutSearchFields(filters, true),
          ]}
          onChange={setFilters}
          onExport={onClickExport}
          value={filters}
        />
      </div>

      {filterOpts != null && <TurnoutsKpiBar filterOpts={filterOpts} />}

      <div className="col-12">
        <IxTile>
          <TurnoutTable
            pagedData={pagedTurnouts}
            error={pagedTurnoutsData.error}
            gridRef={ref}
            isLoading={pagedTurnoutsData.isLoading}
            onSortChanged={onSort}
            onPageSelected={setPageNo}
            selectable
          />
        </IxTile>
      </div>
    </div>
  )
}
