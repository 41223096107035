import { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { IxWorkflowStep, IxWorkflowSteps } from '@siemens/ix-react'

import { MessageBar } from '@/core/components/MessageBar'
import type { AnalyticsTypes } from '@/core/models/analytics-types'

import { AssetTypeSelection } from '@/features/config/assets/components/CreateAssetModal/AssetTypeSelection'
import { CreatePointMachines } from '@/features/config/assets/components/CreatePointMachines'
import { UpdateTurnoutForm } from '@/features/config/assets/components/UpdateTurnoutForm'
import {
  getAnalyticsTypeById,
  getPointMachinesRequired,
  TurnoutFunctionalityTypeTurnout,
  type Turnout,
  type TurnoutFunctionalityType,
} from '@/features/assets/models/turnout'

type CreateAssetProps = {
  onAfterSubmit?: () => void
  onCancel?: () => void
  analytics?: AnalyticsTypes
}

export const CreateAsset: FC<CreateAssetProps> = (props): ReactElement => {
  const { t } = useTranslation()
  const [step, setStep] = useState<number>(0)
  const [createdAsset, setCreatedAsset] = useState<Turnout>()

  const [assetType, setAssetType] = useState<TurnoutFunctionalityType | undefined>()

  const handleAssetType = useCallback((assetType:TurnoutFunctionalityType) => {
    setAssetType(assetType)
    setStep(1)
  }, [setStep])

  const onAssetCreated = useCallback((assets?:Turnout[]) => {
    setCreatedAsset(assets?.[0])
    setStep(2)
  }, [])

  const stepStatus = (currentStep:number, requiredStep:number):'done'|'open' => {
    if(currentStep < requiredStep) return 'open'
    return 'done'
  }

  const pointMachinesRequired = useMemo(() => {
    return assetType && getPointMachinesRequired(getAnalyticsTypeById(createdAsset?.analyticsTypeId, props.analytics))
  }, [props.analytics, createdAsset, assetType])

  useEffect(() => {
    // Hack to adjust the shadow dom element
    let style = document.createElement( 'style' )
    style.innerHTML = '.step { width: auto !important; min-width: 160px; }'
    const host = document.getElementsByClassName('create-asset-step')[0]
    host?.shadowRoot?.appendChild( style )
  }, [])

  return (
    <div className="d-flex flex-column">

      <IxWorkflowSteps selectedIndex={step}>
        <IxWorkflowStep status={stepStatus(step, 0)} className={'create-asset-step'}>{t('assets.steps.step1')}</IxWorkflowStep>
        <IxWorkflowStep status={stepStatus(step, 1)} className={'create-asset-step'}>{t('assets.steps.step2')}</IxWorkflowStep>
        <IxWorkflowStep status={stepStatus(step, 2)} className={'create-asset-step'}>{t('assets.steps.step3')}</IxWorkflowStep>
      </IxWorkflowSteps>

      <div className="d-flex flex-column my-4">
        {step === 0 && <AssetTypeSelection
          onSelect={handleAssetType}
        />}

        {step === 1 && assetType &&
          <>
            <h3>{
              assetType === TurnoutFunctionalityTypeTurnout
              ? t('assets.create.turnout.heading')
              : t('assets.create.derail.heading')
            }</h3>
            <UpdateTurnoutForm
              assetType={assetType}
              analytics={props.analytics}
              onAfterSubmit={onAssetCreated}
              onCancel={props.onCancel}
            />
          </>

        }
        {step === 2 && assetType && createdAsset &&
          <div>
            <MessageBar dismissible={false} type={'success'}>
              <Trans
                components={{
                  strong: <strong />,
                }}
                values={{name: createdAsset.name}}
                i18nKey={
                  assetType === TurnoutFunctionalityTypeTurnout
                    ? 'assets.create.turnout.created'
                    : 'assets.create.derail.created'
                }
              />
            </MessageBar>
            <h3>
              {t('assets.create.pointMachines.heading')}
            </h3>

            <CreatePointMachines
              turnoutId={createdAsset.id}
              onCancel={props.onCancel}
              required={pointMachinesRequired}
              analytics={props.analytics}
            />

          </div>
        }

      </div>

    </div>

  );
}