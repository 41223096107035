import { useCallback, useEffect, useState } from 'react'

import { Filter, isDateRange } from '@/core/components/DataFilter/types'

export type WithPage = {
  page?: number
}

/**
 * Transforms filters and enriches with page handling
 * Page will be reset to 0 on filter change
 */
export const useFilterOpts = <T>(filters: Filter[] | null | undefined, initialValue?: Partial<T>) => {
  const [filterOpts, setFilterOpts] = useState<Partial<T> & WithPage>({ page: 0, ...initialValue } as Partial<T> &
    WithPage)

  useEffect(() => {
    if (filters == null) {
      setFilterOpts({ page: 0, ...initialValue } as Partial<T> & WithPage)
      return
    }

    const newOpts: Partial<T> & WithPage = {
      ...filters?.reduce(
        (opts, filter) => {
          if (isDateRange(filter.value)) {
            return { ...opts, ...filter.value }
          }

          return {
            ...opts,
            [filter.path]: Array.isArray(filter.value) && filter.value.length > 0 ? String(filter.value) : filter.value,
          }
        },
        initialValue ?? ({} as Partial<T> & WithPage),
      ),
      page: 0,
    }

    setFilterOpts(newOpts)
    // on purpose
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const setPageNo = useCallback(
    (page: number) => {
      setFilterOpts({ ...filterOpts, page } as Partial<T> & WithPage)
    },
    [filterOpts],
  )

  return {
    filterOpts,
    setPageNo,
  }
}
