import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { IxButton } from '@siemens/ix-react'

export const DownloadReportsButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <IxButton icon="download" onClick={onClick} variant="secondary">
      {t('assets.maintenance_reports.cta.download')}
    </IxButton>
  )
}
