import { JSX, useCallback, useEffect, useMemo, useState } from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import { IxDateDropdown } from '@siemens/ix-react'
import { JSX as IxJSX } from '@siemens/ix/dist/types/components'

import { isDateRange, type FieldProps } from '@/core/components/DataFilter/types'
import { getDateRangeDefaults, getLast24HoursRange, parseDateRangeToIsoDates } from '@/core/lib/date'

export function DateRangeField({ field, value, onChange }: FieldProps): JSX.Element {
  const [dateRangeId, setDateRangeId] = useState('custom')
  const { t } = useTranslation()
  const maxDate = useMemo(() => DateTime.now().plus({ days: 1 }).startOf('day').toISODate(), [])

  const valueFromTo = useMemo(() => {
    if (value?.value == null || Array.isArray(value.value)) {
      return undefined
    }

    const dateRange = isDateRange(value.value) ? value.value : undefined
    return {
      from: dateRange?.from.startOf('day').toISODate() ?? undefined,
      to: dateRange?.to.endOf('day').toISODate() ?? undefined,
    }
  }, [value])

  const valueChanged = useCallback<NonNullable<IxJSX.IxDateDropdown['onDateRangeChange']>>(
    (event) => {
      setDateRangeId(event.detail.id)
      let value
      if (event.detail.id === 'last-24h') {
        value = getLast24HoursRange()
      } else if (event.detail.id !== 'custom') {
        value = getDateRangeDefaults(event.detail.id)
      } else {
        value = {
          from: DateTime.fromISO(event.detail.from).startOf('day'),
          to: DateTime.fromISO(event.detail.to).endOf('day'),
        }
      }
      onChange(field.path, value)
    },
    [field.path, onChange],
  )

  const dateRangeOptions = useMemo<IxJSX.IxDateDropdown['dateRangeOptions']>(() => {
    return [
      {
        id: 'last-24h',
        label: t('Last 24h'),
        ...parseDateRangeToIsoDates(getDateRangeDefaults('last-24h')),
      },
      {
        id: 'last-3d',
        label: t('Last 3d'),
        ...parseDateRangeToIsoDates(getDateRangeDefaults('last-3d')),
      },
      {
        id: 'last-7d',
        label: t('Last 7d'),
        ...parseDateRangeToIsoDates(getDateRangeDefaults('last-7d')),
      },
      {
        id: 'last-30d',
        label: t('Last 30d'),
        ...parseDateRangeToIsoDates(getDateRangeDefaults('last-30d')),
      },
    ]
  }, [t])

  useEffect(() => {
    const option = dateRangeOptions?.find(
      (option) => option.from === valueFromTo?.from && option.to === valueFromTo?.to,
    )
    if (option != null) {
      setDateRangeId(option.id)
    }
  }, [dateRangeOptions, valueFromTo])

  return (
    <IxDateDropdown
      // TODO: workaround until https://github.com/siemens/ix/issues/1555 is fixed
      key={`${dateRangeId}-${dateRangeOptions?.toString()}-${JSON.stringify(valueFromTo)}-${maxDate}`}
      dateRangeId={dateRangeId}
      dateRangeOptions={dateRangeOptions}
      format="yyyy-LL-dd"
      from={valueFromTo?.from}
      i18nCustomItem={t('Custom') ?? undefined}
      i18nDone={t('Done') ?? undefined}
      i18nNoRange={t('No range set') ?? undefined}
      maxDate={maxDate}
      onDateRangeChange={valueChanged}
      to={valueFromTo?.to}
    />
  )
}
