import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { DataFilter } from '@/core/components/DataFilter/DataFilter'

import { usePointMachineSearchFields } from '@/features/assets/hooks/use-point-machine'

import { useMaintenanceReportsStore } from '@/features/maintenance-reports/store/store'

export const Filter: FC = () => {
  const { t } = useTranslation()
  const filters = useMaintenanceReportsStore((state) => state.filters)
  const setFilters = useMaintenanceReportsStore((state) => state.setFilters)
  const baseSearchFields = usePointMachineSearchFields(filters)

  return (
    <DataFilter
      fields={[
        ...baseSearchFields,
        {
          icon: 'search',
          label: t('Search for turnout name'),
          path: 'assetName',
          type: 'text',
        },
      ]}
      onChange={setFilters}
      value={filters}
    />
  )
}
