import { JSX, useMemo } from 'react'
import type { ColDef } from 'ag-grid-community'

import EventCell from '@/core/components/EventTable/components/EventCell'
import { Table, type TableProps } from '@/core/components/Table'
import type { BaseModel } from '@/core/models/base'

import * as S from './styles'

export interface EventColor {
  eventColor: string
}

export function EventTable<T extends EventColor & BaseModel>(props: TableProps<T>): JSX.Element {
  const columnDefs = useMemo<ColDef<T>[] | undefined>(() => {
    if (props.columnDefs == null) {
      return undefined
    }

    return [
      {
        cellRenderer: EventCell,
        field: 'eventColor' as any,
        headerName: '',
        width: 0,
      },

      ...props.columnDefs,
    ]
  }, [props.columnDefs])

  return (
    <S.EventTableWrapper>
      <Table
        {...props}
        columnDefs={columnDefs}
        rowHeight={58}
        selectable={props.selectable === true ? { columnIndex: 1 } : props.selectable}
      />
    </S.EventTableWrapper>
  )
}
