import * as t from 'io-ts'

import { BaseModelCodec, DirectionCodec } from '@/core/models/base'

export const BaseThresholdCodec = t.type({
  direction: DirectionCodec,
  meanPower: t.number,
  pointMachineId: t.number,
  throwingTime: t.number,
  work: t.number,
})
export const BaseThresholdsCodec = t.array(BaseThresholdCodec)
export type BaseThreshold = t.TypeOf<typeof BaseThresholdCodec>

export const ThresholdCodec = t.intersection([BaseModelCodec, BaseThresholdCodec])
export const ThresholdsCodec = t.array(ThresholdCodec)
export type Threshold = t.TypeOf<typeof ThresholdCodec>

export interface ThresholdConfigurationFilterOpts {
  thresholdConfiguration: 'complete' | 'incomplete'
}
