import { RouteConfig } from '@/core/routes'

import { configurationRoute } from '@/features/config/configuration-route'

export const assetConfigurationRoute: RouteConfig<
  `${typeof configurationRoute.pathname}/asset`,
  typeof configurationRoute
> = {
  icon: 'hexagon-vertical-bars',
  name: 'Asset configuration',
  parent: configurationRoute,
  pathname: `${configurationRoute.pathname}/asset`,
}

export const createAssetConfigurationRoute: RouteConfig<
  `${typeof assetConfigurationRoute.pathname}/create`,
  typeof assetConfigurationRoute
> = {
  name: 'Create asset configuration',
  parent: assetConfigurationRoute,
  pathname: `${assetConfigurationRoute.pathname}/create`,
}

export const editAssetConfigurationRoute: RouteConfig<
  `${typeof assetConfigurationRoute.pathname}/edit/:assetId`,
  typeof assetConfigurationRoute
> = {
  name: 'Edit asset configuration',
  parent: assetConfigurationRoute,
  pathname: `${assetConfigurationRoute.pathname}/edit/:assetId`,
}
