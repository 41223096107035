import { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'
import { generatePath, Link } from 'react-router-dom'

import { IxButton } from '@siemens/ix-react'

import { Avatar } from '@/core/components/Avatar'
import { logoutRoute, settingsRoute } from '@/core/routes'

import * as S from './styles'

export interface UserMenuProps {}

export function UserMenu(props: UserMenuProps): JSX.Element {
  const { t } = useTranslation()
  const { user } = useAuth()

  return (
    <S.UserMenuWrapper>
      <div className="d-flex">
        <Avatar size={48} />

        <div className="ms-3 mt-1 mb-1 d-flex flex-column">
          <div className="fs-6 fw-bold">{user?.profile?.name}</div>

          <div className="mt-auto">{user?.profile?.email}</div>
        </div>
      </div>

      <div className="d-flex justify-content-end mt-4">
        <Link to={generatePath(settingsRoute.pathname)}>
          <IxButton className="me-4" ghost icon={settingsRoute.icon}>
            {t(`routes.${settingsRoute.name}`)}
          </IxButton>
        </Link>

        <Link to={generatePath(logoutRoute.pathname)}>
          <S.RedButtonWrapper>
            <IxButton ghost>{logoutRoute.name}</IxButton>
          </S.RedButtonWrapper>
        </Link>
      </div>
    </S.UserMenuWrapper>
  )
}
