import { useCallback, useMemo, useState } from 'react'

import { TableProps } from '@/core/components/Table'
import { useFilterOpts } from '@/core/hooks/use-filter-opts'

import type { QueryTurnoutFilterOpts } from '@/features/assets/api/turnout'
import type { Turnout } from '@/features/assets/models/turnout'

import { useTurntimeThresholdConfigStore } from '../store/turntime-threshold-config-store'

export const useExpertTurntimeFilter = () => {
  const filters = useTurntimeThresholdConfigStore((state) => state.filters)
  const filterOpts = useFilterOpts<QueryTurnoutFilterOpts>(filters)
  const [pageNo, setPageNo] = useState(0)
  const [sortBy, setSortBy] = useState<string | undefined>('engineeringId')
  const [sortingDirection, setSortingDirection] = useState<QueryTurnoutFilterOpts['sortingDirection'] | undefined>(
    'asc',
  )

  const pagedFilterOpts = useMemo<Partial<QueryTurnoutFilterOpts>>(() => {
    return {
      ...filterOpts,
      page: pageNo,
      sortBy,
      sortingDirection,
      analyticsTypeName: ['turntime_expertsystem'],
    }
  }, [filterOpts, pageNo, sortBy, sortingDirection])

  const onSortChanged = useCallback<NonNullable<TableProps<Turnout>['onSortChanged']>>(
    ({ columnApi }) => {
      const columns = columnApi.getColumnState()
      const sortedColumn = columns.find((column) => column.sort != null)

      setSortBy(sortedColumn?.colId)
      setSortingDirection(sortedColumn?.sort ?? undefined)
    },
    [setSortBy, setSortingDirection],
  )

  return {
    filters,
    pagedFilterOpts,
    pageNo,
    setPageNo,
    sortBy,
    onSortChanged,
    sortingDirection,
    setSortingDirection,
  }
}
