import * as t from 'io-ts'

export const ActivationStatisticsCodec = t.type({
  active: t.number,
  inactive: t.number,
})

export const OperationStateStatisticsCodec = t.type({
  green: t.number,
  yellow: t.number,
  red: t.number,
})

export const TurnoutStateStatisticsCodec = t.type({
  activationStatistics: ActivationStatisticsCodec,
  operationState: OperationStateStatisticsCodec,
})
export type TurnoutStateStatistics = t.TypeOf<typeof TurnoutStateStatisticsCodec>
