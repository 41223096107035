import { JSONSchema7Object } from 'json-schema'

import { CrudApiClient } from '@/core/api/base'
import { ProjectApiClient } from '@/core/api/project'
import type { Project } from '@/core/models/project'

import { RawAreaCodec, type RawArea } from '@/features/assets/models/area'

class AreaApiClient extends CrudApiClient<RawArea, Project> {
  protected getCreateBody(model: RawArea): JSONSchema7Object {
    return { name: model.name }
  }

  protected getUpdateBody(model: RawArea): JSONSchema7Object {
    return this.getCreateBody(model)
  }
}

const instance = new AreaApiClient('areas', RawAreaCodec, ProjectApiClient, 'projectId')
export { instance as AreaApiClient }
