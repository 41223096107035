import { JSX, useRef } from 'react'
import type { AgGridReact } from 'ag-grid-react'
import { DateTime } from 'luxon'
import { Trans, useTranslation } from 'react-i18next'

import { IxIconButton, IxTooltip } from '@siemens/ix-react'

import { StateEnabledItem } from '@/core/components/StateEnabledItem'
import { Table, type TableProps } from '@/core/components/Table'

import { EventDirectionCell } from '@/features/assets/components/PointMachineEventCharts/components/EventDirectionCell'
import { OutlierCell } from '@/features/assets/components/PointMachineEventCharts/components/OutlierCell'
import { useExportEvents } from '@/features/assets/components/PointMachineEventCharts/components/PointMachineEventTable/hooks'
import { ReferenceCell } from '@/features/assets/components/PointMachineEventCharts/components/ReferenceCell'
import type { PagedPointMachineEvents, PointMachineEvent } from '@/features/assets/models/point-machine-event'

export interface PointMachineEventTableProps extends Omit<TableProps<PointMachineEvent>, 'columnDefs'> {
  pointMachineEvents?: PagedPointMachineEvents
}

const PAGE_SIZE = 15

function parseNumber(original: any, digitsAfterPoint: number = 2, appendix: string = ''): string {
  if (typeof original === 'number' || !isNaN(Number.parseInt(original))) {
    const n1: number = Math.round(original * 100) / 100

    if (Math.round(n1) === n1) {
      // no comma at all
      return `${n1}.00${appendix}`
    }

    const s1 = n1.toString().split('.')

    if (s1[1]) {
      while (s1[1].length < digitsAfterPoint) {
        s1[1] = `${s1[1]}0`
      }
      return `${s1.join('.')}${appendix}`
    }
  }
  return '–'
}

export function PointMachineEventTable({
  pointMachineEvents,
  selectedIds,
  ...rest
}: PointMachineEventTableProps): JSX.Element {
  const { i18n, t } = useTranslation()
  const ref = useRef<AgGridReact<PointMachineEvent> | null>(null)
  const { onClickExport } = useExportEvents(ref, pointMachineEvents?.content ?? [])

  return (
    <>
      <div className="d-flex align-items-end justify-content-between">
        <div className="fs-6 fw-bold">
          <Trans
            i18nKey={'Power_curves_table_headline'}
            components={{
              i: <i />,
            }}
            values={{ count: (selectedIds ?? []).length }}
          />
        </div>

        <IxIconButton
          data-tooltip="PointMachineEventTable-export"
          ghost
          icon="export"
          onClick={onClickExport}
          variant="primary"
        />
        <IxTooltip for='[data-tooltip="PointMachineEventTable-export"]'>{t('Export')}</IxTooltip>
      </div>

      <hr />

      <Table
        columnDefs={[
          {
            field: 'pointMachine.engineeringId',
            headerName: 'PM ID',
            sortable: true,
            unSortIcon: true,
            minWidth: 150,
            flex: 2,
            resizable: true,
          },
          {
            cellRenderer: EventDirectionCell,
            field: 'direction',
            headerName: t('models.event.direction') ?? undefined,
            sortable: true,
            unSortIcon: true,
            valueGetter: ({ data }) => (data == null ? undefined : t(`models.event.direction-${data.direction}`)),
            width: 120,
          },
          {
            comparator: (_, __, nodeA, nodeB) =>
              nodeA.data == null || nodeB.data == null
                ? 0
                : DateTime.fromISO(nodeA.data.start).diff(DateTime.fromISO(nodeB.data.start), 'milliseconds')
                    .milliseconds,
            field: 'start',
            headerName: t('models.event.startTime') ?? undefined,
            initialSort: 'desc',
            sortable: true,
            unSortIcon: true,
            valueGetter: ({ data }) =>
              data == null
                ? null
                : DateTime.fromISO(data.start)
                    .setLocale(i18n.language)
                    .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS),
          },
          {
            headerName: t('assets.details.duration_fitness') ?? undefined,
            children: [
              {
                field: 'throwingTime',
                headerName: `ms`,
                sortable: true,
                unSortIcon: true,
                width: 100,
              },
              {
                field: 'fitnessScore',
                headerName: `(0-1)`,
                sortable: true,
                unSortIcon: true,
                width: 100,
                valueGetter: ({ data }) => parseNumber(data?.fitnessScore),
              },
            ],
          },
          {
            headerName: t('assets.details.temperature') ?? undefined,
            children: [
              {
                field: 'estimatedEnvironmentMeasurements.outsideTemperature',
                headerName: t('models.event.outsideTemperature') ?? undefined,
                sortable: true,
                unSortIcon: true,
                minWidth: 110,
                maxWidth: 120,
                valueGetter: ({ data }) =>
                  parseNumber(data?.estimatedEnvironmentMeasurements?.outsideTemperature, 2, '°'),
              },
              {
                field: 'estimatedEnvironmentMeasurements.trackTemperature',
                headerName: t('models.event.trackTemperature') ?? undefined,
                sortable: true,
                unSortIcon: true,
                minWidth: 100,
                maxWidth: 120,
                valueGetter: ({ data }) =>
                  parseNumber(data?.estimatedEnvironmentMeasurements?.trackTemperature, 2, '°'),
              },
              {
                field: 'estimatedEnvironmentMeasurements.humidityAboveThreshold',
                headerName: t('models.event.humidityDetected') ?? undefined,
                sortable: true,
                unSortIcon: true,
                minWidth: 100,
                maxWidth: 120,
                cellRenderer: ({ data }: any) =>
                  Boolean(data?.estimatedEnvironmentMeasurements?.humidityAboveThreshold) ? (
                    <StateEnabledItem id={`${data.id}-active-state`} enabled={true} />
                  ) : (
                    <div className={'text-center'}>–</div>
                  ),
              },
            ],
          },
          {
            headerName: t('assets.details.mark') ?? undefined,
            children: [
              {
                cellRenderer: OutlierCell,
                field: 'isOutlier',
                headerName: t('"Outlier"') ?? undefined,
                sortable: true,
                unSortIcon: true,
                width: 120,
              },
              {
                cellRenderer: ReferenceCell,
                field: 'reference',
                headerName: t('"Reference"') ?? undefined,
                sortable: true,
                unSortIcon: true,
                width: 140,
              },
            ],
          },
        ]}
        pagedData={pointMachineEvents}
        selectedIds={selectedIds}
        gridRef={ref}
        selectable
        paginationPageSize={PAGE_SIZE}
        resetPageOnDataChange={true}
        {...rest}
      />
    </>
  )
}
