import { JSX } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import { GlobalPageHeader } from '@/core/components/GlobalPageHeader'
import { appTitle } from '@/core/lib/config'

import { AlarmsListing } from '@/features/alarms/components/AlarmsListing'
import { alarmsRoute } from '@/features/alarms/routes'

export function AlarmsPage(): JSX.Element {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>
          {appTitle} - {t(`routes.${alarmsRoute.name}`)}
        </title>
      </Helmet>

      <GlobalPageHeader route={alarmsRoute} title={t(`routes.${alarmsRoute.name}`)}>
        <AlarmsListing />
      </GlobalPageHeader>
    </>
  )
}
