import { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { Modal, ModalContent } from '@/core/components/Modal'

import type { TrackLayer } from '@/features/assets/models/track-layer'

import { UpdateTrackLayerForm } from '@/features/config/track/components/UpdateTrackLayerForm'
import { editTrackLayerConfigurationRoute, trackConfigurationRoute } from '@/features/config/track/routes/track'

export interface UpdateTrackLayerModalProps {
  layer: TrackLayer | undefined
  layers: TrackLayer[]
  visible: boolean
}

export function UpdateTrackLayerModal({ layer, layers, visible }: UpdateTrackLayerModalProps): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Modal title={t(`routes.${editTrackLayerConfigurationRoute.name}`)} visible={visible}>
      <ModalContent
        cancelButton={null}
        okButton={null}
        onCancel={() => navigate(generatePath(trackConfigurationRoute.pathname))}
        title={t(`routes.${editTrackLayerConfigurationRoute.name}`)}
      >
        <UpdateTrackLayerForm
          layer={layer}
          layers={layers}
          onAfterSubmit={() => navigate(generatePath(trackConfigurationRoute.pathname))}
          onCancel={() => navigate(generatePath(trackConfigurationRoute.pathname))}
        />
      </ModalContent>
    </Modal>
  )
}
