import { createContext, PropsWithChildren, useContext, useRef } from 'react'
import { create, useStore } from 'zustand'

import type { BaseModel, IdType, NamedType } from '@/core/models/base'

import type { MaintenanceTask } from '@/features/maintenance-reports/models/maintenance-task'

type BaseProps = {
  task: Partial<MaintenanceTask>
  readOnly?: boolean //view mode = true; edit mode = false
  readonly isTurnoutSelectionDisabled?: boolean
}
type EditModeProps = {
  freeTextWorkItem?: string
  area?: BaseModel & NamedType
  interlocking?: BaseModel & NamedType
  techRoom?: BaseModel & NamedType
}

type ModalStateProps = BaseProps & EditModeProps

type ModalStateEditActions = {
  setArea: (area: BaseModel & NamedType) => void

  setInterlocking: (interlocking: BaseModel & NamedType) => void
  resetInterlocking: () => void

  setTechRoom: (techRoom: BaseModel & NamedType) => void
  resetTechRoom: () => void

  setTurnoutId: (id: IdType) => void
  resetTurnoutId: () => void

  setReason: (reason: string) => void
  setDueAt: (isoDateTime: string) => void
  setComment: (comment: string) => void

  addWorkItem: (workItem: string) => void
  removeWorkItem: (workItem: string) => void
  setFreeTextWorkItem: (freeText: string) => void
}

type MaintenanceTaskModalState = ModalStateProps & ModalStateEditActions

const initialDefaultState: ModalStateProps = {
  task: {
    work: [],
  },
}

const createStore = (initialState?: Partial<MaintenanceTaskModalState>) =>
  create<MaintenanceTaskModalState>((set) => ({
    ...initialDefaultState,
    ...initialState,
    isTurnoutSelectionDisabled: initialState?.task?.turnoutId !== undefined,
    setArea: (area) =>
      set(() => ({
        area,
      })),
    setInterlocking: (interlocking) =>
      set(() => ({
        interlocking,
      })),
    resetInterlocking: () =>
      set(() => ({
        interlocking: undefined,
      })),
    setTechRoom: (techRoom) =>
      set(() => ({
        techRoom,
      })),
    resetTechRoom: () =>
      set(() => ({
        techRoom: undefined,
      })),
    setTurnoutId: (turnoutId) =>
      set((state) => ({
        task: {
          ...state.task,
          turnoutId,
        },
      })),
    resetTurnoutId: () =>
      set((state) => ({
        task: {
          ...state.task,
          turnoutId: undefined,
        },
      })),
    setReason: (reason) =>
      set((state) => ({
        task: {
          ...state.task,
          reason,
        },
      })),
    setDueAt: (dueAt) =>
      set((state) => ({
        task: {
          ...state.task,
          dueAt,
        },
      })),
    setComment: (comment) =>
      set((state) => ({
        task: {
          ...state.task,
          comment,
        },
      })),
    addWorkItem: (workItem) =>
      set((state) => ({
        task: {
          ...state.task,
          work: [...(state.task.work?.filter((w) => w !== workItem) ?? []), workItem].sort(),
        },
      })),
    removeWorkItem: (workItem) =>
      set((state) => ({
        task: {
          ...state.task,
          work: state.task.work?.filter((w) => w !== workItem) ?? [],
        },
      })),
    setFreeTextWorkItem: (freeTextWorkItem) =>
      set(() => ({
        freeTextWorkItem,
      })),
  }))

type MaintenanceTaskModalStore = ReturnType<typeof createStore>

const MaintenanceTaskModalContext = createContext<MaintenanceTaskModalStore | null>(null)

export const MaintenanceTaskModalProvider = ({
  children,
  ...initialProps
}: PropsWithChildren & Partial<MaintenanceTaskModalState>) => {
  const storeRef = useRef<MaintenanceTaskModalStore>()
  if (!storeRef.current) {
    storeRef.current = createStore(initialProps)
  }
  return (
    <MaintenanceTaskModalContext.Provider value={storeRef.current}>{children}</MaintenanceTaskModalContext.Provider>
  )
}

export function useMaintenanceTaskModalStore<T>(selector: (state: MaintenanceTaskModalState) => T): T {
  const store = useContext(MaintenanceTaskModalContext)
  if (!store) throw new Error('Missing useMaintenanceTaskModalStore.Provider in the tree')
  return useStore(store, selector)
}
