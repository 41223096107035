import { useCallback, useState } from 'react'

import type { IdType } from '@/core/models/base'

export const useMaintenanceTaskViewModal = () => {
  const [taskId, setTaskId] = useState<IdType | null>()
  const openModal = useCallback((taskId: IdType) => {
    setTaskId(taskId)
  }, [])
  const closeModal = useCallback(() => {
    setTaskId(null)
  }, [])

  return {
    taskId,
    openModal,
    closeModal,
  }
}
