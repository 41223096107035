import { FC, PropsWithChildren, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { IxButton, IxTooltip } from '@siemens/ix-react'

import { ModalInlineFooter } from '@/core/components/Modal/ModalInlineFooter'

type AssetModalFooterProps = {
  required?:boolean
  loading?:boolean
  nextDisabled?:boolean
  handleNext: () => void
  handleCancel: () => void
  nextCopy?:string
  cancelCopy?:string
}

/**
 * Inline modal footer used by CreateAssetModal and UpdateTurnoutModal
 * @param children
 * @param required
 * @param loading
 * @param nextDisabled
 * @param handleNext
 * @param handleCancel
 * @param nextCopy
 * @param cancelCopy
 * @constructor
 */
export const AssetModalFooter: FC<PropsWithChildren<AssetModalFooterProps>> = ({children, required, loading, nextDisabled, handleNext, handleCancel, nextCopy, cancelCopy}): ReactElement => {
  const { t } = useTranslation()

  return (
    <ModalInlineFooter
      nextButton={required
        ?
        <div id="SaveAssetWrapper" style={{width: "fit-content"}}>
          <IxButton
            id="SaveTurnout"
            variant="primary"
            loading={loading}
            disabled={nextDisabled}
            onClick={handleNext}
          >
            {nextCopy ?? t('assets.cta.save_and_activate')}
          </IxButton>
          {nextDisabled &&
            <IxTooltip for={`[id='SaveAssetWrapper']`} placement="top" >
              {t('assets.info.PM_required_tooltip')}
            </IxTooltip>
          }
        </div>
        :
        <IxButton
          id="SaveTurnout"
          variant="primary"
          loading={loading}
          onClick={handleNext}
        >
          {nextCopy ?? t('assets.cta.save_and_activate')}
        </IxButton>
      }
      onCancel={handleCancel}
      cancelCopy={cancelCopy}
    >
      {children}
    </ModalInlineFooter>
  );
}