import { FC, useCallback, useMemo } from 'react'
import { generatePath, useMatch, useNavigate } from 'react-router-dom'

import { MaintenanceTaskViewModal } from '@/features/maintenance-reports/components/MaintenanceTaskViewModal'
import {
  maintenanceReportsRoute,
  maintenanceReportsViewRoute,
} from '@/features/maintenance-reports/routes/maintenance-report-routes'

export const MaintenanceTaskViewPage: FC = () => {
  const pathMatch = useMatch(maintenanceReportsViewRoute.pathname)
  const navigate = useNavigate()
  const closeModal = useCallback(() => navigate(generatePath(maintenanceReportsRoute.pathname)), [navigate])
  const taskId = useMemo(() => Number(pathMatch?.params.reportId), [pathMatch?.params.reportId])

  return <MaintenanceTaskViewModal taskId={taskId} key={taskId} onClose={closeModal} />
}
