import { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { LoadingErrorWrapper } from '@/core/components/LoadingErrorWrapper'
import { Modal, ModalContent } from '@/core/components/Modal'

import { useTrackLayerItems } from '@/features/assets/hooks/use-tack-layer-items'
import type { TrackLayer } from '@/features/assets/models/track-layer'

import {
  UpdateTrackLayerItemForm,
  type UpdateTrackLayerItemFormProps,
} from '@/features/config/track/components/UpdateTrackLayerItemForm'
import { editTrackLayerItemConfigurationRoute, trackConfigurationRoute } from '@/features/config/track/routes/track'

type FormDataWrapperProps = Pick<UpdateTrackLayerItemFormProps, 'onAfterSubmit' | 'onCancel'> &
  Pick<UpdateTrackLayerItemModalProps, 'layer' | 'layerItemId'>

function FormDataWrapper({ layerItemId, ...rest }: FormDataWrapperProps): JSX.Element {
  const { data: layerItems, ...layerItemsData } = useTrackLayerItems()

  return (
    <LoadingErrorWrapper data={layerItems} error={layerItemsData.error} isLoading={layerItemsData.isLoading}>
      {(layerItems) => (
        <UpdateTrackLayerItemForm
          {...rest}
          layerItem={
            rest.layer == null
              ? undefined
              : layerItems.at(rest.layer?.depth - 1)?.find((layerItem) => String(layerItem.id) === layerItemId)
          }
        />
      )}
    </LoadingErrorWrapper>
  )
}

export interface UpdateTrackLayerItemModalProps {
  layer: TrackLayer | undefined
  layerItemId?: string
  visible: boolean
}

export function UpdateTrackLayerItemModal({ visible, ...rest }: UpdateTrackLayerItemModalProps): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Modal title={t(`routes.${editTrackLayerItemConfigurationRoute.name}`)} visible={visible}>
      <ModalContent
        cancelButton={null}
        okButton={null}
        onCancel={() => navigate(generatePath(trackConfigurationRoute.pathname))}
        title={t(`routes.${editTrackLayerItemConfigurationRoute.name}`)}
      >
        <FormDataWrapper
          {...rest}
          onAfterSubmit={() => navigate(generatePath(trackConfigurationRoute.pathname))}
          onCancel={() => navigate(generatePath(trackConfigurationRoute.pathname))}
        />
      </ModalContent>
    </Modal>
  )
}
