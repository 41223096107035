import { Key, mutate } from 'swr'
import useSWRMutation, { SWRMutationResponse } from 'swr/mutation'

import { ProjectConfigurationApiClient } from '@/core/api/project-configuration'
import { useSWR, type SWRResponse } from '@/core/hooks/swr'
import { useMainProject } from '@/core/hooks/use-project'
import { mapToPagedResponseDummy } from '@/core/lib/response-util'
import type { IdType, PagedResultType } from '@/core/models/base'
import type { ProjectConfiguration } from '@/core/models/project-configuration'
import type { ProjectConfigurationChangeLog } from '@/core/models/project-configuration-change-log'
import type { ServiceError } from '@/core/models/service-error'

export const getUseProjectConfigurationKey = (projectId?: IdType): Key => {
  if (projectId == null) {
    return null
  }

  return ['useProjectConfiguration', projectId]
}

export const useProjectConfiguration = (): SWRResponse<ProjectConfiguration> => {
  const mainProject = useMainProject()

  return useSWR(getUseProjectConfigurationKey(mainProject?.id), () => {
    return ProjectConfigurationApiClient.read(mainProject!.id)
  })
}

export const getProjectConfigurationChangeLogsKey = (parentId?: IdType): Key => {
  if (parentId == null) {
    return null
  }

  return ['useProjectConfigurationChangeLogs', parentId]
}

export const useProjectConfigurationChangeLogs = (): SWRResponse<PagedResultType<ProjectConfigurationChangeLog>> => {
  const mainProject = useMainProject()

  return useSWR(getProjectConfigurationChangeLogsKey(mainProject?.id), async () => {
    const nonPagedChangeLogsResponse = await ProjectConfigurationApiClient.getChangeLogs(mainProject!.id)
    return { ...nonPagedChangeLogsResponse, data: mapToPagedResponseDummy(nonPagedChangeLogsResponse.data) }
  })
}

interface UpdateProjectConfigurationOpts {
  configuration: Partial<ProjectConfiguration>
  projectId: IdType
}

export const useUpdateProjectConfiguration = (): SWRMutationResponse<
  ProjectConfiguration,
  ServiceError,
  UpdateProjectConfigurationOpts,
  Key
> => {
  return useSWRMutation('useUpdateProjectConfiguration', async (_, { arg }) => {
    const { data: projectConfiguration } = await ProjectConfigurationApiClient.update(arg.projectId, arg.configuration)

    await mutate(getUseProjectConfigurationKey(arg.projectId))

    return projectConfiguration
  })
}
