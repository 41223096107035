import type { JSX } from 'react'
import type { ICellRendererParams } from 'ag-grid-community'

import type { EventColor } from '@/core/components/EventTable'
import type { BaseModel } from '@/core/models/base'

import * as S from './styles'

export function EventCell<T extends EventColor & BaseModel>({ data }: ICellRendererParams<T>): JSX.Element | null {
  if (data == null) {
    return null
  }

  return <S.EventColumn color={data.eventColor} />
}
