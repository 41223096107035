import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import '@siemens/ix/dist/siemens-ix/siemens-ix.css'
import '@siemens/ix-aggrid/dist/ix-aggrid/ix-aggrid.css'
import '@siemens/ix-icons/dist/css/ix-icons.css'
import '@siemens/ix-brand-theme/dist/ix-brand-theme/ix-brand-theme.css'

import { applyPolyfills, defineCustomElements } from '@siemens/ix-brand-theme/loader'

import '@/i18n'

import { createRoot } from 'react-dom/client'

import { App } from '@/core/components/App'

applyPolyfills().then(() => defineCustomElements())

const container = document.getElementById('root')

if (container != null) {
  const root = createRoot(container)
  root.render(<App />)
}
