import { JSX, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { generatePath, Navigate, Outlet, useMatch, useNavigate } from 'react-router-dom'

import { IxTabItem, IxTabs } from '@siemens/ix-react'

import { GlobalPageHeader } from '@/core/components/GlobalPageHeader'
import { appTitle } from '@/core/lib/config'

import {
  alarmThresholdConfigurationRoute,
  parameterThresholdConfigurationRoute,
  referenceThresholdConfigurationRoute,
  thresholdConfigurationRoute,
} from '@/features/config/threshold/threshold-route'

const route = thresholdConfigurationRoute

export function ThresholdConfigurationPage(): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const parameterMatch = useMatch(`${parameterThresholdConfigurationRoute.pathname}/*`)
  const alarmMatch = useMatch(alarmThresholdConfigurationRoute.pathname)
  const referenceMatch = useMatch(referenceThresholdConfigurationRoute.pathname)
  const selectedTabIndex = useMemo(() => (referenceMatch ? 1 : alarmMatch ? 2 : 0), [alarmMatch, referenceMatch])

  if (!selectedTabIndex && !parameterMatch) {
    return <Navigate to={parameterThresholdConfigurationRoute.pathname} replace />
  }

  return (
    <>
      <Helmet>
        <title>
          {appTitle} - {t(`routes.${route.name}`)}
        </title>
      </Helmet>

      <GlobalPageHeader route={route} title={t(`routes.${route.name}`)}>
        <IxTabs selected={selectedTabIndex}>
          <IxTabItem onClick={() => navigate(generatePath(parameterThresholdConfigurationRoute.pathname))}>
            {t(`routes.${parameterThresholdConfigurationRoute.name}`)}
          </IxTabItem>

          <IxTabItem onClick={() => navigate(generatePath(referenceThresholdConfigurationRoute.pathname))}>
            {t(`routes.${referenceThresholdConfigurationRoute.name}`)}
          </IxTabItem>

          <IxTabItem onClick={() => navigate(generatePath(alarmThresholdConfigurationRoute.pathname))}>
            {t(`routes.${alarmThresholdConfigurationRoute.name}`)}
          </IxTabItem>
        </IxTabs>

        <div className="mt-4">
          <Outlet />
        </div>
      </GlobalPageHeader>
    </>
  )
}
