import { I18nKeys } from '@/i18n/i18n-keys'

const de: I18nKeys = {
  translation: {
    language: 'Deutsch',
    auth: {
      login: 'Einloggen',
      logout: 'Ausloggen',
    },
    routes: {
      assets: 'Assets',
      maintenanceReports: 'Wartungsberichte',
      Dashboard: 'Dashboard',
      Home: 'Startseite',
      Settings: 'Einstellungen',
      FAQ: 'FAQ',

      Turnout: 'Weiche',
      Turnouts: 'Weichen',

      Alarms: 'Alarme',

      Configuration: 'Konfig',

      'Track configuration': 'STW Aufbau',
      'Edit layer configuration': 'Ebene bearbeiten',
      'Edit layer item configuration': 'Element bearbeiten',
      'Create layer item configuration': 'Element erstellen',

      'Asset configuration': 'Geräte',
      'Create asset configuration': 'Asset erstellen',
      'Edit asset configuration': 'Asset bearbeiten',

      'Threshold configuration': 'Schwellenwerte',
      'Alarm thresholds': 'Schwellenwerte Alarme',
      'Parameter thresholds': 'Schwellenwerte Parameter',
      'Reference curves': 'Referenzkurven',

      'User configuration': 'Benutzer',
      'Edit user configuration': 'Benutzer bearbeiten',

      'Sample Entries': 'Mustereinträge',
      'Sample Entry': 'Mustereintrag',
      'Create Sample Entry': 'Mustereintrag erstellen',
    },
    dashboard: {
      create_new_report: 'Neuer Wartungsbericht'
    },
    config: {
      thresholds: {
        parameter: {
          turntime: {
            title: 'Analysetyp Umlaufzeit',
          },
          meanpower: {
            title: 'Analysetyp mittlere Leistung',
          },
          ai: {
            title: 'Analysetyp AI',
            'no-config-necessary': 'Keine Konfiguration notwendig für Powercurve AI Modell.',
          },
        },
      },
    },
    models: {
      event: {
        direction: 'Richtung',
        'direction-left': 'Links',
        'direction-right': 'Rechts',
        startTime: 'Start',
        duration: 'Dauer',
        meanPower: 'Durchschn. Leistung',
        outsideTemperature: 'Außen',
        trackTemperature: 'Gleis',
        humidityDetected: 'Feuchtigkeit',
      },

      alarmEvent: {
        severity: 'Schweregrad',

        'severity-failure': 'Störung',
        'severity-failure_one': 'Störung',
        'severity-failure_other': 'Störung',
        'severity-failure-with-count_one': '{{count}} Störung',
        'severity-failure-with-count_other': '{{count}} Störung',

        'severity-warning': 'Warnung',
        'severity-warning_one': 'Warnung',
        'severity-warning_other': 'Warnungen',
        'severity-warning-with-count_one': '{{count}} Warnung',
        'severity-warning-with-count_other': '{{count}} Warnungen',

        state: 'Status',
        'state-active': 'Aktiv',
        'state-resolved': 'Erholt',

        type: 'Alarmtyp',
        'type-connection_lost': 'Verbindung verloren',
        'type-condition_degradation': 'Zustandsverschlechterung',
      },

      turnout: {
        state: 'Health Status',
        'state-healthy': 'Gesund',
        'state-alert': 'Störung',
        'state-warning': 'Warnung',
        'state-connected': 'Verbunden',
        'state-disconnected': 'Verbindung verloren',
        connectionState: 'Verbindungsstatus',
        active: 'Aktiv',
        'activity-status': 'Aktivitäts-Status',
        'model-type': 'Analysetyp',
        trackPoint: 'Weichenheizungsgruppe',
        turnoutFunctionalityType: 'Gerät',
        'turnoutFunctionalityType-turnout': 'Weiche',
        'turnoutFunctionalityType-derail': 'Entgleisung',
        waypoint: 'Kilometerpunkt an der Strecke',
        analyticsTypes: {
          turntime_expertsystem: 'Umlaufzeit',
          meanpower_expertsystem: 'Mittlere Leistung',
          powercurve_aimodel: 'KI Leistungskurve',
        },
      },

      healthState: {
        healthState: 'Health Status',
        score: 'Weichen Health Score',
      },

      pointMachine: {
        thresholdConfiguration: 'Schwellenwert Konfiguration',
        'thresholdConfiguration-complete': 'abgeschlossen',
        'thresholdConfiguration-incomplete': 'unvollständig',
      },
    },
    assets: {
      maintenance_reports: {
        create: {
          title: 'Neuer Wartungsbericht',
          create_maintenance_report_for: 'Neuen Wartungsbericht erstellen für:',
          filter: {
            select_area_short: 'Gebiet wählen',
            select_area_long: 'Bitte wählen Sie ein Gebiet',
            select_interlocking_short: 'Interlocking wählen',
            select_interlocking_long: 'Bitte wählen Sie eine Interlocking',
            select_technical_room_short: 'Technical Room wählen',
            select_technical_room_long: 'Bitte wählen Sie einen Technical Room',
            select_turnout_short: 'Weiche wählen',
            select_turnout_long: 'Bitte wählen Sie eine Weiche',
          },
          reason: 'Wählen Sie den Grund für die Wartung/Instandhaltung aus:',
          work: 'Durchgeführte Arbeiten (optional):',
          comment: {
            title: 'Kommentar (optional):',
            placeholder: 'Ist Ihnen etwas Besonderes aufgefallen? Text eingeben (max. 240 Zeichen)',
          },
          save: 'Speichern',
          cancel: 'Abbrechen',
        },
        view: {
          title: 'Wartungsbericht',
          maintenance_report_for: 'Wartungsbericht für:',
          reason: 'Grund für die Wartung/Instandhaltung:',
          work: 'Durchgeführte Arbeiten:',
          comment: 'Kommentar:',
          close: 'Schließen',
        },
        reason: {
          mandatory_manufacturer: 'Vorgegeben Hersteller',
          mandatory_operator: 'Vorgegeben MGB',
          incident: 'Störung',
        },
        work: {
          readjust: 'Neu einstellen',
          replace: 'Auswechseln',
          lubricate: 'Schmieren',
          check: 'Kontrollieren',
          clean: 'Reinigen',
          maintenance_action: 'Wartungsaktion',
          enter_free_text: 'Freitext eingeben',
        },
        doneAt: 'Durchgeführt am',
        table: {
          title: 'Wartungsberichte',
          turnout: 'Weiche',
          doneAt: 'Datum & Uhrzeit',
          reason: 'Grund',
          work: 'Durchgeführte Arbeiten',
          comment: 'Kommentar',
        },
        cta: {
          create_new_report: 'Bericht erstellen',
          download: 'Download',
        },
      },
      modal: {
        turnout: {
          title: 'Weiche bearbeiten',
        },
        derail: {
          title: 'Entgleisungsvorrichtung bearbeiten',
        },
      },
      steps: {
        step1: 'Typ wählen',
        step2: 'Asset erstellen',
        step3: 'Weichenantrieb hinzufügen',
      },
      selection: {
        turnout: {
          heading: 'Weiche hinzufügen',
          subheading: 'Erstellen Sie ein Gerät des Typs "Weiche"',
        },
        derail: {
          heading: 'Entgleisungsvorrichtung hinzufügen',
          subheading: 'Erstellen Sie ein Gerät des Typs "Entgleisungsvorrichtung"',
        },
      },
      create: {
        description: {
          analyticsType:
            'Die Auswahl des Analysetyps bestimmt, wie der Health Score einer Weiche berechnet wird.<br/><a href="{{url}}" target="{{target}}">Mehr Informationen</a>',
          turntime_expertsystem:
            'Das Expertensystem errechnet anhand von Referenzwerten und aktuellen Weichenstellungen einen Health Score.',
          meanpower_expertsystem:
            'Das Expertensystem verwendet als Eingabe Referenzkurven und aktuelle Leistungskurven, um einen Gesamt-Health Score zu berechnen.',
          powercurve_aimodel:
            'Das KI-Modell verwendet als Eingabe die aktuelle und die historische Leistungskurve, um den Health Score direkt zu berechnen (0 .. 1)',
          status:
            'Wenn Sie "Inaktiv" wählen, wird die Weiche nicht in der Weichenübersicht und in der Übersicht über den Health Score aufgeführt.',
        },
        turnout: {
          heading: 'Neue Weiche erstellen',
          created: 'Weiche <strong>"{{name}}"</strong> erfolgreich erstellt.',
        },
        derail: {
          heading: 'Neue Entgleisungsvorrichtung erstellen',
          created: 'Entgleisungsvorrichtung <strong>"{{name}}"</strong> erfolgreich erstellt.',
        },
        pointMachines: {
          heading: 'Weichenantrieb hinzufügen',
        },
      },
      cta: {
        save_and_activate: 'Speichern und aktivieren',
        save: 'Speichern ohne Aktivierung',
      },
      info: {
        PM_required:
          'Für die Auswahl "KI-Leistungskurve" ist es zwingend erforderlich, Weichenantriebe zu erstellen und die Weiche muss mit SidisW ausgestattet sein (da die mittlere Leistung aller Punktmaschinen der Weiche für die Berechnung des Health Score benötigt wird).',
        PM_required_tooltip: 'Es muss mindestens ein Weichenantrieb erstellt werden.',
      },
      analyticsType: {
        info: {
          turntime_expertsystem: 'Expert turnover time',
          turntime_expertsystem_info: 'Das Hinzufügen eines Weichenantriebes ist optional.',
          powercurve_aimodel:
            'Für die Auswahl "KI-Leistungskurve" ist es zwingend erforderlich, <b>Weichenantriebe im nächsten Schritt zu erstellen</b> und die Weiche muss mit SidisW ausgestattet sein (da die mittlere Leistung aller Punktmaschinen der Weiche für die Berechnung des Health Score benötigt wird).',
          meanpower_expertsystem:
            'Für die Auswahl "Mittlere Leistung" ist es zwingend erforderlich, <b>Weichenantriebe im nächsten Schritt zu erstellen</b> und die Weiche muss mit SidisW ausgestattet sein (da die mittlere Leistung aller Punktmaschinen der Weiche für die Berechnung des Health Score benötigt wird).',
          PM_required:
            'Für die Aktivierung ist mindestens einen Weichenantrieb erforderlich und die Weiche muss mit SidisW ausgestattet sein (da die mittlere Leistung aller Punktmaschinen der Weiche für die Berechnung des Health Score benötigt wird).',
        },
      },
      details: {
        event: 'Weichenantriebs-Ereignis',
        duration_fitness: 'Dauer & Fitness',
        temperature: 'Temperatur & Feuchtigkeit',
        mark: 'Kennzeichnen als',
      },
    },

    charts: {
      legend: {
        events: 'Eregnisse',
        alarms: 'Alarme',
        maintenance: 'Wartungen',
      },
      tooltip: {
        maintenance: {
          reason: 'Grund',
          doneAt: 'Durchgeführt am',
          work: 'Arbeiten',
          comment: 'Kommentar',
        },
      },
    },

    A: 'A',
    'A failure alarm is triggered if the health score for a turnout falls below the defined alarm threshold.':
      'Ein Störungsalarm wird ausgelöst, wenn der Health Score einer Weiche unter den vom Admin-Nutzer konfigurierten Schwellenwert für einen Störungs-Alarm fällt (roter Bereich).',
    'A warning alarm is triggered if the health score for a turnout falls below the defined warning threshold.':
      'Ein Warnungsalarm wird ausgelöst, wenn der Health Score einer Weiche unter den vom Admin-Nutzer konfigurierten Schwellenwert für einen Warnungsalarm fällt (gelber Bereich).',
    About: 'Über uns',
    'Account management': 'Kontoverwaltung',
    Actions: 'Aktionen',
    'Active alarms': 'Aktive Alarme',
    Active: 'Aktiv',
    'Add {item}': '{{item}} hinzufügen',
    'Alarm ID': 'Alarm ID',
    'Alarms for': 'Alarms für',
    'Alarms overview': 'Alarmübersicht',
    'Alarms recovered last 24h': 'Erholte Alarme 24h',
    'Alarms since installation': 'Alarme seit Installation',
    'Alarms triggered last 24h': 'Ausgelöste Alarme 24h',
    AlarmThresholdConfig:
      '<p>Der Health Status einer Weiche wird durch den Health Score festgelegt, einen Wert zwischen 0 bis 1 (0 = hohes Störungsrisiko, 1 = optimale Funktionalität).</p><p>Der Health Score einer Weiche wird über die kontinuierlichen Bewertung der Umläufe aller zur Weiche gehörenden Weichenantriebe errechnet.</p><p>Der Fitness Score eines Umlaufs basiert auf drei Parametern (sofern verfügbar): Zeit in Sekunden, Leistung in Watt und Arbeit in Joule/Wattsekunden.</p><p>Es wird ein Alarm ausgelöst, wenn der Health Score einer Weiche unter den vom Admin-Nutzer konfigurierten Wert fällt.</p><p>Es gibt zwei unterschiedliche Alarmtypen:</p><ul><li>Ein gelber Alarm ist ein Warnungsalarm. Das bedeutet, dass sich der Zustand der Weiche verschlechtert und es angeraten sein kann, die Weiche zu inspizieren um eine potentielle Störung zu vermeiden.</li><li>Ein roter Alarm ist ein Störungsalarm. Das bedeutet, der Zustand der Weiche wird als kritisch eingeschätzt und der Betrieb ist in Gefahr.</li></ul>',
    'App settings': 'App Einstellungen',
    'App version': 'App Version',
    'Are you sure?': 'Bist du sicher?',
    'Average turn time 24h': 'Duchschnittl. Umstellzeit 24h',

    B: 'B',
    'Back home': 'Zurück zur Startseite',
    'Build timestamp': 'Build timestamp',
    'Bulk edit': 'Massenbearbeitung',
    'Bulk edit turnouts': 'Weichen-Massenbearbeitung',

    C: 'C',
    '{count} turnouts_one': '{{count}} Weiche',
    '{count} turnouts_other': '{{count}} Weichen',
    Cancel: 'Abbrechen',
    'Change e-mail address': 'E-mail Adresse ändern',
    Changelog: 'Changelog',
    'Click on the following link to:': 'Klick auf den folgenden Link um:',
    'Commit-ID': 'Commit-ID',
    'Configure thresholds for alarm types': 'Konfiguriere Schwellenwerte für Alarmtypen',
    'Configure thresholds for performance parameters': 'Konfiguriere Schwellenwerte für Performance Parameter',
    Configure: 'Konfigurieren',
    'Corporate information': 'Corporate information',
    'Corrected power': 'Korrigierte Leistung',
    Count: 'Anzahl',
    'Create point machine': 'Weichenantrieb hinzufügen',
    Custom: 'Benutzerdefiniert',

    D: 'D',
    'Data privacy': 'Datenschutz',
    Date: 'Datum',
    'Delete point machine': 'Weichenantrieb löschen',
    'Delete reference curve_one': 'Referenzkurve löschen',
    'Delete reference curve_other': 'Referenzkurven löschen',
    'Delete reference curve': 'Referenzkurve löschen',
    'Delete turnout_one': 'Gerät löschen',
    'Delete turnout_other': 'Geräte löschen',
    'Delete turnout': 'Gerät löschen',
    'Delete pointmachines': 'Alle verbundenen Weichenantriebe werden ebenfalls gelöscht.',
    'Deleted at': 'Gelöscht am',
    'Deleted by': 'Gelöscht von',
    Description: 'Beschreibung',
    Done: 'Fertig',

    E: 'E',
    'Edit {name}': '{{name}} bearbeiten',
    'Edit point machines': 'Weichenantriebe bearbeiten',
    'End time': 'Endzeit',
    'Error while loading last status': 'Fehler beim Laden des letzten Status',
    Export: 'Exportieren',

    F: 'F',
    'Failure alarm': 'Störungsalarm',
    Filter: 'Filter',
    'Fitness score': 'Fitness Score',
    'For example, if the threshold for mean power difference is set at 300 Watts, it means that as soon as the turn event mean power exceeds the optimal turn event power (provided by optimal reference curves) by 300 Watts, the fitness score of this turn event is voting towards a potential alarm.':
      'Als Beispiel: Wenn für die Leistung ein Schwellenwert von 300 Watt konfiguriert wurde, tendiert der Fitness Score eines Umlaufs ins Negative wenn die gemessene Umlaufleistung 300 Watt von der auf den Referenzkurven basierenden optimalen Umlaufleistung abweicht.',
    'For example, if the threshold for time difference is set at 0,5 sec, it means that as soon as the turn time exceeds the optimal turn time (provided by optimal reference curves) by 0,5 sec, the fitness score of this turn event is voting towards a potential alarm.':
      'Als Beispiel: Wenn für die Umlaufzeit ein Schwellenwert von 0,5 Sekunden konfiguriert wurde, tendiert der Fitness Score eines Umlaufs ins Negative wenn die gemessene Umlaufzeit 0,5 Sekunden von der auf den Referenzkurven basierenden optimalen Umlaufzeit abweicht.',
    'For example, if the threshold for work difference is set at 500 Joule, it means that as soon as the turn event work exceeds the optimal turn event work (provided by optimal reference curves) by 500 Joule, the fitness score of this turn event is voting towards a potential alarm.':
      'Als Beispiel: Wenn für die Arbeit ein Schwellenwert von 500 Joule konfiguriert wurde, tendiert der Fitness Score eines Umlaufs ins Negative wenn die gemessene Arbeitsleistung 500 Joule von der auf den Referenzkurven basierenden optimalen Arbeitsleistung abweicht.',

    G: 'G',
    General: 'Allgemein',

    H: 'H',
    'Health score': 'Health Score',
    'Health score at time of alarm': 'Health Score zum Zeitpunkt des Alarms',
    'Health score below {threshold}': 'Health Score unter {{threshold}}',
    'Health status history': 'Verlauf Health Status',
    'Health status of turnouts': 'Health Status der Weichen',

    I: 'I',
    'If the deviation threshold is low, the measured performance of a turn event has to be be within this narrow range to not cause a deterioration of the health score. The lower the deviation threshold, the more sensitive is the system towards alarm generation.':
      'Wenn ein geringer Schwellenwert für die Abweichung definiert wurde, muss die gemessene Umlaufleistung innerhalb dieses Bereichs liegen, damit es zu keiner Verschlechterung des Health Scores kommt. Je kleiner der erlaubte Abweichungsbereich, desto empfindlicher reagiert das System bei der Alarmgenerierung.',
    'Installation date': 'Datum der Installation',

    J: 'J',

    K: 'K',

    L: 'L',
    Language: 'Sprache',
    'Last 24h': 'Letzte 24h',
    'Last 3d': 'Letzte 3d',
    'Last 7d': 'Letzte 7d',
    'Last 30d': 'Letzte 30d',
    'Last connection': 'Letzte Verbindung',
    'Last status not found': 'Letzter Status kann nicht gefunden werden',
    'Last status received': 'Letzter empfangener Status',
    'Last update': 'Letztes Update',
    Layer: 'Ebene',
    Loading: 'Lädt',

    M: 'M',
    'Manage reference curves': 'Referenzkurven verwalten',
    'Mark as "Outlier"': 'Als "Sonderfall" markieren',
    'Mark as "Reference"': 'Als "Referenz" markieren',
    'Maximum number of entries exceeded. Not all entries are being displayed.':
      '<b>Bitte beachten Sie:</b><br/>Für die ausgewählten Filtereinstellungen gibt es zu viele Ereignisse, sie können nicht alle angezeigt werden.<br/>Bitte passen Sie die Filtereinstellungen an. Zum Beispiel, indem Sie nur eine Point Machine oder nur eine Richtung auswählen oder den Zeitraum einschränken. Vielen Dank!',
    'Mean power & trend': 'Durchschn. Leistung & Trend',
    'Mean power': 'Durchschn. Leistung',

    N: 'N',
    Name: 'Name',
    Next: 'Weiter',
    'No active alarms': 'Keine aktiven Alarme',
    'No alarms in the last {{time}}': 'Keine Alarme in den letzten {{time}}',
    'No data': 'Keine Daten',
    'No range set': 'Kein Zeitraum',
    'No recovered alarms': 'Keine erholten Alarme',
    'Nr of total turn events': 'Anzahl aller Umläufe',
    'Number of triggered alarms': 'Anzahl ausgelöster Alarme',

    O: 'O',
    Okay: 'Okay',
    'Once you have successfully entered all mandatory information about the point machine(s) that constitute this turnout, the connection to CSC will be established as soon as possible.':
      'Sobald Sie alle Pflichtangaben zu den Weichenantrieben dieser Weiche ausgefüllt haben, wird die Verbindung zu CSC so schnell wie möglich hergestellt.',
    'Overview all turnouts': 'Überblick aller Weichen',
    'Overview turnout condition': 'Überblick Weichenzustand',

    P: 'P',
    ParameterThresholdConfig:
      '<p>Der Admin-Nutzer kann für jeden Parameter einen Schwellenwert für die Abweichung konfigurieren. Dieser Schwellenwert bestimmt, wie weit die tatsächlichen Leistungswerte von den optimalen Leistungswerten des Weichenantriebs abweichen dürfen. Die optimalen Leistungswerte des Weichenantriebs werden durch die ausgewählten Referenzkurven ermittelt.</p><p>Wenn sich die Abweichung innerhalb des erlaubten Abweichungsbereichs befindet, wird der Zustand als unkritisch eingestuft. Überschreitet die Abweichung den akzeptierten Abweichungsbereich, wird dies als kritisch eingestuft.</p><p>Der Schwellenwert für die Abweichung wirkt sich auf den Fitness Score eines Umlaufs aus und damit, wenngleich mit weniger Einfluss, auch auf den Health Score der Weiche.</p>',
    Parent: 'Überordnung',
    'Performance data': 'Leistungsdaten',
    'PM{{index}} name': 'PM{{index}} Name',
    'PM1 ID': '1. Weichenantriebs-ID',
    'PM2 ID': '2. Weichenantriebs-ID',
    'Point machine info': 'Weichenantrieb Info',
    'Point machine_one': 'Weichenantrieb',
    'Point machine_other': 'Weichenantriebe',
    'Point machine': 'Weichenantrieb',
    Position: 'Position',
    Power_curves_table_headline_zero: 'Angezeigte Leistungskurven',
    Power_curves_table_headline_one: 'Angezeigte Leistungskurven<i> - eine Reihe ausgewählt</i>',
    Power_curves_table_headline_other: 'Angezeigte Leistungskurven<i> - {{count}} Reihen ausgewählt</i>',
    'Power curves': 'Leistungskurven',

    Q: 'Q',

    R: 'R',
    'Required field': 'Benötigtes Feld',
    'Reset to default': 'Standardwerte zurücksetzen',

    S: 'S',
    'Save layer item': 'Element speichern',
    'Save layer': 'Ebene speichern',
    'Save point machine': 'Weichenantrieb speichern',
    'Save thresholds': 'Schwellenwerte speichern',
    'Save turnout_one': 'Weiche speichern',
    'Save turnout_other': 'Weichen speichern',
    'Save turnout': 'Weiche speichern',
    'Save user': 'Benutzer speichern',
    'Search for PM ID': 'Suche nach Antriebs-ID',
    'Search for turnout ID or PM ID': 'Suche nach Weichen-ID oder Antriebs-ID',
    'Search for turnout name': 'Suche nach Weichenname',
    'Serial number': 'Seriennummer',
    'Show all': 'Alle anzeigen',
    'Sorry, the page you visited does not exist.': 'Entschuldigung, diese Seite existiert nicht.',
    'Start time': 'Startzeit',
    Submit: 'Absenden',
    'Successfully updated event': 'Event wurde erfolgreich aktualisiert',
    'Successfully updated thresholds': 'Schwellenwerte wurden erfolgreich aktualisiert',
    Support: 'Support',

    T: 'T',
    'Technical ID': 'Technik-ID',
    'Technical room': 'Technikraum',
    'Terms of use': 'Nutzungsbedingungen',
    Theme: 'Theme',
    Threshold: 'Schwellenwert',
    'Thresholds left': 'Schwellenwerte links',
    'Thresholds right': 'Schwellenwerte rechts',
    Timestamp: 'Zeitstempel',
    Timezone: 'Zeitzone',
    Total: 'Gesamt',
    Trend: 'Trend',
    'Turn event': 'Umschaltvorgang',
    'Turn time': 'Umlaufzeit',
    'Turnout condition': 'Weichenzustand',
    'Turnout configuration status': 'Weichenkonfigurationsstatus',
    'Turnout health score': 'Health Score Weiche',
    'Turnout ID': 'Weichen-ID',
    'Turnout name': 'Weichenname',
    Type: 'Typ',

    U: 'U',
    "Uncheck this box if you don't want to approve of the automatic connection to CSC.":
      "Uncheck this box if you don't want to approve of the automatic connection to CSC.",
    'Update display name': 'Anzeigename anpassen',
    'Update password': 'Passwort anpassen',
    'Updating event was unsuccessful': 'Aktualisieren des Events war nicht erfolgreich',
    'Updating event': 'Event wird aktualisiert',
    'Updating thresholds was unsuccessful': 'Aktualisieren der Schwellenwerte war nicht erfolgreich',
    'Updating thresholds': 'Schwellenwerte werden aktualisiert',
    'User account settings': 'Benutzerkonto Einstellungen',
    User: 'Benutzer',
    'User name': 'Benutzername',
    'Unmark as "Reference"': 'Als "Referenz" demarkieren',

    V: 'V',
    'Value changed from': 'Wert geändert von',
    'Value changed to': 'Wert geändert zu',

    W: 'W',
    'Warning alarm': 'Warnungsalarm',
    'Weather data': 'Durchschn. Leistung & Wetter',

    weatherGraph: {
      axis: {
        temperature: 'Celsius',
      },
      legend: {
        temperature: 'Temperaturkurven',
        humidity: 'Feuchtigkeit',
        track: 'Schiene',
        outside: 'Aussentemperatur',
        humidityDetected: 'erkannt',
        humidityNotDetected: 'Nein',
      },
      tooltip: {
        sensor: 'Sensor',
        temperature: 'Temperatur',
        humidity: 'Feuchtigkeit gemessen',
      },
    },

    X: 'X',

    Y: 'Y',

    Z: 'Z',

    FAQ: [
      {
        title: 'Alarme',
        questions: [
          {
            question: 'Wie funktioniert das Alarm System?',
            answer:
              '<p>Es gibt zwei unterschiedliche Alarmtypen:</p><ul><li>Ein gelber Alarm ist ein Warnungsalarm. Das bedeutet, dass sich der Zustand der Weiche verschlechtert und es angeraten sein kann, die Weiche zu inspizieren um eine potentielle Störung zu vermeiden.</li><li>Ein roter Alarm ist ein Störungsalarm. Das bedeutet, der Zustand der Weiche wird als kritisch eingeschätzt und der Betrieb ist in Gefahr.</li></ul>',
          },
        ],
      },
      {
        title: 'Health Status und Fitness Score',
        questions: [
          {
            question: 'Wie funktioniert das Health Status System?',
            answer:
              '<p>Jede Weiche besitzt einen Health Status: grün bedeutet gesund, gelb bedeutet Warnung, rot bedeutet hohes Ausfallrisiko. Er betrachtet alle Weichenantriebe an der Weiche.</p><p>Der Health Status wird durch den Health Score einer Weiche definiert, ein Bereich zwischen 0 und 1 (0 = hohes Ausfallrisiko, 1 = optimale Funktionalität).</p><p>Der Health Score einer Weiche wird über die kontinuierlich berechneten Fitness Scores aller Weichenantriebe dieser Weiche ermittelt.</p><p>Die Fitnessbewertung basiert auf drei Leistungsparametern: Zeit in Sekunden, mittlere Leistung in Watt und Arbeit in Joule.</p>',
          },
          {
            question: 'Wie wird der Fitness Score für einen Umlauf berechnet?',
            answer:
              '<p>Der Fitness Score eines Umlaufs hängt davon ab, wie stark die Leistung von den konfigurierten Schwellenwerten abweicht.</p><p>Für jeden Leistungsparameter kann der Administrator eine Abweichungsschwelle konfigurieren. </p><p>Die Schwelle definiert, wie stark die Leistung einer Weiche von der optimalen Leistung für diese Weiche abweichen darf. Die optimale Leistung wird auf der Grundlage der vom Administrator ausgewählten Referenzkurven berechnet.</p><p>Liegt die Abweichung innerhalb des Schwellenbereichs, ist der Zustand unkritisch. Liegt die Abweichung über dem zulässigen Schwellenwert, so wird der Zustand als kritisch eingestuft.</p>',
          },
          {
            question: 'Wie kann der Admin-Benutzer den Fitness Score des Umlaufs beeinflussen?',
            answer:
              '<p>Der Administrator konfiguriert die Schwellenwerte für die Parameter Zeit, mittlere Leistung und Arbeit. Damit legt er fest:</p><ul><li>Ist die Schwelle für die erlaubt Abweichung gering, müssen die Umläufe sehr nahe an den Referenzkurven liegen.</li><li>Ist die Schwelle der erlaubten Abweichung höher, lässt sie einen größeren Spielraum zu. Je höher der Abweichungswert eingestellt wird, desto unempfindlicher ist die Einstellung gegenüber der Auslösung von Alarmen.</li></ul>',
          },
        ],
      },
      {
        title: 'Diagramme',
        questions: [
          {
            question: 'Wie funktioniert das Zoomen?',
            answer:
              '<p>In Diagrammen kann mit dem Mausrad oder auf Touchpads mit der Zwei-Finger-Geste herein- und herausgezoomt werden.</p><p>Zum Zurücksetzen der Zoom-Stufe führen Sie einen Doppelklick auf einen beliebigen Bereich im Diagramm durch.</p>',
          },
        ],
      },
    ],
  },
}

// we need default export for i18nally vscode plugin
export default de
