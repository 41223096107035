import { useMemo } from 'react'
import { Key } from 'swr'

import { useSWR, type SWRResponse } from '@/core/hooks/swr'
import type { BaseModel, IdType } from '@/core/models/base'

import { TechnicalRoomApiClient } from '@/features/assets/api/technical-room'
import type { TechnicalRoom } from '@/features/assets/models/technical-room'

import { useInterlocking, useInterlockings } from './use-interlocking'

export const getTechnicalRoomsKey = (parentId: Parameters<typeof useTechnicalRooms>[0], parents?: BaseModel[]): Key => {
  if (parentId == null) {
    if (parents == null) {
      return null
    }

    return ['useTechnicalRooms']
  }

  return ['useTechnicalRooms', parentId]
}

export const useTechnicalRooms = (parentId?: IdType | null): SWRResponse<TechnicalRoom[]> => {
  const { data: parents, ...parentsData } = useInterlockings()

  const { data: rawEntries, ...rawEntriesData } = useSWR(getTechnicalRoomsKey(parentId, parents), async () => {
    if (parentId == null) {
      const responses = await Promise.all(parents!.map((parent) => TechnicalRoomApiClient.list(parent.id)))
      const allEntries = responses.flatMap((entry) => entry.data)
      return { data: allEntries, dateTime: responses[0].dateTime }
    }

    return TechnicalRoomApiClient.list(parentId)
  })

  const data = useMemo<TechnicalRoom[] | undefined>(() => {
    return rawEntries?.map((entry) => ({
      ...entry,
      _interlocking: parents?.find((parent) => parent.id === entry.interlocking.id),
    }))
  }, [rawEntries, parents])

  return useMemo(() => {
    return {
      data,
      ...rawEntriesData,
      isLoading: rawEntriesData.isLoading || parentsData.isLoading,
      isValidating: rawEntriesData.isValidating || parentsData.isValidating,
      mutate: async () => {
        await Promise.all([rawEntriesData.mutate(), parentsData.mutate()])
        return undefined
      },
    }
  }, [rawEntriesData, data, parentsData])
}

export const getTechnicalRoomKey = (id: Parameters<typeof useTechnicalRoom>[0]): Key => {
  if (id == null) {
    return null
  }

  return ['useTechnicalRoom', id]
}

export const useTechnicalRoom = (id: IdType | undefined | null): SWRResponse<TechnicalRoom> => {
  const { data: rawEntry, ...rawEntryData } = useSWR(getTechnicalRoomKey(id), () => {
    return TechnicalRoomApiClient.read(id!)
  })

  const { data: parent, ...parentData } = useInterlocking(rawEntry?.interlocking.id)

  const data = useMemo<TechnicalRoom | undefined>(() => {
    if (rawEntry == null) {
      return undefined
    }

    return { ...rawEntry, _interlocking: parent }
  }, [rawEntry, parent])

  return useMemo(() => {
    return {
      data,
      ...rawEntryData,
      isLoading: rawEntryData.isLoading || parentData.isLoading,
      isValidating: rawEntryData.isValidating || parentData.isValidating,
      mutate: async () => {
        await Promise.all([rawEntryData.mutate(), parentData.mutate()])
        return undefined
      },
    }
  }, [data, rawEntryData, parentData])
}
