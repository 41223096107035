import { CrudApiClient } from '@/core/api/base'
import { BaseModelCodec, type BaseModel } from '@/core/models/base'
import type { DateRangeFilterOpts } from '@/core/models/filter'
import type { PaginationFilterOpts } from '@/core/models/pagination'

import {
  PagedAlarmEventsCodec,
  type AlarmEvent,
  type AlarmEventType,
  type PagedAlarmEvents,
} from '@/features/alarms/models/alarm-event'

import type { TrackConfigurationFilterOpts } from '@/features/config/track/models/track-config-filter-opts'

export interface QueryAlarmEventsFilterOpts
  extends TrackConfigurationFilterOpts,
    PaginationFilterOpts,
    DateRangeFilterOpts,
    Pick<AlarmEvent, 'severity' | 'state'> {
  type: AlarmEventType
}

const instance = new CrudApiClient<BaseModel, BaseModel, PagedAlarmEvents, QueryAlarmEventsFilterOpts>(
  'alarm-events',
  BaseModelCodec,
  undefined,
  undefined,
  PagedAlarmEventsCodec,
)

export { instance as AlarmEventApiClient }
