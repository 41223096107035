import { JSX } from 'react'
import { useTranslation } from 'react-i18next'

import { IxTile } from '@siemens/ix-react'

import { EventKpiTile } from '@/core/components/EventKpiTile'
import { LoadingErrorWrapper } from '@/core/components/LoadingErrorWrapper'

import type { TurnoutStateStatisticsFilterOpts } from '@/features/assets/api/turnout'
import { useTurnoutStateStatistics } from '@/features/assets/hooks/use-turnout'

export interface HealthPointsKpiTileProps {
  filterOpts: Partial<TurnoutStateStatisticsFilterOpts>
}

export function HealthPointsKpiTile({ filterOpts }: HealthPointsKpiTileProps): JSX.Element {
  const { t } = useTranslation()
  const { data: stateStatistics, ...stateStatisticsData } = useTurnoutStateStatistics(filterOpts)

  return (
    <IxTile>
      <div slot="header">{t('Health status of turnouts')}</div>

      <div>
        <LoadingErrorWrapper
          data={stateStatistics}
          error={stateStatisticsData.error}
          isLoading={stateStatisticsData.isLoading}
        >
          {(stateStatistics) => {
            const total = stateStatistics.activationStatistics.active

            return (
              <div className="row g-3">
                <div className="col-6">
                  <EventKpiTile
                    title={t('models.turnout.state-healthy')}
                    type="inner"
                    unit="%"
                    value={total === 0 ? 0 : (stateStatistics.operationState.green / total) * 100}
                  >
                    <div className="fw-bold">
                      {stateStatistics.operationState.green} / {total}
                    </div>
                  </EventKpiTile>
                </div>

                <div className="col-6">
                  <EventKpiTile
                    title={t('models.turnout.state-warning')}
                    type="inner"
                    unit="%"
                    value={total === 0 ? 0 : (stateStatistics.operationState.yellow / total) * 100}
                  >
                    <div className="fw-bold">
                      {stateStatistics.operationState.yellow} / {total}
                    </div>
                  </EventKpiTile>
                </div>

                <div className="col-6">
                  <EventKpiTile
                    title={t('models.turnout.state-alert')}
                    type="inner"
                    unit="%"
                    value={total === 0 ? 0 : (stateStatistics.operationState.red / total) * 100}
                  >
                    <div className="fw-bold">
                      {stateStatistics.operationState.red} / {total}
                    </div>
                  </EventKpiTile>
                </div>
              </div>
            )
          }}
        </LoadingErrorWrapper>
      </div>
    </IxTile>
  )
}
