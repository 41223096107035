import { JSX } from 'react'
import type { ICellRendererParams } from 'ag-grid-community'
import { generatePath, Link } from 'react-router-dom'

import type { AlarmEvent } from '@/features/alarms/models/alarm-event'

import { turnoutRoute } from '@/features/assets/routes/turnouts'

export function TurnoutIdCell({ data }: ICellRendererParams<AlarmEvent>): JSX.Element | null {
  if (data == null) {
    return null
  }

  return (
    <Link to={generatePath(turnoutRoute.pathname, { turnoutId: String(data.turnout.id) })}>
      {data.turnout.engineeringId}
    </Link>
  )
}
