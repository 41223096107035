import { createContext, PropsWithChildren, useContext, useRef } from 'react'
import { create, useStore } from 'zustand'

import { Filter } from '@/core/components/DataFilter/types'

type Props = {
  filters: Filter[]
}

type Actions = {
  setFilters: (filters: Filter[]) => void
}

type MaintenanceReportsState = Props & Actions

const createInitialDefaultState = (turnoutName?: string): Props => {
  const filters: Filter[] = []
  if (turnoutName) {
    filters.push({ path: 'assetName', value: turnoutName, type: 'text' })
  }

  return {
    filters,
  }
}

const createStore = (turnoutName?: string) =>
  create<MaintenanceReportsState>((set) => ({
    ...createInitialDefaultState(turnoutName),
    setFilters: (filters) => {
      return set(() => ({
        filters,
      }))
    },
  }))

type MaintenanceReportsStore = ReturnType<typeof createStore>

const MaintenanceReportsContext = createContext<MaintenanceReportsStore | null>(null)

export const MaintenanceReportsProvider = ({ turnoutName, children }: { turnoutName?: string } & PropsWithChildren) => {
  const storeRef = useRef<MaintenanceReportsStore>()
  if (!storeRef.current) {
    storeRef.current = createStore(turnoutName)
  }
  return <MaintenanceReportsContext.Provider value={storeRef.current}>{children}</MaintenanceReportsContext.Provider>
}

export function useMaintenanceReportsStore<T>(selector: (state: MaintenanceReportsState) => T): T {
  const store = useContext(MaintenanceReportsContext)
  if (!store) throw new Error('Missing MaintenanceReportsProvider.Provider in the tree')
  return useStore(store, selector)
}
