import { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'

import { IxIconButton, IxSpinner, IxTypography } from '@siemens/ix-react'

const StyledModal = styled.div`
  width: 45rem;
  background-color: var(--theme-modal--background);
  border-radius: var(--theme-default-border-radius);
  overflow: auto;
  max-height: 90vh;
`
const StyledBackdrop = styled.div`
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  padding-top: 40px;
  justify-content: center;
`

type BackdropProps = { withBlurAndDarken?: boolean }
const Backdrop: FC<PropsWithChildren<BackdropProps>> = ({ children, withBlurAndDarken = true }) => {
  return (
    <StyledBackdrop
      style={
        withBlurAndDarken
          ? { backgroundColor: 'var(--theme-color-lightbox, #0000008c)', backdropFilter: 'blur(2.7182817459px)' }
          : {}
      }
    >
      {children}
    </StyledBackdrop>
  )
}

type ModalStyles = { backdrop?: BackdropProps; modal?: { shadow?: boolean; width?: string | number } }
type ModalV2Props = {
  title: string
  isLoading?: boolean
  styles?: ModalStyles
  onClose: () => void
}
const defaultStyles: ModalStyles = {
  backdrop: { withBlurAndDarken: true },
  modal: {
    shadow: true,
  },
}
export const ModalV2: FC<PropsWithChildren<ModalV2Props>> = ({
  title,
  isLoading,
  styles = defaultStyles,
  children,
  onClose,
}) => {
  return (
    <Backdrop {...styles?.backdrop}>
      <StyledModal
        className="d-flex flex-column gap-4 p-4"
        style={{
          ...(styles.modal?.shadow ? { boxShadow: 'var(--theme-shadow-4)' } : {}),
          ...(styles.modal?.width ? { width: styles.modal?.width } : {}),
        }}
      >
        <div className="d-flex align-items-start">
          <IxTypography format="h3" className="flex-grow-1">
            {title}
          </IxTypography>
          <IxIconButton icon="close" ghost onClick={onClose}></IxIconButton>
        </div>
        {isLoading ? <IxSpinner size="large" className="align-self-center" /> : children}
      </StyledModal>
    </Backdrop>
  )
}
