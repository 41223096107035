import { JSONSchema7Object } from 'json-schema'

import { CrudApiClient } from '@/core/api/base'

import {
  AnalyticsThresholdCodec,
  type AnalyticsThreshold,
} from '@/features/config/threshold/parameter/turntime/models/analytics-threshold'
import { TurnoutApiClient, type QueryTurnoutFilterOpts } from '@/features/assets/api/turnout'
import type { RawTechnicalRoom } from '@/features/assets/models/technical-room'
import type { RawPagedTurnouts, RawTurnout } from '@/features/assets/models/turnout'

class AnalyticsThresholdApiClient extends CrudApiClient<AnalyticsThreshold, RawTurnout> {
  protected getCreateBody({ direction, turnoverTimeUpper, turnoverTimeLower }: AnalyticsThreshold): JSONSchema7Object {
    return { direction, turnoverTimeUpper, turnoverTimeLower }
  }

  protected getUpdateBody({ direction, turnoverTimeUpper, turnoverTimeLower }: AnalyticsThreshold): JSONSchema7Object {
    return { direction, turnoverTimeUpper, turnoverTimeLower }
  }
}

const instance = new AnalyticsThresholdApiClient(
  'analytics-thresholds/turntime-expertsystem',
  AnalyticsThresholdCodec,
  TurnoutApiClient as CrudApiClient<RawTurnout, RawTechnicalRoom, RawPagedTurnouts, QueryTurnoutFilterOpts>,
  'turnoutId',
)

export { instance as AnalyticsThresholdApiClient }
