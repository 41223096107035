import { FC, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { GlobalPageHeader } from '@/core/components/GlobalPageHeader'
import { appTitle } from '@/core/lib/config'
import type { IdType } from '@/core/models/base'

import { MaintenanceReportsPageContent } from '@/features/maintenance-reports/components/MaintenanceReportsPageContent'
import {
  maintenanceReportsCreateRoute,
  maintenanceReportsRoute,
  maintenanceReportsViewRoute,
} from '@/features/maintenance-reports/routes/maintenance-report-routes'

export const MaintenanceReportsPage: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const navigateToReportViewRoute = useCallback(
    (reportId: IdType) =>
      navigate(
        generatePath(maintenanceReportsViewRoute.pathname, {
          reportId: reportId.toString(),
        }),
      ),
    [navigate],
  )

  const navigateToReportCreateRoute = useCallback(() => {
    navigate(generatePath(maintenanceReportsCreateRoute.pathname))
  }, [navigate])

  return (
    <>
      <Helmet>
        <title>
          {appTitle} - {t('routes.maintenanceReports')}
        </title>
      </Helmet>

      <GlobalPageHeader route={maintenanceReportsRoute} title={t('routes.maintenanceReports')}>
        <MaintenanceReportsPageContent
          readOnly={false}
          onViewReportClick={navigateToReportViewRoute}
          onCreateReportClick={navigateToReportCreateRoute}
        />
      </GlobalPageHeader>
    </>
  )
}
