import { JSX, useCallback, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Trans, useTranslation } from 'react-i18next'
import { generatePath, Outlet, useMatch, useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'

import { IxBlind, IxButton } from '@siemens/ix-react'

import { useMainProject } from '@/core/hooks/use-project'
import { appTitle } from '@/core/lib/config'

import {
  parameterMeanpowerThresholdConfigurationRoute,
  parameterPowercurveAiThresholdConfigurationRoute,
  parameterThresholdConfigurationRoute,
  parameterTurntimeThresholdConfigurationRoute,
  thresholdConfigurationRoute,
} from '@/features/config/threshold/threshold-route'

const ParagraphNoMarginLastChild = styled.p`
  &:last-child {
    margin-bottom: 0;
  }
`

export function ParameterThresholdConfig(): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const turntimeMatch = useMatch(`${parameterTurntimeThresholdConfigurationRoute.pathname}/*`)
  const meanpowerMatch = useMatch(`${parameterMeanpowerThresholdConfigurationRoute.pathname}/*`)
  const powercurveAiMatch = useMatch(`${parameterPowercurveAiThresholdConfigurationRoute.pathname}/*`)
  const systemSelectorRef = useRef<HTMLDivElement>(null)
  const [isInfoCollapsed, setInfoCollapsed] = useState(false)
  const { isExpertsystemTurntimeEnabled, isExpertsystemMeanpowerEnabled, isPowercurveAiEnabled } = useMainProject()

  const selectTurnTimeSystem = useCallback(() => {
    setInfoCollapsed(true)
    navigate(generatePath(parameterTurntimeThresholdConfigurationRoute.pathname))
  }, [navigate])

  const selectMeanpowerSystem = useCallback(() => {
    setInfoCollapsed(true)
    navigate(generatePath(parameterMeanpowerThresholdConfigurationRoute.pathname))
  }, [navigate])

  const selectPowercurveAiSystem = useCallback(() => {
    setInfoCollapsed(true)
    navigate(generatePath(parameterPowercurveAiThresholdConfigurationRoute.pathname))
  }, [navigate])

  return (
    <>
      <Helmet>
        <title>
          {appTitle} - {t(`routes.${thresholdConfigurationRoute.name}`)} -{' '}
          {t(`routes.${parameterThresholdConfigurationRoute.name}`)}
        </title>
      </Helmet>
      <div className="d-flex flex-column gap-4">
        <div className="d-flex flex-column gap-4">
          <div className="row">
            <div className="col-12 col-md-8">
              <IxBlind
                icon="info"
                label={t('Configure thresholds for performance parameters').toString()}
                collapsed={isInfoCollapsed}
              >
                <div>
                  <Trans
                    components={{
                      li: <li />,
                      p: <ParagraphNoMarginLastChild />,
                      ul: <ul />,
                    }}
                    i18nKey="ParameterThresholdConfig"
                  />
                </div>
              </IxBlind>
            </div>
          </div>
        </div>

        <div className="d-flex gap-3 flex-column flex-md-row" ref={systemSelectorRef}>
          {isExpertsystemTurntimeEnabled && (
            <IxButton
              icon="clock"
              outline
              variant={turntimeMatch ? 'primary' : 'secondary'}
              onClick={selectTurnTimeSystem}
            >
              {t('config.thresholds.parameter.turntime.title')}
            </IxButton>
          )}

          {isExpertsystemMeanpowerEnabled && (
            <IxButton
              icon="electrical-energy"
              outline
              variant={meanpowerMatch ? 'primary' : 'secondary'}
              onClick={selectMeanpowerSystem}
            >
              {t('config.thresholds.parameter.meanpower.title')}
            </IxButton>
          )}

          {isPowercurveAiEnabled && (
            <IxButton
              icon="ai"
              outline
              variant={powercurveAiMatch ? 'primary' : 'secondary'}
              onClick={selectPowercurveAiSystem}
            >
              {t('config.thresholds.parameter.ai.title')}
            </IxButton>
          )}
        </div>

        <Outlet />
      </div>
    </>
  )
}
