import * as t from 'io-ts'

import {
  BaseModelCodec,
  caseInsensitive,
  IdTypeCodec,
  nullableType,
  PagedResultCodec,
} from '@/core/models/base'
import { DataSeriesListCodec } from '@/core/models/data-series'

import { BaseEventCodec } from '@/features/alarms/models/event'

import { PointMachineCodec } from './point-machine'

export const ProcessingStateCodec = t.union([
  caseInsensitive('IMPORTED'),
  caseInsensitive('MISSING_REFERENCES'),
  caseInsensitive('JOB_STARTED'),
  caseInsensitive('JOB_FINISHED'),
])

export const EstimatedWeatherDataCodec = t.type({
  outsideTemperature: t.number,
  trackTemperature: t.number,
  humidityAboveThreshold: t.boolean,
})
export type EstimatedWeatherData = t.TypeOf<typeof EstimatedWeatherDataCodec>

export const BasePointMachineEventCodec = t.intersection([
  BaseEventCodec,
  t.type({
    dataSeries: DataSeriesListCodec,
    fitnessScore: t.number,
    isOutlier: t.boolean,
    meanPower: t.number,
    pointMachineId: IdTypeCodec,
    processingState: ProcessingStateCodec,
    reference: t.boolean,
    turnoutEventId: IdTypeCodec,
    work: t.number,
    estimatedEnvironmentMeasurements: EstimatedWeatherDataCodec,
  }),
])
export const BasePointMachineEventsCodec = t.array(BasePointMachineEventCodec)
export type BasePointMachineEvent = t.TypeOf<typeof BasePointMachineEventCodec>

export const RawPointMachineEventCodec = t.intersection([BaseModelCodec, BasePointMachineEventCodec])
export const RawPointMachineEventsCodec = t.array(RawPointMachineEventCodec)
export type RawPointMachineEvent = t.TypeOf<typeof RawPointMachineEventCodec>

export const PointMachineEventCodec = t.intersection([
  RawPointMachineEventCodec,
  t.type({
    pointMachine: nullableType(PointMachineCodec),
  }),
])
export const PointMachineEventsCodec = t.array(PointMachineEventCodec)
export type PointMachineEvent = t.TypeOf<typeof PointMachineEventCodec>

export const PagedPointMachineEventsCodec = PagedResultCodec(PointMachineEventsCodec)
export type PagedPointMachineEvents = t.TypeOf<typeof PagedPointMachineEventsCodec>
