import { JSX, useMemo } from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import { Empty } from '@/core/components/Empty'
import { VegaContainer } from '@/core/components/VegaContainer'
import { useIsMobile } from '@/core/lib/responsive'

import type { AlarmEvent } from '@/features/alarms/models/alarm-event'

const to = DateTime.now()

export interface AlarmsChartProps {
  alarms: AlarmEvent[]
  fromLabel: string
  from: DateTime
}

export function AlarmsChart({ alarms, fromLabel, from }: AlarmsChartProps): JSX.Element {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const mode = useMemo(() => {
    const diff = to.diff(from, 'days').toObject()
    if (diff.days != null && diff.days > 1) {
      return 'days'
    }

    return 'hours'
  }, [from])

  const values = useMemo(() => {
    return alarms.map((alarm) => {
      const dateTime = DateTime.fromISO(alarm.eventTime)
      const severity = t(`models.alarmEvent.severity-${alarm.severity}`)

      if (mode === 'days') {
        return { timestamp: dateTime.toISODate(), severity }
      }

      const binnedDateTime = dateTime.set({ minute: 0, second: 0, millisecond: 0 })
      return { timestamp: binnedDateTime.toISO(), severity }
    })
  }, [alarms, mode, t])

  if (values.length === 0) {
    return <Empty header={t('No alarms in the last {{time}}', { time: fromLabel }) ?? undefined} icon="success" />
  }

  return (
    <VegaContainer
      fit
      height={200}
      spec={{
        data: { values },
        mark: { type: 'bar', width: mode === 'hours' || isMobile ? 25 : 50 },
        encoding: {
          x: {
            axis: { tickCount: mode === 'days' ? 'day' : 'hour' },
            field: 'timestamp',
            scale: { domain: [from.minus({ days: 1 }).toISO(), to.endOf('day').toISO()] },
            title: null,
            type: 'temporal',
          },
          y: {
            aggregate: 'count',
            scale: { nice: 1 },
            title: null,
          },
          color: {
            field: 'severity',
            scale: {
              range: alarms.some((alarm) => alarm.severity === 'failure')
                ? ['var(--theme-color-alarm)', 'var(--theme-color-warning)']
                : ['var(--theme-color-warning)'],
            },
            title: null,
            type: 'nominal',
          },
        },
      }}
    />
  )
}
