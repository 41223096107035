import { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { generatePath, Navigate } from 'react-router-dom'

import { useMainProject } from '@/core/hooks/use-project'
import { appTitle } from '@/core/lib/config'

import { MeanpowerThresholdConfigProvider } from '@/features/config/threshold/parameter/meanpower/store/meanpower-threshold-config-store'
import { MeanpowerThresholdActionPanel } from '@/features/config/threshold/parameter/meanpower/components/MeanpowerThresholdActionPanel'
import { MeanpowerThresholdEditModal } from '@/features/config/threshold/parameter/meanpower/components/MeanpowerThresholdEditModal'
import { MeanpowerThresholdFilter } from '@/features/config/threshold/parameter/meanpower/components/MeanpowerThresholdFilter'
import { MeanpowerThresholdTable } from '@/features/config/threshold/parameter/meanpower/components/MeanpowerThresholdTable'
import { parameterThresholdConfigurationRoute, thresholdConfigurationRoute } from '@/features/config/threshold/threshold-route'

export const MeanpowerThresholdConfig: FC = () => {
  const { t } = useTranslation()
  const mainProject = useMainProject()

  if (!mainProject.isExpertsystemMeanpowerEnabled) {
    return <Navigate to={generatePath(parameterThresholdConfigurationRoute.pathname)} replace />
  }

  return (
    <MeanpowerThresholdConfigProvider>
      <Helmet>
        <title>
          {`${appTitle} - ${t(`routes.${thresholdConfigurationRoute.name}`)} - ${t(
            `routes.${parameterThresholdConfigurationRoute.name}`,
          )} - ${t('config.thresholds.parameter.meanpower.title')}`}
        </title>
      </Helmet>
      <MeanpowerThresholdFilter />
      <MeanpowerThresholdActionPanel />
      <MeanpowerThresholdTable />
      <MeanpowerThresholdEditModal />
    </MeanpowerThresholdConfigProvider>
  )
}
