import { Key } from 'swr'

import { ProjectApiClient } from '@/core/api/project'
import { ProjectConfigurationApiClient } from '@/core/api/project-configuration'
import { useSWR, type SWRResponse } from '@/core/hooks/swr'
import { AnalyticsTypes, EnabledAnalyticsTypes } from '@/core/models/analytics-types'
import { IdType } from '@/core/models/base'
import { Project } from '@/core/models/project'
import { useAppStore } from '@/core/store/app-store'

export const getProjectsKey = (): Key => 'useProjects'

export const useProjects = (): SWRResponse<Project[]> => {
  return useSWR(getProjectsKey(), async () => {
    return ProjectApiClient.list()
  })
}

export const getProjectKey = (id: Parameters<typeof useProject>[0]): Key => {
  if (id == null) {
    return null
  }

  return ['useProject', id]
}

export const useProject = (id: IdType | undefined | null): SWRResponse<Project> => {
  return useSWR(getProjectKey(id), () => {
    return ProjectApiClient.read(id!)
  })
}

export const useAnalytics = (id?: IdType): SWRResponse<AnalyticsTypes> => {
  const mainProject = useMainProject()
  const projectId = id ?? mainProject.id
  return useSWR(`${getProjectKey(projectId)}-analyticsTypes`, () => {
    return ProjectConfigurationApiClient.getAnalyticsTypes(projectId)
  })
}

export const useMainProject = (): Project & EnabledAnalyticsTypes => {
  const mainProject = useAppStore((state) => state.mainProject)
  return mainProject
}
