import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { IxSpinner } from '@siemens/ix-react'

import { ModalV2 } from '@/core/components/ModalV2'
import type { IdType } from '@/core/models/base'

import { MaintenanceTaskModalContent } from '@/features/maintenance-reports/components/MaintenanceTaskModalContent'
import { useMaintenanceTask } from '@/features/maintenance-reports/hooks/use-maintenance-task'
import { MaintenanceTaskModalProvider } from '@/features/maintenance-reports/store/modal-store'

export const MaintenanceTaskViewModal: FC<{ taskId: IdType; onClose: () => void }> = ({ taskId, onClose }) => {
  const { t } = useTranslation()
  const { data: task, isLoading } = useMaintenanceTask(taskId)

  return (
    <MaintenanceTaskModalProvider readOnly={true} task={task} key={task?.id}>
      <ModalV2 title={t('assets.maintenance_reports.view.title')} onClose={onClose}>
        {isLoading ? <IxSpinner size="large" /> : <MaintenanceTaskModalContent onClose={onClose} />}
      </ModalV2>
    </MaintenanceTaskModalProvider>
  )
}
