import { Key } from 'swr'

import { SWRResponse, useSWR } from '@/core/hooks/swr'
import type { IdType } from '@/core/models/base'

import { PointMachineTypeApiClient } from '@/features/assets/api/point-machine-type'
import type { PointMachineType } from '@/features/assets/models/point-machine-type'

export const getPointMachineTypesKey = (): Key => 'usePointMachineTypes'

export const usePointMachineTypes = (): SWRResponse<PointMachineType[]> => {
  return useSWR(getPointMachineTypesKey(), async () => {
    return PointMachineTypeApiClient.list()
  })
}

export const getPointMachineTypeKey = (id: Parameters<typeof usePointMachineType>[0]): Key => {
  if (id == null) {
    return null
  }

  return ['usePointMachineType', id]
}

export const usePointMachineType = (id: IdType | undefined | null): SWRResponse<PointMachineType> => {
  return useSWR(getPointMachineTypeKey(id), () => {
    return PointMachineTypeApiClient.read(id!)
  })
}
