import { DateTime } from 'luxon'

export function getLast24HoursRange () {
  const now = DateTime.now()
  const roundedNow = now.plus({ hours: 1 }).startOf('hour')
  return {
    from: roundedNow.minus({ hours: 24 }),
    to: roundedNow
  }
}

export function getDateRangeDefaults (range: 'last-24h' | 'last-3d' | 'last-7d' | 'last-30d' | string):{from: DateTime<true>, to: DateTime<true>} {
  const now = DateTime.now()
  const to = now.endOf('day')

  switch (range) {
    case 'last-24h':
      const last24h = getLast24HoursRange()
      return {
        from: last24h.from,
        to: last24h.to,
      }
    case 'last-3d':
      return {
        from: now.startOf('day').minus({ days: 2 }),
        to,
      }
    case 'last-7d':
      return {
        from: now.startOf('day').minus({ days: 6 }),
        to,
      }
    case 'last-30d':
      return {
        from: now.startOf('day').minus({ days: 29 }),
        to,
      }
    default:
      console.warn('No defaults defined for', range)
      return {
        from: now.startOf('day').minus({ days: 29 }),
        to,
      }
  }
}

export function parseDateRangeToIsoDates (value:{from: DateTime<true>, to: DateTime<true>}) {
  return {
    from: value.from.toISODate(),
    to: value.to.toISODate(),
  }
}