import * as t from 'io-ts'
import { BaseModelCodec, NamedTypeCodec } from './base'

export const BaseProjectCodec = t.intersection([
  NamedTypeCodec,
  t.type({
    master: t.boolean,
  }),
])
export const BaseProjectsCodec = t.array(BaseProjectCodec)
export type BaseProject = t.TypeOf<typeof BaseProjectCodec>

export const ProjectCodec = t.intersection([BaseModelCodec, BaseProjectCodec])
export const ProjectsCodec = t.array(ProjectCodec)
export type Project = t.TypeOf<typeof ProjectCodec>
