import { FC, RefCallback, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { VisualizationSpec } from 'react-vega'
import { Encoding } from 'vega-lite/build/src/encoding'
import { AnyMark } from 'vega-lite/build/src/mark'
import { GenericUnitSpec, GenericVConcatSpec, NonNormalizedSpec } from 'vega-lite/build/src/spec'

import { useEncoding } from '@/core/components/ConcatenatedIncidentChart/hooks/use-encoding'
import { VegaContainer, type CustomEventListener } from '@/core/components/VegaContainer'
import type { IdType } from '@/core/models/base'

import type { QueryAlarmEventsFilterOpts } from '@/features/alarms/api/alarm-event'
import { useQueryAlarms } from '@/features/alarms/hooks/use-alarm'
import { MaintenanceTaskViewModal } from '@/features/maintenance-reports/components/MaintenanceTaskViewModal'
import { useQueryMaintenanceTasks } from '@/features/maintenance-reports/hooks/use-maintenance-task'
import { useMaintenanceTaskViewModal } from '@/features/maintenance-reports/hooks/use-maintenance-task-view-modal'
import { STATIC_WORK_ITEMS } from '@/features/maintenance-reports/util/static-work-items'

export interface DataPoint {
  start: string
  type: string | null | undefined
  legendI18n?: string
  type2?: string | null
  value: string | number | null | undefined
  tooltip?: Record<string, string>
  taskId?: IdType
}

export interface ConcatenatedIncidentChartProps {
  height: number
  layers: GenericUnitSpec<Encoding<string>, AnyMark>[]
  alarmEventsFilterOpts: Partial<QueryAlarmEventsFilterOpts> | undefined
}

/**
 * SVG path can be scaled etc. with https://yqnn.github.io/svg-path-editor/
 */
const alarmIconSvgPath =
  'M.5151.3333.426.0789.4167.0521V.0238-.1667c0-.2757-.1869-.5-.4167-.5-0 0-0 0-0 0-0 0-0 0-0 0-.2297 0-.4167.2243-.4167.5V.0238.0521L-.426.0789-.5151.3333ZM-0 .6667C.0919.6667.1667.5919.1667.5H-.1667c0 .0919.0748.1667.1667.1667ZM.75.5H.3333C.3333.6841.1841.8333-0 .8333-.1841.8333-.3333.6841-.3333.5H-.75L-.5833.0238V-.1667c0-.3682.2611-.6667.5833-.6667 0 0 0 0 0 0s0 0 0 0c.3222 0 .5833.2985.5833.6667V.0238Z'
const maintenanceIconSvgPath =
  'M-.3595-1.05c-.0685 0-.1345.0108-.1964.0307l.3031.3028-.1.2998-.0134.0134-.3001.0999-.3027-.3022c-.0198.0616-.0304.1274-.0304.1956 0 .3535.2865.64.64.64.0647 0 .1271-.0096.1859-.0274L.4938.87c.1178.1178.3088.1178.4267 0s.1178-.3088 0-.4267L.2531-.2241C.2709-.2829.2805-.3453.2805-.41c0-.3535-.2865-.64-.64-.64Z'

export const ConcatenatedIncidentChart: FC<ConcatenatedIncidentChartProps> = ({
  height,
  layers,
  alarmEventsFilterOpts: filterOpts,
}) => {
  const [baseEncoding, encoding] = useEncoding(filterOpts)
  const [width, setWidth] = useState<number>()
  const { t } = useTranslation()

  // remove sortBy from filterOpts
  const alarmsQuery = useMemo(() => {
    if (filterOpts == null) {
      return undefined
    }

    const { sortBy, ...rest } = filterOpts
    return rest
  }, [filterOpts])

  const { data: pagedAlarms } = useQueryAlarms(alarmsQuery)
  const { data: pagedMaintenanceTasks } = useQueryMaintenanceTasks({
    areaName: filterOpts?.areaName ? [filterOpts?.areaName] : undefined,
    interlockingName: filterOpts?.interlockingName ? [filterOpts?.interlockingName] : undefined,
    technicalRoomName: filterOpts?.technicalRoomName ? [filterOpts?.technicalRoomName] : undefined,
    assetName: filterOpts?.turnoutName,
    from: filterOpts?.from?.toUTC().toISO() ?? undefined,
    to: filterOpts?.to?.toUTC().toISO() ?? undefined,
    page: filterOpts?.page,
    size: filterOpts?.size,
  })

  const needsEncoding = useMemo(() => {
    return layers.filter((layer) => layer.encoding?.x == null).length === layers.length
  }, [layers])

  const alarmsData = useMemo((): DataPoint[] => {
    const data = (pagedAlarms?.content ?? []).map((alarm): DataPoint => {
      const data: DataPoint = {
        start: alarm.eventTime,
        type: 'alarm',
        value: 'alarm',
        legendI18n: t('charts.legend.alarms'),
        tooltip: {
          alarmType: t(`models.alarmEvent.type-${alarm.alarmType}`),
          severity: t(`models.alarmEvent.severity-${alarm.severity}`),
          start: alarm.eventTime,
        },
      }

      return data
    })

    return data
  }, [pagedAlarms, t])

  const maintenanceData = useMemo((): DataPoint[] => {
    const data = (pagedMaintenanceTasks?.content ?? []).map((task): DataPoint => {
      const data: DataPoint = {
        start: task.dueAt,
        legendI18n: t('charts.legend.maintenance'),
        type: 'maintenance',
        value: 'maintenance',
        taskId: task.id,
        tooltip: {
          reason: t(`assets.maintenance_reports.reason.${task.reason}`),
          work: task.work
            .map((task) => (STATIC_WORK_ITEMS.includes(task) ? t(`assets.maintenance_reports.work.${task}`) : task))
            .join(', '),
          dueAt: task.dueAt,
          comment: task.comment ?? '',
        },
      }

      return data
    })

    return data
  }, [pagedMaintenanceTasks, t])

  const alarmsLayer = useMemo((): GenericUnitSpec<Encoding<string>, AnyMark> => {
    return {
      data: { values: alarmsData },
      mark: {
        fill: 'var(--theme-color-component-6)',
        size: 250,
        strokeWidth: 0,
        type: 'point',
      },
      encoding: {
        tooltip: [
          { field: 'tooltip.alarmType', title: t('models.alarmEvent.type') },
          { field: 'tooltip.severity', title: t('models.alarmEvent.severity') },
          { field: 'tooltip.start', format: '%c', title: t('Timestamp'), type: 'temporal' },
        ],
        y: {
          axis: null,
          field: 'value',
          type: 'nominal',
        },
      },
      params: [
        // zoom
        {
          bind: 'scales',
          name: 'grid',
          select: { type: 'interval', encodings: ['x'] },
        },
      ],
    }
  }, [alarmsData, t])

  const maintenanceLayer = useMemo((): GenericUnitSpec<Encoding<string>, AnyMark> => {
    return {
      data: { values: maintenanceData },
      mark: {
        fill: 'var(--theme-color-component-6)',
        size: 200,
        strokeWidth: 0,
        type: 'point',
      },
      encoding: {
        tooltip: [
          { field: 'tooltip.reason', title: t('charts.tooltip.maintenance.reason') },
          { field: 'tooltip.work', title: t('charts.tooltip.maintenance.work') },
          { field: 'tooltip.dueAt', format: '%c', title: t('charts.tooltip.maintenance.doneAt'), type: 'temporal' },
          { field: 'tooltip.comment', title: t('charts.tooltip.maintenance.comment') },
        ],
        y: {
          axis: null,
          field: 'value',
          type: 'nominal',
        },
      },
    }
  }, [maintenanceData, t])

  const propsView = useMemo((): GenericVConcatSpec<NonNormalizedSpec>['vconcat'][0] => {
    const view: GenericVConcatSpec<NonNormalizedSpec>['vconcat'][0] = {
      height,
      layer: layers,
      width,
    }

    if (needsEncoding) {
      view.encoding = baseEncoding
      view.resolve = { scale: { color: 'independent', y: 'independent' } }
    }

    return view
  }, [baseEncoding, needsEncoding, height, layers, width])

  const incidentView = useMemo((): GenericVConcatSpec<NonNormalizedSpec>['vconcat'][0] => {
    return {
      encoding: {
        ...encoding,
        shape: {
          field: 'legendI18n',
          scale: {
            range: [
              ...(alarmsData.length ? [alarmIconSvgPath] : []),
              ...(maintenanceData.length ? [maintenanceIconSvgPath] : []),
            ],
          },
          legend: {
            title: 'Ereignisse',
            symbolStrokeWidth: 0,
            symbolSize: 200,
            rowPadding: 5,
          },
        },
      },

      height: 50,
      layer: [alarmsLayer, maintenanceLayer],
      width,
    }
  }, [encoding, alarmsData.length, maintenanceData.length, alarmsLayer, maintenanceLayer, width])

  const spec = useMemo((): VisualizationSpec => {
    return {
      vconcat: [propsView, incidentView],
      resolve: { scale: { color: 'independent', shape: 'independent', y: 'independent' } },
      spacing: 0,
    }
  }, [incidentView, propsView])

  const onRef = useCallback<RefCallback<HTMLDivElement>>((ref) => {
    if (ref == null) {
      return setWidth(undefined)
    }

    setWidth(ref.clientWidth - 250)
  }, [])

  const { taskId, openModal, closeModal } = useMaintenanceTaskViewModal()

  const eventListeners = useMemo<CustomEventListener[]>(
    () => [
      {
        type: 'click',
        handler: (_e, item) => {
          switch (item?.datum?.type) {
            case 'maintenance':
              openModal?.(item?.datum?.taskId)
              break
          }
        },
      },
    ],
    [openModal],
  )

  return (
    <>
      <VegaContainer
        fit
        height={height}
        legend="right"
        ref={onRef}
        spec={spec}
        width={width}
        eventListeners={eventListeners}
      />
      {taskId && <MaintenanceTaskViewModal taskId={taskId} key={taskId} onClose={closeModal} />}
    </>
  )
}
