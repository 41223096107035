import { FC, PropsWithChildren, ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ErrorInfo } from '@/core/components/ErrorInfo'
import { LoadingErrorWrapper } from '@/core/components/LoadingErrorWrapper'
import type { AnalyticsTypes } from '@/core/models/analytics-types'
import type { IdType } from '@/core/models/base'

import { useTurnout, useUpdateTurnouts } from '@/features/assets/hooks/use-turnout'
import { getAnalyticsTypeById, getPointMachinesRequired, type Turnout } from '@/features/assets/models/turnout'

import { AssetModalFooter } from '@/features/config/assets/components/AssetModalFooter'

import { AnalyticsTypeInfo } from './AnalyticsTypeInfo'
import { UpdatePointMachineForm } from './UpdatePointMachineForm'

type CreatePointMachinesProps = {
  turnoutId: IdType | undefined
  onCancel?: () => void
  required?:boolean
  analytics?:AnalyticsTypes
}

export const CreatePointMachines: FC<PropsWithChildren<CreatePointMachinesProps>> = ({onCancel, turnoutId, required, analytics}): ReactElement => {

  const { t } = useTranslation()
  const { data: turnout, ...turnoutData } = useTurnout(turnoutId)
  const { trigger: updateAsset, ...updateAssetData } = useUpdateTurnouts()

  const handleActivate = useCallback(async () => {
    const assets = await updateAsset([{ ...turnout!, active: true }]) as Turnout[]
    if(assets && assets.length > 0) onCancel?.()
  }, [onCancel, updateAsset, turnout])

  const handleClose = useCallback(() => {
    onCancel?.()
  }, [onCancel])

  return (
    <>
      <LoadingErrorWrapper data={true} error={turnoutData.error} isLoading={turnoutData.isLoading}>
        {updateAssetData.error != null && <ErrorInfo error={updateAssetData.error} />}

        {required && turnout && <AnalyticsTypeInfo type={'message'} selectedId={turnout.analyticsTypeId} analytics={analytics} />}
        {!required && turnout && <AnalyticsTypeInfo type={'message'} selectedId={turnout.analyticsTypeId} analytics={analytics} />}

        {turnout && <>
          {turnout.pointMachines.map((pm) => (
            <div key={pm.id}>
              <UpdatePointMachineForm pointMachine={pm} turnout={turnout} key={pm.id}/>
              <hr />
            </div>
          ))}
          <UpdatePointMachineForm
            key={'create-pointmachine'}
            hideCancelButton
            turnout={turnout}
          />
        </>}
      </LoadingErrorWrapper>

      <AssetModalFooter
        required={getPointMachinesRequired(getAnalyticsTypeById(turnout?.analyticsTypeId, analytics))}
        handleNext={handleActivate}
        handleCancel={handleClose}
        loading={updateAssetData.isMutating || turnoutData.isLoading}
        nextDisabled={(turnout?.pointMachines ?? []).length === 0 || turnoutData.error}
        cancelCopy={t('assets.cta.save') ?? ""}
      />
    </>
  );
}