import * as t from 'io-ts'

import { BaseModelCodec, DirectionCodec, type IdType } from '@/core/models/base'

export const BaseAnalyticsThresholdCodec = t.type({
  direction: DirectionCodec,
  turnoverTimeLower: t.number,
  turnoverTimeUpper: t.number,
})

export const AnalyticsThresholdCodec = t.intersection([BaseModelCodec, BaseAnalyticsThresholdCodec])
export const AnalyticsThresholdsCodec = t.array(AnalyticsThresholdCodec)
export type BaseAnalyticsThreshold = t.TypeOf<typeof BaseAnalyticsThresholdCodec>
export type AnalyticsThreshold = t.TypeOf<typeof AnalyticsThresholdCodec>
export type AnalyticsThresholdWithTurnoutId = {
  turnoutId: IdType
  left?: AnalyticsThreshold
  right?: AnalyticsThreshold
}

type UpperLower = { upper: number; lower: number }
export type UpdateTurntimeThresholdsValues = { left: UpperLower; right: UpperLower }
