import { JSX, useMemo } from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import { EventTable, type EventColor } from '@/core/components/EventTable'
import type { TableProps } from '@/core/components/Table'
import type { PagedResultType } from '@/core/models/base'
import type { PaginationFilterOpts } from '@/core/models/pagination'

import type { AlarmEvent } from '@/features/alarms/models/alarm-event'
import { useTrackLayers } from '@/features/assets/hooks/use-track-layer'
import type { Turnout } from '@/features/assets/models/turnout'

import { TurnoutIdCell } from './components/TurnoutIdCell'

const getEventColor = (alarm: AlarmEvent): string => {
  if (alarm.severity === 'failure') {
    return 'color-alarm'
  }

  return 'color-warning'
}

export interface AlarmTableProps extends Omit<Omit<TableProps<AlarmEvent & EventColor>, 'pagedData'>, 'columnDefs'> {
  pagedData?: PagedResultType<AlarmEvent>
  initialSort?: {
    eventTime?: PaginationFilterOpts['sortingDirection']
    resolveTime?: PaginationFilterOpts['sortingDirection']
  }
  turnout?: Turnout
}

export function AlarmTable({ pagedData, initialSort, turnout, ...rest }: AlarmTableProps): JSX.Element {
  const { i18n, t } = useTranslation()
  const { data: trackLayers } = useTrackLayers()

  const eventData = useMemo<Array<AlarmEvent & EventColor>>(
    () => pagedData?.content.map((entry: AlarmEvent) => ({ ...entry, eventColor: getEventColor(entry) })) ?? [],
    [pagedData?.content],
  )

  const columnDefs = useMemo<TableProps<AlarmEvent & EventColor>['columnDefs']>(() => {
    const columns: TableProps<AlarmEvent & EventColor>['columnDefs'] = [
      {
        comparator: (_, __, nodeA, nodeB) =>
          nodeA.data == null || nodeB.data == null ? 0 : nodeA.data.id - nodeB.data.id,
        field: 'id',
        headerName: t('Alarm ID') ?? undefined,
        sortable: true,
        unSortIcon: true,
        valueGetter: ({ data }) => (data == null ? null : `#${data.id}`),
      },
      {
        comparator: (_, __, nodeA, nodeB) =>
          nodeA.data == null || nodeB.data == null
            ? 0
            : DateTime.fromISO(nodeA.data.eventTime).diff(DateTime.fromISO(nodeB.data.eventTime), 'milliseconds')
                .milliseconds,
        field: 'eventTime',
        headerName: t('Start time') ?? undefined,
        initialSort: initialSort?.eventTime,
        sortable: true,
        unSortIcon: true,
        valueGetter: ({ data }) =>
          data == null
            ? null
            : DateTime.fromISO(data.eventTime).setLocale(i18n.language).toLocaleString(DateTime.DATETIME_MED),
      },
      {
        comparator: (_, __, nodeA, nodeB) =>
          nodeA.data?.resolveTime == null || nodeB.data?.resolveTime == null
            ? 0
            : DateTime.fromISO(nodeA.data.resolveTime).diff(DateTime.fromISO(nodeB.data.resolveTime), 'milliseconds')
                .milliseconds,
        field: 'resolveTime',
        headerName: t('End time') ?? undefined,
        initialSort: initialSort?.resolveTime,
        sortable: true,
        unSortIcon: true,
        valueGetter: ({ data }) =>
          data?.resolveTime == null
            ? null
            : DateTime.fromISO(data.resolveTime).setLocale(i18n.language).toLocaleString(DateTime.DATETIME_MED),
      },
      {
        field: 'description',
        headerName: t('Description') ?? undefined,
        sortable: true,
        unSortIcon: true,
        valueGetter: ({ data }) => (data == null ? null : `${t('Health score at time of alarm')}: ${data.description}`),
        width: 400,
      },
    ]

    if (turnout == null) {
      columns.splice(1, 0, {
        cellRenderer: TurnoutIdCell,
        field: 'turnout.engineeringId',
        headerName: t('Turnout ID') ?? undefined,
        sortable: true,
        unSortIcon: true,
      })
      columns.push(
        {
          field: '_turnout._technicalRoom._interlocking.name',
          headerName: trackLayers?.find((layer) => layer.type === 'interlocking')?.name,
        },
        {
          field: 'turnout.technicalRoom.name',
          headerName: trackLayers?.find((layer) => layer.type === 'technicalRoom')?.name,
        },
      )
    }

    return columns
  }, [i18n.language, initialSort, t, trackLayers, turnout])

  return (
    <EventTable
      {...rest}
      columnDefs={columnDefs}
      pagedData={{ ...(pagedData as PagedResultType<AlarmEvent & EventColor>), content: eventData }}
      sortingOrder={['desc', 'asc']}
    />
  )
}
