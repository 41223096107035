import { JSX, useState } from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import { IxTile } from '@siemens/ix-react'

import { DataFilter } from '@/core/components/DataFilter/DataFilter'
import type { Filter, SearchField } from '@/core/components/DataFilter/types'
import { useFilterOpts } from '@/core/hooks/use-filter-opts'

import type { TurnoutHealthStatesFilterOpts } from '@/features/assets/api/turnout'
import { HealthStateChart } from '@/features/assets/components/HealthStateChart'
import type { Turnout } from '@/features/assets/models/turnout'

const to = DateTime.now()

const searchFields: SearchField[] = [
  {
    path: 'from-to',
    type: 'date_range',
  },
]

const defaultFilters: Filter[] = [{ ...searchFields[0], value: { from: DateTime.now().minus({ days: 2 }), to } }]

export interface TurnoutHealthTileProps {
  turnout: Turnout
}

export function TurnoutHealthTile({ turnout }: TurnoutHealthTileProps): JSX.Element {
  const [filters, setFilters] = useState<Filter[]>(defaultFilters)
  const { t } = useTranslation()
  const { filterOpts } = useFilterOpts<TurnoutHealthStatesFilterOpts>(filters)

  return (
    <IxTile>
      <div slot="header">{t('Health status history')}</div>

      <div className="d-flex flex-grow-1 justify-content-end">
        <DataFilter fields={searchFields} onChange={setFilters} value={filters} removeGrid />
      </div>

      <div>
        <HealthStateChart filterOpts={filterOpts} turnout={turnout} />
      </div>
    </IxTile>
  )
}
