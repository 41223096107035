import { FC, useCallback } from 'react'
import { t } from 'i18next'

import { Table as BaseTable, type TableProps } from '@/core/components/Table'
import { useAnalytics, useMainProject } from '@/core/hooks/use-project'

import { useTrackLayers } from '@/features/assets/hooks/use-track-layer'
import { useQueryTurnouts } from '@/features/assets/hooks/use-turnout'
import { getAnalyticsTypeById, type Turnout } from '@/features/assets/models/turnout'
import { useExpertTurntimeFilter } from '@/features/config/threshold/parameter/turntime/hooks/use-expert-turntime-filter'
import { useTurntimeThresholdConfigStore } from '@/features/config/threshold/parameter/turntime/store/turntime-threshold-config-store'

export const TurntimeThresholdTable: FC = () => {
  const mainProject = useMainProject()
  const { data: layerItems } = useTrackLayers()
  const { data: analytics } = useAnalytics(mainProject.id)
  const { pagedFilterOpts, onSortChanged, setPageNo } = useExpertTurntimeFilter()
  const selectTurnouts = useTurntimeThresholdConfigStore((state) => state.selectTurnouts)
  const selectedTurnoutIds = useTurntimeThresholdConfigStore((state) => state.selectedTurnoutIds)
  const { data: pagedTurnouts, isLoading, error } = useQueryTurnouts(pagedFilterOpts)

  const onSelectionChanged = useCallback<NonNullable<TableProps<Turnout>['onSelectionChanged']>>(
    ({ api }) => {
      const turnouts = api.getSelectedRows()
      selectTurnouts(turnouts?.map((pm) => pm.id))
    },
    [selectTurnouts],
  )

  return (
    <BaseTable
      columnDefs={[
        {
          field: 'id',
          headerName: t('routes.Turnout') ?? undefined,
          sortable: true,
          unSortIcon: true,
          valueGetter: ({ data }) => data?.name,
          flex: 1,
        },
        {
          field: 'engineeringId',
          headerName: 'ID',
          initialSort: 'asc',
          sortable: true,
          unSortIcon: true,
          flex: 1,
        },
        {
          field: 'technicalRoom.id',
          headerName: layerItems?.find((item) => item.type === 'technicalRoom')?.name,
          valueGetter: ({ data }) => data?.technicalRoom?.name,
          flex: 1,
        },
        {
          field: 'turnoutFunctionalityType',
          headerName: t('models.turnout.turnoutFunctionalityType') ?? undefined,
          valueGetter: ({ data }) => {
            const type = data?.turnoutFunctionalityType
            return type ? t(`models.turnout.turnoutFunctionalityType-${type}`) : undefined
          },
          width: 100,
        },
        {
          field: 'analyticsTypeId',
          headerName: t('models.turnout.model-type') ?? undefined,
          valueGetter: ({ data }) => {
            const analyticsTypeName = getAnalyticsTypeById(data?.analyticsTypeId, analytics)?.name

            return analyticsTypeName ? t(`models.turnout.analyticsTypes.${analyticsTypeName}`) : undefined
          },
          width: 150,
        },
      ]}
      error={error}
      isLoading={isLoading}
      onSelectionChanged={onSelectionChanged}
      onSortChanged={onSortChanged}
      selectedIds={selectedTurnoutIds}
      onPageSelected={setPageNo}
      pagedData={pagedTurnouts}
      selectable
    />
  )
}
