import { CrudApiClient } from '@/core/api/base'
import type { DateRangeFilterOpts } from '@/core/models/filter'
import type { PaginationFilterOpts } from '@/core/models/pagination'

import { TurnoutApiClient, type QueryTurnoutFilterOpts } from '@/features/assets/api/turnout'
import type { RawTechnicalRoom } from '@/features/assets/models/technical-room'
import type { RawPagedTurnouts, RawTurnout } from '@/features/assets/models/turnout'
import {
  PagedTurnoutEventsCodec,
  TurnoutEventCodec,
  type PagedTurnoutEvents,
  type TurnoutEvent,
} from '@/features/assets/models/turnout-event'

import type { TrackConfigurationFilterOpts } from '@/features/config/track/models/track-config-filter-opts'

export interface QueryTurnoutEventsFilterOpts
  extends TrackConfigurationFilterOpts,
    PaginationFilterOpts,
    DateRangeFilterOpts {}

const instance = new CrudApiClient<TurnoutEvent, RawTurnout, PagedTurnoutEvents, QueryTurnoutEventsFilterOpts>(
  'turnout-events-with-estimated-weather-data',
  TurnoutEventCodec,
  TurnoutApiClient as CrudApiClient<RawTurnout, RawTechnicalRoom, RawPagedTurnouts, QueryTurnoutFilterOpts>,
  undefined,
  PagedTurnoutEventsCodec,
)

export { instance as TurnoutEventApiClient }
