import type { RouteConfig } from '@/core/routes'

export const maintenanceReportsRoute: RouteConfig<'/reports'> = {
  icon: 'maintenance-documents',
  name: 'maintenanceReports',
  pathname: '/reports',
}

export const maintenanceReportsViewRoute: RouteConfig<
  `${typeof maintenanceReportsRoute.pathname}/:reportId`,
  typeof maintenanceReportsRoute
> = {
  parent: maintenanceReportsRoute,
  icon: 'maintenance-documents',
  name: 'maintenanceReports',
  pathname: `${maintenanceReportsRoute.pathname}/:reportId`,
}

export const maintenanceReportsCreateRoute: RouteConfig<
  `${typeof maintenanceReportsRoute.pathname}/new`,
  typeof maintenanceReportsRoute
> = {
  parent: maintenanceReportsRoute,
  icon: 'maintenance-documents',
  name: 'maintenanceReports',
  pathname: `${maintenanceReportsRoute.pathname}/new`,
}
