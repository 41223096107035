import { FC, MutableRefObject } from 'react'
import type { AgGridReact } from 'ag-grid-react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import { IxIconButton, IxTypography } from '@siemens/ix-react'

import { Table as BaseTable } from '@/core/components/Table'
import type { IdType } from '@/core/models/base'

import { useTurnouts } from '@/features/assets/hooks/use-turnout'
import { useMaintenanceReportsFilter } from '@/features/maintenance-reports/hooks/use-filter'
import { useQueryMaintenanceTasks } from '@/features/maintenance-reports/hooks/use-maintenance-task'
import type { MaintenanceTask } from '@/features/maintenance-reports/models/maintenance-task'
import { isStaticWorkItem } from '@/features/maintenance-reports/util/static-work-items'

export const Table: FC<{
  gridRef: MutableRefObject<AgGridReact<MaintenanceTask> | null>
  onViewReportClick: (reportId: IdType) => void
}> = ({ gridRef, onViewReportClick }) => {
  const { pagedFilterOpts, onSortChanged, setPageNo } = useMaintenanceReportsFilter()
  const { data: pagedTasks, isLoading, error } = useQueryMaintenanceTasks(pagedFilterOpts)
  const { t, i18n } = useTranslation()
  const { data: turnouts } = useTurnouts()
  
  return (
    <div className="d-flex flex-column gap-2">
      <IxTypography format="h4">
        {t('assets.maintenance_reports.table.title')} ({pagedTasks?.totalElements})
      </IxTypography>

      <BaseTable<MaintenanceTask>
        columnDefs={[
          {
            field: 'id',
            headerName: 'ID',
            valueGetter: ({ data }) => data?.id,
            width: 0,
            hide: false,
          },
          {
            field: 'turnoutId',
            headerName: t('assets.maintenance_reports.table.turnout'),
            sortable: true,
            unSortIcon: true,
            valueGetter: ({ data }) => turnouts?.find((t) => t.id === data?.turnoutId)?.name,
            flex: 1,
          },
          {
            field: 'dueAt',
            headerName: t('assets.maintenance_reports.table.doneAt'),
            sortable: true,
            unSortIcon: true,
            valueGetter: ({ data }) =>
              data?.dueAt
                ? DateTime.fromISO(data?.dueAt).setLocale(i18n.language).toLocaleString(DateTime.DATETIME_MED)
                : '-',
            flex: 1,
          },
          {
            field: 'reason',
            headerName: t('assets.maintenance_reports.table.reason'),
            valueGetter: ({ data }) => t(`assets.maintenance_reports.reason.${data?.reason}`),
            flex: 1,
          },
          {
            field: 'work',
            headerName: t('assets.maintenance_reports.table.work'),
            valueGetter: ({ data }) =>
              data?.work
                .map((workItem) =>
                  isStaticWorkItem(workItem) ? t(`assets.maintenance_reports.work.${workItem}`) : workItem,
                )
                .join(', '),
            flex: 1,
            hide: true,
          },
          {
            field: 'comment',
            headerName: t('assets.maintenance_reports.table.comment'),
            valueGetter: ({ data }) => data?.comment,
            flex: 1,
            hide: true,
          },
          {
            cellClass: 'd-flex align-items-center cursor-pointer',
            cellRendererSelector: ({ data }) => ({
              component: ViewReportCell,
              params: { reportId: data?.id, onClick: () => onViewReportClick(data?.id!) },
            }),
            minWidth: 80,
            maxWidth: 80,
          },
        ]}
        error={error}
        gridRef={gridRef}
        isLoading={isLoading}
        onSortChanged={onSortChanged}
        onPageSelected={setPageNo}
        pagedData={pagedTasks}
        selectable
      />
    </div>
  )
}

const ViewReportCell: FC<{ reportId?: number; onClick?: () => void }> = ({ reportId, onClick }) => {
  if (!reportId) {
    return null
  }

  return <IxIconButton icon="eye-filled" ghost variant="primary" onClick={onClick} />
}
