import { ReactNode } from 'react'
import { generatePath } from 'react-router-dom'

export interface RouteConfig<
  Path extends string,
  Parent extends RouteConfig<string, any, any> | null = null,
  Model = any,
> {
  /**
   * https://ix.siemens.io/docs/icon-library/icons
   */
  icon?: string

  name: string
  parent?: Parent
  pathname: Path
  render?: (model: Model) => ReactNode
  virtualAncestors?: RouteConfig<any, any, Model>[]
}

export type RouteParams<Route extends RouteConfig<string, any>> = Parameters<typeof generatePath<Route['pathname']>>[1]

export const homeRoute: RouteConfig<'/'> = {
  icon: 'home',
  name: 'Home',
  pathname: '/',
}

export const logoutRoute: RouteConfig<'/logout'> = {
  icon: 'log-out',
  name: 'Logout',
  pathname: '/logout',
}

export const settingsRoute: RouteConfig<'/settings'> = {
  icon: 'cogwheel',
  name: 'Settings',
  pathname: '/settings',
}
