import { useCallback, useMemo, useState } from 'react'

import type { TableProps } from '@/core/components/Table'
import { useFilterOpts } from '@/core/hooks/use-filter-opts'

import type { QueryPointMachineFilterOpts } from '@/features/assets/api/point-machine'
import type { PointMachine } from '@/features/assets/models/point-machine'
import { useMeanpowerThresholdConfigStore } from '@/features/config/threshold/parameter/meanpower/store/meanpower-threshold-config-store'

export const useExpertMeanpowerFilter = () => {
  const filters = useMeanpowerThresholdConfigStore((state) => state.filters)
  const filterOpts = useFilterOpts<QueryPointMachineFilterOpts>(filters)
  const [pageNo, setPageNo] = useState(0)
  const [sortBy, setSortBy] = useState<string | undefined>('engineeringId')
  const [sortingDirection, setSortingDirection] = useState<QueryPointMachineFilterOpts['sortingDirection'] | undefined>(
    'asc',
  )

  const pagedFilterOpts = useMemo<Partial<QueryPointMachineFilterOpts>>(() => {
    return {
      ...filterOpts,
      page: pageNo,
      sortBy,
      sortingDirection,
      analyticsTypeName: ['meanpower_expertsystem'],
    }
  }, [filterOpts, pageNo, sortBy, sortingDirection])

  const onSortChanged = useCallback<NonNullable<TableProps<PointMachine>['onSortChanged']>>(
    ({ columnApi }) => {
      const columns = columnApi.getColumnState()
      const sortedColumn = columns.find((column) => column.sort != null)

      setSortBy(sortedColumn?.colId)
      setSortingDirection(sortedColumn?.sort ?? undefined)
    },
    [setSortBy, setSortingDirection],
  )

  return {
    filters,
    pagedFilterOpts,
    pageNo,
    setPageNo,
    sortBy,
    onSortChanged,
    sortingDirection,
    setSortingDirection,
  }
}
