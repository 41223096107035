import { JSX } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import { GlobalPageHeader } from '@/core/components/GlobalPageHeader'
import { appTitle } from '@/core/lib/config'

import { TurnoutListing } from '@/features/assets/components/TurnoutListing'
import { turnoutsRoute } from '@/features/assets/routes/turnouts'

export function TurnoutsPage(): JSX.Element {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>
          {appTitle} - {t(`routes.${turnoutsRoute.name}`)}
        </title>
      </Helmet>

      <GlobalPageHeader route={turnoutsRoute} title={t(`routes.${turnoutsRoute.name}`)}>
        <TurnoutListing />
      </GlobalPageHeader>
    </>
  )
}
