import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { LoadingErrorWrapper } from '@/core/components/LoadingErrorWrapper'
import { Modal, ModalContent } from '@/core/components/Modal'
import { useAnalytics } from '@/core/hooks/use-project'
import type { IdType } from '@/core/models/base'

import { CreateAsset } from '@/features/config/assets/components/CreateAsset'
import { assetConfigurationRoute, createAssetConfigurationRoute } from '@/features/config/assets/routes/asset'

export interface CreateAssetModalProps {
  visible: boolean
  projectId: IdType
}

export function CreateAssetModal({ visible, projectId }: CreateAssetModalProps): React.JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: analytics, ...analyticsData } = useAnalytics(projectId)

  return (
    <LoadingErrorWrapper data={analytics} error={analyticsData.error} isLoading={analyticsData.isLoading}>
      <Modal title={t(`routes.${createAssetConfigurationRoute.name}`)} visible={visible}>
        <ModalContent
          cancelButton={null}
          okButton={null}
          onCancel={() => navigate(generatePath(assetConfigurationRoute.pathname))}
          title={t(`routes.${createAssetConfigurationRoute.name}`)}
          inlineFooter
        >
          <CreateAsset
            onAfterSubmit={() => navigate(generatePath(assetConfigurationRoute.pathname))}
            onCancel={() => navigate(generatePath(assetConfigurationRoute.pathname))}
            analytics={analytics}
          />
        </ModalContent>
      </Modal>
    </LoadingErrorWrapper>
  )
}
