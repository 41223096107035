import { JSX, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IxButton } from '@siemens/ix-react'

import { DataFilter } from '@/core/components/DataFilter/DataFilter'
import type { Filter } from '@/core/components/DataFilter/types'
import { useFilterOpts } from '@/core/hooks/use-filter-opts'

import type { QueryAlarmEventsFilterOpts } from '@/features/alarms/api/alarm-event'
import { AlarmsKpiTile } from '@/features/alarms/components/AlarmsKpiTile'
import { AlarmsTile } from '@/features/alarms/components/AlarmsTile'
import { HealthKpiTile } from '@/features/alarms/components/HealthKpiTile'
import { HealthPointsKpiTile } from '@/features/alarms/components/HealthPointsKpiTile'
import { usePointMachineSearchFields } from '@/features/assets/hooks/use-point-machine'
import { MaintenanceTaskCreateModal } from '@/features/maintenance-reports/components/MaintenanceTaskCreateModal'
import { useMaintenanceTaskCreateModal } from '@/features/maintenance-reports/hooks/use-maintenance-task-create-modal'

export function Dashboard(): JSX.Element {
  const { t } = useTranslation()
  const [filters, setFilters] = useState<Filter[]>([])
  const { filterOpts } = useFilterOpts<QueryAlarmEventsFilterOpts>(filters)
  const {
    isOpen: isMaintenanceCreateModalOpen,
    openModal: openMaintenanceCreateModal,
    closeModal: closeMaintenanceCreateModal,
  } = useMaintenanceTaskCreateModal()

  return (
    <div className="row g-3">
      <div className="col-12">
        <DataFilter fields={usePointMachineSearchFields(filters)} onChange={setFilters} value={filters} />
      </div>
      <div className="d-flex justify-content-end">
        <IxButton icon="maintenance-documents" onClick={openMaintenanceCreateModal}>
          {t('dashboard.create_new_report')}
        </IxButton>
        {isMaintenanceCreateModalOpen && <MaintenanceTaskCreateModal onClose={closeMaintenanceCreateModal} />}
      </div>

      {filterOpts != null && (
        <>
          <div className="col-12 col-md-5 col-lg-4">
            <HealthKpiTile filterOpts={filterOpts} />
          </div>

          <div className="col-12 col-md-7 col-lg-8">
            <HealthPointsKpiTile filterOpts={filterOpts} />
          </div>

          <div className="col-12">
            <AlarmsTile filterOpts={filterOpts} />
          </div>

          <div className="col-12">
            <AlarmsKpiTile filterOpts={filterOpts} />
          </div>
        </>
      )}
    </div>
  )
}
