import { ComponentProps } from 'react'
import styled from 'styled-components'

import { IxIcon, IxIconButton } from '@siemens/ix-react'

const IxIconTableItem = styled(IxIcon)`
  display: flex;
  height: 100%;
  align-items: center;
`

export interface PropTypes extends Pick<ComponentProps<typeof IxIconButton>, 'onClick'> {
  /**
   * unique id, specially used for the tooltip
   */
  id: string
  /**
   * enabled: displays a check-icon; !enabled: displays an x-icon
   */
  enabled: boolean
  /**
   * Position of the icon inside the cell. Default is "center"
   */
  align?: 'left' | 'center' | undefined

  /**
   * Called by toggle
   * @e CustomEvent
   */
  onToggle?(e: CustomEvent): void
}

/**
 * Displays an enabled or disabled state with an icon
 * @param props
 * @constructor
 */
export function StateEnabledItem(props: PropTypes) {
  return (
    <IxIconTableItem
      aria-label={props.id}
      id={props.id}
      color={props.enabled ? 'color-success-40' : 'color-alarm-40'}
      name={props.enabled ? 'success' : 'error'}
      size="24"
      style={{ margin: props.align === 'left' ? 0 : 'auto' }}
    />
  )
}
