import * as t from 'io-ts'

import { BaseModelCodec, IdTypeCodec, PagedResultCodec } from '@/core/models/base'

import { BaseEventCodec } from '@/features/alarms/models/event'

import { PointMachineEventsCodec } from '@/features/assets/models/point-machine-event'

export const BaseTurnoutEventCodec = t.intersection([
  BaseEventCodec,
  t.type({
    blockage: t.boolean,
    healthScore: t.number,
    pointMachineEvents: PointMachineEventsCodec,
    turnoutId: IdTypeCodec,
  }),
])
export const BaseTurnoutEventsCodec = t.array(BaseTurnoutEventCodec)
export type BaseTurnoutEvent = t.TypeOf<typeof BaseTurnoutEventCodec>

export const TurnoutEventCodec = t.intersection([BaseModelCodec, BaseTurnoutEventCodec])
export const TurnoutEventsCodec = t.array(TurnoutEventCodec)
export type TurnoutEvent = t.TypeOf<typeof TurnoutEventCodec>

export const PagedTurnoutEventsCodec = PagedResultCodec(TurnoutEventsCodec)
export type PagedTurnoutEvents = t.TypeOf<typeof PagedTurnoutEventsCodec>
