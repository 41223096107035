import { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { LoadingErrorWrapper } from '@/core/components/LoadingErrorWrapper'
import { Modal, ModalContent } from '@/core/components/Modal'
import { useAnalytics } from '@/core/hooks/use-project'
import type { IdType } from '@/core/models/base'

import { useTurnouts } from '@/features/assets/hooks/use-turnout'

import { UpdateTurnoutForm, type UpdateTurnoutFormProps } from '@/features/config/assets/components/UpdateTurnoutForm'
import { assetConfigurationRoute, editAssetConfigurationRoute } from '@/features/config/assets/routes/asset'

type FormDataWrapperProps = Pick<UpdateTurnoutFormProps, 'onAfterSubmit' | 'onCancel' | 'onRemoveItem'> &
  Pick<UpdateTurnoutsModalProps, 'turnoutIds' | 'projectId'>

function FormDataWrapper({ turnoutIds, projectId, ...rest }: FormDataWrapperProps): JSX.Element {
  const { data: turnouts, ...turnoutsData } = useTurnouts(null, turnoutIds)
  const { data: analytics, ...analyticsData } = useAnalytics(projectId)

  return (
    <LoadingErrorWrapper
      data={turnouts}
      error={turnoutsData.error || analyticsData.error}
      isLoading={turnoutsData.isLoading || analyticsData.isLoading}
    >
      {(turnouts) => <UpdateTurnoutForm turnout={turnouts} analytics={analytics} {...rest} />}
    </LoadingErrorWrapper>
  )
}

export interface UpdateTurnoutsModalProps {
  turnoutIds: IdType[] | undefined
  visible: boolean
  projectId: IdType
}

export function UpdateTurnoutsModal({ turnoutIds, projectId, visible }: UpdateTurnoutsModalProps): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Modal title={t('Bulk edit turnouts')} visible={visible}>
      <ModalContent
        cancelButton={null}
        okButton={null}
        onCancel={() => navigate(generatePath(assetConfigurationRoute.pathname))}
        title={t('Bulk edit turnouts')}
        inlineFooter
      >
        <FormDataWrapper
          onAfterSubmit={() => navigate(generatePath(assetConfigurationRoute.pathname))}
          onCancel={() => navigate(generatePath(assetConfigurationRoute.pathname))}
          onRemoveItem={(_, turnouts) => {
            if (turnouts == null) {
              return
            }

            if (turnouts.length === 0) {
              return navigate(generatePath(assetConfigurationRoute.pathname))
            }

            navigate(
              generatePath(editAssetConfigurationRoute.pathname, {
                assetId: turnouts.map((turnout) => turnout.id).join(','),
              }),
            )
          }}
          turnoutIds={turnoutIds}
          projectId={projectId}
        />
      </ModalContent>
    </Modal>
  )
}
