import { JSX } from 'react'
import { useTranslation } from 'react-i18next'

import { EventKpiTile } from '@/core/components/EventKpiTile'

import { PointMachineKpiBar } from '@/features/assets/components/PointMachineKpiBar'
import { useTurnoutStatistics } from '@/features/assets/hooks/use-turnout'
import { getTurnoutState, turnoutStateToColorMap, type Turnout } from '@/features/assets/models/turnout'

export interface TurnoutKpiBarProps {
  turnout: Turnout
}

export function TurnoutKpiBar({ turnout }: TurnoutKpiBarProps): JSX.Element {
  const { data: statistics, ...statisticsData } = useTurnoutStatistics(turnout.id)
  const { t } = useTranslation()

  return (
    <div className="row g-3">
      <div className="col-12">
        <div style={{ width: '100%' }}>
          <div className="row g-3">
            <div className="col-6 col-lg-3">
              <EventKpiTile
                eventColor={turnoutStateToColorMap[getTurnoutState(turnout)]}
                title={t('Turnout name')}
                value={turnout.name}
              />
            </div>

            <div className="col-6 col-lg-3">
              <EventKpiTile
                isLoading={statisticsData.isLoading}
                precision={2}
                title={t('Turnout health score')}
                unit={statistics?.averageHealthScore == null ? undefined : '/ 1'}
                value={statistics?.averageHealthScore ?? ' – '}
              />
            </div>

            <div className="col-6 col-lg-3">
              <EventKpiTile
                isLoading={statisticsData.isLoading}
                title={t('Alarms since installation')}
                value={statistics?.noOfAlarms ?? ' – '}
              />
            </div>

            <div className="col-6 col-lg-3">
              <EventKpiTile
                isLoading={statisticsData.isLoading}
                title={t('Average turn time 24h')}
                value={statistics?.averageTurnoverTime ?? ' – '}
                unit={statistics?.averageTurnoverTime ? 'ms' : ''}
              />
            </div>
          </div>
        </div>
      </div>

      {statistics?.pointMachines
        ?.sort((a, b) => a.position - b.position)
        .map((pm, index) => <PointMachineKpiBar key={pm.id} index={index} pointMachine={pm} />)}
    </div>
  )
}
