import { JSX, useMemo } from 'react'

import { IxSelectItem } from '@siemens/ix-react'

import type { FieldProps } from '@/core/components/DataFilter/types'

import * as S from './styles'

export function SelectField({ field, onChange, ...props }: FieldProps): JSX.Element {
  const value = useMemo(() => {
    if (typeof props.value?.value === 'string') {
      return props.value.value
    }

    if (Array.isArray(props.value?.value)) {
      return props.value?.value.map((value) => String(value))
    }

    return undefined
  }, [props.value])

  const options = useMemo(() => {
    return field.options?.map((option) => ({ ...option, value: String(option.value) })) ?? []
  }, [field.options])

  return (
    <S.Select
      i18nPlaceholder={field.label ?? field.path}
      i18nSelectListHeader={field.label ?? field.path}
      mode="multiple"
      onItemSelectionChange={(event) => onChange?.(field.path, event.detail)}
      placeholder={field.label ?? field.path}
      selectedIndices={value}
      style={field.style}
    >
      {options.map((option) => (
        <IxSelectItem key={option.value} label={option.label ?? undefined} value={option.value} />
      ))}
    </S.Select>
  )
}
