import { useCallback, useState } from 'react'

import { TableProps } from '@/core/components/Table'
import { useFilterOpts } from '@/core/hooks/use-filter-opts'

import type { MaintenanceTaskQueryParams } from '@/features/maintenance-reports/api/maintenance-task-api'
import type { MaintenanceTask } from '@/features/maintenance-reports/models/maintenance-task'
import { useMaintenanceReportsStore } from '@/features/maintenance-reports/store/store'

export const useMaintenanceReportsFilter = () => {
  const filters = useMaintenanceReportsStore((state) => state.filters)
  const [sortBy, setSortBy] = useState<string | undefined>('dueAt')
  const [sortingDirection, setSortingDirection] = useState<MaintenanceTaskQueryParams['sortingDirection'] | undefined>(
    'desc',
  )

  const { filterOpts: pagedFilterOpts, setPageNo } = useFilterOpts<MaintenanceTaskQueryParams>(filters, {
    sortBy,
    sortingDirection,
  })

  const onSortChanged = useCallback<NonNullable<TableProps<MaintenanceTask>['onSortChanged']>>(
    ({ columnApi }) => {
      const columns = columnApi.getColumnState()
      const sortedColumn = columns.find((column) => column.sort != null)

      setSortBy(sortedColumn?.colId)
      setSortingDirection(sortedColumn?.sort ?? undefined)
    },
    [setSortBy, setSortingDirection],
  )

  return {
    pagedFilterOpts,
    setPageNo,
    sortBy,
    onSortChanged,
    sortingDirection,
    setSortingDirection,
  }
}
