import * as t from 'io-ts'

import { BaseModelCodec, caseInsensitive, IdTypeCodec } from '@/core/models/base'

export const maintenanceReasonCodec = t.union([
  caseInsensitive('MANDATORY_MANUFACTURER'),
  caseInsensitive('MANDATORY_OPERATOR'),
  caseInsensitive('INCIDENT'),
])
export const baseMaintenanceTaskCodec = t.type({
  turnoutId: IdTypeCodec,
  reason: maintenanceReasonCodec,
  work: t.array(t.string),
  dueAt: t.string,
  comment: t.union([t.string, t.null, t.undefined]),
})

export const maintenanceTaskCodec = t.intersection([BaseModelCodec, baseMaintenanceTaskCodec])

export type BaseMaintenanceTask = t.TypeOf<typeof baseMaintenanceTaskCodec>
export type MaintenanceTask = t.TypeOf<typeof maintenanceTaskCodec>
export type MaintenanceReason = t.TypeOf<typeof maintenanceReasonCodec>
