import { useEffect, useMemo, useState } from 'react'
import { Middleware, PublicConfiguration } from 'swr/_internal/dist'

import { convertError } from '@/core/models/service-error'

const delayMiddleware: Middleware = (useSWRNext) => {
  return (key, fetcher, config) => {
    const swrResponse = useSWRNext(key, fetcher, config)

    const [isDelayed, setIsDelayed] = useState(() => swrResponse == null)

    const delayedSwrResponse = useMemo<typeof swrResponse>(() => {
      if (isDelayed) {
        return swrResponse
      }

      return {
        data: undefined,
        error: undefined,
        isLoading: true,
        isValidating: false,
        mutate: async () => undefined,
      }
    }, [isDelayed, swrResponse])

    useEffect(() => {
      let mounted = true

      window.setTimeout(() => mounted && setIsDelayed(true), 50)

      return () => {
        mounted = false
      }
    }, [swrResponse.isLoading])

    return delayedSwrResponse
  }
}

const convertErrorMiddleware: Middleware = (useSWRNext) => {
  return (key, fetcher, config) => {
    const swrResponse = useSWRNext(key, fetcher, config)

    const convertedSwrResponse = useMemo<typeof swrResponse>(() => {
      const error = swrResponse.error == null ? swrResponse.error : convertError(swrResponse.error)

      return { ...swrResponse, error } as typeof swrResponse
    }, [swrResponse])

    return convertedSwrResponse
  }
}

const TEN_MINUTES = 600_000

export const swrConfig: Partial<PublicConfiguration> = {
  dedupingInterval: TEN_MINUTES,
  errorRetryInterval: TEN_MINUTES,
  focusThrottleInterval: TEN_MINUTES,
  keepPreviousData: true,
  refreshInterval: TEN_MINUTES,
  shouldRetryOnError: false,
  use: [delayMiddleware, convertErrorMiddleware],
}
