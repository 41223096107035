import { FC, useCallback } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import { MaintenanceTaskCreateModal } from '@/features/maintenance-reports/components/MaintenanceTaskCreateModal'
import { maintenanceReportsRoute } from '@/features/maintenance-reports/routes/maintenance-report-routes'

export const MaintenanceTaskCreatePage: FC = () => {
  const navigate = useNavigate()

  const navigateToReportsOverview = useCallback(
    () => navigate(generatePath(maintenanceReportsRoute.pathname)),
    [navigate],
  )

  return <MaintenanceTaskCreateModal onClose={navigateToReportsOverview} />
}
