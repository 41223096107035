import { JSX, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { EventColor, EventTable } from '@/core/components/EventTable'
import { TableProps } from '@/core/components/Table'
import type { PagedResultType } from '@/core/models/base'

import { IdCell } from '@/features/assets/components/TurnoutTable/components/IdCell'
import { useTrackLayers } from '@/features/assets/hooks/use-track-layer'
import { getTurnoutState, turnoutStateToColorMap, type Turnout } from '@/features/assets/models/turnout'

export interface TurnoutTableProps extends Omit<Omit<TableProps<Turnout & EventColor>, 'pagedData'>, 'columnDefs'> {
  pagedData?: PagedResultType<Turnout>
}

export function TurnoutTable({ pagedData, ...rest }: TurnoutTableProps): JSX.Element {
  const { t } = useTranslation()
  const { data: trackLayers } = useTrackLayers()

  const eventData = useMemo(() => {
    return pagedData?.content?.map((entry): Turnout & EventColor => ({
      ...entry,
      eventColor: turnoutStateToColorMap[getTurnoutState(entry)],
    }))
  }, [pagedData?.content])

  return (
    <EventTable
      {...rest}
      columnDefs={[
        {
          cellRenderer: IdCell,
          field: 'engineeringId',
          headerName: t('Turnout ID') ?? undefined,
          sortable: true,
          unSortIcon: true,
        },
        {
          comparator: (_, __, nodeA, nodeB) =>
            nodeA.data == null || nodeB.data == null
              ? 0
              : nodeA.data.operationState.localeCompare(nodeB.data.operationState),
          field: 'operationState',
          headerName: t('models.turnout.state') ?? undefined,
          initialSort: 'desc',
          valueGetter: ({ data }) => (data == null ? undefined : t(`models.turnout.state-${getTurnoutState(data)}`)),
          sortable: true,
          unSortIcon: true,
        },
        {
          colId: 'technicalRoomInterlockingName',
          field: '_technicalRoom._interlocking.name',
          headerName: trackLayers?.find((layer) => layer.type === 'interlocking')?.name,
          sortable: true,
          unSortIcon: true,
        },
        {
          colId: 'technicalRoomInterlockingAreaName',
          field: '_technicalRoom._interlocking._area.name',
          headerName: trackLayers?.find((layer) => layer.type === 'area')?.name,
          sortable: true,
          unSortIcon: true,
        },
        {
          colId: 'technicalRoomName',
          field: '_technicalRoom.name',
          headerName: trackLayers?.find((layer) => layer.type === 'technicalRoom')?.name,
          sortable: true,
          unSortIcon: true,
        },
        {
          field: 'pointMachines',
          headerName: t('PM1 ID') ?? undefined,
          valueGetter: ({ data }) => data?.pointMachines?.at(0)?.engineeringId,
        },
        {
          field: 'pointMachines',
          headerName: t('PM2 ID') ?? undefined,
          valueGetter: ({ data }) => data?.pointMachines?.at(1)?.engineeringId,
        },
      ]}
      pagedData={{ ...(pagedData as PagedResultType<Turnout & EventColor>), content: eventData ?? [] }}
      sortingOrder={['desc', 'asc']}
    />
  )
}
