import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { DataFilter } from '@/core/components/DataFilter/DataFilter'

import { useMeanpowerThresholdConfigStore } from '@/features/config/threshold/parameter/meanpower/store/meanpower-threshold-config-store'
import { usePointMachineSearchFields } from '@/features/assets/hooks/use-point-machine'

export const MeanpowerThresholdFilter: FC = () => {
  const { t } = useTranslation()
  const filters = useMeanpowerThresholdConfigStore((state) => state.filters)
  const setFilters = useMeanpowerThresholdConfigStore((state) => state.setFilters)
  const baseSearchFields = usePointMachineSearchFields(filters)

  return (
    <DataFilter
      fields={[
        ...baseSearchFields,
        {
          label: t('models.pointMachine.thresholdConfiguration'),
          options: [
            { label: t('models.pointMachine.thresholdConfiguration-complete'), value: 'complete' },
            { label: t('models.pointMachine.thresholdConfiguration-incomplete'), value: 'incomplete' },
          ],
          path: 'thresholdConfiguration',
          type: 'select',
        },
        {
          icon: 'search',
          label: t('Search for PM ID'),
          path: 'engineeringId',
          type: 'text',
        },
      ]}
      onChange={setFilters}
      value={filters}
    />
  )
}
