import { JSX, useEffect } from 'react'
import { useAuth } from 'react-oidc-context'
import { RouterProvider } from 'react-router-dom'

import { LoadingErrorWrapper } from '@/core/components/LoadingErrorWrapper'
import { Contexts } from '@/core/context/Contexts'
import { useAppStore } from '@/core/store/app-store'

import { GlobalStyles } from '@/global-styles'
import { router } from '@/router'

export function Main(): JSX.Element {
  const { isLoading, initError, initialize } = useAppStore()
  const { isAuthenticated } = useAuth()

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    initialize()
  }, [isAuthenticated, initialize])

  if (isLoading || initError) {
    return <LoadingErrorWrapper data={null} error={initError} isLoading={isLoading && !initError}/>
  }

  return (
    <Contexts>
      <GlobalStyles />

      <RouterProvider router={router} />
    </Contexts>
  )
}
