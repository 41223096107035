import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { IxButton } from '@siemens/ix-react'

export const CreateReportButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <IxButton icon="plus" onClick={onClick}>
      {t('assets.maintenance_reports.cta.create_new_report')}
    </IxButton>
  )
}
