import { PropsWithChildren, ReactNode, useEffect, useMemo, useState } from 'react'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'

import { IxBreadcrumb, IxBreadcrumbItem, IxTooltip } from '@siemens/ix-react'

import { usePageHeader } from '@/core/context/PageHeader'
import { routeToArr } from '@/core/lib/route'
import { RouteConfig, RouteParams } from '@/core/routes'

interface PropTypes<T extends RouteConfig<any, any>> {
  extra?: ReactNode
  params?: RouteParams<T>
  subTitle?: ReactNode
  route?: T
  title?: ReactNode
}

export function GlobalPageHeader<T extends RouteConfig<any, any>>({
  children,
  extra,
  params,
  subTitle,
  route,
  title,
}: PropsWithChildren<PropTypes<T>>) {
  const [isInitTitle, setIsInitTitle] = useState(false)
  const [lastUpdateNode, setLastUpdateNode] = useState<ReactNode>()
  const { i18n, t } = useTranslation()

  const {
    routeConfig,
    addRouteConfig,

    setAvatar,
    setExtra,
    setSubTitle,
    setTitle,

    resetState,
    ...pageHeaderProps
  } = usePageHeader()

  useEffect(() => {
    resetState()
  }, [resetState])

  useEffect(() => {
    setExtra(extra)
  }, [extra, setExtra])

  useEffect(() => {
    if (isInitTitle) {
      return
    }

    if (title == null) {
      setSubTitle(null)
      return setTitle(null)
    }

    setSubTitle(subTitle)
    setTitle(title)
    setIsInitTitle(true)
  }, [title, isInitTitle, setTitle, subTitle, setSubTitle])

  useEffect(() => {
    let interval: NodeJS.Timeout

    if (pageHeaderProps.lastUpdate == null) {
      setLastUpdateNode(undefined)
    } else {
      const update = () => {
        const timeString = pageHeaderProps.lastUpdate?.setLocale(i18n.language)?.toRelative({ unit: 'minutes' })
        const updateString = `${t('Last update')}: ${timeString}`
        setLastUpdateNode(timeString == null ? undefined : updateString)
      }

      interval = setInterval(update, 1000)
      update()
    }

    return () => {
      clearInterval(interval)
    }
  }, [i18n.language, pageHeaderProps.lastUpdate, t])

  const routeArr = useMemo(() => {
    if (route == null) {
      return undefined
    }

    const arr = routeToArr(route).map((route) => ({ params, route }))

    return arr.flatMap(({ params, route }, index) => {
      return [
        ...(route.virtualAncestors ?? []).map((ancestorRoute) => ({
          icon: ancestorRoute.icon,
          isLast: false,
          label: ancestorRoute.render?.(routeConfig[route.pathname]) ?? ancestorRoute.name,
          to: undefined,
        })),
        {
          icon: route.icon,
          isLast: index === arr.length - 1,
          label: route.render?.(routeConfig[route.pathname]) ?? t(`routes.${route.name}`),
          to: generatePath(route.pathname, params),
        },
      ]
    })
  }, [params, route, routeConfig, t])

  const key = useMemo<string | undefined>(() => {
    return routeArr?.flatMap((route) => Object.values(route)).join('-')
  }, [routeArr])

  return (
    <>
      {routeArr != null && (
        <IxBreadcrumb className="px-4" key={key}>
          {routeArr.map(({ isLast, label, to }, index) => {
            if (isLast || to == null) {
              return (
                <IxBreadcrumbItem key={`${to}-${index}`}>
                  <div style={{ color: 'var(--theme-color-soft-text)' }}>{label}</div>
                </IxBreadcrumbItem>
              )
            }

            return (
              <Link className="text-decoration-none" key={`${to}-${index}`} to={to}>
                <IxBreadcrumbItem>
                  <div style={{ color: 'var(--theme-btn-invisible-primary--color)' }}>{label}</div>
                </IxBreadcrumbItem>
              </Link>
            )
          })}
        </IxBreadcrumb>
      )}

      <div className="py-4">
        <div className="d-flex align-items-center justify-content-between px-4">
          <div className="d-flex align-items-center">
            {pageHeaderProps.avatar != null && (
              <span className="me-2 d-flex align-items-center">{pageHeaderProps.avatar}</span>
            )}
            {pageHeaderProps.title != null && <span className="text-h2 me-2">{pageHeaderProps.title}</span>}
          </div>

          {pageHeaderProps.extra != null && <div className="row g-2">{pageHeaderProps.extra}</div>}
        </div>

        {(pageHeaderProps.subTitle || lastUpdateNode) && (
          <div className="mt-1 px-4">
            {pageHeaderProps.subTitle}
            {lastUpdateNode != null && (
              <>
                <span data-tooltip="last-update-tooltip">{lastUpdateNode}</span>
                <IxTooltip for="[data-tooltip='last-update-tooltip']">
                  {pageHeaderProps.lastUpdate?.setLocale(i18n.language)?.toLocaleString(DateTime.DATETIME_MED)}
                </IxTooltip>

                {pageHeaderProps.lastUpdateExtra}
              </>
            )}
            &nbsp;
          </div>
        )}

        <div className="mt-4 px-4">{children}</div>
      </div>
    </>
  )
}
