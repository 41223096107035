import * as t from 'io-ts'

import { DirectionCodec } from '@/core/models/base'

export const BaseEventCodec = t.type({
  direction: DirectionCodec,
  end: t.string,
  healthState: t.number,
  start: t.string,
  throwingTime: t.number,
})
export const BaseEventsCodec = t.array(BaseEventCodec)
export type BaseEvent = t.TypeOf<typeof BaseEventCodec>
