import { MutableRefObject, useCallback } from 'react'
import type { AgGridReact } from 'ag-grid-react'
import { showSaveFilePicker } from 'file-system-access'
import { DateTime } from 'luxon'

import { showToast } from '@siemens/ix-react'

import type { PointMachineEvent } from '@/features/assets/models/point-machine-event'

const getFileContent = (events: PointMachineEvent[]): string => {
  const getIsExportable = (value: any): boolean => {
    return value != null && (typeof value === 'boolean' || typeof value === 'number' || typeof value === 'string')
  }

  const headers = events.reduce((headers, event) => {
    Object.entries(event).forEach(
      ([key, value]) => getIsExportable(value) && headers.add(key as keyof PointMachineEvent),
    )
    return headers
  }, new Set<keyof PointMachineEvent>())

  const headersArr = Array.from(headers)

  const columns = events.reduce((columns, event) => {
    const column = headersArr.map((header) =>
      event[header] == null ? '' : String(event[header]).replaceAll(';', '\\;'),
    )

    const strColumn = [event.pointMachine?.engineeringId ?? '', ...column].join(';')

    return [...columns, strColumn]
  }, [] as string[])

  const csv = ['engineeringId', ...headersArr].join(';') + '\n' + columns.join('\n')

  return csv
}

export const useExportEvents = (
  gridRef: MutableRefObject<AgGridReact<PointMachineEvent> | null>,
  fallbackData: PointMachineEvent[],
) => {
  const onClickExport = useCallback(async () => {
    const selectedRows = gridRef.current?.api.getSelectedRows()
    const events = selectedRows?.length === 0 ? fallbackData : (selectedRows ?? [])

    if (gridRef.current == null || events.length === 0) {
      return
    }

    try {
      const csv = getFileContent(events)

      const iso = DateTime.now().toISO({ includeOffset: false })
      const fileName = `export-${iso}.csv`

      const fileHandle = await showSaveFilePicker({
        suggestedName: fileName,
        types: [{ accept: { 'text/csv': 'csv' } }],
      })
      const writable = await fileHandle.createWritable()
      await writable.write(csv)
      await writable.close()
    } catch (error) {
      showToast({ autoCloseDelay: 15_000, message: 'Encountered an error while exporting data', type: 'error' })
    }
  }, [fallbackData, gridRef])

  return { onClickExport }
}
