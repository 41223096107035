import { JSX } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'

import { IxDropdown, IxDropdownItem, IxIcon } from '@siemens/ix-react'

import { Avatar } from '@/core/components/Avatar'
import { PlatformMenu } from '@/core/components/PlatformMenu'
import { UserMenu } from '@/core/components/UserMenu'
import { appTitle } from '@/core/lib/config'
import { homeRoute } from '@/core/routes'
import { faqRoute } from '@/core/routes/faq'

import * as S from './styles'

export interface OsBarProps {}

export function OsBar(props: OsBarProps): JSX.Element {
  const { t } = useTranslation()

  return (
    <S.OsBarWrapper>
      <Link className="me-4" to={generatePath(homeRoute.pathname)}>
        <S.Logo fill="var(--theme-app-header--color)" />
      </Link>

      <div className="d-flex fs-6 fw-bold user-select-none" id="os-bar-platform" style={{ cursor: 'pointer' }}>
        <div className="mx-2">RailXplore</div>
        <div className="mx-2">{appTitle}</div>
      </div>
      <IxDropdown closeBehavior="outside" trigger="os-bar-platform" style={{ minWidth: 300 }}>
        <PlatformMenu />
      </IxDropdown>

      <div className="ms-auto"></div>

      <IxIcon className="mx-2" id="os-bar-help" name="question" style={{ cursor: 'pointer' }} />
      <IxDropdown trigger="os-bar-help" style={{ width: 200 }}>
        <S.MenuLink to="mailto:railxplore-apm.support.mobility@siemens.com">
          <IxDropdownItem label={t('Support') ?? undefined} icon="support" />
        </S.MenuLink>

        <S.MenuLink to={generatePath(faqRoute.pathname)}>
          <IxDropdownItem label={t(`routes.${faqRoute.name}`) ?? undefined} icon={faqRoute.icon} />
        </S.MenuLink>
      </IxDropdown>

      <IxIcon className="mx-2" id="os-bar-menu" name="context-menu" style={{ cursor: 'pointer' }} />
      <IxDropdown trigger="os-bar-menu" style={{ width: 250 }}>
        <S.MenuLink target="_blank" to="https://www.siemens.com/de/de/general/legal.html">
          <IxDropdownItem label={t('Corporate information') ?? undefined} icon="building2" />
        </S.MenuLink>

        <S.MenuLink target="_blank" to="https://www.mobility.siemens.com/global/en/general/terms-of-use.html">
          <IxDropdownItem label={t('Terms of use') ?? undefined} icon="legal" />
        </S.MenuLink>

        <S.MenuLink target="_blank" to="https://www.mobility.siemens.com/global/en/general/privacy-notice.html">
          <IxDropdownItem label={t('Data privacy') ?? undefined} icon="lock-key" />
        </S.MenuLink>
      </IxDropdown>

      <div className="mx-2" id="os-bar-user" style={{ cursor: 'pointer' }}>
        <Avatar size={24} />
      </div>
      <IxDropdown closeBehavior="outside" trigger="os-bar-user" style={{ minWidth: 300 }}>
        <UserMenu />
      </IxDropdown>
    </S.OsBarWrapper>
  )
}
