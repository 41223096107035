import * as t from 'io-ts'

/**
 * Base
 */

const BaseMeasurementCodec = t.type({
  id: t.number,
  interlockingId: t.number,
  measuredAt: t.string,
})

/**
 * TrackTemperature
 */

export const BaseTrackTemperatureMeasurementCodec = t.type({
  trackPoint: t.string,
  measuredValue: t.number,
})

export const TrackTemperatureMeasurementCodec = t.intersection([BaseMeasurementCodec, BaseTrackTemperatureMeasurementCodec])
export type TrackTemperatureMeasurement  = t.TypeOf<typeof TrackTemperatureMeasurementCodec>

/**
 * TrackPointHeaterOn
 */

export const BaseTrackPointHeaterOnMeasurementCodec = t.type({
  measuredValue: t.boolean,
})
export const TrackPointHeaterOnMeasurementCodec = t.intersection([BaseMeasurementCodec, BaseTrackPointHeaterOnMeasurementCodec])
export type TrackPointHeaterOnMeasurement  = t.TypeOf<typeof TrackPointHeaterOnMeasurementCodec>


/**
 * DriftingSnowDetected
 */

export const BaseDriftingSnowDetectedMeasurementCodec = t.type({
  measuredValue: t.boolean,
})
export const DriftingSnowDetectedMeasurementCodec = t.intersection([BaseMeasurementCodec, BaseDriftingSnowDetectedMeasurementCodec])
export type DriftingSnowDetectedMeasurement  = t.TypeOf<typeof DriftingSnowDetectedMeasurementCodec>


/**
 * OutsideTemperature
 */

export const BaseOutsideTemperatureMeasurementCodec = t.type({
  measuredValue: t.number,
})

export const OutsideTemperatureMeasurementCodec = t.intersection([BaseMeasurementCodec, BaseOutsideTemperatureMeasurementCodec])
export type OutsideTemperatureMeasurement  = t.TypeOf<typeof OutsideTemperatureMeasurementCodec>

/**
 * HumidityAboveThreshold
 */

export const BaseHumidityAboveThresholdMeasurementCodec = t.type({
  measuredValue: t.boolean,
})
export const HumidityAboveThresholdMeasurementCodec = t.intersection([BaseMeasurementCodec, BaseHumidityAboveThresholdMeasurementCodec])
export type HumidityAboveThresholdMeasurement  = t.TypeOf<typeof HumidityAboveThresholdMeasurementCodec>

/**
 * SnowfallDetected
 */
export const BaseSnowfallDetectedMeasurementCodec = t.type({
  measuredValue: t.boolean,
})
export const SnowfallDetectedMeasurementCodec = t.intersection([BaseMeasurementCodec, BaseSnowfallDetectedMeasurementCodec])
export type SnowfallDetectedMeasurement  = t.TypeOf<typeof SnowfallDetectedMeasurementCodec>


/**
 * Humidity
 */

export const BaseHumidityMeasurementCodec = t.type({
  measuredValue: t.number,
})
export const HumidityMeasurementCodec = t.intersection([BaseMeasurementCodec, BaseHumidityMeasurementCodec])
export type HumidityMeasurement  = t.TypeOf<typeof HumidityMeasurementCodec>


export const SensorMeasurementsCodec = t.type({
  trackTemperatureMeasurements: t.array(TrackTemperatureMeasurementCodec),
  trackPointHeaterOnMeasurements: t.array(TrackPointHeaterOnMeasurementCodec),
  snowfallDetectedMeasurements: t.array(SnowfallDetectedMeasurementCodec),
  humidityMeasurements: t.array(HumidityMeasurementCodec),
  humidityAboveThresholdMeasurements: t.array(HumidityAboveThresholdMeasurementCodec),
  driftingSnowDetectedMeasurements: t.array(DriftingSnowDetectedMeasurementCodec),
  outsideTemperatureMeasurements: t.array(OutsideTemperatureMeasurementCodec),
})

export type SensorMeasurements = t.TypeOf<typeof SensorMeasurementsCodec>
