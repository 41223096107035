import { JSX, useCallback, useEffect, useMemo, useState } from 'react'
import { DateTime } from 'luxon'
import { Trans, useTranslation } from 'react-i18next'
import { Encoding } from 'vega-lite/build/src/encoding'
import { AnyMark } from 'vega-lite/build/src/mark'
import { GenericUnitSpec } from 'vega-lite/build/src/spec'

import { IxTabItem, IxTabs, IxTile } from '@siemens/ix-react'

import type { DataPoint } from '@/core/components/ConcatenatedIncidentChart/ConcatenatedIncidentChart'
import { DataFilter } from '@/core/components/DataFilter/DataFilter'
import type { Filter, SearchField } from '@/core/components/DataFilter/types'
import { LoadingErrorWrapper } from '@/core/components/LoadingErrorWrapper'
import { MessageBar } from '@/core/components/MessageBar'
import { useFilterOpts } from '@/core/hooks/use-filter-opts'
import { getDateRangeDefaults } from '@/core/lib/date'
import type { IdType } from '@/core/models/base'

import type { QueryAlarmEventsFilterOpts } from '@/features/alarms/api/alarm-event'
import type { QueryPointMachineEventsFilterOpts } from '@/features/assets/api/point-machine-event'
import type { SensorMeasurementFilterOpts } from '@/features/assets/api/sensor-measurements'
import type { TurnoutTrendCurveFilterOpts } from '@/features/assets/api/turnout-trend-curve'
import { FitnessGraph } from '@/features/assets/components/PointMachineEventCharts/components/FitnessGraph'
import { MeanPowerDurationGraph } from '@/features/assets/components/PointMachineEventCharts/components/MeanPowerDurationGraph'
import { MeanPowerTrendGraph } from '@/features/assets/components/PointMachineEventCharts/components/MeanPowerTrendGraph'
import { PointMachineEventFilters } from '@/features/assets/components/PointMachineEventCharts/components/PointMachineEventFilters'
import {
  PointMachineEventTable,
  type PointMachineEventTableProps,
} from '@/features/assets/components/PointMachineEventCharts/components/PointMachineEventTable'
import { PowerGraph } from '@/features/assets/components/PointMachineEventCharts/components/PowerGraph'
import { WeatherGraph } from '@/features/assets/components/PointMachineEventCharts/components/WeatherGraph'
import { useQueryPointMachineEventsOfTurnout } from '@/features/assets/components/PointMachineEventCharts/hooks'
import { usePointMachines } from '@/features/assets/hooks/use-point-machine'
import { useSensorMeasurements } from '@/features/assets/hooks/use-sensor-measurements'
import { useTurnout } from '@/features/assets/hooks/use-turnout'
import { useTurnoutTrendCurve } from '@/features/assets/hooks/use-turnout-trend-curve'

const to = DateTime.now()

const dateSearchFields: SearchField[] = [
  {
    path: 'from-to',
    type: 'date_range',
  },
]

const defaultFilters: Filter[] = [{ ...dateSearchFields[0], value: getDateRangeDefaults('last-3d') }]

export interface PointMachineEventChartsProps {
  turnoutId: IdType
}

export function PointMachineEventCharts({ turnoutId }: PointMachineEventChartsProps): JSX.Element {
  const [dateFilters, setDateFilters] = useState<Filter[]>(defaultFilters)
  const [eventFilters, setEventFilters] = useState<Filter[]>()
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [tab, setTab] = useState(0)
  const { data: turnout, isLoading: isTurnoutLoading } = useTurnout(turnoutId)
  const { t } = useTranslation()

  const filters = useMemo(() => {
    if (eventFilters == null) {
      return undefined
    }

    return [...eventFilters, ...dateFilters]
  }, [dateFilters, eventFilters])

  const { data: pointMachines, ...pointMachinesData } = usePointMachines(turnoutId)

  const { filterOpts: turnoutTrendCurveFilterOpts } = useFilterOpts<TurnoutTrendCurveFilterOpts>(
    filters,
    filters == null ? undefined : { to },
  )

  const { data: trendCurve, ...trendCurveData } = useTurnoutTrendCurve(turnoutId, turnoutTrendCurveFilterOpts)

  /**
   * Sensor data
   */
  const { filterOpts: sensorMeasurementsFilterOpts } = useFilterOpts<SensorMeasurementFilterOpts>(
    filters,
    filters == null ? undefined : { to },
  )
  // Load sensor data
  const { data: sensorMeasurements, ...sensorMeasurementsData } = useSensorMeasurements(
    turnoutId,
    sensorMeasurementsFilterOpts,
  )

  const filteredPointMachines = useMemo(() => {
    const filtered = pointMachines?.filter((pm) => {
      const filter = filters?.find((filter) => filter.path === 'pointMachineName')
      const value =
        typeof filter?.value === 'string' ? filter.value.split(',') : Array.isArray(filter?.value) ? filter!.value : []

      if (filter == null || value.length === 0) {
        return true
      }

      return value.includes(pm.name)
    })

    return filtered
  }, [filters, pointMachines])

  const { filterOpts: pointMachineEventFilterOpts } = useFilterOpts<QueryPointMachineEventsFilterOpts>(
    turnout == null ? undefined : filters,
    // 'size' limits the amount of entries. A value bigger than 200 leads to performance issues.
    // If the limit is reached, a message bar will be displayed above the table.
    turnout == null ? undefined : { sortBy: 'start', sortingDirection: 'desc', size: 200 },
  )

  const { data: pointMachineEvents, ...pointMachineEventsData } = useQueryPointMachineEventsOfTurnout(
    turnout,
    filteredPointMachines,
    pointMachineEventFilterOpts,
  )

  useEffect(() => {
    setSelectedIds((current) => {
      return current.filter((id) => pointMachineEvents?.content.find((pme) => pme.id === id))
    })
  }, [pointMachineEvents])

  const visiblePointMachineEvents = useMemo(() => {
    return selectedIds.length > 0
      ? pointMachineEvents?.content.filter((pme) => selectedIds.find((id) => id === pme.id))
      : pointMachineEvents?.content
  }, [pointMachineEvents, selectedIds])

  const onSelectPointMachineEvents = useCallback<NonNullable<PointMachineEventTableProps['onSelectionChanged']>>(
    (event) => {
      setSelectedIds(event.api.getSelectedRows().map((item) => item.id))
    },
    [],
  )

  const powerData = useMemo((): DataPoint[] => {
    return (visiblePointMachineEvents ?? []).flatMap((pmEvent): DataPoint => {
      return {
        start: pmEvent.start,
        type: t(`models.event.direction-${pmEvent.direction}`),
        type2: pmEvent.pointMachine?.name,
        value: pmEvent.meanPower,
      }
    })
  }, [t, visiblePointMachineEvents])

  const maxPower = useMemo<number>(() => {
    const maxMeanPower = powerData.reduce((max, data) => {
      return typeof data.value === 'number' ? Math.max(max, data.value) : max
    }, 0)

    const maxTrendPower = Object.values(trendCurve?.trendsByDirection ?? {}).reduce((max, trendByDirection) => {
      if (trendByDirection.mean_power == null) {
        return max
      }

      return Math.max(max, ...Object.values(trendByDirection.mean_power))
    }, 0)

    return Math.max(maxMeanPower, maxTrendPower) + 20
  }, [trendCurve, powerData])

  const powerLayer = useMemo((): GenericUnitSpec<Encoding<string>, AnyMark> => {
    const encoding: Encoding<string> = {
      color: {
        field: 'type',
        scale: { range: ['var(--theme-chart-7)', 'var(--theme-chart-13)'] },
        title: t('models.event.meanPower'),
      },
      opacity: { condition: { param: 'highlightPower', value: 0.9 }, value: 0.2 },
      tooltip: [
        { field: 'start', format: '%c', title: t('Timestamp'), type: 'temporal' },
        { field: 'type', title: t('models.event.direction') },
        { field: 'type2', title: t('Point machine') },
        { field: 'value', title: `${t('models.event.meanPower')} [W]`, type: 'quantitative' },
      ],
      y: {
        field: 'value',
        /*scale: { domain: [0, maxPower] },*/ title: `${t('models.event.meanPower')} [W]`,
        type: 'quantitative',
      },
    }

    return {
      data: { values: powerData },
      encoding,
      mark: { size: 80, type: 'circle' },
      params: [
        // highlight
        {
          bind: 'legend',
          name: 'highlightPower',
          select: { type: 'point', fields: ['type'] },
        },
        // zoom
        {
          name: 'grid',
          select: 'interval',
          bind: 'scales',
        },
      ],
    }
  }, [powerData, t])

  const alarmEventsFilterOpts = useMemo<Partial<QueryAlarmEventsFilterOpts>>(
    () => mapPointMachineToAlarmQueryOpts({ turnoutName: turnout?.name, pointMachineEventFilterOpts }),
    [pointMachineEventFilterOpts, turnout?.name],
  )

  return (
    <IxTile>
      <div slot="header">{t('Performance data')}</div>

      <div>
        <LoadingErrorWrapper
          data={pointMachines}
          error={pointMachinesData.error}
          isLoading={pointMachinesData.isLoading || isTurnoutLoading}
        >
          {(pointMachines) => (
            <>
              <div className="row justify-content-between align-items-center mt-4 g-2">
                <div className="col-auto">
                  <PointMachineEventFilters
                    onChange={setEventFilters}
                    pointMachines={pointMachines.sort((a, b) => a.position - b.position)}
                    value={eventFilters}
                  />
                </div>
              </div>

              <div className="row justify-content-end align-items-center mx-1 mt-1">
                <DataFilter fields={dateSearchFields} onChange={setDateFilters} value={dateFilters} removeGrid />
              </div>

              {pointMachineEvents?.warning != null && (
                <div className={'mx-3'}>
                  <MessageBar type="warning" dismissible={false}>
                    <Trans
                      components={{
                        br: <br />,
                        b: <strong />,
                      }}
                      i18nKey={pointMachineEvents?.warning}
                    />
                  </MessageBar>
                </div>
              )}

              <div className="mt-4">
                <IxTabs selected={tab}>
                  <IxTabItem onClick={setTab.bind(null, 0)}>{t('Mean power & trend')}</IxTabItem>
                  <IxTabItem onClick={setTab.bind(null, 1)}>{t('Turn time')}</IxTabItem>
                  <IxTabItem onClick={setTab.bind(null, 2)}>{t('Fitness score')}</IxTabItem>
                  <IxTabItem onClick={setTab.bind(null, 3)}>{t('Power curves')}</IxTabItem>
                  <IxTabItem onClick={setTab.bind(null, 4)}>{t('Weather data')}</IxTabItem>
                </IxTabs>

                {tab === 0 && (
                  <LoadingErrorWrapper
                    data={trendCurve}
                    error={trendCurveData.error}
                    isLoading={trendCurveData.isLoading}
                  >
                    {(trendCurve) => (
                      <MeanPowerTrendGraph
                        maxPower={maxPower}
                        turnoutTrendCurve={trendCurve}
                        powerLayer={powerLayer}
                        alarmEventsFilterOpts={alarmEventsFilterOpts}
                      />
                    )}
                  </LoadingErrorWrapper>
                )}

                {tab === 1 && (
                  <LoadingErrorWrapper
                    data={visiblePointMachineEvents}
                    error={pointMachineEventsData.error}
                    isLoading={pointMachineEventsData.isLoading}
                  >
                    {(pointMachineEvents) => (
                      <MeanPowerDurationGraph
                        powerLayer={powerLayer}
                        pointMachineEvents={pointMachineEvents}
                        alarmEventsFilterOpts={alarmEventsFilterOpts}
                      />
                    )}
                  </LoadingErrorWrapper>
                )}

                {tab === 2 && (
                  <LoadingErrorWrapper
                    data={visiblePointMachineEvents}
                    error={pointMachineEventsData.error}
                    isLoading={pointMachineEventsData.isLoading}
                  >
                    {(pointMachineEvents) => (
                      <FitnessGraph
                        pointMachineEvents={pointMachineEvents}
                        alarmEventsFilterOpts={alarmEventsFilterOpts}
                      />
                    )}
                  </LoadingErrorWrapper>
                )}

                {tab === 3 && (
                  <LoadingErrorWrapper
                    data={visiblePointMachineEvents}
                    error={pointMachineEventsData.error}
                    isLoading={pointMachineEventsData.isLoading}
                  >
                    {(pointMachineEvents) => <PowerGraph pointMachineEvents={pointMachineEvents} />}
                  </LoadingErrorWrapper>
                )}

                {tab === 4 && (
                  <LoadingErrorWrapper
                    data={sensorMeasurements}
                    error={sensorMeasurementsData.error}
                    isLoading={sensorMeasurementsData.isLoading}
                  >
                    {(sensorMeasurements) => (
                      <WeatherGraph
                        powerLayer={powerLayer}
                        measurements={sensorMeasurements}
                        alarmEventsFilterOpts={sensorMeasurementsFilterOpts}
                      />
                    )}
                  </LoadingErrorWrapper>
                )}
              </div>

              <div className="mt-4">
                <PointMachineEventTable
                  onSelectionChanged={onSelectPointMachineEvents}
                  pointMachineEvents={pointMachineEvents}
                  selectedIds={selectedIds}
                />
              </div>
            </>
          )}
        </LoadingErrorWrapper>
      </div>
    </IxTile>
  )
}

const mapPointMachineToAlarmQueryOpts = ({
  pointMachineEventFilterOpts,
  turnoutName,
}: {
  pointMachineEventFilterOpts?: Partial<QueryPointMachineEventsFilterOpts>
  turnoutName?: string
}) => ({
  from: pointMachineEventFilterOpts?.from,
  to: pointMachineEventFilterOpts?.to,
  analyticsTypeName: pointMachineEventFilterOpts?.analyticsTypeName,
  areaName: pointMachineEventFilterOpts?.areaName,
  interlockingName: pointMachineEventFilterOpts?.interlockingName,
  page: pointMachineEventFilterOpts?.page,
  size: pointMachineEventFilterOpts?.size,
  sortBy: pointMachineEventFilterOpts?.sortBy,
  sortingDirection: pointMachineEventFilterOpts?.sortingDirection,
  technicalRoomName: pointMachineEventFilterOpts?.technicalRoomName,
  turnoutName,
})
