import { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { generatePath, Navigate } from 'react-router-dom'

import { IxMessageBar } from '@siemens/ix-react'

import { useMainProject } from '@/core/hooks/use-project'
import { appTitle } from '@/core/lib/config'

import { parameterThresholdConfigurationRoute, thresholdConfigurationRoute } from '@/features/config/threshold/threshold-route'

export const PowercurveAiThresholdConfig: FC = () => {
  const { t } = useTranslation()
  const mainProject = useMainProject()

  if (!mainProject.isPowercurveAiEnabled) {
    return <Navigate to={generatePath(parameterThresholdConfigurationRoute.pathname)} replace />
  }

  return (
    <>
      <Helmet>
        <title>
          {`${appTitle} - ${t(`routes.${thresholdConfigurationRoute.name}`)} - ${t(
            `routes.${parameterThresholdConfigurationRoute.name}`,
          )} - ${t('config.thresholds.parameter.ai.title')}`}
        </title>
      </Helmet>
      <div>
        <IxMessageBar dismissible={false}>{t('config.thresholds.parameter.ai.no-config-necessary')}</IxMessageBar>
      </div>
    </>
  )
}
