import { FC, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMatch } from 'react-router-dom'

import { IxButton, IxIcon, IxPill, IxTypography, showToast } from '@siemens/ix-react'

import { ErrorInfo } from '@/core/components/ErrorInfo'
import { ModalV2 } from '@/core/components/ModalV2'

import {
  DEFAULT_LOWER_THRESHOLD,
  DEFAULT_UPPER_THRESHOLD,
  useAnalyticsThresholds,
  useResetAnalyticsThresholds,
  useUpdateAnalyticsThresholds,
} from '@/features/config/threshold/parameter/turntime/hooks/use-analytics-threshold'
import {
  useTurntimeThresholdEditModal,
  type MultipleValuesIndicator,
  type MultipleValuesIndicatorDirection,
} from '@/features/config/threshold/parameter/turntime/hooks/use-turntime-threshold-edit-modal'
import { useTurntimeThresholdRouter } from '@/features/config/threshold/parameter/turntime/hooks/use-turntime-threshold-router'
import type {
  AnalyticsThreshold,
  AnalyticsThresholdWithTurnoutId,
  UpdateTurntimeThresholdsValues,
} from '@/features/config/threshold/parameter/turntime/models/analytics-threshold'
import { useTurntimeThresholdConfigStore } from '@/features/config/threshold/parameter/turntime/store/turntime-threshold-config-store'
import {
  editParameterTurntimeThresholdConfigurationRoute,
  parameterThresholdConfigurationRoute,
} from '@/features/config/threshold/threshold-route'
import { useTurnouts } from '@/features/assets/hooks/use-turnout'

type InputChangeHandler = (e: SyntheticEvent<HTMLInputElement>) => void

export const TurntimeThresholdEditModal: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation()
  const { navigateToOverview, navigateToEditRoute } = useTurntimeThresholdRouter()
  const editTurntimeThresholdMatch = useMatch(editParameterTurntimeThresholdConfigurationRoute.pathname)
  const { selectedTurnoutIds, initialThresholds, multipleValuesIndicator, isTurnoutsLoading, isThresholdsLoading } =
    useTurntimeThresholdEditModal()

  useEffect(() => {
    if (!editTurntimeThresholdMatch) {
      return
    }

    // update url /edit/1,2,3 whenever we are on the edit route and selected turnout ids change
    // e.g. when turnouts are remove inside the modal via the pills (chips)
    navigateToEditRoute()
  }, [selectedTurnoutIds, editTurntimeThresholdMatch, navigateToEditRoute])

  const closeWithSuccess = () => {
    showToast({ message: t('Successfully updated thresholds'), type: 'success' })
    onClose()
  }

  return (
    <ModalV2
      title={t(`routes.${parameterThresholdConfigurationRoute.name}`)}
      onClose={onClose}
      isLoading={isTurnoutsLoading || isThresholdsLoading}
    >
      <UpdateTurntimeThresholdsForm
        initialThresholds={initialThresholds}
        multipleValuesIndicator={multipleValuesIndicator}
        onCancel={navigateToOverview}
        onResetSuccess={closeWithSuccess}
        onSaveSuccess={closeWithSuccess}
      />
    </ModalV2>
  )
}

const UpdateTurntimeThresholdsForm: FC<{
  initialThresholds?: AnalyticsThresholdWithTurnoutId
  multipleValuesIndicator?: MultipleValuesIndicator
  onResetSuccess: () => void
  onCancel: () => void
  onSaveSuccess: () => void
}> = ({ initialThresholds, multipleValuesIndicator, onCancel, onResetSuccess, onSaveSuccess }) => {
  const { t } = useTranslation()
  const selectedTurnoutIds = useTurntimeThresholdConfigStore((state) => state.selectedTurnoutIds)
  const { data: thresholds, error: fetchError } = useAnalyticsThresholds(selectedTurnoutIds)
  const { trigger: updateThresholds, isMutating: isSaving, error: updateError } = useUpdateAnalyticsThresholds()
  const {
    trigger: resetThresholds,
    isMutating: isResetting,
    error: resetError,
  } = useResetAnalyticsThresholds(selectedTurnoutIds)

  const [values, setValues] = useState<UpdateTurntimeThresholdsValues>({
    left: {
      lower: initialThresholds?.left?.turnoverTimeLower ?? DEFAULT_LOWER_THRESHOLD,
      upper: initialThresholds?.left?.turnoverTimeUpper ?? DEFAULT_UPPER_THRESHOLD,
    },
    right: {
      lower: initialThresholds?.right?.turnoverTimeLower ?? DEFAULT_LOWER_THRESHOLD,
      upper: initialThresholds?.right?.turnoverTimeUpper ?? DEFAULT_UPPER_THRESHOLD,
    },
  })

  const emptyStringToDefault = (inputValue: string, defaultValue: number): number =>
    inputValue ? Number(inputValue) : defaultValue

  const upperLeftChanged: InputChangeHandler = (e) =>
    setValues({
      ...values,
      left: { ...values.left, upper: emptyStringToDefault(e.currentTarget.value, DEFAULT_UPPER_THRESHOLD) },
    })
  const upperRightChanged: InputChangeHandler = (e) =>
    setValues({
      ...values,
      right: { ...values.right, upper: emptyStringToDefault(e.currentTarget.value, DEFAULT_UPPER_THRESHOLD) },
    })
  const lowerLeftChanged: InputChangeHandler = (e) =>
    setValues({
      ...values,
      left: { ...values.left, lower: emptyStringToDefault(e.currentTarget.value, DEFAULT_LOWER_THRESHOLD) },
    })
  const lowerRightChanged: InputChangeHandler = (e) =>
    setValues({
      ...values,
      right: { ...values.right, lower: emptyStringToDefault(e.currentTarget.value, DEFAULT_LOWER_THRESHOLD) },
    })

  const saveThresholdsAndClose = useCallback(async () => {
    await updateThresholds({
      values: selectedTurnoutIds.flatMap((turnoutId) => [
        {
          id: thresholds?.find((t) => t.turnoutId === turnoutId)?.left?.id,
          direction: 'left',
          turnoverTimeLower: values.left.lower,
          turnoverTimeUpper: values.left.upper,
          turnoutId,
        },
        {
          id: thresholds?.find((t) => t.turnoutId === turnoutId)?.right?.id,
          direction: 'right',
          turnoverTimeLower: values.right.lower,
          turnoverTimeUpper: values.right.upper,
          turnoutId,
        },
      ]),
    })

    onSaveSuccess()
  }, [onSaveSuccess, selectedTurnoutIds, thresholds, updateThresholds, values])

  const resetThresholdsAndClose = useCallback(async () => {
    await resetThresholds()
    onResetSuccess()
  }, [onResetSuccess, resetThresholds])

  return (
    <div className="d-flex flex-column gap-4">
      {updateError && <ErrorInfo error={updateError} />}
      {fetchError && <ErrorInfo error={fetchError} />}
      {resetError && <ErrorInfo error={resetError} />}

      <Pills selectedTurnoutIds={selectedTurnoutIds} />

      <div className="d-flex flex-column flex-sm-row gap-4">
        <InfoText />
        <ThresholdInputGroup
          label={t('Thresholds left')}
          initialThreshold={initialThresholds?.left}
          multipleValuesIndicator={multipleValuesIndicator?.left}
          onUpperChange={upperLeftChanged}
          onLowerChange={lowerLeftChanged}
        />
        <ThresholdInputGroup
          label={t('Thresholds right')}
          initialThreshold={initialThresholds?.right}
          multipleValuesIndicator={multipleValuesIndicator?.right}
          onUpperChange={upperRightChanged}
          onLowerChange={lowerRightChanged}
        />
      </div>
      <ActionButtons
        isSaving={isSaving}
        isResetting={isResetting}
        onSave={saveThresholdsAndClose}
        onReset={resetThresholdsAndClose}
        onCancel={onCancel}
      />
    </div>
  )
}

const Pills: FC<{ selectedTurnoutIds?: number[] }> = ({ selectedTurnoutIds }) => {
  const removeSelectedTurnout = useTurntimeThresholdConfigStore((state) => state.removeSelectedTurnout)
  const { data: turnoutsToEdit } = useTurnouts(null, selectedTurnoutIds)
  const remove = useCallback(
    (id: number) => {
      removeSelectedTurnout(id)
    },
    [removeSelectedTurnout],
  )

  return (
    <div className="d-flex gap-2 flex-wrap">
      {turnoutsToEdit?.map((turnout) => (
        <IxPill key={turnout.id}>
          {turnout.name}{' '}
          <IxIcon name="close" onClick={() => remove(turnout.id)} size="12" style={{ cursor: 'pointer' }} />
        </IxPill>
      ))}
    </div>
  )
}

const ThresholdInputGroup: FC<{
  label: string
  initialThreshold?: AnalyticsThreshold
  multipleValuesIndicator?: MultipleValuesIndicatorDirection
  onUpperChange: InputChangeHandler
  onLowerChange: InputChangeHandler
}> = ({ label, initialThreshold, multipleValuesIndicator, onUpperChange, onLowerChange }) => {
  return (
    <div className="d-flex flex-column gap-4" style={{ minWidth: 130 }}>
      <IxTypography format="h4">{label}</IxTypography>
      <ThresholdInputField
        label="Upper time difference (s)"
        initialValue={initialThreshold?.turnoverTimeUpper}
        hasMultipleValues={multipleValuesIndicator?.upper}
        onChange={onUpperChange}
        defaultThresholdWhenUnset={DEFAULT_UPPER_THRESHOLD}
      />
      <ThresholdInputField
        label="Lower time difference (s)"
        initialValue={initialThreshold?.turnoverTimeLower}
        hasMultipleValues={multipleValuesIndicator?.lower}
        onChange={onLowerChange}
        defaultThresholdWhenUnset={DEFAULT_LOWER_THRESHOLD}
      />
    </div>
  )
}

const InfoText: FC = () => {
  const { t } = useTranslation()

  return (
    <div className="d-flex flex-column gap-2">
      <IxTypography format="h4">Time</IxTypography>
      <IxTypography format="body">
        {t(
          'For example, if the threshold for time difference is set at 0,5 sec, it means that as soon as the turn time exceeds the optimal turn time (provided by optimal reference curves) by 0,5 sec, the fitness score of this turn event is voting towards a potential alarm.',
        )}
      </IxTypography>
    </div>
  )
}

const ThresholdInputField: FC<{
  label: string
  hasMultipleValues?: boolean
  initialValue?: number
  defaultThresholdWhenUnset: number
  onChange: InputChangeHandler
}> = ({ label, hasMultipleValues, initialValue, defaultThresholdWhenUnset, onChange }) => {
  const isUnset = isNaN(Number(initialValue))

  return (
    <div className="d-flex flex-column gap-1">
      <IxTypography format="label" textColor="soft">
        {label}
      </IxTypography>
      <input
        className="text-start"
        type="number"
        onChange={onChange}
        defaultValue={hasMultipleValues ? undefined : initialValue}
        placeholder={
          hasMultipleValues
            ? `<multiple> (${initialValue})`
            : isUnset
              ? `<unset> (${defaultThresholdWhenUnset})`
              : undefined
        }
      />
    </div>
  )
}

const ActionButtons: FC<{
  isSaving: boolean
  isResetting: boolean
  onSave: () => void
  onReset: () => void
  onCancel: () => void
}> = ({ isSaving, isResetting, onSave, onReset, onCancel }) => {
  const { t } = useTranslation()

  return (
    <div className="d-flex justify-content-between mt-5">
      <IxButton onClick={onReset} loading={isSaving || isResetting} disabled={isSaving || isResetting}>
        {t('Reset to default')}
      </IxButton>

      <div>
        <IxButton className="me-2" onClick={onCancel} outline>
          {t('Cancel')}
        </IxButton>

        <IxButton onClick={onSave} loading={isSaving || isResetting} disabled={isSaving || isResetting}>
          {t('Save thresholds')}
        </IxButton>
      </div>
    </div>
  )
}
