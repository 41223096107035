import { JSX } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'

import { IxTile } from '@siemens/ix-react'

import { GlobalPageHeader } from '@/core/components/GlobalPageHeader'
import { appTitle } from '@/core/lib/config'
import { homeRoute } from '@/core/routes'

import { Dashboard } from '@/features/alarms/components/Dashboard'

export function HomePage(): JSX.Element {
  const { isAuthenticated } = useAuth()
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>
          {appTitle} - {t(`routes.${homeRoute.name}`)}
        </title>
      </Helmet>

      <GlobalPageHeader subTitle="Point performance" title={appTitle}>
        {isAuthenticated ? (
          <Dashboard />
        ) : (
          <IxTile>
            <div slot="header">Tile header</div>
            <div className="text-l">Lorem Ipsum</div>
          </IxTile>
        )}
      </GlobalPageHeader>
    </>
  )
}
