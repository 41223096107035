import { FC, forwardRef, PropsWithChildren, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalInstance } from '@siemens/ix'
import { IxButton, Modal as IxModal, IxModalFooter, IxModalHeader, ModalRef, showModal } from '@siemens/ix-react'

import { usePrevious } from '@/core/hooks/use-previous'

import * as S from './styles'

export interface ModalContentProps {
  cancelButton?: ReactNode
  okButton?: ReactNode
  onCancel?: () => void | Promise<void>
  onOk?: () => void | Promise<void>
  title?: ReactNode
  /**
   * Shrinks the scrollable content height by 3.5rem what creates space for individual footer buttons
   * (used in combination with ModalInlineFooter)
   */
  inlineFooter?: boolean
}

export const ModalContent: FC<PropsWithChildren<ModalContentProps>> = ({
  children,
  onCancel,
  onOk,
  title,
  ...props
}) => {
  const modalRef = useRef<ModalRef>(null)
  const { t } = useTranslation()

  const _onOk = useCallback(async () => {
    await onOk?.()
    modalRef.current?.close('close payload!')
  }, [onOk])

  const _onCancel = useCallback(async () => {
    await onCancel?.()
    modalRef.current?.dismiss('dismiss payload')
  }, [onCancel])

  const cancelButton = useMemo(() => {
    if (props.cancelButton === null) {
      return null
    }

    if (props.cancelButton == null) {
      return (
        <IxButton outline onClick={_onCancel}>
          {t('Cancel')}
        </IxButton>
      )
    }

    return <div onClick={_onCancel}>{cancelButton}</div>
  }, [_onCancel, props.cancelButton, t])

  const okButton = useMemo(() => {
    if (props.okButton === null) {
      return null
    }

    if (props.okButton == null) {
      return <IxButton onClick={_onOk}>{t('Ok')}</IxButton>
    }

    return <div onClick={_onOk}>{okButton}</div>
  }, [_onOk, props.okButton, t])

  return (
    <IxModal ref={modalRef}>
      <IxModalHeader onCloseClick={_onCancel}>{title}</IxModalHeader>

      {props.inlineFooter ? (
        <S.ModalContentWithFooter>{children}</S.ModalContentWithFooter>
      ) : (
        <S.ModalContent>{children}</S.ModalContent>
      )}

      {!props.inlineFooter && (cancelButton != null || okButton != null) && (
        <IxModalFooter>
          {cancelButton}

          {okButton}
        </IxModalFooter>
      )}
    </IxModal>
  )
}

export interface ModalProps {
  title: string
  visible: boolean
}

/**
 * IxModal
 * @param children Attention: A portal is used to display the modal. Be aware, that you can not pass state or
 * prop updates from outside the portal to the modal content. The whole state management needs to be defined inside the modal content!
 * @param title
 * @param visible
 * @constructor
 */
export const Modal = forwardRef<ModalInstance, PropsWithChildren<ModalProps>>(
  ({ children, title, visible }, forwardedRef) => {
    const isInit = useRef(false)
    const prevVisible = usePrevious(visible)
    const defaultModalRef = useRef<ModalInstance>()
    const ref = forwardedRef ?? defaultModalRef

    useEffect(() => {
      if (visible && (!prevVisible || !isInit.current)) {
        requestAnimationFrame(() => {
          showModal({
            animation: false,
            content: children,
            keyboard: false,
            size: '720',
            title,
            // @ts-ignore
          }).then((element) => (ref.current = element))
        })
      }

      isInit.current = true
    }, [children, prevVisible, ref, title, visible])

    useEffect(() => {
      if (prevVisible && !visible) {
        setTimeout(() => {
          // @ts-ignore
          ref.current?.htmlElement.remove()
        })
      }
    }, [prevVisible, ref, visible])

    return null
  },
)
