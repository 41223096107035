import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  html,
  body {
    min-height: 100vh;
    min-width: 100vw;
    height: 100%;
    width: 100%;
  }

  #root {
    height: 100%;
    width: 100%;
  }

  .sc-ix-validation-tooltip-h {
    display: block;
  }

  .ag-header-cell.text-center {
      .ag-header-cell-label {
          justify-content: center;
      }
  }

  .icon-btn-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  ix-tile {
    height: auto;
    max-height: none;
    max-width: none;
    padding-bottom: 1rem;
    width: 100%;
  }

  .modal-body {
    & > ix-spinner {
      padding-top: 78px;
    }
  }

  .row > [class*="col-"] {
    display: flex;
  }

  [class*="ag-theme-ix"] .ag-input-wrapper input {
    max-height: none;
    max-width: none;
  }
  
  ix-workflow-steps {
    text-align: center;
    margin: auto;
  }

  ix-action-card.create-asset-card {
      width: 40%;
  }
  
  .popover-content {
    background-color: var(--theme-color-3);
    padding: 1rem;
    border-radius: 0.5rem;
    max-width: 240px;
    &:focus-visible {
      outline: none;
    }
    a {
      &:focus-visible {
          outline: none;
      }
    }
    .popover-arrow {
      fill: var(--theme-color-3);
    }
  }

  .info-popover-icon {
      cursor: pointer;
      margin-left: 0.25rem;
      color: var(--theme-color-soft-text);
      opacity: .5;
  }
  
`
