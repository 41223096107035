import { Key, mutate } from 'swr'
import useSWRMutation, { SWRMutationResponse } from 'swr/mutation'

import { useSWR, type SWRResponse } from '@/core/hooks/swr'
import type { Direction, IdType } from '@/core/models/base'
import type { ServiceError } from '@/core/models/service-error'

import { ThresholdApiClient } from '@/features/config/threshold/parameter/meanpower/api/threshold'
import type { ThresholdValues } from '@/features/config/threshold/parameter/meanpower/components/lib'
import type { Threshold } from '@/features/config/threshold/parameter/meanpower/models/threshold'
import type { PointMachine } from '@/features/assets/models/point-machine'

const THRESHOLDS_BASE_KEY = 'useThresholds'

export const getThresholdsKey = (parentIds: Parameters<typeof useThresholds>[0]): Key => {
  if (parentIds == null) {
    return null
  }

  return [THRESHOLDS_BASE_KEY, ...parentIds]
}

export const useThresholds = (parentIds?: IdType[] | null): SWRResponse<Threshold[]> => {
  return useSWR(getThresholdsKey(parentIds), async () => {
    const responses = await Promise.all(parentIds!.map((id) => ThresholdApiClient.list(id)))
    const allEntries = responses.flatMap((entry) => entry.data)
    return { data: allEntries, dateTime: responses[0].dateTime }
  })
}

interface UpdateThresholdsOpts {
  pointMachines: PointMachine[]
  thresholds: Threshold[]
  values: ThresholdValues
}

export const useUpdateThresholds = (): SWRMutationResponse<Threshold[], ServiceError, UpdateThresholdsOpts, Key> => {
  return useSWRMutation('useUpdateThreshold', async (_, { arg }) => {
    const { pointMachines, thresholds, values } = arg

    const promises = (['left', 'right'] as Direction[])
      .filter(
        (direction) =>
          values.power[direction] != null || values.time[direction] != null || values.work[direction] != null,
      )
      .flatMap((direction) => {
        return pointMachines.flatMap((pm) => {
          const thresholdsToUpdate = thresholds.filter(
            (threshold) => threshold.pointMachineId === pm.id && threshold.direction === direction,
          )

          if (thresholdsToUpdate.length === 0) {
            return ThresholdApiClient.create(
              {
                direction,
                meanPower: values.power[direction]!,
                pointMachineId: pm.id,
                throwingTime: values.time[direction]!,
                work: values.work[direction]!,
              },
              pm.id,
            )
          } else {
            return thresholdsToUpdate.flatMap((threshold) =>
              ThresholdApiClient.update({
                ...threshold,
                meanPower: values.power[threshold.direction]!,
                throwingTime: values.time[threshold.direction]!,
                work: values.work[threshold.direction]!,
              }),
            )
          }
        })
      })

    const results = await Promise.all(promises)
    const returnedThresholds = results.map((result) => result.data)

    //refresh all thresholds, no matter with which point machine id combination it was fetched
    await mutate((key) => Array.isArray(key) && key.at(0) === THRESHOLDS_BASE_KEY)

    return returnedThresholds
  })
}

interface ResetThresholdsOpts {
  pointMachines: PointMachine[]
}

export const useResetThresholds = (): SWRMutationResponse<Threshold[], ServiceError, ResetThresholdsOpts, Key> => {
  const { trigger } = useUpdateThresholds()

  return useSWRMutation('useResetThresholds', async (_, { arg }) => {
    const thresholdResponses = await Promise.all(arg.pointMachines.map((pm) => ThresholdApiClient.list(pm.id)))
    const thresholds = thresholdResponses.flatMap((entry) => entry.data)

    const updateResponse = await trigger({
      pointMachines: arg.pointMachines,
      thresholds,
      values: {
        power: { left: 300, right: 300 },
        time: { left: 0.5, right: 0.5 },
        work: { left: 500, right: 500 },
      },
    })

    return updateResponse ?? []
  })
}
